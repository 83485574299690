import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import {
  Row,
  Col,
  MDBInput,
  Button,
  MDBRow,
  MDBTooltip,
  MDBSwitch,
  MDBCol,
} from 'mdbreact';
import messages from '../messages';
import PixelCodeSelector from '../PixelCodeSelector';
import LeadSelector from '../LeadSelector';
import ImageInput from '../../ImageInput';

class Step2 extends Component {
  render() {
    const { formatMessage } = this.props.intl;
    const {
      quizData,
      handleNextPrevClick,
      handleAnswerChange,
      handleResultSwitchChange,
      changeNumber,
      setResultImg,
      pixelCode,
      leadSelect,
    } = this.props;

    const buttonEnabled =
      quizData &&
      quizData.results.filter(
        (r) => r.title.length === 0 || r.desc.length === 0
      ).length === 0;

    const resultSize = 255;

    const titleLength =
      quizData.results.filter((r) => r.title.length === 0).length === 0;

    const descLength =
      quizData &&
      quizData.results.filter((r) => r.desc.length === 0).length === 0;

    return (
      <Col md="12">
        <Row>
          <Col sm="12">
            {quizData.results.map((result, i) => (
              <Row
                key={i}
                className="form-inline collapse-media"
                style={{ background: '#E8E8E8', padding: '10px' }}
              >
                <div className="collapse-media__item col-5">
                  <ImageInput
                    title={formatMessage(messages.AddImage)}
                    setSRC={(s, t) => {
                      setResultImg(i, s);
                    }}
                    src={result.background}
                    answer
                  />
                </div>
                <div className="col-7">
                  <MDBInput
                    type="textarea"
                    className="p-0 w-100"
                    name="title"
                    label={`${formatMessage(messages.Result)} -${
                      resultSize - result.title.length
                    }`}
                    maxLength={resultSize}
                    value={result.title}
                    onChange={(e) => handleAnswerChange(e, i)}
                  />
                  <MDBInput
                    type="textarea"
                    className="p-0 w-100"
                    name="desc"
                    label={formatMessage(messages.ResultDesc)}
                    value={result.desc}
                    onChange={(e) => handleAnswerChange(e, i)}
                  />
                  <MDBInput
                    className={`form-control w-100 
                    ${
                      !result.dont_shorten &&
                      result.url &&
                      result.url.length > 0 &&
                      !result.url.startsWith('http')
                        ? 'is-invalid'
                        : ''
                    }
                    `}
                    name="url"
                    label={formatMessage(messages.Url)}
                    value={result.url}
                    onChange={(e) => handleAnswerChange(e, i)}
                    type="textarea"
                  />
                  <MDBInput
                    className="form-control w-100"
                    name="url_text"
                    label={formatMessage(messages.UrlText)}
                    value={result.url_text}
                    onChange={(e) => handleAnswerChange(e, i)}
                    type="textarea"
                  />
                  <MDBRow>
                    <MDBCol md="6">
                      <label>{formatMessage(messages.DontShortenLink)}</label>
                    </MDBCol>
                    <MDBCol md="6">
                      <MDBTooltip placement="top" domElement>
                        <MDBSwitch
                          checked={result.dont_shorten === 1 ? true : false}
                          onChange={(e) => handleResultSwitchChange(e, i)}
                        />
                        <div>{formatMessage(messages.DontShortenDesc)}</div>
                      </MDBTooltip>
                    </MDBCol>
                  </MDBRow>
                  <div>
                    <PixelCodeSelector
                      index={i}
                      pixelCode={pixelCode}
                      id={result.pixel_code_id}
                    />
                  </div>
                  <div>
                    <LeadSelector
                      index={i}
                      leadSelect={leadSelect}
                      id={result.lead_id}
                    />
                  </div>
                </div>
              </Row>
            ))}
            <Button color="secondary" onClick={(e) => changeNumber(e, 'add')}>
              {formatMessage(messages.AddResult)}
            </Button>
            {quizData.results.length > 2 && (
              <Button
                color="secondary"
                onClick={(e) => changeNumber(e, 'delete')}
              >
                {formatMessage(messages.DeleteResult)}
              </Button>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <button
              type="button"
              className="btn btn-indigo btn-rounded Ripple-parent float-left"
              onClick={handleNextPrevClick(1)}
            >
              {formatMessage(messages.Prev)}
            </button>
            <button
              type="button"
              className={`btn btn-indigo btn-rounded Ripple-parent float-right ${
                buttonEnabled ? '' : 'disabled'
              }`}
              disabled={!buttonEnabled}
              onClick={handleNextPrevClick(3)}
            >
              {`${formatMessage(messages.Next)} (${formatMessage(
                messages.Questions
              )})`}
            </button>
          </Col>
        </Row>

        <Row style={{ marginRight: '15px' }}>
          <Col>
            <div className={'float-right text-danger'}>
              {!titleLength
                ? `${formatMessage(messages.PleaseEnterResult)}`
                : null}
            </div>
          </Col>
        </Row>
        <Row style={{ marginRight: '15px' }}>
          <Col>
            <div className={'float-right text-danger'}>
              {!descLength
                ? `${formatMessage(messages.PleaseEnterResultDesc)}`
                : null}
            </div>
          </Col>
        </Row>
      </Col>
    );
  }
}

export default injectIntl(Step2);
