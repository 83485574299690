import { defineMessages } from 'react-intl.macro';

export default defineMessages({
  VerifyMessage: {
    id: 'ForgotPassword.VerifyMessage',
    defaultMessage: 'Your password has been sent to your email address!',
  },
  Login: {
    id: 'ForgotPassword.Login',
    defaultMessage: 'Login',
  },
  ErrorOccured: {
    id: 'ForgotPassword.ErrorOccured',
    defaultMessage:
      'Error occured while processing your request. Please try again.',
  },
});
