import React from 'react';
import { Row, Col, Spinner } from 'mdbreact';
import { Chart } from 'react-google-charts';
import SectionWithTitle from '../Common/SectionWithTitle';

const VoteStats = ({
  messages,
  formatMessage,
  dataDaily,
  dataWeekly,
  dataMonthly,
  dataYearly,
}) => (
  <SectionWithTitle title={formatMessage(messages.VoteDetails)}>
    <Row style={{ marginTop: '50px' }}>
      <Col md="6" style={{ minHeight: '300px' }}>
        {dataDaily && dataDaily.length > 1 ? (
          <Chart
            width={'100%'}
            height={'400px'}
            chartType="Bar"
            loader={<Spinner blue big />}
            data={dataDaily}
            options={{
              chart: {
                title: formatMessage(messages.Daily),
              },
            }}
            rootProps={{ 'data-testid': '1' }}
          />
        ) : (
          formatMessage(messages.NoData)
        )}
      </Col>
      <Col md="6" style={{ minHeight: '300px' }}>
        {dataWeekly && dataWeekly.length > 1 ? (
          <Chart
            width={'100%'}
            height={'400px'}
            chartType="Bar"
            loader={<Spinner blue big />}
            data={dataWeekly}
            options={{
              chart: {
                title: formatMessage(messages.Weekly),
              },
            }}
            rootProps={{ 'data-testid': '2' }}
          />
        ) : (
          formatMessage(messages.NoData)
        )}
      </Col>
    </Row>
    <Row style={{ marginTop: '50px' }}>
      <Col md="6" style={{ minHeight: '300px' }}>
        {dataMonthly && dataMonthly.length > 1 ? (
          <Chart
            width={'100%'}
            height={'400px'}
            chartType="Bar"
            loader={<Spinner blue big />}
            data={dataMonthly}
            options={{
              chart: {
                title: formatMessage(messages.Monthly),
              },
            }}
            rootProps={{ 'data-testid': '3' }}
          />
        ) : (
          formatMessage(messages.NoData)
        )}
      </Col>
      <Col md="6" style={{ minHeight: '300px' }}>
        {dataYearly && dataYearly.length > 1 ? (
          <Chart
            width={'100%'}
            height={'400px'}
            chartType="Bar"
            loader={<Spinner blue big />}
            data={dataYearly}
            options={{
              chart: {
                title: formatMessage(messages.Yearly),
              },
            }}
            formatters={[
              {
                type: 'NumberFormat',
                column: 0,
                options: {
                  pattern: '####',
                },
              },
            ]}
            rootProps={{ 'data-testid': '4' }}
          />
        ) : (
          formatMessage(messages.NoData)
        )}
      </Col>
    </Row>
  </SectionWithTitle>
);

export default VoteStats;
