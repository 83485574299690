/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Container,
} from 'mdbreact';

import messages from './messages';

class Prompt extends Component {
  render() {
    const { modal, toggleModal, onYesClick } = this.props;
    const { formatMessage } = this.props.intl;

    return (
      <Container height={300}>
        <Modal isOpen={modal} toggle={toggleModal} centered height={300}>
          <ModalBody>
            <Container>
              <Row>{formatMessage(messages.WouldYouSave)}</Row>
            </Container>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleModal}>
              {formatMessage(messages.No)}
            </Button>
            <Button color="secondary" onClick={onYesClick}>
              {formatMessage(messages.Yes)}
            </Button>
          </ModalFooter>
        </Modal>
      </Container>
    );
  }
}

export default injectIntl(Prompt);
