import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavbarToggler,
  MDBCollapse,
  MDBNavItem,
  MDBNavLink,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBChip,
  Fa,
  MDBSpinner,
  MDBIcon,
} from 'mdbreact';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import ReactTooltip from 'react-tooltip';
import { BrowserRouter as Router } from 'react-router-dom';
import history from '../../core/history';
import { logout, getUnreadNotifCount, getNotifs, getProfile } from '../../api';
import logo from '../../images/poltio-logo.svg';
import en_flag from '../../images/us.png';
import tr_flag from '../../images/tr.png';
import { feedAvatarPathRetina } from '../../core/constants';
import { getUser } from '../../core/helper';

import messages from './messages';

dayjs.extend(relativeTime);

class Navigation extends Component {
  state = {
    collapse: false,
    unreadCount: '0',
    loading: false,
    notifications: [],
    lang: null,
  };

  async componentDidMount() {
    try {
      let lang = window.localStorage.getItem('lang');
      if (!lang) {
        lang = this.props.intl.locale.includes('tr') ? 'tr' : 'en';
      }
      const user = getUser();
      if (user && user.username) {
        const responseUser = await getProfile(user.username);
        window.localStorage.setItem(
          'user',
          JSON.stringify(responseUser.data.user)
        );
      }
      const response = await getUnreadNotifCount();
      this.setState({ unreadCount: response.data.unread_count, lang });
    } catch (e) {
      console.log(e);
    }
  }

  onClick = () => {
    this.setState({
      collapse: !this.state.collapse,
    });
  };

  logout = () => {
    logout();
  };

  getNotifications = async () => {
    if (this.state.notifications.length === 0) {
      this.setState({ loading: true });
      try {
        const response = await getNotifs();
        this.setState({ notifications: response.data, loading: false });
      } catch (e) {
        console.log(e);
      }
    }
  };

  changeLang = (val) => {
    window.localStorage.setItem('lang', val);
    window.location.reload();
  };

  render() {
    const {
      location: { pathname },
      user,
    } = this.props;
    const { formatMessage } = this.props.intl;
    const { unreadCount, notifications, loading, lang } = this.state;

    return (
      <Router>
        <header>
          <MDBNavbar color="indigo" dark expand="md" scrolling fixed="top">
            <MDBNavbarBrand
              style={{ cursor: 'pointer' }}
              to="/"
              onClick={() => {
                history.push('/');
                this.setState({ collapse: false });
              }}
            >
              <img src={logo} alt="" />
            </MDBNavbarBrand>
            <MDBNavbarToggler onClick={this.onClick} />
            <MDBCollapse isOpen={this.state.collapse} navbar>
              <ReactTooltip effect="solid" multiline={true} id="navbar" />
              <MDBNavbarNav left>
                <MDBNavItem active={pathname === '/'}>
                  <MDBNavLink
                    to="/"
                    onClick={() => {
                      history.push('/');
                      this.setState({ collapse: false });
                    }}
                  >
                    <span
                      data-tip={formatMessage(messages.HomeTip)}
                      data-place="bottom"
                      data-for="navbar"
                    >
                      {formatMessage(messages.Home)}
                    </span>
                  </MDBNavLink>
                </MDBNavItem>
                <MDBNavItem active={pathname === '/content'}>
                  <MDBNavLink
                    to="/content"
                    onClick={() => {
                      history.push('/content');
                      this.setState({ collapse: false });
                    }}
                  >
                    <span
                      data-tip={formatMessage(messages.ContentTip)}
                      data-place="bottom"
                      data-for="navbar"
                    >
                      {formatMessage(messages.Content)}
                    </span>
                  </MDBNavLink>
                </MDBNavItem>
                <MDBNavItem active={pathname === '/pixelcodes'}>
                  <MDBNavLink
                    to="/pixelcodes"
                    onClick={() => {
                      history.push('/pixelcodes');
                      this.setState({ collapse: false });
                    }}
                  >
                    <span
                      data-tip={formatMessage(messages.PixelCodesTip)}
                      data-place="bottom"
                      data-for="navbar"
                    >
                      {formatMessage(messages.PixelCodes)}
                    </span>
                  </MDBNavLink>
                </MDBNavItem>
                <MDBNavItem active={pathname === '/leads'}>
                  <MDBNavLink
                    to="/leads"
                    onClick={() => {
                      history.push('/leads');
                      this.setState({ collapse: false });
                    }}
                  >
                    <span
                      data-tip={formatMessage(messages.LeadsTip)}
                      data-place="bottom"
                      data-for="navbar"
                    >
                      {formatMessage(messages.Leads)}
                    </span>
                  </MDBNavLink>
                </MDBNavItem>
                <MDBNavItem active={pathname === '/webhooks'}>
                  <MDBNavLink
                    to="/webhooks"
                    onClick={() => {
                      history.push('/webhooks');
                      this.setState({ collapse: false });
                    }}
                  >
                    <span
                      data-tip={formatMessage(messages.WebhooksTip)}
                      data-place="bottom"
                      data-for="navbar"
                    >
                      {formatMessage(messages.Webhooks)}
                    </span>
                  </MDBNavLink>
                </MDBNavItem>
                <MDBNavItem active={pathname === '/sheethooks'}>
                  <MDBNavLink
                    to="/sheethooks"
                    onClick={() => {
                      history.push('/sheethooks');
                      this.setState({ collapse: false });
                    }}
                  >
                    <span
                      data-tip={formatMessage(messages.SheethooksTip)}
                      data-place="bottom"
                      data-for="navbar"
                    >
                      {formatMessage(messages.Sheethooks)}
                    </span>
                  </MDBNavLink>
                </MDBNavItem>
                <MDBNavItem active={pathname === '/theme'}>
                  <MDBNavLink
                    to="/theme"
                    onClick={() => {
                      history.push('/theme');
                      this.setState({ collapse: false });
                    }}
                  >
                    <span
                      data-tip={formatMessage(messages.ThemeTip)}
                      data-place="bottom"
                      data-for="navbar"
                    >
                      {formatMessage(messages.Theme)}
                    </span>
                  </MDBNavLink>
                </MDBNavItem>
                {/* <MDBNavItem active={pathname === '/users'}>
                <MDBNavLink
                  to="/users"
                  onClick={() => {
                    history.push('/users');
                    this.setState({ collapse: false });
                  }}
                >
                  {formatMessage(messages.Users)}
                </MDBNavLink>
              </MDBNavItem> */}
                <MDBNavItem active={pathname === '/reports'}>
                  <MDBNavLink
                    to="/reports"
                    onClick={() => {
                      history.push('/reports');
                      this.setState({ collapse: false });
                    }}
                  >
                    <span
                      data-tip={formatMessage(messages.ReportsTip)}
                      data-place="bottom"
                      data-for="navbar"
                    >
                      {formatMessage(messages.Reports)}
                    </span>
                  </MDBNavLink>
                </MDBNavItem>
                <MDBNavItem active={pathname === '/trivia'}>
                  <MDBNavLink
                    to="/trivia"
                    onClick={() => {
                      history.push('/trivia');
                      this.setState({ collapse: false });
                    }}
                  >
                    <span
                      data-tip={formatMessage(messages.TriviaTip)}
                      data-place="bottom"
                      data-for="navbar"
                    >
                      {formatMessage(messages.Trivia)}
                    </span>
                  </MDBNavLink>
                </MDBNavItem>
              </MDBNavbarNav>
              <MDBNavbarNav right>
                <MDBNavItem
                  style={{
                    height: '40px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <MDBDropdown>
                    <MDBDropdownToggle nav>
                      {lang ? lang.toUpperCase() : ''}{' '}
                      <MDBIcon icon="caret-down" />
                    </MDBDropdownToggle>
                    <MDBDropdownMenu>
                      <MDBDropdownItem
                        href=""
                        onClick={() => {
                          this.changeLang('en');
                        }}
                        style={{
                          alignItems: 'center',
                          borderRadius: 10,
                        }}
                      >
                        <img src={en_flag} alt="en" />
                        {'EN'}
                      </MDBDropdownItem>
                      <MDBDropdownItem
                        href=""
                        onClick={() => {
                          this.changeLang('tr');
                        }}
                        style={{
                          alignItems: 'center',
                          borderRadius: 10,
                        }}
                      >
                        <img src={tr_flag} alt="tr" />
                        {'TR'}
                      </MDBDropdownItem>
                    </MDBDropdownMenu>
                  </MDBDropdown>
                </MDBNavItem>
                <MDBNavItem>
                  <MDBDropdown>
                    <MDBDropdownToggle nav onClick={this.getNotifications}>
                      {unreadCount === '0' ? (
                        <Fa icon="bell" />
                      ) : (
                        <Fa icon="bell" style={{ color: 'red' }} />
                      )}
                    </MDBDropdownToggle>
                    <MDBDropdownMenu>
                      {loading ? (
                        <MDBSpinner blue small />
                      ) : notifications.length === 0 ? (
                        <MDBDropdownItem>
                          {formatMessage(messages.NoNotifications)}
                        </MDBDropdownItem>
                      ) : (
                        notifications.map(
                          (n, i) =>
                            i < 5 && (
                              <MDBDropdownItem key={i}>
                                <img
                                  alt=""
                                  src={
                                    n.pic.length > 0
                                      ? `${feedAvatarPathRetina}/${n.pic}`
                                      : `${feedAvatarPathRetina}/placeholder/profile.jpg`
                                  }
                                />
                                <p>
                                  {n.msg}
                                  <span className="date">
                                    {dayjs()
                                      .to(
                                        dayjs(n.created_at.replace(/ /g, 'T'))
                                      )
                                      .toString()}
                                  </span>
                                </p>
                              </MDBDropdownItem>
                            )
                        )
                      )}
                    </MDBDropdownMenu>
                  </MDBDropdown>
                </MDBNavItem>
                <MDBNavItem
                  style={{
                    height: '40px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <MDBDropdown>
                    <MDBDropdownToggle nav>
                      <MDBChip
                        size="sm"
                        src={`${feedAvatarPathRetina}/${user.photo}`}
                        alt=""
                        bgColor="indigo"
                        text="white"
                        waves
                        style={{ marginBottom: '0' }}
                      >
                        {user.username}
                      </MDBChip>
                    </MDBDropdownToggle>
                    <MDBDropdownMenu>
                      <MDBDropdownItem
                        href=""
                        onClick={() => {
                          history.push('/settings');
                          this.setState({ collapse: false });
                        }}
                      >
                        {formatMessage(messages.Settings)}
                      </MDBDropdownItem>
                      <MDBDropdownItem href="" onClick={this.logout}>
                        {formatMessage(messages.Logout)}
                      </MDBDropdownItem>
                    </MDBDropdownMenu>
                  </MDBDropdown>
                </MDBNavItem>
              </MDBNavbarNav>
            </MDBCollapse>
          </MDBNavbar>
        </header>
      </Router>
    );
  }
}

export default injectIntl(Navigation);
