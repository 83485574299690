import React from 'react';
import history from '../../../core/history';
import { getUser } from '../../../core/helper';

const NewContentButton = () => {
  const user = getUser();

  return user?.trivia_credit > 0 ? (
    <div
      className="fixed-action-btn active"
      style={{ bottom: '45px', right: '24px' }}
    >
      <a
        href="#"
        className="btn-floating red btn-lg Ripple-parent"
        onClick={(e) => {
          e.preventDefault();
          history.push('/content/set');
        }}
      >
        <i className="fa fa-pencil-alt" />
        <div
          className="Ripple "
          style={{
            top: '0px',
            left: '0px',
            width: '0px',
            height: '0px',
          }}
        />
      </a>
    </div>
  ) : null;
};

export default NewContentButton;
