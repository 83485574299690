import React, { Fragment, useState } from 'react';
import { injectIntl } from 'react-intl';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  Input,
  toast,
  MDBSelect,
  MDBSelectInput,
  MDBSelectOptions,
  MDBSelectOption,
  Button,
  MDBSwitch,
} from 'mdbreact';
import { leadSave } from '../../api';
import history from '../../core/history';

import messages from './messages';
import ImageInput from '../ImageInput';
import SectionWithTitle from '../Common/SectionWithTitle';

const initialState = {
  name: '',
  title: '',
  msg: '',
  button_value: '',
  image: null,
  fields: '',
  type: 'input',
  redirect_url: '',
  terms_conditions: '',
  tc_optional: 1,
  tc2_optional: 1,
  terms_conditions2: '',
  codes: '',
  single_use: 0,
  youtube_id: null,
  dont_shorten: 0,
  ios_link: null,
  android_link: null,
  stop_set: 0,
  mandatory: 0,
};

const LeadsAdd = ({ intl: { formatMessage } }) => {
  const [state, setState] = useState(initialState);

  const onChange = (event, trim) => {
    const { name, value } = event.target;
    setState({ ...state, [name]: trim ? value.trim() : value });
  };

  const onFormSubmit = async (e) => {
    if (!validateForm()) return;

    try {
      await leadSave(state);
      history.push('/leads');
    } catch (e) {
      toast.warn(formatMessage(messages.ErrorSaving));
    }
  };

  const validateForm = () => {
    /*     if (type === 'redirect' && !redirect_url.startsWith('http')) {
      toast.info(formatMessage(messages.StartWithHttp));
      return false;
    } */
    if (state.name.length < 3) {
      toast.info(formatMessage(messages.ShortLeadName));
      return false;
    }
    return true;
  };

  const setSRC = (src) => setState({ ...state, image: src });

  const handleSelectChange = (val) => {
    if (val && Array.isArray(val)) {
      setState({
        ...state,
        fields: val.reduce(
          (total, field, i) =>
            (total += `${field}${i === val.length - 1 ? '' : ','}`),
          ''
        ),
      });
    }
  };

  const handleTypeChange = (val) => {
    if (val) {
      setState({
        ...state,
        type: Array.isArray(val) ? val[0] : val,
        mandatory: 0,
      });
    }
  };

  const handleSwitchChange = (e, name) => {
    if (name === 'tc_optional' || name === 'tc2_optional') {
      setState({ ...state, [name]: e.target.checked ? 0 : 1 });
    } else {
      setState({ ...state, [name]: e.target.checked ? 1 : 0 });
    }
  };

  return (
    <MDBContainer fluid>
      <SectionWithTitle title={formatMessage(messages.AddNew)}>
        <div className="grey-text">
          <MDBRow>
            <MDBCol md="6" className="mb-4">
              <div className="info-wrapper">
                <h5 className="pb-5">Basic Info</h5>
                <Input
                  label={formatMessage(messages.TypeName)}
                  group
                  type="text"
                  name="name"
                  onChange={onChange}
                  value={state.name}
                />
                <div className={`invalid-feedback d-block`}>
                  {state.name.length < 3 ? (
                    formatMessage(messages.ShortLeadName)
                  ) : (
                    <br />
                  )}
                </div>
              </div>
              <Input
                label={formatMessage(messages.Title)}
                group
                type="text"
                name="title"
                onChange={onChange}
                value={state.title}
              />
              <Input
                type="textarea"
                label={formatMessage(messages.Message)}
                group
                name="msg"
                onChange={onChange}
                value={state.msg}
              />
              <Input
                type="text"
                label={`${formatMessage(messages.ButtonText)} ${formatMessage(
                  messages.DefaultButton
                )}`}
                group
                name="button_value"
                onChange={onChange}
                value={state.button_value}
              />
            </MDBCol>

            <MDBCol md="6" className="mb-4">
              <h5 className="pb-5">{formatMessage(messages.LeadImageTitle)}</h5>
              <ImageInput
                title={formatMessage(messages.LeadImage)}
                setSRC={setSRC}
                target={'lead_image'}
              />
            </MDBCol>
          </MDBRow>

          <MDBRow>
            <MDBCol md="6" className="mb-4">
              <label>{formatMessage(messages.LeadType)}</label>
              <MDBSelect getValue={handleTypeChange}>
                <MDBSelectInput selected="input" />
                <MDBSelectOptions>
                  <MDBSelectOption value="input" selected={true}>
                    {formatMessage(messages.LeadInput)}
                  </MDBSelectOption>
                  <MDBSelectOption value="redirect">
                    {formatMessage(messages.Redirect)}
                  </MDBSelectOption>
                  <MDBSelectOption value="internal_redirect">
                    {formatMessage(messages.InternalRedirect)}
                  </MDBSelectOption>
                  <MDBSelectOption value="empty">
                    {formatMessage(messages.TextImageDisplay)}
                  </MDBSelectOption>
                </MDBSelectOptions>
              </MDBSelect>
            </MDBCol>
          </MDBRow>

          <MDBRow>
            <MDBCol md="6" className="mb-4">
              {state.type === 'input' && (
                <div>
                  <label>{formatMessage(messages.Fields)}</label>
                  <MDBSelect multiple getValue={handleSelectChange}>
                    <MDBSelectInput
                      selected={formatMessage(messages.ChooseFields)}
                    />
                    <MDBSelectOptions>
                      <MDBSelectOption value="gsm">Gsm</MDBSelectOption>
                      <MDBSelectOption value="email">Email</MDBSelectOption>
                      <MDBSelectOption value="name">Name</MDBSelectOption>
                      <MDBSelectOption value="comment">Comment</MDBSelectOption>
                      <MDBSelectOption value="file">File</MDBSelectOption>
                    </MDBSelectOptions>
                  </MDBSelect>
                </div>
              )}
              {state.type !== 'empty' && (
                <Fragment>
                  <Input
                    type="textarea"
                    label={formatMessage(messages.RedirectUrl)}
                    group
                    name="redirect_url"
                    onChange={(e) => onChange(e, true)}
                    value={state.redirect_url}
                    hint={state.type === 'internal_redirect' ? 'poll:1234' : ''}
                  />
                  <MDBRow>
                    <MDBCol md="2">
                      <label>{formatMessage(messages.DontShortenLink)}</label>
                    </MDBCol>
                    <MDBCol md="2">
                      <MDBSwitch
                        checked={state.dont_shorten === 1 ? true : false}
                        onChange={(e) => handleSwitchChange(e, 'dont_shorten')}
                      />
                    </MDBCol>
                  </MDBRow>
                </Fragment>
              )}
            </MDBCol>
          </MDBRow>
          <MDBRow style={{ marginTop: '20px' }}>
            <MDBCol md="2">
              <label>{formatMessage(messages.TcOp)}</label>
            </MDBCol>
            <MDBCol md="3">
              <MDBSwitch
                checked={state.tc_optional === 0 ? true : false}
                onChange={(e) => handleSwitchChange(e, 'tc_optional')}
              />
            </MDBCol>
          </MDBRow>
          {!state.tc_optional ? (
            <Fragment>
              <Input
                type="textarea"
                label={`${formatMessage(messages.Terms)} ( ${formatMessage(
                  messages.SupportsMarkdown
                )} )`}
                group
                name="terms_conditions"
                onChange={onChange}
                value={state.terms_conditions || ''}
              />
              <MDBRow style={{ marginTop: '20px' }}>
                <MDBCol md="2">
                  <label>{formatMessage(messages.TcOp2)}</label>
                </MDBCol>
                <MDBCol md="3">
                  <MDBSwitch
                    checked={state.tc2_optional === 0 ? true : false}
                    onChange={(e) => handleSwitchChange(e, 'tc2_optional')}
                  />
                </MDBCol>
              </MDBRow>
              {!state.tc2_optional ? (
                <Input
                  type="textarea"
                  label={`${formatMessage(
                    messages.SecondTerms
                  )} ( ${formatMessage(messages.SupportsMarkdown)} )`}
                  group
                  name="terms_conditions2"
                  onChange={onChange}
                  value={state.terms_conditions2 || ''}
                />
              ) : null}
            </Fragment>
          ) : null}
          <Input
            type="textarea"
            label={formatMessage(messages.Codes)}
            group
            name="codes"
            onChange={onChange}
            value={state.codes}
          />
          <MDBRow>
            <MDBCol md="2">
              <label>{formatMessage(messages.SingleUse)}</label>
            </MDBCol>
            <MDBCol md="3">
              <MDBSwitch
                checked={state.single_use === 1 ? true : false}
                onChange={(e) => handleSwitchChange(e, 'single_use')}
              />
            </MDBCol>
          </MDBRow>
          <Input
            type="text"
            label={'Youtube ID'}
            name="youtube_id"
            onChange={onChange}
            value={state.youtube_id || ''}
          />
          {state.type === 'redirect' ? (
            <Fragment>
              <Input
                type="textarea"
                label={'iOS Link'}
                name="ios_link"
                onChange={onChange}
                value={state.ios_link || ''}
              />
              <Input
                type="textarea"
                label={'Android Link'}
                name="android_link"
                onChange={onChange}
                value={state.android_link || ''}
              />
            </Fragment>
          ) : null}
          <MDBRow>
            <MDBCol md="2">
              <label>{formatMessage(messages.StopSet)}</label>
            </MDBCol>
            <MDBCol md="3">
              <MDBSwitch
                checked={state.stop_set === 1 ? true : false}
                onChange={(e) => handleSwitchChange(e, 'stop_set')}
              />
            </MDBCol>
          </MDBRow>
          {state.type === 'input' && (
            <MDBRow>
              <MDBCol md="2">
                <label>{formatMessage(messages.Mandatory)}</label>
              </MDBCol>
              <MDBCol md="3">
                <MDBSwitch
                  checked={state.mandatory === 1 ? true : false}
                  onChange={(e) => handleSwitchChange(e, 'mandatory')}
                />
              </MDBCol>
            </MDBRow>
          )}
        </div>
        <div className="text-center">
          <Button color="primary" type="submit" onClick={onFormSubmit}>
            {formatMessage(messages.Save)}
          </Button>
        </div>
      </SectionWithTitle>
    </MDBContainer>
  );
};

export default injectIntl(LeadsAdd);
