import React from 'react';
import { MDBRow, MDBContainer, MDBMask, MDBView } from 'mdbreact';
import Footer from '../Footer';
import '../style.css';

const ErrorPage = () => {
  return (
    <div id="loginpage">
      <MDBView>
        <MDBMask
          className="d-flex justify-content-center align-items-center"
          overlay="indigo-strong"
        >
          <MDBContainer>
            <MDBRow>
              <div className="white-text text-center text-md-center col-md-12 mt-xl-5 mb-5">
                <h1 className="display-4 font-weight-bold">Poltio PRO </h1>
                <hr className="hr-light" />
                <h6 className="mb-4">
                  We can not reach the server at the moment. Please try again
                  later.
                </h6>
              </div>
            </MDBRow>
          </MDBContainer>
        </MDBMask>
      </MDBView>
      <Footer />
    </div>
  );
};

export default ErrorPage;
