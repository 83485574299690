import React, { useState, useEffect, useCallback } from 'react';
import Sockette from 'sockette';
import {
  toast,
  MDBSpinner,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardBody,
  MDBCol,
} from 'mdbreact';
import { injectIntl } from 'react-intl';
import {
  getTriviaInfo,
  getTriviaState,
  triviaAction,
  getTopPlayers,
  setRedirectUrl,
} from '../../../api';

import messages from '../messages';
import Actions from './Actions';
import Info from './Info';
import Questions from './Questions';

const Manager = ({
  history,
  match: {
    params: { id },
  },
  intl: { formatMessage },
}) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [triviaState, setTriviaState] = useState({});
  const [socketData, setSocketData] = useState({});
  const [topPlayers, setTopPlayers] = useState([]);
  const [topPlayersFetched, setTopPlayersFetched] = useState(false);
  const [url, setUrl] = useState('');
  const [redirectUrlMessage, setMessage] = useState(null);

  const getData = useCallback(async () => {
    try {
      const infoResponse = await getTriviaInfo(id);
      const stateResponse = await getTriviaState(id);
      setData(infoResponse.data);
      setTriviaState(stateResponse.data);
      setLoading(false);
    } catch (e) {
      if (e && e.response && e.response.status === 404) {
        toast.error('Trivia is not active. Enter an active trivia id.');
      } else {
        toast.error('Error occured!');
      }
      history.push('/manage-trivia');
    }
  }, [history, id]);

  const connectWS = useCallback(() => {
    const ws = new Sockette(`wss://live.poltio.com/trivia-status/${id}`, {
      timeout: 5e3,
      maxAttempts: 10,
      onopen: (e) => console.log('Connected!', e),
      onmessage: (e) => {
        if (e.data !== 'ping' || e.data !== 'PING') {
          const message = JSON.parse(e.data);
          setSocketData(message);
        }
      },
      onreconnect: (e) => console.log('Reconnecting...', e),
      onmaximum: (e) => console.log('Stop Attempting!', e),
      onclose: (e) => console.log('Closed!', e),
      onerror: (e) => console.log('Error:', e),
    });

    setInterval(function () {
      ws.send('ping');
    }, 1000);
  }, [id]);

  useEffect(() => {
    getData();
    connectWS();
  }, [getData, connectWS]);

  const checkNewData = useCallback(async () => {
    if (data.questions && data.questions.length === 0) {
      if (
        socketData.status === 'active' ||
        socketData.seconds_to_start_date < 100
      ) {
        try {
          const response = await getTriviaInfo(id);
          setData(response.data);
        } catch (e) {
          window.location.reload();
        }
      }
    }

    if (!topPlayersFetched) {
      if (
        socketData.current_question === socketData.total_questions &&
        socketData.timer_remaining === 0 &&
        topPlayers &&
        topPlayers.length === 0
      ) {
        const response = await getTopPlayers(id);
        setTopPlayers(response.data);
        setTopPlayersFetched(true);
      }
    }
  }, [data.questions, id, socketData, topPlayers, topPlayersFetched]);

  useEffect(() => {
    checkNewData();
  }, [checkNewData]);

  const performTriviaAction = async (e, action) => {
    try {
      if (socketData.timer_remaining > 0) {
        if (!window.confirm(formatMessage(messages.TimerActive))) {
          return;
        }
      }
      if (action === 'reset') {
        if (!window.confirm(formatMessage(messages.ResetConfirm))) {
          return;
        }
      }
      const response = await triviaAction(id, action);
      setTriviaState(response.data);
      setTopPlayersFetched(false);
      if (action === 'reset') {
        history.push('/manage-trivia');
      }
    } catch (e) {
      toast.error('Error!');
    }
  };

  const onSubmit = async (e) => {
    try {
      await setRedirectUrl(id, url);
      setMessage(`${formatMessage(messages.RedirectSet)}`);
    } catch (e) {
      setMessage(`${formatMessage(messages.Error)}`);
    }
  };

  return loading ? (
    <MDBContainer>
      <MDBRow center>
        <MDBSpinner />
      </MDBRow>
    </MDBContainer>
  ) : (
    <MDBContainer>
      <h2 className="text-center font-weight-bold pt-4 pb-5">
        <strong>{formatMessage(messages.ManageTrivia)}</strong>
      </h2>
      <MDBRow center>
        <MDBCol md="6">
          <MDBCard>
            <MDBCardBody>
              <MDBRow center>
                <Actions
                  messages={messages}
                  formatMessage={formatMessage}
                  performTriviaAction={performTriviaAction}
                  triviaState={triviaState}
                />
              </MDBRow>
              <MDBRow center style={{ marginTop: '20px' }}>
                <Info
                  messages={messages}
                  formatMessage={formatMessage}
                  triviaState={triviaState}
                  socketData={socketData}
                />
              </MDBRow>
              <MDBRow center style={{ marginTop: '20px' }}>
                <Questions
                  messages={messages}
                  formatMessage={formatMessage}
                  data={data}
                  socketData={socketData}
                  triviaState={triviaState}
                  performTriviaAction={performTriviaAction}
                  topPlayers={topPlayers}
                  onChange={(e) => setUrl(e.target.value)}
                  onSubmit={onSubmit}
                  redirectUrlMessage={redirectUrlMessage}
                />
              </MDBRow>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default injectIntl(Manager);
