/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, Fragment } from 'react';
import { Router, Route, Redirect, Switch } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import history from '../core/history';
import { getToken, getUser } from '../core/helper';
import { MDBContainer, ToastContainer } from 'mdbreact';

import Navigation from './NavBar';
import Home from './Home';
import Content from './Content';
import ContentCreate from './Content/ContentCreate';
import PollEdit from './Content/PollEdit';
import TestEdit from './Content/TestEdit';
import QuizEdit from './Content/QuizEdit';
import SetCreateEdit from './Content/SetCreateEdit';
import ContentDetail from './ContentDetail';
import PixelCodes from './PixelCodes';
import PixelCodesAdd from './PixelCodes/PixelCodesAdd';
import PixelCodesEdit from './PixelCodes/PixelCodesEdit';
import Leads from './Leads';
import LeadsAdd from './Leads/LeadsAdd';
import LeadsEdit from './Leads/LeadsEdit';
import IncomingLeads from './Leads/IncomingLeads';
import CouponCodes from './Leads/CouponCodes';
import LeadLogs from './Leads/LeadLogs';
import Webhooks from './Webhooks';
import WebhooksAdd from './Webhooks/WebhooksAdd';
import WebhooksEdit from './Webhooks/WebhooksEdit';
import WebhookLogs from './Webhooks/WebhookLogs';
import Sheethooks from './Sheethooks';
import SheethooksAdd from './Sheethooks/SheethooksAdd';
import SheethooksEdit from './Sheethooks/SheethooksEdit';
import SheethookLogs from './Sheethooks/SheethookLogs';
import Theme from './Theme';
import Users from './Users';
import Reports from './Reports';
import Trivia from './Trivia';
import PlayerList from './Trivia/PlayerList';
import ManageTrivia from './Trivia/Landing/ManageTrivia';
import Manager from './Trivia/Manager/Manager';
import ManageDomains from './ManageDomains';
import ManagePools from './ManagePools';
import FQSessions from './FQSessions';
import Players from './FQSessions/Players';
import Settings from './Settings';
import LoginControl from './Login';
import VerifyAccount from './Login/VerifyAccount';
import ForgotPassword from './Login/ForgotPassword/';
import ErrorPage from './Login/ErrorPage';
import Privacy from './Login/Privacy';
import TermsAndConditions from './Login/TermsAndConditions';
import TermsGG from './Login/TermsGG';
import NewContentButton from './Common/NewContentButton';

history.listen((location) => {
  window.scrollTo(0, 0);
});

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (getToken()) {
        const user = getUser();
        return <Component {...props} user={user} />;
      } else {
        return <Redirect to="/login" />;
      }
    }}
  />
);

class App extends Component {
  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      Sentry.captureException(error);
    });
  }

  render() {
    const container = { height: 700, maxWidth: 1600 };

    return (
      <Router history={history}>
        <main>
          <ToastContainer
            hideProgressBar={true}
            newestOnTop={true}
            autoClose={5000}
          />
          <Switch>
            <Route path={'/error'} component={ErrorPage} />
            <Route path={'/privacy'} exact component={Privacy} />
            <Route
              path={'/terms-and-conditions'}
              exact
              component={TermsAndConditions}
            />
            <Route path={'/terms/gg'} exact component={TermsGG} />
            <Route
              path={'/verify-account/:email/:resetCode'}
              component={VerifyAccount}
            />
            <Route
              path="/password-reset/:email/:resetCode"
              component={ForgotPassword}
            />
            <Route
              path={['/login', '/forgot-password', '/signup']}
              component={LoginControl}
            />
            <Fragment>
              <MDBContainer style={container}>
                <div className="row mt-5 pt-5">
                  <Switch>
                    <Route>
                      <Fragment>
                        <header>
                          <PrivateRoute component={Navigation} />
                        </header>
                        <div
                          className="row"
                          style={{
                            width: '100%',
                            backgroundColor: '#d93131',
                            marginBottom: '15px',
                            marginLeft: '15px',
                            marginRight: '15px',
                            color: 'white',
                            fontWeight: 'bold',
                          }}
                        >
                          <div
                            className="col-sm-1"
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              className="w-6 h-6"
                              style={{
                                width: '50px',
                                height: '50px',
                              }}
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z"
                              />
                            </svg>
                          </div>
                          <div className="col-sm-11">
                            <p className="mt-3">
                              1 Ocak 2024 tarihi itibariyle şu anda giriş
                              yaptığınız arayüzü desteklemeyi sonlandırıyoruz.
                              Trivia modülü dışındaki tüm kullanıcılarımızın
                              içerik oluşturmak için{' '}
                              <a
                                href="https://poltio.app"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                  color: 'white',
                                  textDecoration: 'underline',
                                }}
                              >
                                poltio.app
                              </a>
                              ’a geçiş yapmalarını rica ediyoruz. Trivia
                              kullanıcıları mevcut kullanımlarına devam
                              edebilirler.{' '}
                            </p>
                            <p>
                              Sorularınız için bize her zaman destek@poltio.com
                              dan ulaşabilirsiniz. Yeni deneyimimize geçişte
                              size yardımcı olmak için buradayız.
                            </p>
                          </div>
                        </div>
                        <Switch>
                          <PrivateRoute path="/settings" component={Settings} />
                          <PrivateRoute
                            path="/content-detail/:type/:id"
                            component={ContentDetail}
                          />
                          <PrivateRoute
                            path="/content/create"
                            component={ContentCreate}
                          />
                          <PrivateRoute
                            path="/content/edit/poll/:id"
                            component={PollEdit}
                          />
                          <PrivateRoute
                            path="/content/edit/test/:id"
                            component={TestEdit}
                          />
                          <PrivateRoute
                            path="/content/edit/quiz/:id"
                            component={QuizEdit}
                          />
                          <PrivateRoute
                            path="/content/set/:id?"
                            component={SetCreateEdit}
                          />
                          <Route>
                            <Fragment>
                              <Switch>
                                <PrivateRoute exact path="/" component={Home} />
                                <PrivateRoute
                                  exact
                                  path="/content"
                                  component={Content}
                                />
                                <PrivateRoute
                                  exact
                                  path="/pixelcodes"
                                  component={PixelCodes}
                                />
                                <PrivateRoute
                                  exact
                                  path="/pixelcodes/add"
                                  component={PixelCodesAdd}
                                />
                                <PrivateRoute
                                  exact
                                  path="/pixelcodes/edit"
                                  component={PixelCodesEdit}
                                />
                                <PrivateRoute
                                  exact
                                  path="/leads"
                                  component={Leads}
                                />
                                <PrivateRoute
                                  exact
                                  path="/leads/add"
                                  component={LeadsAdd}
                                />
                                <PrivateRoute
                                  exact
                                  path="/leads/edit"
                                  component={LeadsEdit}
                                />
                                <PrivateRoute
                                  exact
                                  path="/incoming-leads"
                                  component={IncomingLeads}
                                />
                                <PrivateRoute
                                  exact
                                  path="/coupon-codes"
                                  component={CouponCodes}
                                />
                                <PrivateRoute
                                  exact
                                  path="/lead-logs"
                                  component={LeadLogs}
                                />
                                <PrivateRoute
                                  exact
                                  path="/webhooks"
                                  component={Webhooks}
                                />
                                <PrivateRoute
                                  exact
                                  path="/webhooks/add"
                                  component={WebhooksAdd}
                                />
                                <PrivateRoute
                                  exact
                                  path="/webhooks/edit"
                                  component={WebhooksEdit}
                                />
                                <PrivateRoute
                                  exact
                                  path="/webhook-logs"
                                  component={WebhookLogs}
                                />
                                <PrivateRoute
                                  exact
                                  path="/sheethooks"
                                  component={Sheethooks}
                                />
                                <PrivateRoute
                                  exact
                                  path="/sheethooks/add"
                                  component={SheethooksAdd}
                                />
                                <PrivateRoute
                                  exact
                                  path="/sheethooks/edit"
                                  component={SheethooksEdit}
                                />
                                <PrivateRoute
                                  exact
                                  path="/sheethook-logs"
                                  component={SheethookLogs}
                                />
                                <PrivateRoute
                                  exact
                                  path="/theme"
                                  component={Theme}
                                />
                                <PrivateRoute path="/users" component={Users} />
                                <PrivateRoute
                                  path="/reports"
                                  component={Reports}
                                />
                                <PrivateRoute
                                  exact
                                  path="/trivia"
                                  component={Trivia}
                                />
                                <PrivateRoute
                                  path="/trivia/player-list/:id"
                                  component={PlayerList}
                                />
                                <PrivateRoute
                                  exact
                                  path="/manage-trivia"
                                  component={ManageTrivia}
                                />
                                <PrivateRoute
                                  path="/manage-trivia/:id"
                                  component={Manager}
                                />
                                <PrivateRoute
                                  exact
                                  path="/manage-domains"
                                  component={ManageDomains}
                                />
                                <PrivateRoute
                                  exact
                                  path="/manage-pools"
                                  component={ManagePools}
                                />
                                <PrivateRoute
                                  path="/fqsessions/:id"
                                  component={FQSessions}
                                />
                                <PrivateRoute
                                  path="/fqplayers/:id/:session_id"
                                  component={Players}
                                />
                              </Switch>

                              <NewContentButton />
                            </Fragment>
                          </Route>
                          <Redirect to="/" />
                        </Switch>
                      </Fragment>
                    </Route>
                  </Switch>
                </div>
              </MDBContainer>
            </Fragment>
          </Switch>
        </main>
      </Router>
    );
  }
}

export default App;
