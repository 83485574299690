import React from 'react';

const Pagination = ({ last, active, size, handlePageChange, pageSizes }) => {
  last = parseInt(last, 10);
  active = parseInt(active, 10);

  let links = [];
  let left = active - 2 <= 0 ? 1 : active - 2;
  if (left + 4 >= last) {
    left = last - 4 > 0 ? last - 4 : 1;
  }

  for (let i = left; i <= left + 4 && i <= last; i++) {
    links.push(i);
  }

  return (
    <div className="row">
      <div className="col-6 bs-select text-left">
        <label>
          <select
            className="custom-select custom-select-sm form-control form-control-sm"
            value={parseInt(size)}
            onChange={(event) => {
              handlePageChange(1, event.target.value);
            }}
          >
            {pageSizes.map((size, i) => (
              <option value={size} key={i}>
                {size}
              </option>
            ))}
          </select>
        </label>
      </div>
      <div className="col-6">
        <ul
          className="pagination justify-content-end"
          style={{ cursor: 'pointer' }}
        >
          {last > 5 && (
            <li
              className={`page-item ${links[0] === 1 ? 'disabled' : ''}`}
              onClick={(event) => {
                handlePageChange(1, size);
              }}
            >
              <div className="page-link">{'<<'}</div>
            </li>
          )}
          {last > 5 && (
            <li
              className={`page-item ${active === 1 ? 'disabled' : ''}`}
              onClick={(event) => {
                handlePageChange(active - 1, size);
              }}
            >
              <div className="page-link">{'<'}</div>
            </li>
          )}
          {links.map((link, i) => (
            <li
              className={`page-item ${active === link ? 'active' : ''}`}
              key={i}
              onClick={(event) => {
                handlePageChange(link, size);
              }}
            >
              <div className="page-link">{link}</div>
            </li>
          ))}
          {last > 5 && (
            <li
              className={`page-item ${active === last ? 'disabled' : ''}`}
              onClick={(event) => {
                handlePageChange(active + 1, size);
              }}
            >
              <div className="page-link">{'>'}</div>
            </li>
          )}
          {last > 5 && (
            <li
              className={`page-item ${links[4] === last ? 'disabled' : ''}`}
              onClick={(event) => {
                handlePageChange(last, size);
              }}
            >
              <div className="page-link">{'>>'}</div>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

Pagination.defaultProps = {
  pageSizes: [10, 50, 100, 250, 500],
};

export default Pagination;
