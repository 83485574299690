import React from 'react';
import {
  MDBContainer,
  MDBModalHeader,
  MDBModal,
  MDBModalBody,
  MDBLightbox,
} from 'mdbreact';

const ImageModal = ({ isOpen, toggleModal, files }) => {
  const images = files.map((file, i) => {
    return {
      src: `https://storage.googleapis.com/poltio/${file.filename}`,
      alt: `image ${i} `,
    };
  });
  return (
    <MDBContainer>
      <MDBModal isOpen={isOpen} toggle={toggleModal}>
        <MDBModalHeader toggle={toggleModal}>Files</MDBModalHeader>
        <MDBModalBody>
          <MDBLightbox md="4" images={images} transition={0} noMargins />
        </MDBModalBody>
      </MDBModal>
    </MDBContainer>
  );
};

export default ImageModal;
