import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Row, Col, Button, Modal, ModalBody, ModalFooter } from 'mdbreact';
import { acceptTc } from '../../api';
import { getUser } from '../../core/helper';
import { Container } from 'mdbreact';

import messages from './messages';

class EmbedModal extends Component {
  componentDidMount() {
    setTimeout(() => {
      const user = getUser();
      if (!user.tc_accepted_at) {
        this.props.toggleModal();
      }
    }, 2000);
  }

  onYesClick = async () => {
    await acceptTc();
    this.props.toggleModal();
  };

  render() {
    const { modal, toggleModal } = this.props;
    const { formatMessage } = this.props.intl;

    return (
      <Container md="12">
        <Modal
          isOpen={modal}
          toggle={toggleModal}
          centered
          style={{ maxWidth: '1000px' }}
        >
          <ModalBody>
            <Container>
              <Row>
                <Col>
                  <a
                    href="https://pro.poltio.com/terms-and-conditions"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {formatMessage(messages.UpdatedTc)}
                  </a>
                </Col>
              </Row>
            </Container>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleModal}>
              {formatMessage(messages.Close)}
            </Button>
            <Button color="secondary" onClick={this.onYesClick}>
              {formatMessage(messages.Continue)}
            </Button>
          </ModalFooter>
        </Modal>
      </Container>
    );
  }
}

export default injectIntl(EmbedModal);
