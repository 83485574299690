import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  toast,
  MDBSpinner,
  Input,
  Button,
  MDBBtn,
  Fa,
} from 'mdbreact';
import { getTheme, saveTheme } from '../../api';

import messages from './messages';
import ImageInput from '../ImageInput';
import SectionWithTitle from '../Common/SectionWithTitle';
import ColorOption from './ColorOption';

const defaultTheme = {
  background_color: 'aliceblue',
  body_color: '#1E3D54',
  button_backgroud_selected: '#009eeb',
  button_background: 'rgba(0, 0, 0, 0.02)',
  button_color: 'rgba(30, 61, 84, 1)',
  button_color_selected: 'white',
  font_family: '"Averta", "Arial", sans-serif',
  font_family_google: 'https://platform.poltio.com/fonts/averta/averta',
  embed_background: null,
  embed_footer_img: null,
  button_other_color: 'rgba(30, 61, 84, 0.75)',
  button_other_background: '#DDE7EB',
  font_size_title: '1.5rem',
  line_height_title: '1.8rem',
  font_size_answer: '1rem',
  line_height_answer: '1.4rem',
  border_radius: '0.5rem',
  answer_border_radius: '0.5rem',
  cover_background_color: '#FFFFFF',
  cover_text_color: 'rgb(30, 61, 84)',
  question_background_color: '#FFFFFF',
  question_text_color: 'rgb(30, 61, 84)',
  result_background_color: '#FFFFFF',
  result_text_color: 'rgb(30, 61, 84)',
  cover_desc_color: '#8392a6',
  result_desc_color: '#8392a6',
  mp_info_color: 'rgba(30, 61, 84, 0.7)',
  title_font_weight: '700',
  button_font_weight: '600',
  textinput_border_radius: '0.5rem',
  card_shadow: '0 0 1rem rgba(20,36,51,.1)',
  card_border_radius: '.5rem',
};

const Theme = ({ intl: { formatMessage } }) => {
  const [loading, setLoading] = useState(true);
  const [saveDisabled, setSaveDisabled] = useState(false);
  const [theme, setTheme] = useState({});

  const getData = async () => {
    try {
      const response = await getTheme();
      setTheme(response.data);
      setLoading(false);
    } catch (e) {
      toast.error('Error occurred!');
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const saveChanges = async () => {
    try {
      setSaveDisabled(true);
      await saveTheme(theme);
      setSaveDisabled(false);
      toast.info(formatMessage(messages.ChangesSaved));
    } catch (e) {
      setSaveDisabled(false);
      toast.error('Error occurred!');
    }
  };

  const handleTextChange = async (e) => {
    setTheme({
      ...theme,
      [e.target.name]: e.target.value,
    });
  };

  const setDefault = (name) =>
    setTheme({ ...theme, [name]: defaultTheme[name] });

  const setSRC = (src, target) => setTheme({ ...theme, [target]: src });

  return loading ? (
    <MDBContainer>
      <MDBRow center>
        <MDBSpinner />
      </MDBRow>
    </MDBContainer>
  ) : (
    <MDBContainer fluid>
      <SectionWithTitle title={formatMessage(messages.ChangeTheme)}>
        <MDBRow>
          <MDBCol>
            <ColorOption
              name={'button_background'}
              val={theme.button_background}
              label={formatMessage(messages.ButtonBackGround)}
              onChange={handleTextChange}
              def={defaultTheme.button_background}
              reset={() => setDefault('button_background')}
              resetText={formatMessage(messages.RestoreDefaults)}
            />
            <ColorOption
              name={'button_backgroud_selected'}
              val={theme.button_backgroud_selected}
              label={formatMessage(messages.SelectedButtonBackGround)}
              onChange={handleTextChange}
              def={defaultTheme.button_backgroud_selected}
              reset={() => setDefault('button_backgroud_selected')}
              resetText={formatMessage(messages.RestoreDefaults)}
            />
            <ColorOption
              name={'button_other_background'}
              val={theme.button_other_background}
              label={formatMessage(messages.ButtonOtherBackGround)}
              onChange={handleTextChange}
              def={defaultTheme.button_other_background}
              reset={() => setDefault('button_other_background')}
              resetText={formatMessage(messages.RestoreDefaults)}
            />
            <ColorOption
              name={'button_color'}
              val={theme.button_color}
              label={formatMessage(messages.ButtonColor)}
              onChange={handleTextChange}
              def={defaultTheme.button_color}
              reset={() => setDefault('button_color')}
              resetText={formatMessage(messages.RestoreDefaults)}
            />
            <ColorOption
              name={'button_color_selected'}
              val={theme.button_color_selected}
              label={formatMessage(messages.SelectedButtonColor)}
              onChange={handleTextChange}
              def={defaultTheme.button_color_selected}
              reset={() => setDefault('button_color_selected')}
              resetText={formatMessage(messages.RestoreDefaults)}
            />
            <ColorOption
              name={'button_other_color'}
              val={theme.button_other_color}
              label={formatMessage(messages.ButtonOtherColor)}
              onChange={handleTextChange}
              def={defaultTheme.button_other_color}
              reset={() => setDefault('button_other_color')}
              resetText={formatMessage(messages.RestoreDefaults)}
            />

            <MDBRow>
              <MDBCol md="8">
                <Input
                  label={formatMessage(messages.FontFamily)}
                  className="mt-3"
                  name="font_family"
                  value={theme.font_family}
                  onChange={handleTextChange}
                />
              </MDBCol>
              <MDBCol md="1" />
              <MDBCol md="3">
                {theme.font_family !== defaultTheme.font_family && (
                  <MDBBtn
                    outline
                    color="info"
                    size="sm"
                    onClick={() => setDefault('font_family')}
                  >
                    <Fa icon="pencil-alt" fixed />{' '}
                    {formatMessage(messages.RestoreDefaults)}
                  </MDBBtn>
                )}
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md="8">
                <Input
                  label={formatMessage(messages.GoogleFontFamily)}
                  className="mt-3"
                  name="font_family_google"
                  value={theme.font_family_google}
                  onChange={handleTextChange}
                />
              </MDBCol>
              <MDBCol md="1" />
              <MDBCol md="3">
                {theme.font_family_google !==
                  defaultTheme.font_family_google && (
                  <MDBBtn
                    outline
                    color="info"
                    size="sm"
                    onClick={() => setDefault('font_family_google')}
                  >
                    <Fa icon="pencil-alt" fixed />{' '}
                    {formatMessage(messages.RestoreDefaults)}
                  </MDBBtn>
                )}
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md="8">
                <Input
                  label={formatMessage(messages.TitleFontWeight)}
                  className="mt-3"
                  name="title_font_weight"
                  value={theme.title_font_weight}
                  onChange={handleTextChange}
                />
              </MDBCol>
              <MDBCol md="1" />
              <MDBCol md="3">
                {theme.title_font_weight !== defaultTheme.title_font_weight && (
                  <MDBBtn
                    outline
                    color="info"
                    size="sm"
                    onClick={() => setDefault('title_font_weight')}
                  >
                    <Fa icon="pencil-alt" fixed />{' '}
                    {formatMessage(messages.RestoreDefaults)}
                  </MDBBtn>
                )}
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md="8">
                <Input
                  label={formatMessage(messages.ButtonFontWeight)}
                  className="mt-3"
                  name="button_font_weight"
                  value={theme.button_font_weight}
                  onChange={handleTextChange}
                />
              </MDBCol>
              <MDBCol md="1" />
              <MDBCol md="3">
                {theme.button_font_weight !==
                  defaultTheme.button_font_weight && (
                  <MDBBtn
                    outline
                    color="info"
                    size="sm"
                    onClick={() => setDefault('button_font_weight')}
                  >
                    <Fa icon="pencil-alt" fixed />{' '}
                    {formatMessage(messages.RestoreDefaults)}
                  </MDBBtn>
                )}
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md="8">
                <Input
                  label={formatMessage(messages.ButtonBorderRadius)}
                  className="mt-3"
                  name="border_radius"
                  value={theme.border_radius}
                  onChange={handleTextChange}
                />
              </MDBCol>
              <MDBCol md="1" />
              <MDBCol md="3">
                {theme.border_radius !== defaultTheme.border_radius && (
                  <MDBBtn
                    outline
                    color="info"
                    size="sm"
                    onClick={() => setDefault('border_radius')}
                  >
                    <Fa icon="pencil-alt" fixed />{' '}
                    {formatMessage(messages.RestoreDefaults)}
                  </MDBBtn>
                )}
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md="8">
                <Input
                  label={formatMessage(messages.AnswerButtonBorderRadius)}
                  className="mt-3"
                  name="answer_border_radius"
                  value={theme.answer_border_radius}
                  onChange={handleTextChange}
                />
              </MDBCol>
              <MDBCol md="1" />
              <MDBCol md="8">
                <Input
                  label={formatMessage(messages.TextInputBorderRadius)}
                  className="mt-3"
                  name="textinput_border_radius"
                  value={theme.textinput_border_radius}
                  onChange={handleTextChange}
                />
              </MDBCol>
              <MDBCol md="1" />
              <MDBCol md="3">
                {theme.answer_border_radius !==
                  defaultTheme.answer_border_radius && (
                  <MDBBtn
                    outline
                    color="info"
                    size="sm"
                    onClick={() => setDefault('answer_border_radius')}
                  >
                    <Fa icon="pencil-alt" fixed />{' '}
                    {formatMessage(messages.RestoreDefaults)}
                  </MDBBtn>
                )}
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md="8">
                <Input
                  label={formatMessage(messages.TitleFontSize)}
                  className="mt-3"
                  name="font_size_title"
                  value={theme.font_size_title}
                  onChange={handleTextChange}
                />
              </MDBCol>
              <MDBCol md="1" />
              <MDBCol md="3">
                {theme.font_size_title !== defaultTheme.font_size_title && (
                  <MDBBtn
                    outline
                    color="info"
                    size="sm"
                    onClick={() => setDefault('font_size_title')}
                  >
                    <Fa icon="pencil-alt" fixed />{' '}
                    {formatMessage(messages.RestoreDefaults)}
                  </MDBBtn>
                )}
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md="8">
                <Input
                  label={formatMessage(messages.TitleLineHeight)}
                  className="mt-3"
                  name="line_height_title"
                  value={theme.line_height_title}
                  onChange={handleTextChange}
                />
              </MDBCol>
              <MDBCol md="1" />
              <MDBCol md="3">
                {theme.line_height_title !== defaultTheme.line_height_title && (
                  <MDBBtn
                    outline
                    color="info"
                    size="sm"
                    onClick={() => setDefault('line_height_title')}
                  >
                    <Fa icon="pencil-alt" fixed />{' '}
                    {formatMessage(messages.RestoreDefaults)}
                  </MDBBtn>
                )}
              </MDBCol>
            </MDBRow>

            <MDBRow>
              <MDBCol md="8">
                <Input
                  label={formatMessage(messages.AnswerFontSize)}
                  className="mt-3"
                  name="font_size_answer"
                  value={theme.font_size_answer}
                  onChange={handleTextChange}
                />
              </MDBCol>
              <MDBCol md="1" />
              <MDBCol md="3">
                {theme.font_size_answer !== defaultTheme.font_size_answer && (
                  <MDBBtn
                    outline
                    color="info"
                    size="sm"
                    onClick={() => setDefault('font_size_answer')}
                  >
                    <Fa icon="pencil-alt" fixed />{' '}
                    {formatMessage(messages.RestoreDefaults)}
                  </MDBBtn>
                )}
              </MDBCol>
            </MDBRow>

            <MDBRow>
              <MDBCol md="8">
                <Input
                  label={formatMessage(messages.AnswerLineHeight)}
                  className="mt-3"
                  name="line_height_answer"
                  value={theme.line_height_answer}
                  onChange={handleTextChange}
                />
              </MDBCol>
              <MDBCol md="1" />
              <MDBCol md="3">
                {theme.line_height_answer !==
                  defaultTheme.line_height_answer && (
                  <MDBBtn
                    outline
                    color="info"
                    size="sm"
                    onClick={() => setDefault('line_height_answer')}
                  >
                    <Fa icon="pencil-alt" fixed />{' '}
                    {formatMessage(messages.RestoreDefaults)}
                  </MDBBtn>
                )}
              </MDBCol>
            </MDBRow>

            <ColorOption
              name={'body_color'}
              val={theme.body_color}
              label={formatMessage(messages.BodyColor)}
              onChange={handleTextChange}
              def={defaultTheme.body_color}
              reset={() => setDefault('body_color')}
              resetText={formatMessage(messages.RestoreDefaults)}
            />

            <ColorOption
              name={'background_color'}
              val={theme.background_color}
              label={formatMessage(messages.BackgroundColor)}
              onChange={handleTextChange}
              def={defaultTheme.background_color}
              reset={() => setDefault('background_color')}
              resetText={formatMessage(messages.RestoreDefaults)}
            />

            <ColorOption
              name={'cover_background_color'}
              val={theme.cover_background_color}
              label={formatMessage(messages.CoverBackgroundColor)}
              onChange={handleTextChange}
              def={defaultTheme.cover_background_color}
              reset={() => setDefault('cover_background_color')}
              resetText={formatMessage(messages.RestoreDefaults)}
            />

            <ColorOption
              name={'cover_text_color'}
              val={theme.cover_text_color}
              label={formatMessage(messages.CoverTextColor)}
              onChange={handleTextChange}
              def={defaultTheme.cover_text_color}
              reset={() => setDefault('cover_text_color')}
              resetText={formatMessage(messages.RestoreDefaults)}
            />

            <ColorOption
              name={'cover_desc_color'}
              val={theme.cover_desc_color}
              label={formatMessage(messages.CoverDescColor)}
              onChange={handleTextChange}
              def={defaultTheme.cover_desc_color}
              reset={() => setDefault('cover_desc_color')}
              resetText={formatMessage(messages.RestoreDefaults)}
            />

            <ColorOption
              name={'question_background_color'}
              val={theme.question_background_color}
              label={formatMessage(messages.QuestionBackgroundColor)}
              onChange={handleTextChange}
              def={defaultTheme.question_background_color}
              reset={() => setDefault('question_background_color')}
              resetText={formatMessage(messages.RestoreDefaults)}
            />

            <ColorOption
              name={'question_text_color'}
              val={theme.question_text_color}
              label={formatMessage(messages.QuestionTextColor)}
              onChange={handleTextChange}
              def={defaultTheme.question_text_color}
              reset={() => setDefault('question_text_color')}
              resetText={formatMessage(messages.RestoreDefaults)}
            />

            <ColorOption
              name={'result_background_color'}
              val={theme.result_background_color}
              label={formatMessage(messages.ResultBackgroundColor)}
              onChange={handleTextChange}
              def={defaultTheme.result_background_color}
              reset={() => setDefault('result_background_color')}
              resetText={formatMessage(messages.RestoreDefaults)}
            />

            <ColorOption
              name={'result_text_color'}
              val={theme.result_text_color}
              label={formatMessage(messages.ResultTextColor)}
              onChange={handleTextChange}
              def={defaultTheme.result_text_color}
              reset={() => setDefault('result_text_color')}
              resetText={formatMessage(messages.RestoreDefaults)}
            />

            <ColorOption
              name={'result_desc_color'}
              val={theme.result_desc_color}
              label={formatMessage(messages.ResultDescColor)}
              onChange={handleTextChange}
              def={defaultTheme.result_desc_color}
              reset={() => setDefault('result_desc_color')}
              resetText={formatMessage(messages.RestoreDefaults)}
            />

            <ColorOption
              name={'mp_info_color'}
              val={theme.mp_info_color}
              label={formatMessage(messages.MPInfoColor)}
              onChange={handleTextChange}
              def={defaultTheme.mp_info_color}
              reset={() => setDefault('mp_info_color')}
              resetText={formatMessage(messages.RestoreDefaults)}
            />

            <MDBRow>
              <MDBCol md="8">
                <Input
                  label={formatMessage(messages.CardShadow)}
                  className="mt-3"
                  name="card_shadow"
                  value={theme.card_shadow}
                  onChange={handleTextChange}
                />
              </MDBCol>
              <MDBCol md="1" />
              <MDBCol md="3">
                {theme.card_shadow !== defaultTheme.card_shadow && (
                  <MDBBtn
                    outline
                    color="info"
                    size="sm"
                    onClick={() => setDefault('card_shadow')}
                  >
                    <Fa icon="pencil-alt" fixed />{' '}
                    {formatMessage(messages.RestoreDefaults)}
                  </MDBBtn>
                )}
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md="8">
                <Input
                  label={formatMessage(messages.CardBorderRadius)}
                  className="mt-3"
                  name="card_border_radius"
                  value={theme.card_border_radius}
                  onChange={handleTextChange}
                />
              </MDBCol>
              <MDBCol md="1" />

              <MDBCol md="3">
                {theme.card_border_radius !==
                  defaultTheme.card_border_radius && (
                  <MDBBtn
                    outline
                    color="info"
                    size="sm"
                    onClick={() => setDefault('card_border_radius')}
                  >
                    <Fa icon="pencil-alt" fixed />{' '}
                    {formatMessage(messages.RestoreDefaults)}
                  </MDBBtn>
                )}
              </MDBCol>
            </MDBRow>

            <MDBRow>
              <MDBCol md="8">
                <ImageInput
                  title={formatMessage(messages.EmbedBackgroundImage)}
                  target={'embed_background'}
                  setSRC={setSRC}
                  src={theme.embed_background}
                />
              </MDBCol>
              <MDBCol md="1" />
              <MDBCol md="3">
                {theme.embed_background !== defaultTheme.embed_background && (
                  <MDBBtn
                    outline
                    color="info"
                    size="sm"
                    onClick={() => setDefault('embed_background')}
                  >
                    <Fa icon="pencil-alt" fixed />{' '}
                    {formatMessage(messages.RestoreDefaults)}
                  </MDBBtn>
                )}
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md="8">
                <ImageInput
                  title={formatMessage(messages.EmbedFooterImage)}
                  target={'embed_footer_img'}
                  setSRC={setSRC}
                  src={theme.embed_footer_img}
                />
              </MDBCol>
              <MDBCol md="1" />
              <MDBCol md="3">
                {theme.embed_footer_img !== defaultTheme.embed_footer_img && (
                  <MDBBtn
                    outline
                    color="info"
                    size="sm"
                    onClick={() => setDefault('embed_footer_img')}
                  >
                    <Fa icon="pencil-alt" fixed />{' '}
                    {formatMessage(messages.RestoreDefaults)}
                  </MDBBtn>
                )}
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol>
                <p>{formatMessage(messages.ItMayTake)}</p>
              </MDBCol>
            </MDBRow>
            <div className="text-center">
              <Button
                color="primary"
                type="submit"
                disabled={saveDisabled}
                onClick={saveChanges}
              >
                {formatMessage(messages.Save)}
              </Button>
            </div>
          </MDBCol>
        </MDBRow>
      </SectionWithTitle>
    </MDBContainer>
  );
};

export default injectIntl(Theme);
