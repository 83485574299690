import React, { Fragment } from 'react';
import { MDBCol, MDBInput, MDBBtn, MDBIcon, MDBRow } from 'mdbreact';

const Submit = ({ formatMessage, messages, ...props }) => (
  <Fragment>
    <MDBRow center>
      <MDBCol md="4">
        <MDBInput
          hint={formatMessage(messages.EnterQuizId)}
          type="number"
          value={props.triviaCode}
          onChange={(e) => props.setTriviaCode(e.target.value)}
        />
      </MDBCol>
      <MDBCol md="2">
        <MDBBtn
          color="primary"
          rounded
          onClick={props.onSubmit}
          disabled={props.triviaCode.length < 2}
        >
          <MDBIcon icon="magic" size="sm" className="mr-2" />
          {formatMessage(messages.Create)}
        </MDBBtn>
      </MDBCol>
    </MDBRow>
  </Fragment>
);

export default Submit;
