import { defineMessages } from 'react-intl.macro';

export default defineMessages({
  Edit: {
    id: 'Content.Edit',
    defaultMessage: 'Edit',
  },
  EmbedCode: {
    id: 'Content.EmbedCode',
    defaultMessage: 'Embed Code',
  },
  Poll: {
    id: 'Content.Poll',
    defaultMessage: 'Poll',
  },
  Test: {
    id: 'Content.Test',
    defaultMessage: 'Test',
  },
  Quiz: {
    id: 'Content.Quiz',
    defaultMessage: 'Quiz',
  },
  EmbedCodeGenerator: {
    id: 'Content.EmbedCodeGenerator',
    defaultMessage: 'Embed Code Generator',
  },
  Width: {
    id: 'Content.Width',
    defaultMessage: 'Width',
  },
  Height: {
    id: 'Content.Height',
    defaultMessage: 'Height',
  },
  CopyCode: {
    id: 'Content.CopyCode',
    defaultMessage: 'Copy Code',
  },
  ClickHere: {
    id: 'Content.ClickHere',
    defaultMessage: 'Click to learn about our embed features',
  },
  Close: {
    id: 'Content.Close',
    defaultMessage: 'Close',
  },
  Yes: {
    id: 'Content.Yes',
    defaultMessage: 'yes',
  },
  No: {
    id: 'Content.No',
    defaultMessage: 'no',
  },
  CreatePoll: {
    id: 'Content.CreatePoll',
    defaultMessage: 'Create Poll',
  },
  PollPreview: {
    id: 'Content.PollPreview',
    defaultMessage: 'Poll Preview',
  },
  YourContent: {
    id: 'Content.YourContent',
    defaultMessage: 'Your content will appear here',
  },
  BasicInfo: {
    id: 'Content.BasicInfo',
    defaultMessage: 'Basic Info',
  },
  AnswerType: {
    id: 'Content.AnswerType',
    defaultMessage: 'Answer Type',
  },
  Options: {
    id: 'Content.Options',
    defaultMessage: 'Options',
  },
  Search: {
    id: 'Content.Search',
    defaultMessage: 'Search',
  },
  EnterSearchText: {
    id: 'Content.EnterSearchText',
    defaultMessage: 'Enter Search Text',
  },
  Name: {
    id: 'Content.Name',
    defaultMessage: 'Name',
  },
  Loading: {
    id: 'Content.Loading',
    defaultMessage: 'Loading...',
  },
  Select: {
    id: 'Content.Select',
    defaultMessage: 'Select',
  },
  SelectCat: {
    id: 'Content.SelectCat',
    defaultMessage: 'Select Category',
  },
  YourQ: {
    id: 'Content.YourQ',
    defaultMessage: 'Your Question',
  },
  CropImage: {
    id: 'Content.CropImage',
    defaultMessage: 'Crop Image',
  },
  DeleteImage: {
    id: 'Content.DeleteImage',
    defaultMessage: 'Delete Image',
  },
  Prev: {
    id: 'Content.Prev',
    defaultMessage: 'Previous',
  },
  Next: {
    id: 'Content.Next',
    defaultMessage: 'Next',
  },
  Finish: {
    id: 'Content.Finish',
    defaultMessage: 'Finish',
  },
  Yesno: {
    id: 'Content.Yesno',
    defaultMessage: 'YesNo',
  },
  Text: {
    id: 'Content.Text',
    defaultMessage: 'Text',
  },
  Media: {
    id: 'Content.Media',
    defaultMessage: 'Media',
  },
  Score: {
    id: 'Content.Score',
    defaultMessage: 'Score',
  },
  AddPixelCode: {
    id: 'Content.AddPixelCode',
    defaultMessage: 'Add pixel code (On View)',
  },
  AddClickPixelCode: {
    id: 'Content.ClickPixelCode',
    defaultMessage: 'Add pixel code (On Click)',
  },
  Answer: {
    id: 'Content.Answer',
    defaultMessage: 'Answer',
  },
  DeleteAnswer: {
    id: 'Content.DeleteAnswer',
    defaultMessage: 'Delete Answer',
  },
  AddAnswer: {
    id: 'Content.AddAnswer',
    defaultMessage: 'Add Answer',
  },
  AddImage: {
    id: 'Content.AddImage',
    defaultMessage: 'Add Image',
  },
  AddCodeFor: {
    id: 'Content.AddCodeFor',
    defaultMessage: 'Add pixel code for',
  },
  Hidden: {
    id: 'Content.Hidden',
    defaultMessage: 'Hidden on Poltio Social',
  },
  HideVoteResults: {
    id: 'Content.HideVoteResults',
    defaultMessage: 'Hide Vote Results',
  },
  HideVoteCounter: {
    id: 'Content.HideVoteCounter',
    defaultMessage: 'Vote Counter',
  },
  Instant: {
    id: 'Content.Instant',
    defaultMessage: 'Instant Vote Lock',
  },
  Shuffle: {
    id: 'Content.Shuffle',
    defaultMessage: 'Shuffle Row',
  },
  ShuffleLast: {
    id: 'Content.ShuffleLast',
    defaultMessage: 'Shuffle Except Last Answer',
  },
  RequireLogin: {
    id: 'Content.RequireLogin',
    defaultMessage: 'Require Poltio Login',
  },
  DisableComments: {
    id: 'Content.DisableComments',
    defaultMessage: 'Disable Comments on Poltio',
  },
  VoteNotifs: {
    id: 'Content.VoteNotifs',
    defaultMessage: 'Vote Notifications',
  },
  EndDate: {
    id: 'Content.EndDate',
    defaultMessage: 'End Date',
  },
  Days: {
    id: 'Content.Days',
    defaultMessage: 'Days',
  },
  Hours: {
    id: 'Content.Hours',
    defaultMessage: 'Hours',
  },
  Minutes: {
    id: 'Content.Minutes',
    defaultMessage: 'Minutes',
  },
  AllowMultiple: {
    id: 'Content.AllowMultiple',
    defaultMessage: 'Allow Multiple Answers',
  },
  MaxNumber: {
    id: 'Content.MaxNumber',
    defaultMessage: 'Max number of answers',
  },
  CreateTest: {
    id: 'Content.CreateTest',
    defaultMessage: 'Create Test',
  },
  TestInfo: {
    id: 'Content.TestInfo',
    defaultMessage: 'Test Info',
  },
  TestResults: {
    id: 'Content.TestResults',
    defaultMessage: 'Test Results',
  },
  TestQuestions: {
    id: 'Content.TestQuestions',
    defaultMessage: 'Test Questions',
  },
  TestPreview: {
    id: 'Content.TestPreview',
    defaultMessage: 'Test Preview',
  },
  Description: {
    id: 'Content.Description',
    defaultMessage: 'Description (required)',
  },
  DescriptionSource: {
    id: 'Content.DescriptionSource',
    defaultMessage: 'Source Description',
  },
  ResultSource: {
    id: 'Content.ResultSource',
    defaultMessage: 'Source Result',
  },
  TestTitle: {
    id: 'Content.TestTitle',
    defaultMessage: 'Test Title (required)',
  },
  QuizTitle: {
    id: 'Content.QuizTitle',
    defaultMessage: 'Quiz Title (required)',
  },
  Result: {
    id: 'Content.Result',
    defaultMessage: 'Result (required)',
  },
  ResultDesc: {
    id: 'Content.ResultDesc',
    defaultMessage: 'Result Description (required)',
  },
  Url: {
    id: 'Content.Url',
    defaultMessage: 'Url',
  },
  UrlText: {
    id: 'Content.UrlText',
    defaultMessage: 'Url Text',
  },
  DeleteResult: {
    id: 'Content.DeleteResult',
    defaultMessage: 'Delete Result',
  },
  AddResult: {
    id: 'Content.AddResult',
    defaultMessage: 'Add Result',
  },
  AddQuestion: {
    id: 'Content.AddQuestion',
    defaultMessage: 'Add Question',
  },
  SaveQuestion: {
    id: 'Content.SaveQuestion',
    defaultMessage: 'Save Question',
  },
  DidntSaveCurrent: {
    id: 'Content.DidntSaveCurrent',
    defaultMessage:
      'You did not save the current question. Are you sure you want to continue?',
  },
  QuestionTitle: {
    id: 'Content.QuestionTitle',
    defaultMessage: 'Question Title (required)',
  },
  PrevQuestion: {
    id: 'Content.PrevQuestion',
    defaultMessage: 'Previous Question',
  },
  NextQuestion: {
    id: 'Content.NextQuestion',
    defaultMessage: 'Next Question',
  },
  Answers: {
    id: 'Content.Answers',
    defaultMessage: 'Answers',
  },
  Question: {
    id: 'Content.Question',
    defaultMessage: 'Question',
  },
  FinishTest: {
    id: 'Content.FinishTest',
    defaultMessage: 'Finish Test',
  },
  CreateQuiz: {
    id: 'Content.CreateQuiz',
    defaultMessage: 'Create Quiz',
  },
  QuizPreview: {
    id: 'Content.QuizPreview',
    defaultMessage: 'Quiz Preview',
  },
  QuizInfo: {
    id: 'Content.QuizInfo',
    defaultMessage: 'Quiz Info',
  },
  QuizResults: {
    id: 'Content.QuizResults',
    defaultMessage: 'Quiz Results',
  },
  QuizQuestions: {
    id: 'Content.QuizQuestions',
    defaultMessage: 'Quiz Questions',
  },
  ShowCorrect: {
    id: 'Content.ShowCorrect',
    defaultMessage: 'Show correct/wrong answer',
  },
  TimeRace: {
    id: 'Content.TimeRace',
    defaultMessage: 'Time race',
  },
  HidePerc: {
    id: 'Content.HidePerc',
    defaultMessage: 'Hide percentages',
  },
  ShowResults: {
    id: 'Content.ShowResults',
    defaultMessage: 'Show Score',
  },
  Elim: {
    id: 'Content.Elim',
    defaultMessage: 'Elimination',
  },
  RLogin: {
    id: 'Content.RLogin',
    defaultMessage: 'Require Login',
  },
  TimePerQ: {
    id: 'Content.TimePerQ',
    defaultMessage: 'Time per question',
  },
  RightAnswer: {
    id: 'Content.RightAnswer',
    defaultMessage: 'Correct answer',
  },
  FinishQuiz: {
    id: 'Content.FinishQuiz',
    defaultMessage: 'Finish Quiz',
  },
  MinAnswers: {
    id: 'Content.MinAnswers',
    defaultMessage: 'Min Answers',
  },
  MaxAnswers: {
    id: 'Content.MaxAnswers',
    defaultMessage: 'Max Answers',
  },
  Views: {
    id: 'Content.Views',
    defaultMessage: 'views',
  },
  Votes: {
    id: 'Content.Votes',
    defaultMessage: 'votes',
  },
  Delete: {
    id: 'Content.Delete',
    defaultMessage: 'Delete',
  },
  Copy: {
    id: 'Content.Copy',
    defaultMessage: 'Copy',
  },
  ContentDeleted: {
    id: 'Content.ContentDeleted',
    defaultMessage: 'Content Deleted',
  },
  Sure: {
    id: 'Content.Sure',
    defaultMessage: 'Content will be deleted!',
  },
  InSite: {
    id: 'Content.InSite',
    defaultMessage: 'In Site Link',
  },
  SingleView: {
    id: 'Content.SingleView',
    defaultMessage: 'Dedicated Content Link',
  },
  ChooseImage: {
    id: 'Content.ChooseImage',
    defaultMessage: 'Choose Image',
  },
  QuestionImage: {
    id: 'Content.QuestionImage',
    defaultMessage: 'Upload Question Image',
  },
  TestImage: {
    id: 'Content.TestImage',
    defaultMessage: 'Upload Test Image (required)',
  },
  QuizImage: {
    id: 'Content.QuizImage',
    defaultMessage: 'Upload Quiz Image (required)',
  },
  EmbedBg: {
    id: 'Content.EmbedBg',
    defaultMessage: 'Upload Embed Background',
  },
  CustomLogo: {
    id: 'Content.CustomLogo',
    defaultMessage: 'Upload Custom Logo',
  },
  DisplayRepeat: {
    id: 'Content.DisplayRepeat',
    defaultMessage: 'Show Repeat Button',
  },
  Relation: {
    id: 'Content.Relation',
    defaultMessage: 'Relation with the results',
  },
  Results: {
    id: 'Content.Results',
    defaultMessage: 'Results',
  },
  Questions: {
    id: 'Content.Questions',
    defaultMessage: 'Questions',
  },
  YourQuestions: {
    id: 'Content.YourQuestions',
    defaultMessage: 'Your Questions',
  },
  AddLead: {
    id: 'Content.AddLead',
    defaultMessage: 'Add Lead',
  },
  EnterLeadName: {
    id: 'Content.EnterLeadName',
    defaultMessage: 'Enter Lead Name',
  },
  EnterPcName: {
    id: 'Content.EnterPcName',
    defaultMessage: 'Enter Pixel Code Name',
  },
  EditPoll: {
    id: 'Content.EditPoll',
    defaultMessage: 'Edit Poll',
  },
  EditTest: {
    id: 'Content.EditTest',
    defaultMessage: 'Edit Test',
  },
  EditQuiz: {
    id: 'Content.EditQuiz',
    defaultMessage: 'Edit Quiz',
  },
  CopyLink: {
    id: 'Content.CopyLink',
    defaultMessage: 'Copy Link',
  },
  Copied: {
    id: 'Content.Copied',
    defaultMessage: 'Link Copied',
  },
  Error: {
    id: 'Content.Error',
    defaultMessage: 'Error copying link!',
  },
  Set: {
    id: 'Content.Set',
    defaultMessage: 'Set',
  },
  CreateSet: {
    id: 'Content.CreateSet',
    defaultMessage: 'Create Set',
  },
  SetPreview: {
    id: 'Content.SetPreview',
    defaultMessage: 'Set Preview',
  },
  YourPolls: {
    id: 'Content.YourPolls',
    defaultMessage: 'Your Polls',
  },
  DisplayShare: {
    id: 'Content.DisplayShare',
    defaultMessage: 'Display share buttons',
  },
  DisplayNotify: {
    id: 'Content.DisplayNotify',
    defaultMessage: 'Display notify controls',
  },
  DisplayDisclaimer: {
    id: 'Content.DisplayDisclaimer',
    defaultMessage: 'Display Disclaimer',
  },
  Collapse: {
    id: 'Content.Collapse',
    defaultMessage: 'Collapse Options',
  },
  Expand: {
    id: 'Content.Expand',
    defaultMessage: 'Show Other Options',
  },
  WouldYouSave: {
    id: 'Content.WouldYouSave',
    defaultMessage: 'Would you like to save your changes as default?',
  },
  Off: {
    id: 'Content.Off',
    defaultMessage: 'Off',
  },
  On: {
    id: 'Content.On',
    defaultMessage: 'On',
  },
  Info: {
    id: 'Content.Info',
    defaultMessage: 'Info',
  },
  Save: {
    id: 'Content.Save',
    defaultMessage: 'Save',
  },
  ContentCreated: {
    id: 'Content.ContentCreated',
    defaultMessage: 'Content is created successfully',
  },
  SetSettings: {
    id: 'Content.SetSettings',
    defaultMessage: 'Set as default settings',
  },
  SuccessSetting: {
    id: 'Content.SuccessSetting',
    defaultMessage: 'Settings saved!',
  },
  ErrorSetting: {
    id: 'Content.ErrorSetting',
    defaultMessage: 'Error occured!',
  },
  UniqueVoters: {
    id: 'Content.UniqueVoters',
    defaultMessage: 'unique voters',
  },
  CompletionRate: {
    id: 'Content.CompletionRate',
    defaultMessage: 'completion rate',
  },
  ViewReport: {
    id: 'Content.ViewReport',
    defaultMessage: 'View Report',
  },
  ViewStats: {
    id: 'Content.ViewStats',
    defaultMessage: 'View Stats',
  },
  Back: {
    id: 'Content.Back',
    defaultMessage: 'Back',
  },
  Status: {
    id: 'Content.Status',
    defaultMessage: 'Status',
  },
  Published: {
    id: 'Content.Published',
    defaultMessage: 'Published',
  },
  Unpublished: {
    id: 'Content.Unpublished',
    defaultMessage: 'Unpublished',
  },
  SuccessPublish: {
    id: 'Content.SuccessPublish',
    defaultMessage: 'Status successfully changed',
  },
  ErrorPublish: {
    id: 'Content.ErrorPublish',
    defaultMessage: 'Error occured while changing status!',
  },
  FinishEditing: {
    id: 'Content.FinishEditing',
    defaultMessage: 'Finish Editing',
  },
  FinishEditingSuccess: {
    id: 'Content.FinishEditingSuccess',
    defaultMessage: 'Content was edited successfully',
  },
  Required: {
    id: 'Content.Required',
    defaultMessage: 'Required',
  },
  SelectRel: {
    id: 'Content.SelectRel',
    defaultMessage:
      "You didn't add any relevancy scores. Please add relevancy scores to related answers so that we can calculate which result the user should get based on his/her answers.",
  },
  FooterLink: {
    id: 'Content.FooterLink',
    defaultMessage: 'Footer Link',
  },
  Left: {
    id: 'Content.Left',
    defaultMessage: 'Left',
  },
  Center: {
    id: 'Content.Center',
    defaultMessage: 'Center',
  },
  Right: {
    id: 'Content.Right',
    defaultMessage: 'Right',
  },
  Alignment: {
    id: 'Content.Alignment',
    defaultMessage: 'Alignment',
  },
  DontShortenLink: {
    id: 'Content.DontShortenLink',
    defaultMessage: "Don't Shorten Link",
  },
  DontShortenDesc: {
    id: 'Content.DontShortenDesc',
    defaultMessage:
      "If you disable short link, we can't check your link and track clicks.",
  },
  PlayOnce: {
    id: 'Content.PlayOnce',
    defaultMessage: 'Play Once',
  },
  PlayOnceMsg: {
    id: 'Content.PlayOnceMsg',
    defaultMessage: 'Play Once Message',
  },
  PlayOnceImg: {
    id: 'Content.PlayOnceImg',
    defaultMessage: 'Play Once Image',
  },
  PlayOnceLink: {
    id: 'Content.PlayOnceLink',
    defaultMessage: 'Play Once Link',
  },
  PlayOnceBtn: {
    id: 'Content.PlayOnceBtn',
    defaultMessage: 'Play Once Button',
  },
  SelectCorrectAnswer: {
    id: 'Content.SelectCorrectAnswer',
    defaultMessage: 'Please select correct answer!',
  },
  ErrorDeletingQuestion: {
    id: 'Content.ErrorDeletingQuestion',
    defaultMessage: 'Error Deleting Question!',
  },
  AtLeastTwo: {
    id: 'Content.AtLeastTwo',
    defaultMessage: 'You need to have at least 2 questions. Deleting disabled.',
  },
  ChangesNotSaved: {
    id: 'Content.ChangesNotSaved',
    defaultMessage: 'Changes not saved. Do you want to continue?',
  },
  Vertical: {
    id: 'Content.Vertical',
    defaultMessage: 'Vertical',
  },
  EditEndDate: {
    id: 'Content.EditEndDate',
    defaultMessage: 'Edit End Date',
  },
  PoolQuestionCount: {
    id: 'Content.PoolQuestionCount',
    defaultMessage: 'Pool Question Count',
  },
  PoolGreaterThan: {
    id: 'Content.PoolGreaterThan',
    defaultMessage:
      'Pool Question Count can not be greater than question count',
  },
  ValidUrl: {
    id: 'Content.ValidUrl',
    defaultMessage: 'Please enter a valid url!',
  },
  SureDeleteQuestion: {
    id: 'Content.SureDeleteQuestion',
    defaultMessage: 'Are you sure you want to delete the selected question?',
  },
  EditSet: {
    id: 'Content.EditSet',
    defaultMessage: 'Edit Set',
  },
  SetTitle: {
    id: 'Content.SetTitle',
    defaultMessage: 'Set Title',
  },
  SetImage: {
    id: 'Content.SetImage',
    defaultMessage: 'Set Image',
  },
  SkipStart: {
    id: 'Content.SkipStart',
    defaultMessage: 'Skip Start',
  },
  SkipResult: {
    id: 'Content.SkipResult',
    defaultMessage: 'Skip Result',
  },
  SetSaved: {
    id: 'Content.SetSaved',
    defaultMessage: 'Set is saved successfully',
  },
  QuestionSaved: {
    id: 'Content.QuestionSaved',
    defaultMessage: 'Question is saved successfully',
  },
  DisplayThis: {
    id: 'Content.DisplayThis',
    defaultMessage: 'Display This Question To:',
  },
  ViewConditions: {
    id: 'Content.ViewConditions',
    defaultMessage: 'View Conditions',
  },
  SaveAndFinish: {
    id: 'Content.SaveAndFinish',
    defaultMessage: 'Save And Finish Editing',
  },
  SaveAndEditQuestions: {
    id: 'Content.SaveAndEditQuestions',
    defaultMessage: 'Save And Edit Questions',
  },
  DeleteQuestion: {
    id: 'Content.DeleteQuestion',
    defaultMessage: 'Delete Question',
  },
  QuestionDeleted: {
    id: 'Content.QuestionDeleted',
    defaultMessage: 'Question Deleted',
  },
  HasConditions: {
    id: 'Content.HasConditions',
    defaultMessage:
      'You have to clear conditions before deleting the question.',
  },
  HasConditionsAnswer: {
    id: 'Content.HasConditionsAnswer',
    defaultMessage: 'You have to clear conditions before deleting the answer.',
  },
  HasClones: {
    id: 'Content.HasClones',
    defaultMessage:
      "This question's answers are linked to another question. Please remove connected question before deleting.",
  },
  LeadUrlVariable: {
    id: 'Content.LeadUrlVariable',
    defaultMessage: 'Lead Url Variable',
  },
  Duplicate: {
    id: 'Content.Duplicate',
    defaultMessage: 'Duplicate',
  },
  ContentDuplicated: {
    id: 'Content.ContentDuplicated',
    defaultMessage: 'Content Duplicated',
  },
  HidePercTip: {
    id: 'Content.HidePercTip',
    defaultMessage:
      ' If this option is “Off” any user that gives an answer to your content will immediately see the current results. If it is “On”, we will not display the percentages.',
  },
  HideVoteCounterTip: {
    id: 'Content.HideVoteCounterTip',
    defaultMessage:
      ' If this option is “On” users will be able to see the number of votes that your content gathers. If it is “Off” we will not show the vote count.',
  },
  InstantTip: {
    id: 'Content.InstantTip',
    defaultMessage:
      'When this option is “On” answers that people give to your poll will immediately be locked. If it is “Off” users will be able to change their choice in 1 minute period and then the vote will be locked.',
  },
  ShuffleTip: {
    id: 'Content.ShuffleTip',
    defaultMessage:
      'If this option is “On” the answer choices of your poll will be shown in a random order to each user.',
  },
  ShuffleLastTip: {
    id: 'Content.ShuffleLastTip',
    defaultMessage:
      'If this option is “On” the answer choices of your poll will be shown in a random order to each user except the last answer.',
  },
  HiddenTip: {
    id: 'Content.HiddenTip',
    defaultMessage:
      'If this option is “On” your content will not be published on Poltio.com. Only people that you share the content link with will be able to answer the poll. You can still embed the content.',
  },
  PlayOnceTip: {
    id: 'Content.PlayOnceTip',
    defaultMessage:
      'If this option is “On” your content will be unreachable to responders after they vote.',
  },
  AllowMultipleTip: {
    id: 'Content.AllowMultipleTip',
    defaultMessage:
      ' If this option is “On” users can cast more than one vote.',
  },
  EndDateTip: {
    id: 'Content.EndDateTip',
    defaultMessage:
      'If this option is “On” the content will end in the specified date.',
  },
  ShowCorrectTip: {
    id: 'Content.ShowCorrectTip',
    defaultMessage:
      'Shows user if the answer was correct or wrong after every answer.',
  },
  TimeRaceTip: {
    id: 'Content.TimeRaceTip',
    defaultMessage:
      ' If this option is “On” user takes the quiz against time and time spent is shown on the result screen.',
  },
  ShowResultsTip: {
    id: 'Content.ShowResultsTip',
    defaultMessage: 'If this option is “On” user can see the result screen.',
  },
  RLoginTip: {
    id: 'Content.RLoginTip',
    defaultMessage:
      ' If this option is “On” Poltio.com login is required to take the content',
  },
  DisplayRepeatTip: {
    id: 'Content.DisplayRepeatTip',
    defaultMessage:
      'If this option is “On” users will be shown a “Repeat Button” on the result screen and will be able to retake the content.',
  },
  DisplayShareTip: {
    id: 'Content.DisplayShareTip',
    defaultMessage: 'Facebook and Twitter share buttons are displayed',
  },
  DisplayNotifyTip: {
    id: 'Content.DisplayNotifyTip',
    defaultMessage:
      '“Notify me of the final result” option is displayed on each question',
  },
  DisplayDisclaimerTip: {
    id: 'Content.DisplayDisclaimerTip',
    defaultMessage: 'Toggle display of Disclaimer message when embed loads',
  },
  PollTip: {
    id: 'Content.PollTip',
    defaultMessage:
      'Create polls (Answer Types : Yes/No, Text, Media and Score) to cast your readers’ vote',
  },
  SetTip: {
    id: 'Content.SetTip',
    defaultMessage: 'Create an embeddable poll set with consecutive polls',
  },
  TestTip: {
    id: 'Content.TestTip',
    defaultMessage:
      'Create a personality test to capture your reader’s attention by personalized results',
  },
  QuizTip: {
    id: 'Content.QuizTip',
    defaultMessage:
      'Create a quiz and give readers a challenge by asking questions to test their knowledge on any subject',
  },
  SkipStartTip: {
    id: 'Content.SkipStartTip',
    defaultMessage:
      'If this option is "On" set cover is not displayed and voters will directly see the first question',
  },
  SkipResultTip: {
    id: 'Content.SkipResultTip',
    defaultMessage: 'If this option is "On" result page is not displayed.',
  },
  FriendQuiz: {
    id: 'Content.FriendQuiz',
    defaultMessage: 'Friend Quiz',
  },
  FriendQuizTip: {
    id: 'Content.FriendQuizTip',
    defaultMessage: 'Friend Quiz',
  },
  Language: {
    id: 'Content.Language',
    defaultMessage: 'Language',
  },
  LanguageTip: {
    id: 'Content.LanguageTip',
    defaultMessage:
      'Buttons and instructions will be shown in selected language',
  },
  Default: {
    id: 'Content.Default',
    defaultMessage: 'Device Language',
  },
  English: {
    id: 'Content.English',
    defaultMessage: 'English',
  },
  Turkish: {
    id: 'Content.Turkish',
    defaultMessage: 'Turkish',
  },
  FQEditWarning: {
    id: 'Content.FQEditWarning',
    defaultMessage:
      'Active friend quiz sessions will be deactivated if you add/delete any questions or answers. You can duplicate the content to make edits without changing the original content.',
  },
  TimeLimit: {
    id: 'Content.TimeLimit',
    defaultMessage:
      'Time Limit (Users are directed to result after time is up)',
  },
  ResultOption: {
    id: 'Content.ResultOption',
    defaultMessage: 'Result',
  },
  StartOption: {
    id: 'Content.StartOption',
    defaultMessage: 'Start',
  },
  ResultOptionDesc: {
    id: 'Content.ResultOptionDesc',
    defaultMessage: "User can't play again after they saw the result",
  },
  StartOptionDesc: {
    id: 'Content.StartOptionDesc',
    defaultMessage: "User can't play again after they press start",
  },
  UseAnswers: {
    id: 'Content.UseAnswers',
    defaultMessage: 'Use answers from a previous question',
  },
  CalculatorSet: {
    id: 'Content.CalculatorSet',
    defaultMessage: 'Calculator Set',
  },
  CalculatorSetTip: {
    id: 'Content.CalculatorSetTip',
    defaultMessage: 'Creates a calculator set',
  },
  DefaultCalculatorValue: {
    id: 'Content.DefaultCalculatorValue',
    defaultMessage: 'Default Calculator Value',
  },
  CalculatorValue: {
    id: 'Content.CalculatorValue',
    defaultMessage: 'Calculator Value',
  },
  CalculatorFormula: {
    id: 'Content.CalculatorFormula',
    defaultMessage: 'Calculator Formula',
  },
  CalculatorResult: {
    id: 'Content.CalculatorResult',
    defaultMessage: 'Result',
  },
  CalculatorVariableInputs: {
    id: 'Content.CalculatorVariableInputs',
    defaultMessage: 'Question Variables',
  },
  CalculatorBadVariable: {
    id: 'Content.CalculatorBadVariable',
    defaultMessage: 'Bad Question ID',
  },
  FreeText: {
    id: 'Content.FreeText',
    defaultMessage: 'Free Text Input',
  },
  FreeNumber: {
    id: 'Content.FreeNumber',
    defaultMessage: 'Free Number Input',
  },
  PleaseEnterTitle: {
    id: 'Content.PleaseEnterTitle',
    defaultMessage: 'Please Enter a Title',
  },
  PleaseChooseImage: {
    id: 'Content.PleaseChooseImage',
    defaultMessage: 'Please Choose an Image',
  },
  PleaseEnterAnswer: {
    id: 'Content.PleaseEnterAnswer',
    defaultMessage: 'Please Enter Answers',
  },
  PleaseEnterQuestion: {
    id: 'Content.PleaseEnterQuestion',
    defaultMessage: 'Please Enter Question',
  },
  PleaseEnterDesc: {
    id: 'Content.PleaseEnterDescription',
    defaultMessage: 'Please Enter Set Description',
  },
  PleaseEnterResultDesc: {
    id: 'Content.PleaseEnterResultDesc',
    defaultMessage: 'Please Enter Result Description',
  },
  PleaseEnterResult: {
    id: 'Content.PleaseEnterResult',
    defaultMessage: 'Please Enter Result',
  },
  PleaseEnter: {
    id: 'Content.PleaseEnter',
    defaultMessage: 'Please Enter Title or Image for Answer',
  },
  PleaseAddQuestion: {
    id: 'Content.PleaseAddQuestion',
    defaultMessage: 'Please Save a Question',
  },
  NoContentFound: {
    id: 'Content.NoContentFound',
    defaultMessage: 'No Content Found',
  },
  PleaseCorrectAnswer: {
    id: 'Content.PleaseCorrectAnswer',
    defaultMessage: 'Please Choose Correct Answer',
  },
  PleaseEnterScore: {
    id: 'Content.PleaseEnterScore',
    defaultMessage: ' Please Select Relation (Just 1 enoughth)',
  },
  AutocompleteHelp: {
    id: 'Content.AutocompleteHelp',
    defaultMessage: 'Autocomplete Help',
  },
  AutocompletePlaceholder: {
    id: 'Content.AutocompletePlaceholder',
    defaultMessage: 'Autocomplete Placeholder',
  },
  LoadingNextQuestionLabel: {
    id: 'Editor.Question.Conf.LoadingNextQuestionLabel',
    defaultMessage: 'Loading Next Question Label ( default: Next Question )',
  },
  LoadingResultLabel: {
    id: 'Editor.Question.Conf.LoadingResultLabel',
    defaultMessage: 'Loading Result Label ( default: Preparing Result )',
  },
});
