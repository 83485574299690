import React from 'react';
import {
  MDBContainer,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from 'mdbreact';

const PayloadModal = ({ payload, toggle, isOpen, formatMessage, messages }) => (
  <MDBContainer>
    <MDBModal isOpen={isOpen} toggle={toggle} size="lg">
      <MDBModalHeader toggle={toggle}>Payload</MDBModalHeader>
      <MDBModalBody>
        <pre>
          <code>{payload}</code>
        </pre>
      </MDBModalBody>
      <MDBModalFooter>
        <MDBBtn color="secondary" onClick={toggle}>
          {formatMessage(messages.Close)}
        </MDBBtn>
      </MDBModalFooter>
    </MDBModal>
  </MDBContainer>
);

export default PayloadModal;
