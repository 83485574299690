import React, { useState, useEffect, useCallback } from 'react';
import { injectIntl } from 'react-intl';
import {
  MDBCol,
  MDBRow,
  MDBContainer,
  MDBIcon,
  MDBBtn,
  MDBSpinner,
} from 'mdbreact';
import ReactTooltip from 'react-tooltip';
import SectionWithTitle from '../Common/SectionWithTitle';
import Pagination from '../Pagination';
import { getPixelCodesPaginated } from '../../api';
import history from '../../core/history';

import messages from './messages';

const PixelCodes = ({ intl: { formatMessage } }) => {
  const [codes, setCodes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageData, setPageData] = useState({
    totalSize: 100,
    page: 1,
    sizePerPage: 50,
    lastPage: null,
  });

  const fetchCodes = useCallback(async () => {
    setLoading(true);
    const response = await getPixelCodesPaginated(
      pageData.page,
      pageData.sizePerPage
    );
    setCodes(response.data.data);
    setLoading(false);
    setPageData((p) => {
      return {
        page: p.page,
        sizePerPage: p.sizePerPage,
        totalSize: response.data.total,
        lastPage: response.data.last_page,
      };
    });
  }, [pageData.page, pageData.sizePerPage]);

  const handlePageChange = (page, sizePerPage) => {
    setPageData({
      ...pageData,
      page,
      sizePerPage,
    });
  };

  useEffect(() => {
    fetchCodes();
  }, [fetchCodes]);

  return (
    <MDBContainer fluid>
      <SectionWithTitle title={`${formatMessage(messages.PixelCodes)}`}>
        <MDBRow
          style={{ marginBottom: '20px' }}
          className="justify-content-center"
        >
          <MDBCol md="3">
            <MDBBtn
              onClick={() => history.push('/pixelcodes/add')}
              color="primary"
              block
            >
              <MDBIcon icon="plus" className="left" />{' '}
              {formatMessage(messages.AddNew)}
            </MDBBtn>
          </MDBCol>
        </MDBRow>

        <Pagination
          last={pageData.lastPage}
          active={pageData.page}
          size={pageData.sizePerPage}
          handlePageChange={handlePageChange}
        />
        {loading ? (
          <MDBSpinner />
        ) : (
          <div className="row text-center">
            <div className="col-12">
              <table className="table table-striped table-bordered table-sm table-fixed-header">
                <thead>
                  <tr>
                    <th>{formatMessage(messages.Id)}</th>
                    <th>{formatMessage(messages.Name)}</th>
                    <th>{formatMessage(messages.FireCount)}</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  <ReactTooltip effect="solid" multiline={true} />
                  {codes.map((row, i) => (
                    <tr key={i} style={{ cursor: 'pointer' }}>
                      <td>
                        <div data-tip={row.id}>{row.id}</div>
                      </td>
                      <td>
                        <div data-tip={row.name}>{row.name}</div>
                      </td>
                      <td>
                        <div data-tip={row.counter}>{row.counter}</div>
                      </td>

                      <div
                        className="btn-group"
                        role="group"
                        aria-label="options"
                      >
                        <MDBBtn
                          outline
                          color="info"
                          size="sm"
                          onClick={() => {
                            history.push('/pixelcodes/edit', { data: row });
                          }}
                          data-tip={formatMessage(messages.Edit)}
                        >
                          <MDBIcon icon="pencil-alt" />{' '}
                        </MDBBtn>
                      </div>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </SectionWithTitle>
    </MDBContainer>
  );
};

export default injectIntl(PixelCodes);
