import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { toast, Button, MDBRow, MDBCol, MDBContainer } from 'mdbreact';
import { sheethooksSave } from '../../api';
import history from '../../core/history';

import messages from './messages';
import SectionWithTitle from '../Common/SectionWithTitle';
import TextInput from '../TextInput';

const SheethooksAdd = ({
  intl: { formatMessage },
  location: { state: { content_type, content_id } = {} },
}) => {
  const [sheetId, setSheetId] = useState('');
  const [name, setName] = useState('');
  const [saving, setSaving] = useState(false);

  const onFormSubmit = async (e) => {
    e.preventDefault();
    try {
      setSaving(true);
      await sheethooksSave({
        sheet_id: sheetId,
        name,
        content_type,
        content_id: parseInt(content_id, 10),
      });
      history.push('/sheethooks', { type: content_type, id: content_id });
      toast.success(formatMessage(messages.SheethookAdded));
    } catch (e) {
      setSaving(false);
      toast.error(formatMessage(messages.ErrorSaving));
    }
  };

  return (
    <MDBContainer fluid>
      <MDBRow>
        <MDBCol className=".col-12 .col-sm-6 .col-md-8">
          <SectionWithTitle title={formatMessage(messages.AddNew)}>
            <h5>
              <p className="text-center">
                {formatMessage(messages.ShareEmail)}
              </p>
            </h5>
            <p className="text-center">
              api-184@poltio-164412.iam.gserviceaccount.com
            </p>
            <form method="post" onSubmit={onFormSubmit}>
              <div className="grey-text">
                <TextInput
                  label={formatMessage(messages.Type)}
                  group
                  type="text"
                  name="type"
                  value={content_type}
                  icon="disabled"
                  disabled
                />
                <TextInput
                  label={formatMessage(messages.ID)}
                  group
                  type="text"
                  name="id"
                  value={content_id}
                  icon="disabled"
                  disabled
                />
                <TextInput
                  label={formatMessage(messages.Name)}
                  group
                  type="text"
                  name="name"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  icon="signature"
                  infoText={formatMessage(messages.NameInfo)}
                />
                <TextInput
                  type="text"
                  label={formatMessage(messages.SheetId)}
                  group
                  name="sheetId"
                  onChange={(e) => {
                    setSheetId(e.target.value);
                  }}
                  value={sheetId}
                  rows="10"
                  icon="code"
                  infoText={formatMessage(messages.SheetIdInfo)}
                />
              </div>
              <div className="text-center" style={{ marginTop: '20px' }}>
                <Button color="primary" type="submit" disabled={saving}>
                  {formatMessage(messages.Save)}
                </Button>
              </div>
            </form>
            <MDBRow style={{ marginTop: '50px' }}>
              <MDBCol md="12">
                <img
                  src="https://mdbootstrap.com/img/Others/documentation/1.jpg"
                  className="rounded mx-auto d-block"
                  alt=""
                />
              </MDBCol>
            </MDBRow>
          </SectionWithTitle>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default injectIntl(SheethooksAdd);
