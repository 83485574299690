import React, { Component, Fragment } from 'react';
import { injectIntl } from 'react-intl';
import {
  MDBInput,
  Col,
  Row,
  MDBSelect,
  MDBSelectInput,
  MDBSelectOption,
  MDBSelectOptions,
  Select,
  SelectInput,
  SelectOption,
  SelectOptions,
  InputSwitch,
  MDBBtn,
  MDBIcon,
} from 'mdbreact';
import ReactTooltip from 'react-tooltip';

import messages from '../messages';
import ImageInput from '../../ImageInput';
import LeadSelector from '../LeadSelector';

class Step1 extends Component {
  state = {
    expanded: false,
  };

  render() {
    const { formatMessage } = this.props.intl;
    const {
      poll,
      handleTextChange,
      setSRC,
      getSRC,
      getSelectValue3,
      handleSwitchChange,
      handleTextChangeWithoutEmbedly,
      endDateEditClick,
      endDateEdited,
      leadSelect,
    } = this.props;

    let remTitleLimit =
      250 -
      (poll && poll.title && poll.title.length && poll.title.length > 0
        ? poll.title.length
        : 0);

    const inputSwitcher = (text, target, value, tooltip) => (
      <Row style={{ marginTop: '1.5rem' }}>
        <Col>
          <label>
            {text}{' '}
            <span data-tip={tooltip}>
              <MDBIcon far icon="question-circle" />
            </span>
            <ReactTooltip effect="solid" multiline={true} />
          </label>
        </Col>
        <Col>
          <InputSwitch
            onChange={(e) => handleSwitchChange(e, target)}
            checked={value}
            labelLeft={formatMessage(messages.Off)}
            labelRight={formatMessage(messages.On)}
          />
        </Col>
      </Row>
    );

    return (
      <Col md="12">
        <Col md="12">
          <MDBInput
            type="textarea"
            maxLength={250}
            label={`${formatMessage(messages.QuizTitle)} - ${remTitleLimit}`}
            className="mt-3"
            name="title"
            value={poll.title}
            onChange={handleTextChange}
          />
        </Col>
        <Col md="12">
          <MDBInput
            type="textarea"
            label={formatMessage(messages.Description)}
            className="mt-3"
            name="desc"
            value={poll.desc}
            onChange={handleTextChange}
          />
        </Col>
        {poll.friend_quiz ? (
          <Col md="12">
            <MDBInput
              type="textarea"
              label={formatMessage(messages.DescriptionSource)}
              className="mt-3"
              name="desc_source"
              value={poll.desc_source}
              onChange={handleTextChange}
            />
          </Col>
        ) : null}
        {poll.friend_quiz ? (
          <Col md="12">
            <MDBInput
              type="textarea"
              label={formatMessage(messages.ResultSource)}
              className="mt-3"
              name="result_source"
              value={poll.result_source}
              onChange={handleTextChange}
            />
          </Col>
        ) : null}
        <ImageInput
          title={formatMessage(messages.QuizImage)}
          setSRC={setSRC}
          getSRC={getSRC}
        />
        <ImageInput
          title={formatMessage(messages.EmbedBg)}
          setSRC={setSRC}
          getSRC={getSRC}
          target={'embed_background'}
        />
        <ImageInput
          title={formatMessage(messages.CustomLogo)}
          ratio={2}
          setSRC={setSRC}
          getSRC={getSRC}
          target={'embed_footer_img'}
        />
        <Col md="12">
          <MDBInput
            type="textarea"
            maxLength={250}
            label={`${formatMessage(messages.FooterLink)}`}
            className="mt-3 mb-1"
            name="embed_footer_url"
            value={poll.embed_footer_url || ''}
            onChange={handleTextChangeWithoutEmbedly}
          />
        </Col>
        <Col md="4">
          <LeadSelector index={0} leadSelect={leadSelect} id={poll.lead_id} />
        </Col>
        {this.state.expanded ? (
          <Col md="12">
            <Row style={{ margin: '1.5rem' }}>
              <Col className="text-center">
                <MDBBtn
                  color="primary"
                  onClick={() =>
                    this.setState({ expanded: !this.state.expanded })
                  }
                >
                  <strong>{`- ${formatMessage(messages.Collapse)}`}</strong>
                </MDBBtn>
              </Col>
            </Row>
            {inputSwitcher(
              formatMessage(messages.Hidden),
              'privacy',
              poll.privacy !== 'public',
              formatMessage(messages.HiddenTip)
            )}
            {inputSwitcher(
              formatMessage(messages.ShowCorrect),
              'gives_feedback',
              poll.gives_feedback === 1,
              formatMessage(messages.ShowCorrectTip)
            )}
            {inputSwitcher(
              formatMessage(messages.TimeRace),
              'show_timer',
              poll.show_timer === 1,
              formatMessage(messages.TimeRaceTip)
            )}
            {inputSwitcher(
              formatMessage(messages.HidePerc),
              'hide_results',
              poll.hide_results !== 0,
              formatMessage(messages.HidePercTip)
            )}
            {inputSwitcher(
              formatMessage(messages.HideVoteCounter),
              'hide_counter',
              poll.hide_counter === 0,
              formatMessage(messages.HideVoteCounterTip)
            )}
            {inputSwitcher(
              formatMessage(messages.ShowResults),
              'display_results',
              poll.display_results === 1,
              formatMessage(messages.ShowResultsTip)
            )}
            {inputSwitcher(
              formatMessage(messages.DisplayRepeat),
              'display_repeat',
              poll.display_repeat !== 0,
              formatMessage(messages.DisplayRepeatTip)
            )}
            {inputSwitcher(
              formatMessage(messages.FriendQuiz),
              'friend_quiz',
              poll.friend_quiz !== 0,
              formatMessage(messages.FriendQuizTip)
            )}
            {inputSwitcher(
              formatMessage(messages.PlayOnce),
              'play_once',
              poll.play_once === 1,
              formatMessage(messages.PlayOnceTip)
            )}
            {poll.play_once ? (
              <Fragment>
                <Row>
                  <Col>
                    <MDBSelect
                      getValue={(val) =>
                        getSelectValue3(val[0], 'play_once_strategy')
                      }
                      label={
                        poll.play_once_strategy === 'result'
                          ? formatMessage(messages.ResultOptionDesc)
                          : formatMessage(messages.StartOptionDesc)
                      }
                    >
                      <MDBSelectInput />
                      <MDBSelectOptions>
                        <MDBSelectOption
                          value={'result'}
                          checked={poll.play_once_strategy === 'result'}
                        >
                          {formatMessage(messages.ResultOption)}
                        </MDBSelectOption>
                        <MDBSelectOption
                          value={'start'}
                          checked={poll.play_once_strategy === 'start'}
                        >
                          {formatMessage(messages.StartOption)}
                        </MDBSelectOption>
                      </MDBSelectOptions>
                    </MDBSelect>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <MDBInput
                      type="text"
                      maxLength={250}
                      label={`${formatMessage(messages.PlayOnceMsg)}`}
                      className="mt-3 mb-1"
                      name="play_once_msg"
                      value={poll.play_once_msg}
                      onChange={handleTextChangeWithoutEmbedly}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <MDBInput
                      type="text"
                      maxLength={250}
                      label={`${formatMessage(messages.PlayOnceLink)}`}
                      className="mt-3 mb-1"
                      name="play_once_link"
                      value={poll.play_once_link}
                      onChange={handleTextChangeWithoutEmbedly}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <MDBInput
                      type="text"
                      maxLength={250}
                      label={`${formatMessage(messages.PlayOnceBtn)}`}
                      className="mt-3 mb-1"
                      name="play_once_btn"
                      value={poll.play_once_btn}
                      onChange={handleTextChangeWithoutEmbedly}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <ImageInput
                      title={formatMessage(messages.PlayOnceImg)}
                      setSRC={setSRC}
                      target={'play_once_img'}
                    />
                  </Col>
                </Row>
              </Fragment>
            ) : null}
            <MDBInput
              label={formatMessage(messages.TimePerQ)}
              className="mt-3"
              name="time_per_question"
              value={poll.time_per_question}
              onChange={handleTextChange}
            />
            <MDBInput
              label={formatMessage(messages.TimeLimit)}
              className="mt-3"
              name="time_limit"
              value={poll.time_limit}
              onChange={handleTextChange}
            />
            <Row style={{ marginTop: '1.5rem' }}>
              <Col>
                <label>
                  {formatMessage(messages.EndDate)}{' '}
                  <span data-tip={formatMessage(messages.EndDateTip)}>
                    <MDBIcon far icon="question-circle" />
                  </span>
                  <ReactTooltip effect="solid" multiline={true} />
                </label>
              </Col>
              <Col>
                <InputSwitch
                  onChange={(e) => handleSwitchChange(e, 'end_date')}
                  checked={poll.end_date ? true : false}
                  labelLeft={formatMessage(messages.Off)}
                  labelRight={formatMessage(messages.On)}
                />
              </Col>
            </Row>
            {poll.end_date ? (
              endDateEdited ? (
                <Row style={{ marginTop: '1.5rem' }}>
                  <Col md="4">
                    <label>{formatMessage(messages.Days)}:</label>
                    <Select
                      getValue={(val) =>
                        getSelectValue3(val[0], 'end_date_day')
                      }
                    >
                      <SelectInput selected={poll.end_date_day} />
                      <SelectOptions>
                        {[...Array(32).keys()].map((elem, index) => (
                          <SelectOption
                            value={`0${index}`.slice(-2)}
                            key={`day${index}`}
                          >
                            {`0${index}`.slice(-2)}
                          </SelectOption>
                        ))}
                      </SelectOptions>
                    </Select>
                  </Col>
                  <Col md="4">
                    <label>{formatMessage(messages.Hours)}:</label>
                    <Select
                      getValue={(val) =>
                        getSelectValue3(val[0], 'end_date_hour')
                      }
                    >
                      <SelectInput selected={poll.end_date_hour} />
                      <SelectOptions>
                        {[...Array(24).keys()].map((elem, index) => (
                          <SelectOption
                            value={`0${index}`.slice(-2)}
                            key={`hour${index}`}
                          >
                            {`0${index}`.slice(-2)}
                          </SelectOption>
                        ))}
                      </SelectOptions>
                    </Select>
                  </Col>
                  <Col md="4">
                    <label>{formatMessage(messages.Minutes)}:</label>
                    <Select
                      getValue={(val) =>
                        getSelectValue3(val[0], 'end_date_minute')
                      }
                    >
                      <SelectInput selected={poll.end_date_minute} />
                      <SelectOptions>
                        {[...Array(60).keys()].map((elem, index) => (
                          <SelectOption
                            value={`0${index}`.slice(-2)}
                            key={`minute${index}`}
                          >
                            {`0${index}`.slice(-2)}
                          </SelectOption>
                        ))}
                      </SelectOptions>
                    </Select>
                  </Col>
                </Row>
              ) : (
                <MDBBtn size="sm" onClick={endDateEditClick}>
                  {formatMessage(messages.EditEndDate)}
                </MDBBtn>
              )
            ) : null}
            <MDBInput
              label={formatMessage(messages.PoolQuestionCount)}
              type="number"
              name="pool_question_count"
              value={poll.pool_question_count || ''}
              onChange={handleTextChange}
            />
          </Col>
        ) : (
          <Row style={{ margin: '1.5rem' }}>
            <Col className="text-center">
              <MDBBtn
                color="primary"
                onClick={() =>
                  this.setState({ expanded: !this.state.expanded })
                }
              >
                <strong>{`+ ${formatMessage(messages.Expand)}`}</strong>
              </MDBBtn>
            </Col>
          </Row>
        )}
      </Col>
    );
  }
}

export default injectIntl(Step1);
