import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import {
  MDBInput,
  toast,
  Button,
  MDBRow,
  MDBCol,
  MDBContainer,
  MDBBadge,
} from 'mdbreact';
import { pcSave, pcDelete } from '../../api';
import history from '../../core/history';
import { validateHtml } from '../../core/helper';

import messages from './messages';
import SectionWithTitle from '../Common/SectionWithTitle';

const PixelCodesEdit = ({ location, intl: { formatMessage } }) => {
  const [code, setCode] = useState(location.state.data.code);
  const [name, setName] = useState(location.state.data.name);
  const [saving, setSaving] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [event, setEvent] = useState({ target: {} });

  const onFormSubmit = async (e) => {
    e.preventDefault();
    if (!validateHtml(code)) {
      toast.warn(formatMessage(messages.EnterValidHtml));
      return;
    }

    try {
      setSaving(true);
      await pcSave(code, name, location.state.data.id);
      history.push('/pixelcodes');
      toast.success(formatMessage(messages.PcAdded));
    } catch (e) {
      setSaving(false);
      toast.warn(formatMessage(messages.ErrorSaving));
    }
  };

  const onDelete = async (e) => {
    e.preventDefault();
    const { id, is_in_use } = location.state.data;
    try {
      if (
        window.confirm(
          is_in_use
            ? formatMessage(messages.InUse)
            : formatMessage(messages.AreYouSure)
        )
      ) {
        setDeleting(true);
        await pcDelete(id);
        history.push('/pixelcodes');
        toast.success(formatMessage(messages.PcDeleted));
      } else {
        return;
      }
    } catch (e) {
      setDeleting(false);
      toast.warn(formatMessage(messages.ErrorDeleting));
    }
  };

  const handleChange = (val) => {
    const startPos = event.target.selectionStart || code.length;
    const endPos = event.target.selectionEnd || code.length;
    setCode(
      (code) =>
        code.substring(0, startPos) + val + code.substring(endPos, code.length)
    );
  };

  return (
    <MDBContainer fluid>
      <MDBRow>
        <MDBCol className=".col-12 .col-sm-6 .col-md-8">
          <SectionWithTitle title={formatMessage(messages.EditPc)}>
            <form method="post" onSubmit={onFormSubmit}>
              <div className="grey-text">
                <MDBInput
                  label={formatMessage(messages.TypeName)}
                  group
                  type="text"
                  name="name"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  icon="signature"
                />
                <MDBInput
                  label={formatMessage(messages.Type)}
                  group
                  type="textarea"
                  name="code"
                  onChange={(e) => {
                    e.persist();
                    setCode(e.target.value.trim());
                    setEvent(e);
                  }}
                  onFocus={(e) => {
                    e.persist();
                    setCode(e.target.value);
                    setEvent(e);
                  }}
                  value={code}
                  rows="10"
                  icon="code"
                />
              </div>
              <section className="section-preview text-center">
                {formatMessage(messages.AddParams)}
              </section>
              <section className="section-preview text-center">
                <MDBBadge
                  pill
                  tag="a"
                  color="primary"
                  onClick={() => handleChange('[parent_page_url]')}
                >
                  {formatMessage(messages.ParentPage)}
                </MDBBadge>
                <MDBBadge
                  pill
                  tag="a"
                  color="default"
                  onClick={() => handleChange('[q_title]')}
                >
                  {formatMessage(messages.QuestionTitle)}
                </MDBBadge>
                <MDBBadge
                  pill
                  tag="a"
                  color="primary"
                  onClick={() => handleChange('[q_number]')}
                >
                  {formatMessage(messages.QuestionNumber)}
                </MDBBadge>
                <MDBBadge
                  pill
                  tag="a"
                  color="secondary"
                  onClick={() => handleChange('[q_id]')}
                >
                  {formatMessage(messages.QuestionID)}
                </MDBBadge>
                <MDBBadge
                  pill
                  tag="a"
                  color="success"
                  onClick={() => handleChange('[a_number]')}
                >
                  {formatMessage(messages.AnswerNumber)}
                </MDBBadge>
                <MDBBadge
                  pill
                  tag="a"
                  color="info"
                  onClick={() => handleChange('[a_id]')}
                >
                  {formatMessage(messages.AnswerID)}
                </MDBBadge>
                <MDBBadge
                  pill
                  tag="a"
                  color="warning"
                  onClick={() => handleChange('[a_title]')}
                >
                  {formatMessage(messages.AnswerTitle)}
                </MDBBadge>
              </section>
              <div className="text-center" style={{ marginTop: '20px' }}>
                <Button color="primary" type="submit" disabled={saving}>
                  {formatMessage(messages.Save)}
                </Button>
                <Button color="danger" onClick={onDelete} disabled={deleting}>
                  {formatMessage(messages.Delete)}
                </Button>
              </div>
            </form>
          </SectionWithTitle>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default injectIntl(PixelCodesEdit);
