import { defineMessages } from 'react-intl.macro';

export default defineMessages({
  GetReport: {
    id: 'Reports.GetReport',
    defaultMessage: 'Get Report',
  },
  EnterId: {
    id: 'Reports.EnterId',
    defaultMessage: 'Enter Id',
  },
  Download: {
    id: 'Reports.Download',
    defaultMessage: 'Download',
  },
  Submitted: {
    id: 'Reports.Submitted',
    defaultMessage: 'Report request submitted!',
  },
  SeeRequests: {
    id: 'Reports.SeeRequests',
    defaultMessage: 'See All Requests',
  },
  YourRequests: {
    id: 'Reports.YourRequests',
    defaultMessage: 'Your Report Requests',
  },
});
