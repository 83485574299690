import React from 'react';
import { Spinner } from 'mdbreact';

let AnswerInput = ({ onDelete, src, title, fileChange, isLoading }) => {
  return (
    <label
      htmlFor=""
      className="collapse-media__element button button--block button--secondary-border"
    >
      {isLoading && (
        <div className="text-center">
          <Spinner big blue />
        </div>
      )}
      {src && !isLoading && (
        <div>
          <span className="button button--black button--edit button--size-sm js-edit">
            <i className="fa fa-edit" aria-hidden="true" />
            <input type="file" className="input-file" onChange={fileChange} />
          </span>
          <picture
            className="collapse-media__picture"
            id="collapse-media__picture1"
          >
            <img src={src} alt="" />
          </picture>
          <span
            className="button button--black button--edit button--size-sm js-edit"
            style={{ bottom: 0, top: 'auto' }}
            onClick={onDelete}
          >
            <i className="fa fa-trash" aria-hidden="true" />
          </span>
        </div>
      )}
      {!src && !isLoading && (
        <div>
          <span>{title}</span>
          <input type="file" className="input-file" onChange={fileChange} />
        </div>
      )}
    </label>
  );
};

export default AnswerInput;
