import { defineMessages } from 'react-intl.macro';

export default defineMessages({
  Day: {
    id: 'Home.Day',
    defaultMessage: 'Day',
  },
  Week: {
    id: 'Home.Week',
    defaultMessage: 'Week',
  },
  Month: {
    id: 'Home.Month',
    defaultMessage: 'Month',
  },
  Total: {
    id: 'Home.Total',
    defaultMessage: 'Total',
  },
  Graph1: {
    id: 'Home.Graph1',
    defaultMessage: 'Vote Performance in the last 7 days',
  },
  Graph2: {
    id: 'Home.Graph2',
    defaultMessage: 'Content Performance in the last 7 days',
  },
  Graph3: {
    id: 'Home.Graph3',
    defaultMessage: 'Monthly votes',
  },
  Graph4: {
    id: 'Home.Graph4',
    defaultMessage: 'Monthly votes for the last 10 months',
  },
  GraphCalendar: {
    id: 'Home.GraphCalendar',
    defaultMessage: 'Contents',
  },
  NoData: {
    id: 'Home.NoData',
    defaultMessage: 'No data available',
  },
  TotalVote: {
    id: 'Home.TotalVote',
    defaultMessage: 'Total Vote Count',
  },
  TotalVoters: {
    id: 'Home.TotalVoters',
    defaultMessage: 'Total Voters',
  },
  LeadCount: {
    id: 'Home.LeadCount',
    defaultMessage: 'Lead Count',
  },
  TotalContent: {
    id: 'Home.TotalContent',
    defaultMessage: 'Total Content',
  },
  TotalView: {
    id: 'Home.TotalView',
    defaultMessage: 'Total View',
  },
  VoteCount: {
    id: 'Home.VoteCount',
    defaultMessage: 'Vote Count',
  },
  MonthlyVoteCounts: {
    id: 'Home.MonthlyVoteCounts',
    defaultMessage: 'Monthly Vote Counts',
  },
  VoteGraphs: {
    id: 'Home.VoteGraphs',
    defaultMessage: 'Vote Graphs',
  },
  Contents: {
    id: 'Home.Contents',
    defaultMessage: 'Contents',
  },
  TodayVoteVsYesterday: {
    id: 'Home.TodayVoteVsYesterday',
    defaultMessage: `Today's vote vs. yesterday`,
  },
  BetterThanYesterday: {
    id: 'Home.Better',
    defaultMessage: 'Better than yesterday',
  },
  WorseThanYesterday: {
    id: 'Home.Worse',
    defaultMessage: 'Worse than yesterday',
  },
  ThisMonthVoteVsLastMonth: {
    id: 'Home.ThisMonthVoteVsLastMonth',
    defaultMessage: `This month's vote vs. last month`,
  },
  BetterThanLastMonth: {
    id: 'Home.BetterThanLastMonth',
    defaultMessage: 'Better than last month',
  },
  WorseThanLastMonth: {
    id: 'Home.WorseThanLastMonth',
    defaultMessage: 'Worse than last month',
  },
  ThisWeekContentVsLastWeek: {
    id: 'Home.ThisWeekContentVsLastWeek',
    defaultMessage: `This week's content vs. last month`,
  },
  BetterThanLastWeek: {
    id: 'Home.BetterThanLastWeek',
    defaultMessage: 'Better than last week',
  },
  WorseThanLastWeek: {
    id: 'Home.WorseThanLastWeek',
    defaultMessage: 'Worse than last week',
  },
  VoteCounts: {
    id: 'Home.VoteCounts',
    defaultMessage: 'Vote Counts',
  },
  SelectDate: {
    id: 'Home.SelectDate',
    defaultMessage: 'Select Date',
  },
  TotalSelectedVote: {
    id: 'Home.TotalSelectedVote',
    defaultMessage: 'Total',
  },
  SelectAllDates: {
    id: 'Home.SelectAllDates',
    defaultMessage: 'Select All',
  },
  ContentStats: {
    id: 'Home.ContentStats',
    defaultMessage: 'Content Stats',
  },
  StartDate: {
    id: 'Home.StartDate',
    defaultMessage: 'Start Date',
  },
  EndDate: {
    id: 'Home.EndDate',
    defaultMessage: 'End Date',
  },
  NumberOfContent: {
    id: 'Home.NumberOfContent',
    defaultMessage: 'Number of content',
  },
  Close: {
    id: 'Home.Close',
    defaultMessage: 'Close',
  },
  Continue: {
    id: 'Home.Continue',
    defaultMessage: 'Agree',
  },
  UpdatedTc: {
    id: 'Home.UpdatedTc',
    defaultMessage:
      'We have updated our terms and conditions. To see please click. You can access Poltio by agreeing terms and conditions',
  },
});
