import { defineMessages } from 'react-intl.macro';

export default defineMessages({
  TotalVote: {
    id: 'ContentDetail.TotalVote',
    defaultMessage: 'Total Vote',
  },
  ViewCount: {
    id: 'ContentDetail.ViewCount',
    defaultMessage: 'View Count',
  },
  UniqueVote: {
    id: 'ContentDetail.UniqueVote',
    defaultMessage: 'Unique Voter',
  },
  CompletionRate: {
    id: 'ContentDetail.CompletionRate',
    defaultMessage: 'Completion Rate',
  },
  VoteDetails: {
    id: 'ContentDetail.VoteDetails',
    defaultMessage: 'Vote Details',
  },
  CompletionDetails: {
    id: 'ContentDetail.CompletionDetails',
    defaultMessage: 'Completion Details',
  },
  Daily: {
    id: 'ContentDetail.Daily',
    defaultMessage: 'Daily',
  },
  Weekly: {
    id: 'ContentDetail.Weekly',
    defaultMessage: 'Weekly',
  },
  Monthly: {
    id: 'ContentDetail.Monthly',
    defaultMessage: 'Monthly',
  },
  Yearly: {
    id: 'ContentDetail.Yearly',
    defaultMessage: 'Yearly',
  },
  NoData: {
    id: 'ContentDetail.NoData',
    defaultMessage: 'No Data',
  },
  QuizStats: {
    id: 'ContentDetail.QuizStats',
    defaultMessage: 'Test/Quiz Stats',
  },
  Correct: {
    id: 'ContentDetail.Correct',
    defaultMessage: 'Correct Answer',
  },
  Wrong: {
    id: 'ContentDetail.Wrong',
    defaultMessage: 'Wrong Answer',
  },
  Finish: {
    id: 'ContentDetail.Finish',
    defaultMessage: '% Users finishing the test/quiz',
  },
  NotFinish: {
    id: 'ContentDetail.NotFinish',
    defaultMessage: '% Users dropping out',
  },
  QuizCompletionRate: {
    id: 'ContentDetail.QuizCompletionRate',
    defaultMessage: 'Completion Rate',
  },
  ResultsSplit: {
    id: 'ContentDetail.ResultsSplit',
    defaultMessage: '% Split of results',
  },
  AveragePlayPerUser: {
    id: 'ContentDetail.AveragePlayPerUser',
    defaultMessage: 'Average Play Per User',
  },
  TimesTaken: {
    id: 'ContentDetail.TimesTaken',
    defaultMessage: '# of Times Started',
  },
  TimesFinished: {
    id: 'ContentDetail.TimesFinished',
    defaultMessage: '# of Times Finished',
  },
  NumberQuestions: {
    id: 'ContentDetail.NumberQuestions',
    defaultMessage: '# of Questions',
  },
  AverageNumberCorrect: {
    id: 'ContentDetail.AverageNumberCorrect',
    defaultMessage: 'Average Correct Answers',
  },
  Answer: {
    id: 'ContentDetail.Answer',
    defaultMessage: 'Answer',
  },
  Voters: {
    id: 'ContentDetail.Voters',
    defaultMessage: 'Voters',
  },
  VoteSources: {
    id: 'ContentDetail.VoteSources',
    defaultMessage: 'Vote Sources',
  },
  ResultUrls: {
    id: 'ContentDetail.ResultUrls',
    defaultMessage: 'Result Urls',
  },
  BetterThanLastWeek: {
    id: 'ContentDetail.BetterThanLastWeek',
    defaultMessage: 'Better than last week',
  },
  WorseThanLastWeek: {
    id: 'ContentDetail.WorseThanLastWeek',
    defaultMessage: 'Worse than last week',
  },
  ThisWeekVoteVsLastWeek: {
    id: 'ContentDetail.ThisWeekVoteVsLastWeek',
    defaultMessage: `This week's vote vs. last week`,
  },
  ThisWeekVoterVsLastWeek: {
    id: 'ContentDetail.ThisWeekVoterVsLastWeek',
    defaultMessage: `This week's voters vs. last week`,
  },
  TodayStartVsYesterday: {
    id: 'ContentDetail.TodayStartVsYesterday',
    defaultMessage: `Today's start/finish vs yesterday`,
  },
  BetterThanYesterday: {
    id: 'ContentDetail.BetterThanYesterday',
    defaultMessage: 'Better than yesterday',
  },
  WorseThanYesterday: {
    id: 'ContentDetail.WorseThanYesterday',
    defaultMessage: 'Worse than yesterday',
  },
  NotFound: {
    id: 'ContentDetail.NotFound',
    defaultMessage: 'Content was not found!',
  },
  EditContent: {
    id: 'ContentDetail.EditContent',
    defaultMessage: 'Edit Content',
  },
  CopyLink: {
    id: 'ContentDetail.CopyLink',
    defaultMessage: 'Copy Link',
  },
  Copied: {
    id: 'ContentDetail.Copied',
    defaultMessage: 'Link Copied',
  },
  Error: {
    id: 'ContentDetail.Error',
    defaultMessage: 'Error copying link!',
  },
  Delete: {
    id: 'ContentDetail.Delete',
    defaultMessage: 'Delete',
  },
  ContentDeleted: {
    id: 'ContentDetail.ContentDeleted',
    defaultMessage: 'Content Deleted',
  },
  Sure: {
    id: 'ContentDetail.Sure',
    defaultMessage: 'Content will be deleted!',
  },
  SuccessSetting: {
    id: 'ContentDetail.SuccessSetting',
    defaultMessage: 'Settings saved!',
  },
  ErrorSetting: {
    id: 'ContentDetail.ErrorSetting',
    defaultMessage: 'Error occured!',
  },
  EmbedCode: {
    id: 'ContentDetail.EmbedCode',
    defaultMessage: 'EmbedCode',
  },
  VotesReport: {
    id: 'ContentDetail.VotesReport',
    defaultMessage: 'Votes Report',
  },
  AnswersReport: {
    id: 'ContentDetail.AnswersReport',
    defaultMessage: 'Quiz Winners Report',
  },
  TestReport: {
    id: 'ContentDetail.TestReport',
    defaultMessage: 'Test Report',
  },
  SetReport: {
    id: 'ContentDetail.SetReport',
    defaultMessage: 'Set Report',
  },
  WebHooks: {
    id: 'ContentDetail.WebHooks',
    defaultMessage: 'Web Hooks',
  },
  SheetHooks: {
    id: 'ContentDetail.SheetHooks',
    defaultMessage: 'Sheet Hooks',
  },
  Close: {
    id: 'ContentDetail.Close',
    defaultMessage: 'Close',
  },
  AnswerVotersReport: {
    id: 'ContentDetail.AnswerVotersReport',
    defaultMessage: 'Users By Answer',
  },
  Cancel: {
    id: 'ContentDetail.Cancel',
    defaultMessage: 'Cancel',
  },
  GetReport: {
    id: 'ContentDetail.GetReport',
    defaultMessage: 'Get Report',
  },
});
