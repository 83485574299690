/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import {
  Row,
  Col,
  Container,
  Stepper,
  Step,
  toast,
  Card,
  CardBody,
  MDBIcon,
  MDBBtn,
} from 'mdbreact';
import ReactTooltip from 'react-tooltip';
import {
  createTest,
  finishTest,
  getPublisherSettings,
  setPublisherSettings,
} from '../../../api';
import history from '../../../core/history';

import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Prompt from '../Prompt';
import messages from '../messages';

const initialState = {
  formActivePanel: 1,
  prompt: false,
  quizData: {
    title: '',
    background: '',
    desc: '',
    privacy: 'private',
    results: [
      {
        title: '',
        background: '',
        desc: '',
        url: '',
        url_text: '',
      },
      {
        title: '',
        background: '',
        desc: '',
        url: '',
        url_text: '',
      },
    ],
    display_repeat: 0,
    hide_counter: 0,
    hide_results: 0,
    play_once: 0,
    play_once_strategy: 'result',
    play_once_msg: null,
    play_once_img: null,
    play_once_link: null,
    play_once_btn: null,
    embed_background: '',
    embed_footer_img: '',
    embed_footer_url: null,
    end_date: 0,
    end_date_day: '00',
    end_date_hour: '00',
    end_date_minute: '00',
  },
  questions: [],
  src: null,
  crop: {
    x: 0,
    y: 0,
    width: 50,
    aspect: 9 / 5,
  },
  pixelCrop: null,
  cropped: false,
  testResults: [],
};

class TestCreate extends Component {
  state = initialState;

  async componentDidMount() {
    const response = await getPublisherSettings('test');

    this.setState({
      quizData: {
        ...this.state.quizData,
        ...response.data,
        end_date: 0,
      },
    });
  }

  pixelCode = (index, pc) => {
    if (pc) {
      this.setState({
        quizData: {
          ...this.state.quizData,
          results: this.state.quizData.results.map((a, i) => {
            if (i === index) {
              return {
                ...a,
                pixel_code_id: pc.id,
                pixel_code_name: pc.name,
              };
            }
            return a;
          }),
        },
      });
    }
  };

  leadSelect = (index, lead) => {
    if (lead) {
      this.setState({
        quizData: {
          ...this.state.quizData,
          results: this.state.quizData.results.map((a, i) => {
            if (i === index) {
              return {
                ...a,
                lead_id: lead.id,
                lead_name: lead.name,
              };
            }
            return a;
          }),
        },
      });
    }
  };

  togglePrompt = () => {
    this.setState({
      prompt: !this.state.prompt,
    });
  };

  handleNextPrevClick = (param) => (e) => {
    const { formatMessage } = this.props.intl;
    if (param === 2 && this.state.optionsDirty) {
      this.setState({ prompt: true });
    }
    if (param === 3) {
      let validated = true;
      this.state.quizData.results.forEach((r) => {
        if (!r.dont_shorten && r.url && !r.url.startsWith('http')) {
          toast.error(formatMessage(messages.ValidUrl));
          validated = false;
        }
      });
      if (validated) {
        this.createTest();
        this.setState({
          formActivePanel: param,
        });
      }
    } else {
      this.setState({
        formActivePanel: param,
      });
    }
  };

  onYesClick = async () => {
    const { quizData } = this.state;
    this.togglePrompt();

    await setPublisherSettings('test', {
      privacy: quizData.privacy,
      display_repeat: quizData.display_repeat,
      hide_counter: quizData.hide_counter,
      hide_results: quizData.hide_results,
    });
  };

  createTest = async () => {
    const { quizData, src, testCreated, createdTestId } = this.state;
    try {
      const r2 = await createTest(quizData, src, testCreated, createdTestId);
      this.setState({
        createdTestId: r2.data.test.id,
        testResults: r2.data.results,
        testFinished: false,
        testCreated: true,
      });
    } catch (e) {
      console.log(e);
    }
  };

  finishTest = async () => {
    const { formatMessage } = this.props.intl;

    try {
      await finishTest(this.state.createdTestId);
      toast.success(formatMessage(messages.ContentCreated));
      history.push('/content');
    } catch (e) {
      toast.error('Error occured!');
    }
  };

  handleTextChange = async (e) => {
    const inputText = e.target.value;
    this.setState({
      quizData: {
        ...this.state.quizData,
        [e.target.name]: inputText,
      },
    });
  };

  handleTextChangeWithoutEmbedly = (e) => {
    this.setState({
      quizData: {
        ...this.state.quizData,
        [e.target.name]: e.target.value === '' ? null : e.target.value,
      },
    });
  };

  setSRC = (src, target) => {
    if (target && target.length > 0) {
      this.setState({
        quizData: {
          ...this.state.quizData,
          [target]: src,
        },
      });
    } else {
      this.setState({ src: src });
    }
  };

  getSelectValue = (value, name) => {
    if (value) {
      this.setState({
        quizData: {
          ...this.state.quizData,
          [name]: value,
        },
      });
    }
  };

  handleSwitchChange = (e, name) => {
    if (name === 'privacy') {
      this.setState({
        quizData: {
          ...this.state.quizData,
          [name]: e.target.value === 'true' ? 'public' : 'private',
        },
      });
    } else if (name === 'play_once') {
      this.setState({
        quizData: {
          ...this.state.quizData,
          [name]: e.target.checked ? 1 : 0,
        },
      });
    } else if (name === 'hide_counter') {
      this.setState({
        quizData: {
          ...this.state.quizData,
          [name]: e.target.value === 'true' ? 1 : 0,
        },
      });
    } else if (name === 'end_date') {
      this.setState({
        quizData: {
          ...this.state.quizData,
          [name]: e.target.value === 'true' ? 0 : 1,
          end_date_day: e.target.value === 'true' ? '00' : '30',
          end_date_hour: '00',
          end_date_minute: '00',
        },
      });
    } else {
      this.setState({
        quizData: {
          ...this.state.quizData,
          [name]: e.target.value === 'true' ? 0 : 1,
        },
      });
    }
    if (name !== 'end_date') {
      this.setState({ optionsDirty: true });
    }
  };

  getSelectValue2 = (value, name) => {
    if (value === '3' || value === '0' || !value) return;
    this.setState({
      quizData: {
        ...this.state.quizData,
        [name]: value,
      },
      optionsDirty: true,
    });
  };

  setResultImg = (index, value) => {
    const { quizData } = this.state;

    this.setState({
      quizData: {
        ...quizData,
        results: quizData.results.map((a, i) => {
          if (index === i) {
            return { ...a, background: value };
          }
          return a;
        }),
      },
    });
  };

  changeNumber = (e, direction) => {
    e.preventDefault();
    if (direction === 'add') {
      this.setState({
        quizData: {
          ...this.state.quizData,
          results: [
            ...this.state.quizData.results,
            { title: '', background: '', desc: '' },
          ],
        },
      });
    } else if (direction === 'delete') {
      this.setState({
        quizData: {
          ...this.state.quizData,
          results: this.state.quizData.results.slice(0, -1),
        },
      });
    }
  };

  handleAnswerChange = (e, i) => {
    const { name, value } = e.target;
    this.setState({
      quizData: {
        ...this.state.quizData,
        results: this.state.quizData.results.map((a, index) => {
          if (index === i) {
            return { ...a, [name]: name === 'url' ? value.trim() : value };
          }
          return a;
        }),
      },
    });
  };

  handleResultSwitchChange = (e, i) => {
    this.setState({
      quizData: {
        ...this.state.quizData,
        results: this.state.quizData.results.map((a, index) => {
          if (index === i) {
            return { ...a, dont_shorten: e.target.checked ? 1 : 0 };
          }
          return a;
        }),
      },
    });
  };

  setQuestions = (questions) => this.setState({ questions });

  copyQuestion = (qi) => {
    const { questions } = this.state;
    const qu = questions[qi];
    this.setState({
      questions: [
        ...questions,
        {
          ...qu,
          id: null,
          answers: qu.answers.map((a) => {
            return {
              ...a,
              id: null,
            };
          }),
        },
      ],
    });
  };

  leadSelectSet = (i, lead) => {
    if (lead) {
      this.setState({
        quizData: {
          ...this.state.quizData,
          lead_id: lead.id,
          lead_name: lead.name,
        },
      });
    }
  };

  render() {
    const {
      quizData,
      src,
      createdTestId,
      testFinished,
      prompt,
      testResults,
      questions,
    } = this.state;
    const { formatMessage } = this.props.intl;

    return (
      <Container>
        <Prompt
          toggleModal={this.togglePrompt}
          modal={prompt}
          onYesClick={this.onYesClick}
        />
        <Row>
          <Col md="7">
            <Card>
              <CardBody>
                <h2 className="text-center font-weight-bold pt-4 pb-5">
                  <strong>{formatMessage(messages.CreateTest)}</strong>
                </h2>

                <Stepper form>
                  <Step form>
                    <a href="" onClick={(e) => e.preventDefault()}>
                      <button
                        type="button"
                        className={`btn btn-${
                          this.state.formActivePanel === 1
                            ? 'indigo'
                            : 'default'
                        } btn-circle Ripple-parent`}
                      >
                        1
                        <div
                          className="Ripple "
                          style={{
                            top: '0px',
                            left: '0px',
                            width: '0px',
                            height: '0px',
                          }}
                        />
                      </button>
                    </a>
                    <p>{formatMessage(messages.TestInfo)}</p>
                  </Step>
                  <Step form>
                    <a href="" onClick={(e) => e.preventDefault()}>
                      <button
                        type="button"
                        className={`btn btn-${
                          this.state.formActivePanel === 2
                            ? 'indigo'
                            : 'default'
                        } btn-circle Ripple-parent`}
                      >
                        2
                        <div
                          className="Ripple "
                          style={{
                            top: '0px',
                            left: '0px',
                            width: '0px',
                            height: '0px',
                          }}
                        />
                      </button>
                    </a>
                    <p>{formatMessage(messages.TestResults)}</p>
                  </Step>
                  <Step form>
                    <a href="" onClick={(e) => e.preventDefault()}>
                      <button
                        type="button"
                        className={`btn btn-${
                          this.state.formActivePanel === 3
                            ? 'indigo'
                            : 'default'
                        } btn-circle Ripple-parent`}
                      >
                        3
                        <div
                          className="Ripple "
                          style={{
                            top: '0px',
                            left: '0px',
                            width: '0px',
                            height: '0px',
                          }}
                        />
                      </button>
                    </a>
                    <p>{formatMessage(messages.TestQuestions)}</p>
                  </Step>
                </Stepper>

                <Row>
                  {this.state.formActivePanel === 1 && (
                    <Step1
                      handleNextPrevClick={this.handleNextPrevClick}
                      handleTextChange={this.handleTextChange}
                      poll={quizData}
                      src={src}
                      setSRC={this.setSRC}
                      getSelectValue={this.getSelectValue}
                      getSelectValue2={this.getSelectValue2}
                      handleSwitchChange={this.handleSwitchChange}
                      handleTextChangeWithoutEmbedly={
                        this.handleTextChangeWithoutEmbedly
                      }
                      leadSelect={this.leadSelectSet}
                    />
                  )}
                  {this.state.formActivePanel === 2 && (
                    <Step2
                      handleNextPrevClick={this.handleNextPrevClick}
                      quizData={quizData}
                      setResultImg={this.setResultImg}
                      changeNumber={this.changeNumber}
                      handleAnswerChange={this.handleAnswerChange}
                      handleResultSwitchChange={this.handleResultSwitchChange}
                      pixelCode={this.pixelCode}
                      leadSelect={this.leadSelect}
                    />
                  )}
                  {this.state.formActivePanel === 3 && (
                    <Step3
                      handleNextPrevClick={this.handleNextPrevClick}
                      createdTestId={createdTestId}
                      testResults={testResults}
                      finishTest={this.finishTest}
                      setQuestions={this.setQuestions}
                      questions={questions}
                    />
                  )}
                </Row>
              </CardBody>
            </Card>
          </Col>

          <Col md="5">
            {questions && questions.length > 0 ? (
              <Card>
                <CardBody>
                  <h2 className="text-center font-weight-bold pt-4 pb-5">
                    <strong>{formatMessage(messages.YourQuestions)}</strong>
                  </h2>

                  <table style={{ width: '100%' }}>
                    {questions.map((q, i) => (
                      <tr key={i}>
                        <td style={{ width: '25%' }}>
                          <ReactTooltip effect="solid" multiline={true} />
                          <span
                            data-tip={formatMessage(messages.Copy)}
                            data-place="bottom"
                          >
                            <MDBBtn
                              rounded
                              color="info"
                              size="sm"
                              onClick={() => this.copyQuestion(i)}
                              disabled={!q.id}
                            >
                              <MDBIcon icon="copy" />
                            </MDBBtn>
                          </span>
                        </td>
                        <td>
                          <h6 key={i}>{`${i + 1}. ${q.title}`}</h6>
                        </td>
                      </tr>
                    ))}
                  </table>
                </CardBody>
              </Card>
            ) : null}
            <h2 className="text-center font-weight-bold pt-4 pb-5">
              <strong>{formatMessage(messages.TestPreview)}</strong>
            </h2>
            {testFinished ? (
              <iframe
                src={`//www.poltio.com/e/test/${createdTestId}`}
                width="100%"
                height="650"
                scrolling="no"
                frameBorder="0"
                title={`poll${createdTestId}`}
              />
            ) : (
              <h6 className="text-center pt-4 pb-5">
                {formatMessage(messages.YourContent)}
              </h6>
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default injectIntl(TestCreate);
