import { defineMessages } from 'react-intl.macro';

export default defineMessages({
  Search: {
    id: 'Leads.Search',
    defaultMessage: 'Search',
  },
  EnterSearchText: {
    id: 'Leads.EnterSearchText',
    defaultMessage: 'Enter Search Text',
  },
  Id: {
    id: 'Leads.Id',
    defaultMessage: 'ID',
  },
  Name: {
    id: 'Leads.Name',
    defaultMessage: 'Name',
  },
  LeadCount: {
    id: 'Leads.LeadCount',
    defaultMessage: 'Lead Count',
  },
  Loading: {
    id: 'Leads.Loading',
    defaultMessage: 'Loading...',
  },
  Edit: {
    id: 'Leads.Edit',
    defaultMessage: 'Edit',
  },
  ErrorSaving: {
    id: 'Leads.ErrorSaving',
    defaultMessage: 'Error Saving Pixel Code',
  },
  ErrorDeleting: {
    id: 'Leads.ErrorDeleting',
    defaultMessage: 'Error Deleting Pixel Code',
  },
  AddNew: {
    id: 'Leads.AddNew',
    defaultMessage: 'Add new lead',
  },
  Type: {
    id: 'Leads.Type',
    defaultMessage: 'Type your code',
  },
  TypeName: {
    id: 'Leads.TypeName',
    defaultMessage: 'Type a name for your code',
  },
  Save: {
    id: 'Leads.Save',
    defaultMessage: 'Save',
  },
  Delete: {
    id: 'Leads.Delete',
    defaultMessage: 'Delete',
  },
  Message: {
    id: 'Leads.Message',
    defaultMessage: 'Message',
  },
  ChooseImage: {
    id: 'Leads.ChooseImage',
    defaultMessage: 'Choose Image',
  },
  LeadImageTitle: {
    id: 'Leads.LeadImageTitle',
    defaultMessage: 'Lead Image',
  },
  LeadImage: {
    id: 'Leads.LeadImage',
    defaultMessage: 'Lead Image (1600 x 748)',
  },
  Fields: {
    id: 'Leads.Fields',
    defaultMessage: 'Fields',
  },
  ChooseFields: {
    id: 'Leads.ChooseFields',
    defaultMessage: 'Choose Fields',
  },
  LeadType: {
    id: 'Leads.LeadType',
    defaultMessage: 'Lead Type',
  },
  SelectLeadType: {
    id: 'Leads.SelectLeadType',
    defaultMessage: 'Select Lead Type',
  },
  ButtonText: {
    id: 'Leads.ButtonText',
    defaultMessage: 'Button Text',
  },
  RedirectUrl: {
    id: 'Leads.RedirectUrl',
    defaultMessage: 'Redirect Url (insert #next to trigger next question)',
  },
  Terms: {
    id: 'Leads.Terms',
    defaultMessage: 'Text next to check box (you can include a link)',
  },
  SecondTerms: {
    id: 'Leads.SecondTerms',
    defaultMessage: 'Text next to check box (you can include a link)',
  },
  TcOp: {
    id: 'Leads.TcOp',
    defaultMessage: 'Require Terms and Conditions',
  },
  TcOp2: {
    id: 'Leads.TcOp2',
    defaultMessage: 'Require Second Terms and Conditions',
  },
  EditLead: {
    id: 'Leads.EditLead',
    defaultMessage: 'Edit Lead',
  },
  IncomingLeads: {
    id: 'Leads.IncomingLeads',
    defaultMessage: 'Incoming Leads',
  },
  Select: {
    id: 'Leads.Select',
    defaultMessage: 'Select',
  },
  Close: {
    id: 'Leads.Close',
    defaultMessage: 'Close',
  },
  SelectLead: {
    id: 'Leads.SelectLead',
    defaultMessage: 'Select Lead',
  },
  Codes: {
    id: 'Leads.Codes',
    defaultMessage: 'Codes (Code1,Code2,Code3...)',
  },
  SingleUse: {
    id: 'Leads.SingleUse',
    defaultMessage: 'Single Use',
  },
  CouponCodes: {
    id: 'Leads.CouponCodes',
    defaultMessage: 'Coupon Codes',
  },
  StartWithHttp: {
    id: 'Leads.StartWithHttp',
    defaultMessage: 'Url must start with http or https!',
  },
  ShortLeadName: {
    id: 'Leads.ShortLeadName',
    defaultMessage: 'Name should be at least 3 characters!',
  },
  ViewLogs: {
    id: 'Leads.ViewLogs',
    defaultMessage: 'View Logs',
  },
  Download: {
    id: 'Leads.Download',
    defaultMessage: 'Download',
  },
  Submitted: {
    id: 'Leads.Submitted',
    defaultMessage: 'Report request submitted!',
  },
  ViewCount: {
    id: 'Leads.ViewCount',
    defaultMessage: 'View Count',
  },
  InputCount: {
    id: 'Leads.InputCount',
    defaultMessage: 'Input Count',
  },
  RedirectCount: {
    id: 'Leads.RedirectCount',
    defaultMessage: 'Redirect Count',
  },
  Leads: {
    id: 'Leads.Leads',
    defaultMessage: 'Leads',
  },
  AreYouSure: {
    id: 'Leads.AreYouSure',
    defaultMessage: 'Are you sure you want to delete this lead?',
  },
  DontShortenLink: {
    id: 'Leads.DontShortenLink',
    defaultMessage: "Don't Shorten Link",
  },
  DontShortenDesc: {
    id: 'Leads.DontShortenDesc',
    defaultMessage:
      "If you disable short link, we can't check your link and track clicks.",
  },
  StopSet: {
    id: 'Leads.StopSet',
    defaultMessage: 'Stop Set',
  },
  Mandatory: {
    id: 'Leads.Mandatory',
    defaultMessage: 'Mandatory',
  },
  SupportsMarkdown: {
    id: 'Leads.SupportsMarkdown',
    defaultMessage: 'Supports Markdown: www.markdownguide.org',
  },
  LeadInput: {
    id: 'Leads.LeadInput',
    defaultMessage: 'Input',
  },
  Redirect: {
    id: 'Leads.Redirect',
    defaultMessage: 'Redirect',
  },
  InternalRedirect: {
    id: 'Leads.InternalRedirect',
    defaultMessage: 'Internal Redirect',
  },
  TextImageDisplay: {
    id: 'Leads.TextImageDisplay',
    defaultMessage: 'Text/Image Display',
  },
  DefaultButton: {
    id: 'Leads.DefaultButton',
    defaultMessage: '(default: "Submit")',
  },
  Files: {
    id: 'Leads.Files',
    defaultMessage: 'Files',
  },
  Title: {
    id: 'Leads.Title',
    defaultMessage: 'Title',
  },
});
