import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import {
  TabPane,
  TabContent,
  MDBNav,
  MDBNavItem,
  MDBLink,
  Fa,
  Row,
  Col,
  Container,
  MDBIcon,
} from 'mdbreact';
import ReactTooltip from 'react-tooltip';
import history from '../../core/history';

import PollCreate from './PollCreate';
import TestCreate from './TestCreate';
import QuizCreate from './QuizCreate';
import SetCreate from './SetCreate';
import messages from './messages';

class ContentCreate extends Component {
  state = {
    activeItem: '',
  };

  async componentDidMount() {
    if (this.props.user?.trivia_credit < 1) {
      history.push('/');
    }

    this.setState({
      activeItem: this.props.location.state.activeItem,
    });
  }

  toggle = (e, tab) => {
    e.preventDefault();
    if (this.state.activeItem !== tab) {
      this.setState({
        activeItem: tab,
      });
    }
  };

  render() {
    const { activeItem } = this.state;
    const { formatMessage } = this.props.intl;

    return (
      <Container>
        <MDBNav tabs className="nav-justified">
          <ReactTooltip effect="solid" multiline={true} />
          <MDBNavItem>
            <MDBLink
              to="#"
              active={activeItem === '1'}
              onClick={() => history.push('/content/set')}
            >
              <Fa icon="pencil-alt" /> {formatMessage(messages.Set)}{' '}
              <span
                data-tip={formatMessage(messages.SetTip)}
                data-place="bottom"
              >
                <MDBIcon far icon="question-circle" />
              </span>
            </MDBLink>
          </MDBNavItem>
          <MDBNavItem>
            <MDBLink
              to="#"
              active={activeItem === '2'}
              onClick={(e) => {
                this.toggle(e, '2');
              }}
            >
              <Fa icon="pencil-alt" /> {formatMessage(messages.Test)}{' '}
              <span
                data-tip={formatMessage(messages.TestTip)}
                data-place="bottom"
              >
                <MDBIcon far icon="question-circle" />
              </span>
            </MDBLink>
          </MDBNavItem>
          <MDBNavItem>
            <MDBLink
              to="#"
              active={activeItem === '3'}
              onClick={(e) => {
                this.toggle(e, '3');
              }}
            >
              <Fa icon="pencil-alt" /> {formatMessage(messages.Quiz)}{' '}
              <span
                data-tip={formatMessage(messages.QuizTip)}
                data-place="bottom"
              >
                <MDBIcon far icon="question-circle" />
              </span>
            </MDBLink>
          </MDBNavItem>
          <MDBNavItem>
            <MDBLink
              to="#"
              active={activeItem === '4'}
              onClick={(e) => {
                this.toggle(e, '4');
              }}
            >
              <Fa icon="pencil-alt" /> {formatMessage(messages.Poll)}{' '}
              <span
                data-tip={formatMessage(messages.PollTip)}
                data-place="bottom"
              >
                <MDBIcon far icon="question-circle" />
              </span>
            </MDBLink>
          </MDBNavItem>
        </MDBNav>
        <TabContent activeItem={activeItem}>
          <TabPane tabId="1">
            <Row>
              <Col sm="12">
                <br />
                <SetCreate />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="2">
            <Row>
              <Col sm="12">
                <br />
                <TestCreate />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="3">
            <Row>
              <Col sm="12">
                <br />
                <QuizCreate />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="4">
            <Row>
              <Col sm="12">
                <br />
                <PollCreate />
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </Container>
    );
  }
}

export default injectIntl(ContentCreate);
