import React from 'react';
import { Row, Col } from 'mdbreact';
import { Chart } from 'react-google-charts';
import SectionWithTitle from '../Common/SectionWithTitle';

const PollVoteGraphs = ({ messages, formatMessage, data }) => (
  <SectionWithTitle title="Total Vote & Geo">
    <Row style={{ marginTop: '50px', marginBottom: '50px' }}>
      <Col md="7">
        <Chart
          chartType="PieChart"
          columns={[{ type: 'string' }, { type: 'number', label: '' }]}
          rows={data.answers.map((a) => [a.title, a.vote_count])}
          graph_id={`total_votes_${data.id}`}
          width="100%"
          height="500px"
          options={{
            title: formatMessage(messages.TotalVote),
            is3D: true,
          }}
        />
      </Col>
      <Col md="5">
        <div className="geo-location-container">
          <iframe
            src={`//geo.poltio.com/?id=${data.id}&v2.3`}
            width="100%"
            height="450"
            scrolling="no"
            frameBorder="0"
            title={`iframechart${data.id}`}
          />
        </div>
      </Col>
    </Row>
  </SectionWithTitle>
);

export default PollVoteGraphs;
