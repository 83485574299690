import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBInput,
  MDBRow,
} from 'mdbreact';
import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';

import api from '../../../api/client';
import messages from '../messages';
import { CalculatorFormulaValueInputForm } from './CalculatorFormulaValueInput';

const CalculatorFormula = ({
  intl: { formatMessage },
  questions,
  value,
  onChange,
  onClick,
  disabled = false,
}) => {
  let [result, setResult] = useState(0);
  let [changed, setChanged] = useState(false);
  let [error, setError] = useState('');
  let [isLoading, setIsLoading] = useState(false);
  let [inputs, setInputs] = useState({});

  let remCharLimit = 10000 - (value?.length ? value.length : 0);

  useEffect(() => {
    setIsLoading(true);

    // generate inputs
    let _inputs = {};
    Array.from(questions || []).forEach((question, index) => {
      let _val = question?.cal_val_default || 0;
      _val = !isNaN(_val) ? _val : 0;

      _inputs[`Q${index + 1}`] = _val;
    });

    setInputs({ ..._inputs });
    setIsLoading(false);
  }, [questions]);

  useEffect(() => {
    !!value && setChanged(true);
    setError('');
  }, [value]);

  const evaluate = () => {
    if (!value) {
      setResult(0);
      return;
    }

    const payload = {
      formula: value,
      vars: [],
    };

    const variables = [...String(value).matchAll(/Q(\d*)/g)];
    // validate variables
    for (const index in variables) {
      const variable = variables[index];
      const variableIDX = String(variable[1]);

      if (
        !variableIDX ||
        variableIDX > (questions || []).length ||
        variableIDX <= 0
      ) {
        // bad question idx
        setError(formatMessage(messages.CalculatorBadVariable));
        return;
      }

      // check if variable exists
      if (inputs[variable[0]] === undefined) return;

      let QX = {};
      QX[variable[0]] = inputs[variable[0]];

      payload.vars.push(QX);
    }

    api.post('/calculator-test', payload).then((response) => {
      setChanged(false);
      setResult(response.data.result);
    });
  };

  const valueChangeHandler = (name, val) => {
    let next = inputs;
    next[name] = val;
    setInputs(next);
  };

  return (
    <>
      <h2 className="text-center font-weight-bold pt-4 pb-5">
        <strong>{formatMessage(messages.CalculatorFormula)}</strong>
      </h2>
      <MDBCard>
        <MDBCardBody>
          <MDBInput
            label={`${formatMessage(
              messages.CalculatorFormula
            )} - ${remCharLimit}`}
            className="mt-3"
            name="cal_formula"
            value={value}
            onChange={onChange}
            type="textarea"
          />
          <CalculatorFormulaValueInputForm
            isLoading={isLoading}
            inputs={inputs}
            valueChangeHandler={valueChangeHandler}
          />
          <MDBRow className="mt-3">
            <MDBCol size={12}>
              <div className="btn-group m-0 d-inline-flex">
                <button
                  className="btn btn-primary btn-rounded m-0 py-1 px-2"
                  onClick={evaluate}
                >
                  <i
                    className="fas fa-redo mr-2"
                    // mdb colour Blue A100
                    style={changed ? {} : { color: '#82B1FF' }}
                  />
                  {formatMessage(messages.CalculatorResult)}
                </button>
                <div className="btn btn-rounded m-0 py-1 disabled text-dark">
                  {result}
                </div>
              </div>
              <div className="ml-2 d-inline-flex align-middle text-danger">
                {error}
              </div>
            </MDBCol>
          </MDBRow>
          <MDBRow className="mt-3">
            <MDBCol className="text-center align-items-center">
              <MDBBtn
                rounded
                color="danger"
                onClick={onClick}
                disabled={disabled}
              >
                {formatMessage(messages.SaveAndFinish)}
              </MDBBtn>
            </MDBCol>
          </MDBRow>
        </MDBCardBody>
      </MDBCard>
    </>
  );
};

export default injectIntl(CalculatorFormula);
