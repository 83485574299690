import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import {
  TabPane,
  TabContent,
  MDBNav,
  MDBNavItem,
  MDBLink,
  Fa,
  Row,
  Col,
  Container,
} from 'mdbreact';
import { submitPass } from '../../api';

import SettingsForm from './SettingsForm';
import ChangePass from './ChangePass';
import messages from './messages';

class Settings extends Component {
  state = {
    activeItem: '1',
    passData: {
      old: '',
      new: '',
      new_again: '',
    },
    buttonLoadingPass: false,
    resultStatusPass: '',
  };

  toggle = (e, tab) => {
    e.preventDefault();
    if (this.state.activeItem !== tab) {
      this.setState({
        activeItem: tab,
      });
    }
  };

  handlePassInputChange = (event) => {
    this.setState({
      passData: {
        ...this.state.passData,
        [event.target.name]: event.target.value,
      },
    });
  };

  submitPass = async (event) => {
    event.preventDefault();
    const { passData } = this.state;

    if (passData.new !== passData.new_again) {
      this.setState({ buttonLoadingPass: false, resultStatusPass: 'notmatch' });
      return;
    }

    this.setState({ buttonLoadingPass: true, resultStatusPass: '' });
    try {
      await submitPass(passData);
      this.setState({ buttonLoadingPass: false, resultStatusPass: 'success' });
    } catch (error) {
      console.log(error);
      this.setState({ buttonLoadingPass: false, resultStatusPass: 'error' });
    }
  };

  render() {
    const {
      activeItem,
      passData,
      buttonLoadingPass,
      resultStatusPass,
    } = this.state;
    const { formatMessage } = this.props.intl;
    return (
      <Container>
        <MDBNav tabs className="nav-justified">
          <MDBNavItem>
            <MDBLink
              to="#"
              active={activeItem === '1'}
              onClick={(e) => {
                this.toggle(e, '1');
              }}
            >
              <Fa icon="cog" /> {formatMessage(messages.Settings)}
            </MDBLink>
          </MDBNavItem>
          <MDBNavItem>
            <MDBLink
              to="#"
              active={activeItem === '2'}
              onClick={(e) => {
                this.toggle(e, '2');
              }}
            >
              <Fa icon="pencil-alt" /> {formatMessage(messages.ChangePass)}
            </MDBLink>
          </MDBNavItem>
        </MDBNav>
        <TabContent activeItem={activeItem}>
          <TabPane tabId="1">
            <Row>
              <Col sm="12">
                <br />
                <SettingsForm />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="2">
            <Row>
              <Col sm="12">
                <br />
                <ChangePass
                  pass={passData}
                  handlePassInputChange={this.handlePassInputChange}
                  submitPass={this.submitPass}
                  buttonLoading={buttonLoadingPass}
                  resultStatus={resultStatusPass}
                />
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </Container>
    );
  }
}

export default injectIntl(Settings);
