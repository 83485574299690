import React, { useState, useEffect, useCallback } from 'react';
import { injectIntl } from 'react-intl';
import { MDBContainer, MDBSpinner, MDBBtn, MDBIcon, MDBRow } from 'mdbreact';
import ReactTooltip from 'react-tooltip';
import SectionWithTitle from '../Common/SectionWithTitle';
import Pagination from '../Pagination';
import { getFriendQuizPlayers } from '../../api';
import history from '../../core/history';

import messages from './messages';

const Players = ({
  intl: { formatMessage, formatDate, formatTime },
  match,
}) => {
  const [players, setPlayers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageData, setPageData] = useState({
    totalSize: 100,
    page: 1,
    sizePerPage: 50,
    lastPage: null,
  });

  const fetchPlayers = useCallback(async () => {
    setLoading(true);
    const response = await getFriendQuizPlayers(
      match.params.id,
      match.params.session_id,
      pageData.page,
      pageData.sizePerPage
    );
    setPlayers(response.data.data);
    setLoading(false);
    setPageData((p) => {
      return {
        page: p.page,
        sizePerPage: p.sizePerPage,
        totalSize: response.data.total,
        lastPage: response.data.last_page,
      };
    });
  }, [
    match.params.id,
    match.params.session_id,
    pageData.page,
    pageData.sizePerPage,
  ]);

  const handlePageChange = (page, sizePerPage) => {
    setPageData({
      ...pageData,
      page,
      sizePerPage,
    });
  };

  useEffect(() => {
    fetchPlayers();
  }, [fetchPlayers]);

  return (
    <MDBContainer fluid>
      <SectionWithTitle title={`${formatMessage(messages.SessionPlayers)}`}>
        <MDBRow style={{ marginBottom: '20px' }}>
          <MDBBtn
            color="primary"
            rounded
            onClick={() => history.push(`/fqsessions/${match.params.id}`)}
          >
            <MDBIcon icon="angle-left" /> {`${formatMessage(messages.Back)}`}
          </MDBBtn>
        </MDBRow>

        <Pagination
          last={pageData.lastPage}
          active={pageData.page}
          size={pageData.sizePerPage}
          handlePageChange={handlePageChange}
        />
        {loading ? (
          <MDBSpinner />
        ) : (
          <div className="row text-center">
            <div className="col-12">
              <table className="table table-striped table-bordered table-sm table-fixed-header">
                <thead>
                  <tr>
                    <th>{'Session ID'}</th>
                    <th>{'User ID'}</th>
                    <th>{'Name'}</th>
                    <th>{'Correct Answer'}</th>
                    <th>{'Points'}</th>
                    <th>{'Time Spent'}</th>
                    <th>{'Created At'}</th>
                  </tr>
                </thead>
                <tbody>
                  <ReactTooltip effect="solid" multiline={true} />
                  {players.map((row, i) => (
                    <tr key={i} style={{ cursor: 'pointer' }}>
                      <td>
                        <div data-tip={row.player_session}>
                          {row.player_session}
                        </div>
                      </td>
                      <td>
                        <div data-tip={row.user_id}>{row.user_id}</div>
                      </td>
                      <td>
                        <div data-tip={row.name}>{row.name}</div>
                      </td>
                      <td>
                        <div data-tip={row.right_answer}>
                          {row.right_answer}/{row.total_question}
                        </div>
                      </td>
                      <td>
                        <div data-tip={row.points}>{row.points}</div>
                      </td>
                      <td>
                        <div data-tip={row.time_spent}>{row.time_spent}</div>
                      </td>
                      <td>
                        <div data-tip={row.created_at}>{`${formatDate(
                          row.created_at
                        )} ${formatTime(row.created_at)}`}</div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </SectionWithTitle>
    </MDBContainer>
  );
};

export default injectIntl(Players);
