import React, { Component } from 'react';
import { MDBDataTable, MDBContainer } from 'mdbreact';

import SectionWithTitle from '../Common/SectionWithTitle';

const data = {
  columns: [
    {
      label: 'ID',
      field: 'id',
      sort: 'asc',
      width: 150,
    },
    {
      label: 'Name',
      field: 'name',
      sort: 'asc',
      width: 270,
    },
    {
      label: 'Email',
      field: 'email',
      sort: 'asc',
      width: 200,
    },
    {
      label: 'Gender',
      field: 'gender',
      sort: 'asc',
      width: 100,
    },
    {
      label: 'Username',
      field: 'username',
      sort: 'asc',
      width: 150,
    },
    {
      label: 'Vote Count',
      field: 'votecount',
      sort: 'asc',
      width: 100,
    },
  ],
  rows: [],
};

class Users extends Component {
  render() {
    return (
      <MDBContainer fluid>
        <SectionWithTitle title="Users">
          <MDBDataTable striped bordered hover data={data} />
        </SectionWithTitle>
      </MDBContainer>
    );
  }
}

export default Users;
