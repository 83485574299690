import logo from '../images/poltio-logo-texture.svg';

export const getToken = () => {
  const token = JSON.parse(localStorage.getItem('token'));
  if (token) return true;
  return false;
};

export const getUser = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  if (user) return user;
  return false;
};

export const validateEmail = (email) => {
  // eslint-disable-next-line
  const re = /(([A-Za-z0-9]+_+)|([A-Za-z0-9]+\-+)|([A-Za-z0-9]+\.+)|([A-Za-z0-9]+\++))*[A-Za-z0-9]+@((\w+\-+)|(\w+\.))*\w{1,63}\.[a-zA-Z0-9]{2,6}/;
  return re.test(email);
};

export const validateHtml = (html) => {
  const re = /<[a-z/][\s\S]*>/;
  const result = html.match(re);
  if (result) {
    const removed = html.replace(result[0], '');
    return removed.length === 0;
  } else {
    return false;
  }
};

export const setBg = () => {
  document.body.style.backgroundImage = `url(${logo})`;
  document.body.style.backgroundAttachment = 'fixed';
  document.body.style.backgroundSize = '100px';
};

export const removeBg = () => {
  document.body.style.backgroundImage = '';
};

export const groupBy = (items) =>
  items.reduce(
    (result, item) => ({
      ...result,
      [item['day']]: [...(result[item['day']] || [item['day']]), item.value],
    }),
    {}
  );

export const groupByAddWeek = (items) =>
  items.reduce(
    (result, item) => ({
      ...result,
      [`${item['year']} Week #${item['week']}`]: [
        ...(result[`${item['year']} Week #${item['week']}`] || [
          `${item['year']} Week #${item['week']}`,
        ]),
        item.value,
      ],
    }),
    {}
  );

export const groupByAddMonth = (items) =>
  items.reduce(
    (result, item) => ({
      ...result,
      [`${item['year']}-${item['month']}`]: [
        ...(result[`${item['year']}-${item['month']}`] || [
          `${item['year']}-${item['month']}`,
        ]),
        item.value,
      ],
    }),
    {}
  );

export const groupByAddYear = (items) =>
  items.reduce(
    (result, item) => ({
      ...result,
      [`${item['year']}`]: [
        ...(result[`${item['year']}`] || [`${item['year']}`]),
        item.value,
      ],
    }),
    {}
  );

export const groupByAddDay = (items, locale) => {
  const groupedYear = items.reduce(
    (result, item) => ({
      ...result,
      [`${item['day']}`]: [
        ...(result[`${item['day']}`] || [`${item['day']}`]),
        item.value,
      ],
    }),
    []
  );

  return Object.keys(groupedYear).map((key) => {
    return [
      new Date(groupedYear[key][0]),
      groupedYear[key][1],
      `<big><b>${new Date(groupedYear[key][0]).toLocaleDateString(
        locale
      )}</b></big><br />
      Total: ${groupedYear[key][1]} <br />
      Poll: ${groupedYear[key][2]} <br />
      Quiz: ${groupedYear[key][3]} <br />
      Test: ${groupedYear[key][4]}`,
    ];
  });
};

export const groupByMonthlyVotes = (items) =>
  items.reduce(
    (result, item) => ({
      ...result,
      [`${item['year']}-${item['month']}`]: [
        ...(result[`${item['year']}-${item['month']}`] || [
          `${item['year']}-${item['month']}`,
        ]),
        item.value,
      ],
    }),
    {}
  );

export const convertToBase64Image = (source) => {
  if (!source) {
    return new Promise((resolve) => resolve(''));
  }

  if (source.includes('data:image')) {
    return new Promise((resolve) => resolve(source));
  }

  return new Promise((resolve, reject) => {
    let img = new Image();
    img.setAttribute('crossOrigin', 'anonymous');
    img.src = source;
    img.onload = async () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');

      ctx.drawImage(img, 0, 0, img.width, img.height);

      let type = img.src.split('.').pop().toLowerCase();
      type = type === 'jpg' ? 'jpeg' : type;

      const b64 = canvas.toDataURL(`image/${type}`);
      resolve(b64);
    };
    img.onerror = (err) => reject(err);
  });
};

export const compareVoteCounts = (a, b) => {
  if (a.vote_count < b.vote_count) {
    return 1;
  }
  if (a.vote_count > b.vote_count) {
    return -1;
  }
  return 0;
};

export const calculatePercentage = (total, option) =>
  ((option / (total || 1)) * 100).toFixed(1);

export const isValidUrl = (url) => {
  try {
    new URL(url);
  } catch (e) {
    return false;
  }
  return true;
};
