import React from 'react';

const Timezone = ({ timezone, onTimezoneChange }) => {
  return (
    <select
      className="browser-default custom-select"
      onChange={(e) => onTimezoneChange(e)}
      value={timezone}
      style={{ width: '20rem' }}
    >
      <option value="12">(GMT-12:00)</option>
      <option value="11">(GMT-11:00)</option>
      <option value="10">(GMT-10:00)</option>
      <option value="9">(GMT-09:00)</option>
      <option value="8">(GMT-08:00)</option>
      <option value="7">(GMT-07:00)</option>
      <option value="6">(GMT-06:00)</option>
      <option value="5">(GMT-05:00)</option>
      <option value="4">(GMT-04:00)</option>
      <option value="3.5">(GMT-03:30)</option>
      <option value="3">(GMT-03:00)</option>
      <option value="2">(GMT-02:00)</option>
      <option value="1">(GMT-01:00)</option>
      <option value="0">(GMT+00:00)</option>
      <option value="-1">(GMT+01:00)</option>
      <option value="-2">(GMT+02:00)</option>
      <option value="-3">(GMT+03:00)</option>
      <option value="-3.5">(GMT+03:30)</option>
      <option value="-4">(GMT+04:00)</option>
      <option value="-4.5">(GMT+04:30)</option>
      <option value="-5">(GMT+05:00)</option>
      <option value="-5.5">(GMT+05:30)</option>
      <option value="-5.75">(GMT+05:45)</option>
      <option value="-6">(GMT+06:00)</option>
      <option value="-6.5">(GMT+06:30)</option>
      <option value="-7">(GMT+07:00)</option>
      <option value="-8">(GMT+08:00)</option>
      <option value="-9">(GMT+09:00)</option>
      <option value="-9.5">(GMT+09:30)</option>
      <option value="-10">(GMT+10:00)</option>
      <option value="-11">(GMT+11:00)</option>
      <option value="-12">(GMT+12:00)</option>
      <option value="-13">(GMT+13:00)</option>
    </select>
  );
};

export default Timezone;
