/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { FlippingCard } from 'mdbreact';

import './ContentCard.css';

import Front from './Front';
import Back from './Back';

let UserCard = ({
  content,
  openEmbedModal,
  intl,
  deleteContent,
  duplicateContent,
  copyLink,
  goToReport,
  handleSwitchChange2,
}) => {
  const [flipped, setFlipped] = useState(false);

  const flip = (e) => {
    e.preventDefault();
    setFlipped(!flipped);
  };

  return (
    <FlippingCard
      flipped={flipped}
      className="text-center h-100 w-100"
      style={{ maxWidth: '22rem' }}
    >
      <Front content={content} flip={flip} intl={intl} />
      <Back
        content={content}
        copyLink={copyLink}
        deleteContent={deleteContent}
        duplicateContent={duplicateContent}
        flip={flip}
        goToReport={goToReport}
        handleSwitchChange2={handleSwitchChange2}
        intl={intl}
        openEmbedModal={openEmbedModal}
      />
    </FlippingCard>
  );
};

export default injectIntl(UserCard);
