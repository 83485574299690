import React, { useState, useEffect, useCallback } from 'react';
import { injectIntl } from 'react-intl';
import { MDBContainer, MDBSpinner, MDBRow, MDBBtn, MDBIcon } from 'mdbreact';
import ReactTooltip from 'react-tooltip';
import SectionWithTitle from '../Common/SectionWithTitle';
import Pagination from '../Pagination';
import { getFriendQuizSessions } from '../../api';
import history from '../../core/history';

import messages from './messages';

const FQSessions = ({
  intl: { formatMessage, formatDate, formatTime },
  match,
}) => {
  const [sessions, setSessions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageData, setPageData] = useState({
    totalSize: 100,
    page: 1,
    sizePerPage: 50,
    lastPage: null,
  });

  const fetchSessions = useCallback(async () => {
    setLoading(true);
    const response = await getFriendQuizSessions(
      match.params.id,
      pageData.page,
      pageData.sizePerPage
    );
    setSessions(response.data.data);
    setLoading(false);
    setPageData((p) => {
      return {
        page: p.page,
        sizePerPage: p.sizePerPage,
        totalSize: response.data.total,
        lastPage: response.data.last_page,
      };
    });
  }, [match.params.id, pageData.page, pageData.sizePerPage]);

  const handlePageChange = (page, sizePerPage) => {
    setPageData({
      ...pageData,
      page,
      sizePerPage,
    });
  };

  useEffect(() => {
    fetchSessions();
  }, [fetchSessions]);

  return (
    <MDBContainer fluid>
      <SectionWithTitle title={`${formatMessage(messages.FQSessions)}`}>
        <MDBRow style={{ marginBottom: '20px' }}>
          <MDBBtn
            color="primary"
            rounded
            onClick={() =>
              history.push(`/content-detail/quiz/${match.params.id}`)
            }
          >
            <MDBIcon icon="angle-left" /> {`${formatMessage(messages.Back)}`}
          </MDBBtn>
        </MDBRow>
        <Pagination
          last={pageData.lastPage}
          active={pageData.page}
          size={pageData.sizePerPage}
          handlePageChange={handlePageChange}
        />
        {loading ? (
          <MDBSpinner />
        ) : (
          <div className="row text-center">
            <div className="col-12">
              <table className="table table-striped table-bordered table-sm table-fixed-header">
                <thead>
                  <tr>
                    <th>{'ID'}</th>
                    <th>{'User ID'}</th>
                    <th>{'Name'}</th>
                    <th>{'Created At'}</th>
                    <th>{'Players Count'}</th>
                  </tr>
                </thead>
                <tbody>
                  <ReactTooltip effect="solid" multiline={true} />
                  {sessions.map((row, i) => (
                    <tr
                      key={i}
                      style={{ cursor: 'pointer' }}
                      onClick={() =>
                        history.push(`/fqplayers/${match.params.id}/${row.id}`)
                      }
                    >
                      <td>
                        <div data-tip={row.id}>{row.id}</div>
                      </td>
                      <td>
                        <div data-tip={row.user_id}>{row.user_id}</div>
                      </td>
                      <td>
                        <div data-tip={row.name}>{row.name}</div>
                      </td>
                      <td>
                        <div data-tip={row.created_at}>{`${formatDate(
                          row.created_at
                        )} ${formatTime(row.created_at)}`}</div>
                      </td>
                      <td>
                        <div data-tip={row.players_count}>
                          {row.players_count}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </SectionWithTitle>
    </MDBContainer>
  );
};

export default injectIntl(FQSessions);
