import React from 'react';

import { Card, CardBody, CardUp, Avatar } from 'mdbreact';
import { feedAvatarPathRetina } from '../../core/constants';

const UserCard = ({ settingsData }) => (
  <Card testimonial>
    <CardUp className="indigo lighten-1"></CardUp>
    <Avatar className="mx-auto white">
      {settingsData.photo ? (
        <img src={`${feedAvatarPathRetina}/${settingsData.photo}`} alt="" />
      ) : null}
    </Avatar>
    <CardBody>
      <h4 className="card-title">{settingsData.username}</h4>
      <hr />
      <p>{settingsData.email}</p>
    </CardBody>
  </Card>
);

export default UserCard;
