/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useCallback } from 'react';
import { injectIntl } from 'react-intl';
import {
  MDBContainer,
  MDBIcon,
  MDBBtn,
  MDBSpinner,
  MDBTooltip,
  toast,
  MDBRow,
  MDBCol,
  MDBPopover,
  MDBPopoverHeader,
  MDBPopoverBody,
  MDBSelect,
  MDBSelectInput,
  MDBSelectOptions,
  MDBSelectOption,
  MDBInput,
} from 'mdbreact';
import { getSheethooks, sheethooksDelete, sheethooksToggle } from '../../api';
import history from '../../core/history';
import SectionWithTitle from '../Common/SectionWithTitle';
import Pagination from '../Pagination';

import messages from './messages';

const sampleCode = `{
  "poltio_user_id": 1,
  "publisher_uuid": "XXXXX",
  "event_time": 1556182272,
  "answers": [
      {
          "query_id": 1,
          "query": "XXXXXXX",
          "answer_id": 1,
          "answer": "XXXXXXX"
      }
  ]
}`;

const Sheethooks = ({ intl: { formatMessage }, location: { state } }) => {
  const { type: content_type, id: content_id } = state || {};
  const [hooks, setHooks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageData, setPageData] = useState({
    totalSize: 100,
    page: 1,
    sizePerPage: 50,
    lastPage: null,
  });
  const [toggling, setToggling] = useState(false);
  const [type, setType] = useState(content_type);
  const [id, setId] = useState(content_id);
  const [typeValue, setTypeValue] = useState(null);
  const [idValue, setIdValue] = useState(null);
  const [seeAll, setSeeAll] = useState(!content_type && !content_id);

  const fetchHooks = useCallback(async () => {
    setLoading(true);
    const response = await getSheethooks(
      type,
      id,
      pageData.page,
      pageData.sizePerPage
    );
    setHooks(response.data.data);
    setLoading(false);
    setPageData((p) => {
      return {
        page: p.page,
        sizePerPage: p.sizePerPage,
        totalSize: response.data.total,
        lastPage: response.data.last_page,
      };
    });
  }, [pageData.page, pageData.sizePerPage, type, id]);

  const handlePageChange = (page, sizePerPage) => {
    setPageData({
      ...pageData,
      page,
      sizePerPage,
    });
  };

  useEffect(() => {
    fetchHooks();
  }, [fetchHooks]);

  const handleDeleteClick = async (id) => {
    if (window.confirm(formatMessage(messages.AreYouSure))) {
      try {
        await sheethooksDelete(id);
        toast.success(formatMessage(messages.Deleted));
        fetchHooks();
      } catch (e) {
        toast.error('Error occured!');
      }
    }
  };

  const handleToggleClick = async (id) => {
    setToggling(true);
    try {
      await sheethooksToggle(id);
      toast.success(formatMessage(messages.StatusSet));
      setToggling(false);
      fetchHooks();
    } catch (e) {
      toast.error('Error occured!');
      setToggling(true);
    }
  };

  const showHooks = () => {
    setType(typeValue);
    setId(idValue);
    setSeeAll(false);
  };

  return (
    <MDBContainer fluid>
      <SectionWithTitle
        title={`${formatMessage(messages.Sheethooks)} ${
          type && id ? `- ${type.toUpperCase()} #${id}` : ''
        }`}
      >
        <MDBRow
          style={{ marginBottom: '20px' }}
          className="justify-content-center"
        >
          <MDBCol md="4">
            <MDBBtn
              onClick={() =>
                history.push('/sheethooks/add', {
                  content_id: id,
                  content_type: type,
                })
              }
              color="primary"
              block
              disabled={!type || !id}
            >
              <MDBIcon icon="plus" className="left" />{' '}
              {formatMessage(messages.AddNew)}
            </MDBBtn>
          </MDBCol>
          {content_type && content_id ? (
            <MDBCol md="3">
              <MDBBtn
                onClick={() => {
                  setType(null);
                  setId(null);
                  setSeeAll(true);
                }}
                color="primary"
                block
                disabled={seeAll}
              >
                {formatMessage(messages.SeeAll)}
              </MDBBtn>
            </MDBCol>
          ) : null}
          <div className="col-md-2 popover-jump-fix">
            <MDBPopover placement="bottom" popover clickable id="popper1">
              <MDBBtn color="info" block>
                <MDBIcon icon="info-circle" className="left" />{' '}
                {formatMessage(messages.Info)}
              </MDBBtn>
              <div>
                <MDBPopoverHeader>
                  {formatMessage(messages.HookDesc)}
                </MDBPopoverHeader>
                <MDBPopoverBody>
                  <pre>
                    <code>
                      {JSON.stringify(JSON.parse(sampleCode), null, 4)}
                    </code>
                  </pre>
                </MDBPopoverBody>
              </div>
            </MDBPopover>
          </div>
        </MDBRow>

        {seeAll ? (
          <MDBRow
            style={{ marginBottom: '20px' }}
            className="justify-content-center"
          >
            <MDBSelect
              getValue={(val) => setTypeValue(val[0] || typeValue)}
              style={{ marginRight: '1rem' }}
            >
              <MDBSelectInput selected={typeValue || ''} />
              <MDBSelectOptions>
                <MDBSelectOption value="test">Test</MDBSelectOption>
                <MDBSelectOption value="quiz">Quiz</MDBSelectOption>
                <MDBSelectOption value="set">Set</MDBSelectOption>
              </MDBSelectOptions>
            </MDBSelect>
            <MDBInput
              label={formatMessage(messages.EnterId)}
              name="pollid"
              value={idValue || ''}
              onChange={(e) => setIdValue(e.target.value)}
            />
            <MDBBtn
              style={{ height: '50px' }}
              color="primary"
              onClick={showHooks}
              disabled={loading}
            >
              {formatMessage(messages.Show)}
            </MDBBtn>
          </MDBRow>
        ) : null}

        <Pagination
          last={pageData.lastPage}
          active={pageData.page}
          size={pageData.sizePerPage}
          handlePageChange={handlePageChange}
        />
        {loading ? (
          <MDBSpinner />
        ) : (
          <div className="row text-center">
            <div className="col-12">
              <table className="table table-striped table-bordered table-sm table-fixed-header">
                <thead>
                  <tr>
                    <th className="th-sm">{'ID'}</th>
                    <th className="th-sm">{'Content Type'}</th>
                    <th className="th-sm">{'Content ID'}</th>
                    <th className="th-sm">{'Name'}</th>
                    <th className="th-sm">{'Sheet ID'}</th>
                    <th className="th-sm">{'Is Active (Click to toggle)'}</th>
                    <th className="th-sm">{'Health'}</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {hooks.map((row, i) => (
                    <tr key={i} style={{ cursor: 'pointer' }}>
                      <td>
                        <MDBTooltip placement="top" domElement>
                          <span>{row.id}</span>
                          <div>{row.id}</div>
                        </MDBTooltip>
                      </td>
                      <td>
                        <MDBTooltip placement="top" domElement>
                          <span>{row.content_type}</span>
                          <div>{row.content_type}</div>
                        </MDBTooltip>
                      </td>
                      <td>
                        <MDBTooltip placement="top" domElement>
                          <span>{row.content_id}</span>
                          <div>{row.content_id}</div>
                        </MDBTooltip>
                      </td>
                      <td>
                        <MDBTooltip placement="top" domElement>
                          <span>
                            {row.name && row.name.length > 15
                              ? `${row.name.substring(0, 15)}...`
                              : row.name}
                          </span>
                          <div>{row.name}</div>
                        </MDBTooltip>
                      </td>
                      <td>
                        <MDBTooltip placement="top" domElement>
                          <a
                            href={`https://docs.google.com/spreadsheets/d/${row.sheet_id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {row.sheet_id && row.sheet_id.length > 15
                              ? `${row.sheet_id.substring(0, 15)}...`
                              : row.sheet_id}
                          </a>
                          <div>{row.sheet_id}</div>
                        </MDBTooltip>
                      </td>
                      <td>
                        <div
                          className="btn-group"
                          role="group"
                          aria-label="options"
                        >
                          <MDBBtn
                            color={row.is_active ? 'success' : 'danger'}
                            size="sm"
                            onClick={() => handleToggleClick(row.id)}
                            disabled={toggling}
                          >
                            {row.is_active
                              ? formatMessage(messages.Yes)
                              : formatMessage(messages.No)}
                          </MDBBtn>
                        </div>
                      </td>
                      <td>
                        <div
                          className="btn-group"
                          role="group"
                          aria-label="options"
                        >
                          <MDBBtn color={row.health || 'grey'} size="sm">
                            {row.health || 'N/A'}
                          </MDBBtn>
                        </div>
                      </td>
                      <td>
                        <div
                          className="btn-group"
                          role="group"
                          aria-label="options"
                        >
                          <MDBBtn
                            outline
                            color="danger"
                            size="sm"
                            onClick={() => handleDeleteClick(row.id)}
                          >
                            <MDBIcon icon="eraser" />
                          </MDBBtn>
                          <MDBBtn
                            outline
                            color="info"
                            size="sm"
                            onClick={() => {
                              history.push('/sheethooks/edit', {
                                id: row.id,
                                content_id: row.content_id,
                                content_type: row.content_type,
                              });
                            }}
                          >
                            <MDBIcon icon="pencil-alt" />
                          </MDBBtn>
                          <MDBBtn
                            outline
                            color="default"
                            size="sm"
                            onClick={() =>
                              history.push(`/sheethook-logs`, { row })
                            }
                          >
                            <MDBIcon icon="memory" />
                          </MDBBtn>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </SectionWithTitle>
    </MDBContainer>
  );
};

export default injectIntl(Sheethooks);
