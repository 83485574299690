import api from './client';
import {
  clientId as client_id,
  clientSecret as client_secret,
} from '../core/constants';
import history from '../core/history';
import { toast } from 'mdbreact';

export function login(email, password, returnUrl = '/') {
  api.defaults.headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: '',
  };

  return api
    .post('/auth/login', { client_id, client_secret, email, password })
    .then((response) => {
      if (
        response &&
        response.data &&
        response.data.user &&
        response.data.user.level > 11
      ) {
        api.defaults.headers = {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${response.data.token}`,
        };

        window.localStorage.setItem(
          'token',
          JSON.stringify(response.data.token)
        );
        window.localStorage.setItem('user', JSON.stringify(response.data.user));

        history.push(returnUrl);
      } else {
        toast.warn('Please contact support@poltio.com');
      }
    });
}

export function forgotPassword(email) {
  api.defaults.headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: '',
  };

  return api.post('/auth/password-reminder', {
    client_id,
    client_secret,
    email,
  });
}

export function register(email, password, returnUrl = '/') {
  return api
    .post('/auth/register', {
      client_id,
      client_secret,
      email,
      password,
    })
    .then((response) => {
      if (
        response &&
        response.data &&
        response.data.user &&
        response.data.user.level > 11
      ) {
        api.defaults.headers = {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${response.data.token}`,
        };

        window.localStorage.setItem(
          'token',
          JSON.stringify(response.data.token)
        );
        window.localStorage.setItem('user', JSON.stringify(response.data.user));

        history.push(returnUrl);
      } else {
        toast.warn('Please contact support@poltio.com');
      }
    });
}

export function acceptTc() {
  return api.get('/tc/accept');
}

export function verify(email, token) {
  return api.post('/auth/verify', { client_id, client_secret, email, token });
}

export function resetPassword(email, token) {
  return api.post('/auth/password-reset', {
    client_id,
    client_secret,
    email,
    token,
  });
}

export function logout() {
  window.localStorage.removeItem('token');
  window.localStorage.removeItem('user');
  window.location.href = '/login';
}

export function submitPass(passData) {
  return api.post('/update-password', {
    old_password: passData.old,
    password: passData.new,
  });
}

export function getProfile(username) {
  return api.get(`/profile/${username}`);
}

export function getSettings() {
  return api.get('/settings');
}

export function uploadImage(f, ext) {
  return api.post('/create/upload', { f, ext });
}

export function submitSettings(settingsData) {
  return api.post('/settings', { ...settingsData });
}

export function createPoll(pollData, background) {
  return api.post('/create/question', {
    ...pollData,
    background,
  });
}

export function editPoll(pollData, background) {
  return api.post('/edit/question', {
    ...pollData,
    background,
  });
}

export function getLeads(last_id, q) {
  let query = null;
  query = `?last_id=${last_id || ''}&q=${q || ''}`;
  return api.get(`/lead-campaigns${query || ''}`);
}

export function getLeadsPaginated(last_id, q) {
  let query = null;
  query = `?page=${last_id || ''}&per_page=${q || ''}`;
  return api.get(`/lead/campaigns${query || ''}`);
}

export function getCampaignLeads(lead_id, page, size) {
  return api.get(
    `/lead-campaigns/leads/${lead_id}?page=${page}&lead_per_page=${size}`
  );
}

export function getLeadLogs(lead_id, page, size) {
  return api.get(
    `/lead-campaigns/logs/${lead_id}?page=${page}&lead_per_page=${size}`
  );
}

export function getLeadCoupons(lead_id, page, size) {
  return api.get(
    `/lead-campaigns/coupon-codes/${lead_id}?page=${page}&lead_per_page=${size}`
  );
}

export function leadSave(params) {
  return api.post('/lead-campaigns/save', params);
}

export function leadEdit(id) {
  return api.get(`/lead-campaigns/edit/${id}`);
}

export function leadDelete(id) {
  return api.get(`/lead-campaigns/delete/${id}`);
}

export function getWebhooks(type, id, last_id, q) {
  let query = null;
  query = `?${type ? `content_type=${type}&content_id=${id}&` : ''}page=${
    last_id || ''
  }&per_page=${q || ''}`;
  return api.get(`/hooks${query || ''}`);
}

export function webhooksSave(params) {
  return api.post('/hook/save', params);
}

export function webhooksDelete(id) {
  return api.get(`/hook/${id}/delete`);
}

export function webhooksEdit(id) {
  return api.get(`/hook/${id}/edit`);
}

export function webhooksToggle(id) {
  return api.get(`/hook/${id}/toggle`);
}

export function getWebhookLogs(lead_id, page, size) {
  return api.get(`/hook/${lead_id}/logs?page=${page}&per_page=${size}`);
}

export function getSheethooks(type, id, last_id, q) {
  let query = null;
  query = `?${type ? `content_type=${type}&content_id=${id}&` : ''}page=${
    last_id || ''
  }&per_page=${q || ''}`;
  return api.get(`/sheet-hooks${query || ''}`);
}

export function sheethooksSave(params) {
  return api.post('/sheet-hook/save', params);
}

export function sheethooksDelete(id) {
  return api.get(`/sheet-hook/${id}/delete`);
}

export function sheethooksEdit(id) {
  return api.get(`/sheet-hook/${id}/edit`);
}

export function sheethooksToggle(id) {
  return api.get(`/sheet-hook/${id}/toggle`);
}

export function getSheethookLogs(lead_id, page, size) {
  return api.get(`/sheet-hook/${lead_id}/logs?page=${page}&per_page=${size}`);
}

export function getPixelCodes(last_id, q) {
  let query = null;
  query = `?last_id=${last_id || ''}&q=${q || ''}`;
  return api.get(`/pixel-codes/inf${query || ''}`);
}

export function getPixelCodesPaginated(last_id, q) {
  let query = null;
  query = `?page=${last_id || ''}&per_page=${q || ''}`;
  return api.get(`/pixel-codes${query || ''}`);
}

export function pcSave(code, name, id) {
  if (id) {
    return api.post('/pixel-codes/save', { code, name, pixel_id: id });
  } else {
    return api.post('/pixel-codes/save', { code, name });
  }
}

export function pcEdit(id) {
  return api.get(`/pixel-codes/edit/${id}`);
}

export function pcDelete(id) {
  return api.get(`/pixel-codes/delete/${id}`);
}

export function getProfileFeed(nextOffset) {
  const user = JSON.parse(localStorage.getItem('user'));
  if (user && user.username) {
    const query = `&start=${
      nextOffset || Math.floor(new Date().valueOf() / 1000) + 100
    }`;

    return api.get(`/feed/profile?custom=${user.username}${query}`);
  }
}

export function getNotifs() {
  return api.get('/notifications');
}

export function getUnreadNotifCount() {
  return api.get('/notifications/unread-count');
}

export function createTest(testData, background, isEdit, testId) {
  return api.post(`/${isEdit && testId ? 'edit' : 'create'}/test`, {
    ...testData,
    background,
    id: testId,
  });
}

export function createTestQuestion(question) {
  return api.post(
    `/${question.id ? 'edit/question' : 'create/test-question'}`,
    question
  );
}

export function finishTest(id) {
  return api.post('/create/test-finish', { id });
}

export function getMetrics() {
  return api.get('/metrics');
}

export function getMetricsDaily(query, count) {
  return api.get(`/metrics/daily?metrics=${query}&count=${count}`);
}

export function getMetricsWeekly(query) {
  return api.get(`/metrics/weekly?metrics=${query}`);
}

export function getMetricsMonthly(query, dir = 'asc', count = 10) {
  return api.get(`/metrics/monthly?metrics=${query}&dir=${dir}&count=${count}`);
}

export function getContentMetrics(type, id, timespan, query) {
  return api.get(`/content-metrics/${timespan}/${type}/${id}?metrics=${query}`);
}

export function getVsMetricsDashboard(metric, dim) {
  return api.get(`/metrics/vs/dashboard?metric=${metric}&dim=${dim}`);
}

export function getVsMetricsContent(type, id, metric, dim) {
  return api.get(
    `/metrics/vs/content/${type}/${id}?metric=${metric}&dim=${dim}`
  );
}

export function getFriendQuizMetrics(id) {
  return api.get(`/friend-quiz/metrics/${id}`);
}

export function getFriendQuizSessions(id, last_id, q) {
  let query = null;
  query = `?page=${last_id || ''}&per_page=${q || ''}`;
  return api.get(`/friend-quiz/sessions/${id}${query || ''}`);
}

export function getFriendQuizPlayers(id, session_id, last_id, q) {
  let query = null;
  query = `?page=${last_id || ''}&per_page=${q || ''}`;
  return api.get(`/friend-quiz/players/${id}/${session_id}${query || ''}`);
}

export function createQuiz(quizData, background, isEdit, quizId) {
  return api.post(`/${isEdit && quizId ? 'edit' : 'create'}/quiz`, {
    ...quizData,
    background,
    id: quizId,
  });
}

export function createQuizQuestion(question) {
  return api.post(
    `/${question.id ? 'edit/question' : 'create/quiz-question'}`,
    question
  );
}

export function finishQuiz(id, results) {
  return api.post('/create/quiz-finish', { id, results });
}

export function editQuizResults(id, results) {
  return api.post('/edit/quiz-results', { id, results });
}

export function performContentAction(action, content_type, content_id) {
  return api.get(`/content/${action}/${content_type}/${content_id}`);
}

export function getQuestion(id) {
  return api.get(`/question/${id}`);
}

export function getEditQuestion(id) {
  return api.get(`/edit/question/${id}`);
}

export function getTest(id) {
  return api.get(`/test/${id}`);
}

export function getEditTest(id) {
  return api.get(`/edit/test/${id}`);
}

export function getQuiz(id) {
  return api.get(`/quiz/${id}`);
}

export function getEditQuiz(id) {
  return api.get(`/edit/quiz/${id}`);
}

export function getEditSet(id) {
  return api.get(`/edit/set/${id}`);
}

export function getTheme() {
  return api.get('/theme');
}

export function saveTheme(data) {
  return api.post('/theme', data);
}

export function getVoters(id, type, page, size) {
  type = type.toLowerCase();
  return api.get(`/voters/${type}/${id}?page=${page}&per_page=${size}`);
}

export function getVoteSources(id, type, page, size) {
  return api.get(`/vote-sources/${type}/${id}?page=${page}&per_page=${size}`);
}

export function getVotersRequest(id, type, page, size) {
  return api.get(
    `/voters/${type}/${id}?download&page=${page}&per_page=${size}`
  );
}

export function getLeadsRequest(lead_id, page, size) {
  return api.get(
    `/lead-campaigns/leads/${lead_id}?download&page=${page}&lead_per_page=${size}`
  );
}

export function getPublisherSettings(type) {
  return api.get(`/publisher-content-settings/${type}`);
}

export function setPublisherSettings(type, data) {
  return api.post(`/publisher-content-settings/${type}`, { settings: data });
}

export function getEmbedSettings(type) {
  return api.get(`/publisher-embed-settings/${type}`);
}

export function setEmbedSettings(type, data) {
  return api.post(`/publisher-embed-settings/${type}`, { settings: data });
}

export function getReportRequests(page, size) {
  return api.get(`/report-requests?page=${page}&per_page=${size}`);
}

export function getPublisherDashboard() {
  return api.get(`/publisher-dashboard`);
}

export function getSessionState(sessionId) {
  return api.get(`/lp/state/${sessionId}`);
}

export function getSessions() {
  return api.get('/lp/sessions');
}

export function getSessionInfo(sessionId) {
  return api.get(`/lp/session/${sessionId}`);
}

export function createSession() {
  return api.get('/lp/create-session');
}

export function getRecentPolls() {
  return api.get('/lp/recent-polls');
}

export function closeSession(sessionId) {
  return api.get(`/lp/close-session/${sessionId}`);
}

export function addPoll(sessionId, pollId) {
  return api.get(`/lp/add-poll/${sessionId}/${pollId}`);
}

export function removePoll(sessionId, pollId) {
  return api.get(`/lp/remove-poll/${sessionId}/${pollId}`);
}

export function readySession(sessionId) {
  return api.get(`/lp/ready-session/${sessionId}`);
}

export function nextStep(sessionId) {
  return api.get(`/lp/next-step/${sessionId}`);
}

export function setSessionAssets(session_id, assets) {
  return api.post('/lp/set-session-assets', { session_id, ...assets });
}

export function getTriviaHistory(page, size) {
  return api.get(`/trivia/history?page=${page}&per_page=${size}`);
}

export function getPlayerList(id, page, size, winners_only) {
  return api.get(
    `/trivia/playerlist/${id}?page=${page}&per_page=${size}&winners_only=${winners_only}`
  );
}

export function getTriviaActions(id) {
  return api.get(`/trivia/actions/${id}`);
}

export function getTriviaCredit() {
  return api.get(`/trivia-credits`);
}

export function triviaInit(id, options) {
  return api.post(`/trivia/init`, { quiz_id: id, ...options });
}

export function triviaAction(id, action) {
  return api.get(`/trivia/${action}/${id}`);
}

export function getTriviaInfo(id) {
  return api.get(`/trivia/${id}`);
}

export function getTriviaState(id) {
  return api.get(`/trivia/state/${id}`);
}

export function getTopPlayers(id) {
  return api.get(`/trivia/top-players/${id}`);
}

export function setRedirectUrl(id, url) {
  return api.post('/trivia/set-redirect-url', { quiz_id: id, url });
}

export function getDomainsList(page, size, isApproved) {
  return api.get(
    `/domain/list?page=${page}&per_page=${size}&approved=${isApproved}`
  );
}

export function addDomain(domain) {
  return api.post('/domain/add', { domain });
}

export function deleteDomain(domainId) {
  return api.get(`/domain/delete/${domainId}`);
}

export function getPoolsList(page, size) {
  return api.get(`/pool/list?page=${page}&per_page=${size}`);
}

export function addPool(name, register_fields) {
  return api.post('/pool/add', { name, register_fields });
}

export function deletePool(poolId) {
  return api.get(`/pool/delete/${poolId}`);
}

export function deleteContent(type, id) {
  return api.get(`/content/delete/${type}/${id}`);
}

export function getSankeyMetrics(type, id) {
  return api.get(`/metrics/sankey/${type}/${id}`);
}

export function getSankey(type, id, id1, id2, page, size) {
  return api.get(
    `/sankey/users/${type}/${id}/${id1}/${id2}?page=${page}&per_page=${size}`
  );
}
