import React, { useState, useEffect, useCallback } from 'react';
import { injectIntl } from 'react-intl';
import {
  MDBInput,
  toast,
  Button,
  MDBRow,
  MDBCol,
  MDBContainer,
  MDBSpinner,
} from 'mdbreact';
import { sheethooksSave, sheethooksEdit } from '../../api';
import history from '../../core/history';

import messages from './messages';
import SectionWithTitle from '../Common/SectionWithTitle';

const SheethooksEdit = ({
  intl: { formatMessage },
  location,
  location: { state: { content_type, content_id } = {} },
}) => {
  const [sheetId, setSheetId] = useState('');
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);

  const getHook = useCallback(async () => {
    const id = location.state.id;
    setLoading(true);
    const { data } = await sheethooksEdit(id);
    setSheetId(data.sheet_id);
    setName(data.name);
    setLoading(false);
  }, [location.state.id]);

  useEffect(() => {
    getHook();
  }, [getHook]);

  const onFormSubmit = async (e) => {
    e.preventDefault();
    try {
      setSaving(true);
      await sheethooksSave({
        sheet_id: sheetId,
        name,
        id: location.state.id,
        content_type,
        content_id: parseInt(content_id, 10),
      });
      history.push('/sheethooks', { type: content_type, id: content_id });
      toast.success(formatMessage(messages.SheethookAdded));
    } catch (e) {
      setSaving(false);
      toast.error(formatMessage(messages.ErrorSaving));
    }
  };

  return loading ? (
    <MDBContainer>
      <MDBRow center>
        <MDBSpinner blue big />
      </MDBRow>
    </MDBContainer>
  ) : (
    <MDBContainer fluid>
      <MDBRow>
        <MDBCol className=".col-12 .col-sm-6 .col-md-8">
          <SectionWithTitle title={formatMessage(messages.EditWebhook)}>
            <form method="post" onSubmit={onFormSubmit}>
              <div className="grey-text">
                <MDBInput
                  label={formatMessage(messages.Type)}
                  group
                  type="text"
                  name="type"
                  value={content_type}
                  icon="disabled"
                  disabled
                />
                <MDBInput
                  label={formatMessage(messages.ID)}
                  group
                  type="text"
                  name="id"
                  value={content_id}
                  icon="disabled"
                  disabled
                />
                <MDBInput
                  label={formatMessage(messages.Name)}
                  group
                  type="text"
                  name="name"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  icon="signature"
                />
                <MDBInput
                  type="text"
                  label={formatMessage(messages.SheetId)}
                  group
                  name="sheetId"
                  onChange={(e) => {
                    setSheetId(e.target.value);
                  }}
                  value={sheetId}
                  rows="10"
                  icon="code"
                />
              </div>
              <div className="text-center" style={{ marginTop: '20px' }}>
                <Button color="primary" type="submit" disabled={saving}>
                  {formatMessage(messages.Save)}
                </Button>
              </div>
            </form>
          </SectionWithTitle>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default injectIntl(SheethooksEdit);
