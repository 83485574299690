import React, { Component, Fragment } from 'react';
import { injectIntl } from 'react-intl';
import { Col, Row, MDBBtn } from 'mdbreact';

import messages from '../messages';
import ImageInput from '../../ImageInput';
import TextInput from '../../TextInput';
import PollCreateOptions from './PollCreateOptions';
import PollCreateAdvancedOptions from './PollCreateAdvancedOptions';
import Step2 from './Step2';

class Step1 extends Component {
  state = {
    expanded: false,
    waitingAPI: false,
  };

  render() {
    const { waitingAPI } = this.state;
    const { formatMessage } = this.props.intl;
    const {
      poll,
      handleTextChange,
      setSRC,
      isEmbedly,
      handleTextChangeWithoutEmbedly,
      changePollType,
      handleAnswerChange,
      changeNumber,
      setAnswerImg,
      pixelCode,
      leadSelect,
      createPoll,
    } = this.props;

    const canFinish =
      poll.title.length > 1 &&
      poll.answers.filter((a) => a.title.length < 1).length === 0;

    return (
      <>
        <Col md="12">
          <div className="col-12 mb-3">
            <TextInput
              className="mt-3 mb-1"
              label={formatMessage(messages.YourQ)}
              maxLength={250}
              minLength={1}
              name="title"
              onChange={handleTextChange}
              rows={2}
              value={poll.title}
              required
              textarea
            />
          </div>
          <div className="col-12 mb-3">
            <ImageInput
              title={formatMessage(messages.QuestionImage)}
              disabled={isEmbedly}
              setSRC={setSRC}
            />
          </div>
          <Step2
            poll={poll}
            changePollType={changePollType}
            handleAnswerChange={handleAnswerChange}
            changeNumber={changeNumber}
            setAnswerImg={setAnswerImg}
            pixelCode={pixelCode}
            leadSelect={leadSelect}
          />
          {this.state.expanded ? (
            <Fragment>
              <Row style={{ margin: '1.5rem' }}>
                <Col className="text-center">
                  <MDBBtn
                    color="primary"
                    onClick={() =>
                      this.setState({ expanded: !this.state.expanded })
                    }
                  >
                    <strong>{`- ${formatMessage(messages.Collapse)}`}</strong>
                  </MDBBtn>
                </Col>
              </Row>
              <PollCreateOptions
                poll={this.props.poll}
                handleSwitchChange={this.props.handleSwitchChange}
                handleTextChangeWithoutEmbedly={handleTextChangeWithoutEmbedly}
                setSRC={setSRC}
              />
              <PollCreateAdvancedOptions
                poll={this.props.poll}
                handleSwitchChange={this.props.handleSwitchChange}
                getSelectValue={this.props.getSelectValue2}
              />
            </Fragment>
          ) : (
            <Row style={{ margin: '1.5rem' }}>
              <Col className="text-center">
                <MDBBtn
                  color="primary"
                  onClick={() =>
                    this.setState({ expanded: !this.state.expanded })
                  }
                >
                  <strong>{`+ ${formatMessage(messages.Expand)}`}</strong>
                </MDBBtn>
              </Col>
            </Row>
          )}
          <div className="col-12 mb-5">
            <ImageInput
              title={formatMessage(messages.EmbedBg)}
              setSRC={setSRC}
              target={'embed_background'}
            />
            <ImageInput
              title={formatMessage(messages.CustomLogo)}
              ratio={2}
              setSRC={setSRC}
              target={'embed_footer_img'}
            />
            <TextInput
              maxLength={250}
              label={formatMessage(messages.FooterLink)}
              className="mt-3 mb-1"
              name="embed_footer_url"
              value={poll.embed_footer_url || ''}
              onChange={handleTextChangeWithoutEmbedly}
            />
          </div>
          <button
            type="button"
            className={`float-right btn btn-red ${
              canFinish && !waitingAPI ? '' : 'disabled'
            }`}
            onClick={() => {
              this.setState({ waitingAPI: true }, createPoll);
            }}
          >
            {formatMessage(messages.Finish)}
          </button>
        </Col>
        <Col>
          <Row>
            <Col>
              {!canFinish ? (
                <div className={'float-right text-danger'}>
                  {poll.title.length === 0
                    ? `${formatMessage(messages.PleaseEnterQuestion)}`
                    : null}
                </div>
              ) : null}
            </Col>
          </Row>
          <Row>
            <Col>
              {(!canFinish && poll.answer_type === 'text') ||
              (!canFinish && poll.answer_type === 'media') ? (
                <div className={'float-right text-danger'}>
                  {poll.answers.filter((a) => a.title.length === 1)
                    ? `${formatMessage(messages.PleaseEnterAnswer)}`
                    : null}
                </div>
              ) : null}
            </Col>
          </Row>
        </Col>
      </>
    );
  }
}

export default injectIntl(Step1);
