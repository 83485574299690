import React, { useState, useEffect, useReducer } from 'react';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCardBody,
  MDBCard,
  MDBRotatingCard,
  toast,
} from 'mdbreact';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { injectIntl } from 'react-intl';
import { triviaInit, getTriviaCredit } from '../../../api';

import messages from '../messages';
import StartDate from './StartDate';
import RegisterFields from './RegisterFields';
import Submit from './Submit';
import Tier from './Tier';
import SwitchOptions from './SwitchOptions';
import RotateButton from './RotateButton';
import { initialTriviaState, reducer } from './reducer';

dayjs.extend(localizedFormat);

const ManageTrivia = ({ intl: { formatMessage }, intl, history }) => {
  const [triviaCode, setTriviaCode] = useState('');
  const [startDate, setStartDate] = useState(new Date());
  const [credit, setCredit] = useState(0);
  const [rotated, setRotated] = useState(false);
  const [triviaState, dispatch] = useReducer(reducer, initialTriviaState);

  const getCredit = async () => {
    const d = new Date();
    const n = d.getTimezoneOffset();
    const timezone = n / 60;

    const response = await getTriviaCredit();
    setCredit(response.data.credits_available);
    dispatch({ type: 'timezone_change', data: timezone });
    dispatch({
      type: 'set_tier',
      data: response.data.credits_available > 0 ? 'credit' : 'free',
    });
  };

  useEffect(() => {
    getCredit();
  }, []);

  const onSubmit = async (e) => {
    try {
      const activeFields = triviaState.register_fields.filter((r) => r.active);
      const response = await triviaInit(parseInt(triviaCode, 10), {
        ...triviaState,
        start_date: dayjs(startDate, 'yyyy-MM-dd HH:mm:ss'),
        register_fields: activeFields,
      });
      history.push(`/manage-trivia/${response.data.id}`);
    } catch (e) {
      if (e.response.status === 403) {
        toast.error(formatMessage(messages.QuizNotYours));
      } else if (e.response.status === 410) {
        toast.error(formatMessage(messages.QuizUnpublished));
      } else {
        toast.error(formatMessage(messages.QuizNotFound));
      }
    }
  };

  return (
    <MDBContainer fluid>
      <h2 className="text-center font-weight-bold pt-4 pb-5">
        <strong>{formatMessage(messages.CreateNewTrivia)}</strong>
      </h2>
      <Submit
        formatMessage={formatMessage}
        messages={messages}
        triviaCode={triviaCode}
        setTriviaCode={setTriviaCode}
        onSubmit={onSubmit}
      />
      <MDBRow center>
        <MDBCol style={{ minHeight: '26rem' }} md="6">
          <MDBRotatingCard
            flipped={rotated}
            className="text-center h-100 w-100"
          >
            <MDBCard className="face front">
              <MDBCardBody>
                {/* <Mode
                  formatMessage={formatMessage}
                  messages={messages}
                  triviaState={triviaState}
                  dispatch={dispatch}
                /> */}

                <Tier
                  formatMessage={formatMessage}
                  messages={messages}
                  credit={credit}
                  triviaState={triviaState}
                  dispatch={dispatch}
                />

                <SwitchOptions
                  formatMessage={formatMessage}
                  messages={messages}
                  triviaState={triviaState}
                  dispatch={dispatch}
                />

                <StartDate
                  startDate={startDate}
                  setStartDate={setStartDate}
                  timezone={triviaState.start_date_timezone}
                  dispatch={dispatch}
                  intl={intl}
                />

                <RotateButton
                  formatMessage={formatMessage}
                  messages={messages}
                  rotated={rotated}
                  setRotated={setRotated}
                />
              </MDBCardBody>
            </MDBCard>
            <MDBCard className="face back">
              <MDBCardBody>
                <RegisterFields
                  messages={messages}
                  formatMessage={formatMessage}
                  fields={triviaState.register_fields}
                  dispatch={dispatch}
                />

                <RotateButton
                  formatMessage={formatMessage}
                  messages={messages}
                  rotated={rotated}
                  setRotated={setRotated}
                />
              </MDBCardBody>
            </MDBCard>
          </MDBRotatingCard>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default injectIntl(ManageTrivia);
