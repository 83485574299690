/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { toast, Spinner, MDBTooltip } from 'mdbreact';
import { getVoters } from '../../api';
import history from '../../core/history';

import messages from './messages';
import Pagination from '../Pagination';
import SectionWithTitle from '../Common/SectionWithTitle';

class VotersReport extends Component {
  state = {
    id: null,
    type: null,
    voters: [],
    totalSize: 25,
    page: 1,
    sizePerPage: 25,
    lastPage: 0,
    fetching: false,
    downloading: false,
    loading: true,
  };

  async componentDidMount() {
    const { id, type } = this.props;
    this.setState({ id, type });

    if (id && type) {
      try {
        await this.fetchData(this.state.page, this.state.sizePerPage, id, type);
        this.setState({ loading: false });
      } catch (e) {
        history.push('/reports', { id, type });
      }
    }
  }

  fetchData = async (
    page = this.state.page,
    sizePerPage = this.state.sizePerPage,
    idParam,
    typeParam
  ) => {
    const { id, type } = this.state;
    if (!id && !idParam) return;

    this.setState({ fetching: true });
    try {
      const r = await getVoters(
        id || idParam,
        type || typeParam,
        page,
        sizePerPage
      );
      this.setState({
        voters: r.data.data,
        totalSize: r.data.total,
        page,
        sizePerPage,
        lastPage: r.data.last_page,
        fetching: false,
      });
    } catch (e) {
      toast.error('Error occured!');
      this.setState({
        voters: [],
        totalSize: 100,
        page: 1,
        sizePerPage: 100,
        lastPage: 0,
        fetching: false,
      });
    }
  };

  handlePageChange = (page, sizePerPage) => {
    this.fetchData(page, sizePerPage);
  };

  render() {
    const { formatMessage } = this.props.intl;
    const { voters, loading, fetching } = this.state;
    let { type } = this.state;

    type = type && type.toLowerCase();

    return (
      <SectionWithTitle title={formatMessage(messages.Voters)}>
        {(loading || fetching) && voters.length === 0 && <Spinner big blue />}
        {!loading && voters.length > 0 && (
          <div>
            <Pagination
              last={this.state.lastPage}
              active={this.state.page}
              size={this.state.sizePerPage}
              handlePageChange={this.handlePageChange}
              pageSizes={[10, 25, 50]}
            />
            <div className="row">
              {loading || fetching ? (
                <Spinner big blue />
              ) : (
                <div className="col-12">
                  <table className="table table-striped table-bordered table-sm table-fixed-header">
                    <thead>
                      <tr>
                        <th className="th-sm">{'VoteID'}</th>
                        <th className="th-sm">{'UserID'}</th>
                        <th className="th-sm">{'VoteDate'}</th>
                        <th className="th-sm">{'PublisherSourceID'}</th>
                        {type !== 'poll' && (
                          <th className="th-sm">{'QuestionID'}</th>
                        )}
                        <th className="th-sm">{'Question'}</th>
                        {type !== 'poll' && <th className="th-sm">{'Type'}</th>}
                        <th className="th-sm">{'Parent'}</th>
                        <th className="th-sm">{'AnswerID'}</th>
                        <th className="th-sm">{'Answer'}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {voters.map((voter, i) => {
                        let question = voter.QuestionTitle
                          ? voter.QuestionTitle.split(' ')
                          : '';
                        if (question.length > 2) {
                          question = question.slice(0, 2).join(' ') + '...';
                        } else {
                          question = voter.QuestionTitle;
                        }

                        let answer = voter.AnswerTitle
                          ? voter.AnswerTitle.split(' ')
                          : '';
                        if (answer.length > 2) {
                          answer = answer.slice(0, 2).join(' ') + '...';
                        } else {
                          answer = voter.AnswerTitle;
                        }

                        return (
                          <tr key={i}>
                            <td>
                              <MDBTooltip placement="top" domElement>
                                <span>{voter.VoteID}</span>
                                <div>{voter.VoteID}</div>
                              </MDBTooltip>
                            </td>
                            <td>
                              <MDBTooltip placement="top" domElement>
                                <span>{voter.PoltioUserID}</span>
                                <div>{voter.PoltioUserID}</div>
                              </MDBTooltip>
                            </td>
                            <td>
                              <MDBTooltip placement="top" domElement>
                                <span>{voter.VoteDate}</span>
                                <div>{voter.VoteDate}</div>
                              </MDBTooltip>
                            </td>
                            <td>
                              <MDBTooltip placement="top" domElement>
                                <span>{voter.PublisherSourceID}</span>
                                <div>{voter.PublisherSourceID}</div>
                              </MDBTooltip>
                            </td>
                            {type !== 'poll' && (
                              <td>
                                <MDBTooltip placement="top" domElement>
                                  <span>{voter.QuestionID}</span>
                                  <div>{voter.QuestionID}</div>
                                </MDBTooltip>
                              </td>
                            )}
                            <td>
                              <MDBTooltip placement="top" domElement>
                                <span>{question}</span>
                                <div>{voter.QuestionTitle}</div>
                              </MDBTooltip>
                            </td>
                            {type !== 'poll' && (
                              <td>
                                <MDBTooltip placement="top" domElement>
                                  <span>{voter.ContentType}</span>
                                  <div>{voter.ContentType}</div>
                                </MDBTooltip>
                              </td>
                            )}
                            <td>
                              <MDBTooltip placement="top" domElement>
                                <span>{voter.ParentContentID}</span>
                                <div>
                                  {voter.ParentContentID
                                    ? voter.ParentContentID.toString()
                                    : ''}
                                </div>
                              </MDBTooltip>
                            </td>
                            <td>
                              <MDBTooltip placement="top" domElement>
                                <span>{voter.AnswerID}</span>
                                <div>{voter.AnswerID}</div>
                              </MDBTooltip>
                            </td>
                            <td>
                              <MDBTooltip
                                placement="top"
                                domElement
                                tooltipContent={`${voter.AnswerTitle}`}
                              >
                                <span>{answer}</span>
                                <div>{voter.AnswerTitle}</div>
                              </MDBTooltip>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        )}
      </SectionWithTitle>
    );
  }
}

export default injectIntl(VotersReport);
