import React, { useState } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import {
  MDBBtn,
  MDBMask,
  MDBRow,
  MDBCol,
  MDBView,
  MDBContainer,
  toast,
} from 'mdbreact';
import { injectIntl } from 'react-intl';

import { login, forgotPassword, register } from '../../api';
import { validateEmail } from '../../core/helper';

import messages from './messages';
import Login from './Login';
import ForgotPassword from './ForgotPassword';
import Signup from './Signup';
import Footer from './Footer';
import './style.css';

const LoginControl = ({ intl, intl: { formatMessage }, history }) => {
  const [loginFormData, setLoginFormData] = useState({
    email: '',
    pass: '',
    errorMessage: '',
  });
  const [forgotFormData, setForgotFormData] = useState({
    email: '',
    errorMessage: '',
  });
  const [signupFormData, setSignupFormData] = useState({
    email: '',
    pass: '',
    passAgain: '',
    termsAccepted: false,
    errorMessage: '',
  });
  const [sending, setSending] = useState(false);

  const onLoginFormSubmit = async (e) => {
    e.preventDefault();
    const { email, pass } = loginFormData;
    const returnUrl =
      history.location.state && history.location.state.returnUrl;

    if (!validateEmail(email)) {
      setLoginFormData({
        ...loginFormData,
        errorMessage: formatMessage(messages.EnterValidEmail),
      });
      return;
    }

    setSending(true);
    try {
      await login(email, pass, returnUrl || '/');
    } catch (e) {
      setSending(false);
      setLoginFormData({
        ...loginFormData,
        errorMessage: formatMessage(messages.WrongCombination),
      });
    }
  };

  const onForgotFormSubmit = async (e) => {
    e.preventDefault();
    const { email } = forgotFormData;

    if (!validateEmail(forgotFormData.email)) {
      setForgotFormData({
        ...forgotFormData,
        errorMessage: formatMessage(messages.EnterValidEmail),
      });
      return;
    }

    setSending(true);
    try {
      await forgotPassword(email);
      toast.success(formatMessage(messages.EmailSent));
      setSending(false);
    } catch (e) {
      setSending(false);
      setForgotFormData({
        ...forgotFormData,
        errorMessage: formatMessage(messages.CouldNotFindEmail),
      });
    }
  };

  const onSignupFormSubmit = async (e) => {
    e.preventDefault();
    const { email, pass } = signupFormData;
    const returnUrl =
      history.location.state && history.location.state.returnUrl;

    if (formInvalid()) return;

    setSending(true);
    try {
      await register(email, pass, returnUrl || '/');
    } catch (e) {
      setSending(false);
      setSignupFormData({
        ...signupFormData,
        errorMessage: formatMessage(messages.EmailTaken),
      });
    }
  };

  const formInvalid = () => {
    const { email, pass, passAgain, termsAccepted } = signupFormData;

    if (!validateEmail(email)) {
      setSignupFormData({
        ...signupFormData,
        errorMessage: formatMessage(messages.EnterValidEmail),
      });
      return true;
    }
    if (pass.length < 6) {
      setSignupFormData({
        ...signupFormData,
        errorMessage: formatMessage(messages.PasswordSix),
      });
      return true;
    }
    if (pass !== passAgain) {
      setSignupFormData({
        ...signupFormData,
        errorMessage: formatMessage(messages.PasswordMatch),
      });
      return true;
    }
    if (!termsAccepted) {
      setSignupFormData({
        ...signupFormData,
        errorMessage: formatMessage(messages.PleaseAgree),
      });
      return true;
    }

    return false;
  };

  return (
    <div id="loginpage">
      <MDBView>
        <MDBMask
          className="d-flex justify-content-center align-items-center"
          overlay="indigo-strong"
        >
          <MDBContainer>
            <MDBRow>
              <div className="white-text text-center text-md-left col-md-6 mt-xl-5 mb-5">
                <h1 className="display-4 font-weight-bold">Poltio PRO </h1>
                <hr className="hr-light" />
                <h6 className="mb-4">{formatMessage(messages.LoginText)}</h6>
                <MDBBtn
                  outline
                  color="white"
                  onClick={() =>
                    window.open(
                      'https://www.poltio.com?utm_source=prologinpage',
                      '_blank'
                    )
                  }
                >
                  {formatMessage(messages.LearnMore)}
                </MDBBtn>
              </div>
              <MDBCol md="6" xl="5" className="mb-4">
                <Switch>
                  <Route
                    path="/login"
                    render={(props) => (
                      <Login
                        {...props}
                        messages={messages}
                        formatMessage={formatMessage}
                        formData={loginFormData}
                        setFormData={setLoginFormData}
                        onFormSubmit={onLoginFormSubmit}
                        sending={sending}
                      />
                    )}
                  />
                  <Route
                    path="/forgot-password"
                    render={(props) => (
                      <ForgotPassword
                        {...props}
                        messages={messages}
                        formatMessage={formatMessage}
                        formData={forgotFormData}
                        setFormData={setForgotFormData}
                        onFormSubmit={onForgotFormSubmit}
                        sending={sending}
                      />
                    )}
                  />
                  <Route
                    path="/signup"
                    render={(props) => (
                      <Signup
                        {...props}
                        messages={messages}
                        formatMessage={formatMessage}
                        locale={intl.locale}
                        formData={signupFormData}
                        setFormData={setSignupFormData}
                        onFormSubmit={onSignupFormSubmit}
                        sending={sending}
                      />
                    )}
                  />
                  <Redirect to="/l/login" />
                </Switch>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBMask>
      </MDBView>
      <Footer messages={messages} formatMessage={formatMessage} />
    </div>
  );
};

export default injectIntl(LoginControl);
