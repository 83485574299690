import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { MDBCollapse, MDBCollapseHeader } from 'mdbreact';

import messages from '../messages';

export const CalculatorFormulaValueInput = ({
  name,
  set,
  cal_val_default = 0,
}) => {
  let [value, setValue] = useState(cal_val_default);

  const onChange = (event) => {
    let _val = event.target.value;

    if (isNaN(_val)) return;

    setValue(Number(_val));
    set(name, _val);
  };

  return (
    <div className="col-2 btn-group my-0 mx-2 px-1 py-1 justify-content-center">
      <div
        className="btn btn-primary btn-rounded m-0 py-1 px-2"
        style={{ pointerEvents: 'none', flex: 'unset' }}
      >
        {name}
      </div>
      <input
        className="btn btn-rounded m-0 py-1 px-2 w-50"
        style={{ flex: 'unset' }}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

const ValueInputForm = ({
  intl: { formatMessage },
  isLoading,
  inputs,
  valueChangeHandler,
}) => {
  const [isCollapsed, setCollapsed] = useState(true);

  return (
    <>
      <MDBCollapseHeader
        onClick={() => {
          setCollapsed(!isCollapsed);
        }}
        className="col-12 bg-transparent pb-1 px-0"
      >
        {formatMessage(messages.CalculatorVariableInputs)}
        <i
          className={
            isCollapsed
              ? 'float-right fa fa-angle-down'
              : 'float-right fa fa-angle-up'
          }
        />
      </MDBCollapseHeader>
      <MDBCollapse isOpen={!isCollapsed} className="mt-2 pb-2 border-bottom">
        {isLoading ? (
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        ) : (
          <div className="row">
            {Object.keys(inputs).map((key) => (
              <CalculatorFormulaValueInput
                name={key}
                cal_val_default={inputs[key]}
                set={valueChangeHandler}
              />
            ))}
          </div>
        )}
      </MDBCollapse>
    </>
  );
};

export const CalculatorFormulaValueInputForm = injectIntl(ValueInputForm);
