import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import './core/localStoragePolyfill.js';

import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import { sentryConfig } from './core/sentryConfig';

import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import './styles/app.css';

import App from './components/App';
import history from './core/history';

import { IntlProvider } from 'react-intl';
import translations from './i18n/locales';

import dayjs from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/tr';

Sentry.init(sentryConfig);

const search = history.location.search;
const params = new URLSearchParams(search); // eslint-disable-line no-undef
const loc = params.get('loc');
const locParam = ['en', 'tr'].indexOf(loc) > -1 ? loc : null;

const language =
  locParam ||
  window.localStorage.getItem('lang') ||
  (navigator.languages && navigator.languages[0]) ||
  navigator.language ||
  navigator.userLanguage;
const languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0];
const messages =
  translations[languageWithoutRegionCode] ||
  translations[language] ||
  translations.en;

dayjs.locale(language);
document.documentElement.setAttribute('lang', languageWithoutRegionCode);

ReactDOM.render(
  <IntlProvider
    locale={languageWithoutRegionCode}
    key={language}
    messages={messages}
  >
    <App />
  </IntlProvider>,
  document.getElementById('root')
);
