import React, { Fragment } from 'react';
import { injectIntl } from 'react-intl';
import {
  MDBBtn,
  MDBCol,
  MDBRow,
  MDBInput,
  Fa,
  InputSwitch,
  MDBSelect,
  MDBSelectInput,
  MDBSelectOption,
  MDBSelectOptions,
  Stepper,
  Step,
} from 'mdbreact';

import messages from '../messages';
import ImageInput from '../../ImageInput';
import PixelCodeSelector from '../PixelCodeSelector';
import LeadSelector from '../LeadSelector';

const Step2 = ({
  intl: { formatMessage },
  data,
  questions,
  setStep,
  questionIndex,
  handleTitleChange,
  setSRC,
  setAnswerImg,
  handleAnswerChange,
  pixelCode,
  leadSelect,
  changeNumber,
  saveQuestion,
  savingQuestion,
  handleSwitchChange,
  getSelectValue,
  moveQuestions,
  finishSet,
  addNewQuestionToTheEnd,
  dirty,
  scoreCheckboxChanged,
  previousCheckboxChanged,
  deleteAnswer,
  getUseAnswersValue,
}) => {
  const titleSize = 255;
  const answerSize = 255;
  const questionTitleLength = questions[questionIndex]?.title.length;

  let answerTitle = questions[questionIndex]?.answers.every(function (e) {
    return e.title;
  });
  let answerBg = questions[questionIndex]?.answers.every(function (e) {
    return e.background;
  });

  return (
    <MDBCol>
      {questions &&
        questions.map(
          (question, i) =>
            questionIndex === i && (
              <Fragment key={i}>
                <h4>{`${formatMessage(messages.Question)} ${i + 1}`}</h4>
                <MDBCol md="12">
                  <MDBInput
                    label={`${formatMessage(messages.QuestionTitle)} -${
                      titleSize - question.title.length
                    }`}
                    className="mt-3"
                    maxlenght={titleSize}
                    type="textarea"
                    name="title"
                    id={`questionInput${i}`}
                    value={question.title}
                    onChange={handleTitleChange}
                  />
                </MDBCol>
                <div className={'ml-2'}>
                  <ImageInput
                    title={''}
                    setSRC={setSRC}
                    src={question.background}
                  />
                </div>
                <MDBCol md="12">
                  <MDBInput
                    label={`${formatMessage(messages.LeadUrlVariable)}`}
                    className="form-control"
                    type="textarea"
                    size="sm"
                    name="luv"
                    value={question.luv}
                    onChange={handleTitleChange}
                  />
                </MDBCol>
                {question.answer_type === 'autocomplete' ? (
                  <MDBCol md="12">
                    <MDBInput
                      label={`${formatMessage(messages.AutocompleteHelp)}`}
                      className="form-control"
                      type="textarea"
                      size="sm"
                      name="autocomplete_help"
                      value={question.autocomplete_help}
                      onChange={handleTitleChange}
                    />
                  </MDBCol>
                ) : null}
                {question.answer_type === 'autocomplete' ? (
                  <MDBCol md="12">
                    <MDBInput
                      label={`${formatMessage(
                        messages.AutocompletePlaceholder
                      )}`}
                      className="form-control"
                      type="textarea"
                      size="sm"
                      name="autocomplete_placeholder"
                      value={question.autocomplete_placeholder}
                      onChange={handleTitleChange}
                    />
                  </MDBCol>
                ) : null}
                {data.is_calculator ? (
                  <MDBCol md="12">
                    <MDBInput
                      label={`${formatMessage(
                        messages.DefaultCalculatorValue
                      )}`}
                      className="form-control"
                      type="number"
                      size="sm"
                      name="cal_val_default"
                      value={question.cal_val_default}
                      onChange={handleTitleChange}
                    />
                  </MDBCol>
                ) : null}
                <MDBCol md="12">
                  <MDBRow>
                    <MDBCol md="6" style={{ marginLeft: '-.5rem' }}>
                      <PixelCodeSelector
                        id={question.question_pixel_code_id}
                        index={-1}
                        pixelCode={pixelCode}
                      />
                    </MDBCol>
                    <MDBCol md="6">
                      <LeadSelector
                        id={question.question_lead_id}
                        index={-1}
                        leadSelect={leadSelect}
                      />
                    </MDBCol>
                  </MDBRow>
                </MDBCol>
                <MDBCol md="12">
                  <MDBRow style={{ marginTop: '20px' }}>
                    <MDBCol>
                      <label>{formatMessage(messages.FreeText)}</label>
                    </MDBCol>
                    <MDBCol>
                      <InputSwitch
                        onChange={(e) => handleSwitchChange(e, 'free_text')}
                        checked={question.answer_type === 'free_text'}
                        labelLeft={formatMessage(messages.Off)}
                        labelRight={formatMessage(messages.On)}
                      />
                    </MDBCol>
                  </MDBRow>
                </MDBCol>
                <MDBCol md="12">
                  <MDBRow style={{ marginTop: '20px' }}>
                    <MDBCol>
                      <label>{formatMessage(messages.FreeNumber)}</label>
                    </MDBCol>
                    <MDBCol>
                      <InputSwitch
                        onChange={(e) => handleSwitchChange(e, 'free_number')}
                        checked={question.answer_type === 'free_number'}
                        labelLeft={formatMessage(messages.Off)}
                        labelRight={formatMessage(messages.On)}
                      />
                    </MDBCol>
                  </MDBRow>
                </MDBCol>
                <MDBCol md="12">
                  <MDBRow style={{ marginTop: '20px' }}>
                    <MDBCol>
                      <label>{'Autocomplete'}</label>
                    </MDBCol>
                    <MDBCol>
                      <InputSwitch
                        onChange={(e) => handleSwitchChange(e, 'autocomplete')}
                        checked={question.answer_type === 'autocomplete'}
                        labelLeft={formatMessage(messages.Off)}
                        labelRight={formatMessage(messages.On)}
                      />
                    </MDBCol>
                  </MDBRow>
                </MDBCol>

                {question.answer_type !== 'free_text' &&
                  question.answer_type !== 'free_number' && (
                    <Fragment>
                      {question.use_answers ||
                      question.clone_answers_from ||
                      question.id ? null : (
                        <MDBCol md="12">
                          <MDBRow
                            style={{ marginBottom: '1em', marginTop: '0.5em' }}
                          >
                            <MDBInput
                              label="Score Type Answers"
                              type="checkbox"
                              id="checkbox_score"
                              onChange={scoreCheckboxChanged}
                              checked={question.answer_type === 'score'}
                            />
                          </MDBRow>
                        </MDBCol>
                      )}
                      {i > 0 && question.answer_type !== 'score' ? (
                        <MDBCol md="12">
                          <MDBRow style={{ marginBottom: '10px' }}>
                            <MDBInput
                              label={`${formatMessage(messages.UseAnswers)}`}
                              type="checkbox"
                              id="checkbox_useanswers"
                              onChange={previousCheckboxChanged}
                              checked={
                                question.use_answers ||
                                question.clone_answers_from
                              }
                            />
                          </MDBRow>
                        </MDBCol>
                      ) : null}
                      {question.use_answers || question.clone_answers_from ? (
                        <MDBSelect
                          getValue={(val) => getUseAnswersValue(val, i)}
                        >
                          <MDBSelectInput
                            selected={
                              question.clone_answers_from
                                ? `${formatMessage(messages.Question)} ${
                                    questions.findIndex(
                                      (x) =>
                                        x.id === question.clone_answers_from
                                    ) + 1
                                  }`
                                : 'Choose question'
                            }
                          />
                          <MDBSelectOptions>
                            {questions.slice(0, i).map((q, ind) => (
                              <MDBSelectOption value={ind + 1} key={ind}>
                                {`${formatMessage(messages.Question)} ${
                                  ind + 1
                                }`}
                              </MDBSelectOption>
                            ))}
                          </MDBSelectOptions>
                        </MDBSelect>
                      ) : null}
                    </Fragment>
                  )}

                {question.answer_type === 'score' ? (
                  <MDBRow>
                    <MDBCol sm="12">
                      <Stepper form>
                        <Step form>
                          <a
                            href="#formstep1"
                            onClick={(e) => e.preventDefault()}
                          >
                            <MDBBtn color="default" circle>
                              1
                            </MDBBtn>
                          </a>
                        </Step>
                        <Step form>
                          <a
                            href="#formstep1"
                            onClick={(e) => e.preventDefault()}
                          >
                            <MDBBtn color="default" circle>
                              2
                            </MDBBtn>
                          </a>
                        </Step>
                        <Step form>
                          <a
                            href="#formstep1"
                            onClick={(e) => e.preventDefault()}
                          >
                            <MDBBtn color="default" circle>
                              3
                            </MDBBtn>
                          </a>
                        </Step>
                        <Step form>
                          <a
                            href="#formstep1"
                            onClick={(e) => e.preventDefault()}
                          >
                            <MDBBtn color="default" circle>
                              4
                            </MDBBtn>
                          </a>
                        </Step>
                        <Step form>
                          <a
                            href="#formstep1"
                            onClick={(e) => e.preventDefault()}
                          >
                            <MDBBtn color="default" circle>
                              5
                            </MDBBtn>
                          </a>
                        </Step>
                      </Stepper>
                    </MDBCol>
                    <MDBCol>
                      {question.answers &&
                        question.answers.map((answer, i) => (
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}
                            key={i}
                          >
                            <div style={{ width: '50%' }}>
                              <PixelCodeSelector
                                index={i}
                                pixelCode={pixelCode}
                                id={answer.pixel_code_id}
                              />
                            </div>
                            <div style={{ width: '50%' }}>
                              <LeadSelector
                                index={i}
                                leadSelect={leadSelect}
                                id={answer.lead_id}
                              />
                            </div>
                          </div>
                        ))}
                    </MDBCol>
                  </MDBRow>
                ) : question.answer_type !== 'free_text' &&
                  question.answer_type !== 'free_number' ? (
                  <MDBCol md="12">
                    <h4>{formatMessage(messages.Answers)}</h4>
                    <MDBCol
                      sm="12"
                      style={{
                        opacity:
                          question.use_answers || question.clone_answers_from
                            ? '0.5'
                            : '1',
                      }}
                    >
                      {question?.answers?.map((answer, ai) => (
                        <MDBRow
                          key={ai}
                          className="collapse-media"
                          style={{ background: '#E8E8E8' }}
                        >
                          <div
                            className="collapse-media__item col-5"
                            style={{ overflow: 'visible' }}
                          >
                            <ImageInput
                              title={formatMessage(messages.AddImage)}
                              setSRC={(s, t) => {
                                setAnswerImg(ai, s);
                              }}
                              src={answer.background}
                              answer
                            />
                          </div>
                          <div className="col-7">
                            <MDBInput
                              className="form-control"
                              type="textarea"
                              maxlenght={answerSize}
                              name="title"
                              label={`${formatMessage(messages.Answer)} ${
                                ai + 1
                              } -${answerSize - answer.title.length}`}
                              value={answer.title}
                              onChange={(e) => handleAnswerChange(e, ai)}
                              disabled={
                                question.use_answers ||
                                question.clone_answers_from
                              }
                            />
                            <MDBRow>
                              <PixelCodeSelector
                                index={ai}
                                pixelCode={pixelCode}
                                id={answer.pixel_code_id}
                              />
                            </MDBRow>
                            <MDBRow>
                              <LeadSelector
                                index={ai}
                                leadSelect={leadSelect}
                                id={answer.lead_id}
                              />
                            </MDBRow>
                            <MDBRow>
                              <MDBInput
                                label={`${formatMessage(
                                  messages.LeadUrlVariable
                                )}`}
                                className="form-control"
                                type="textarea"
                                size="sm"
                                name="luv"
                                value={answer.luv}
                                onChange={(e) => handleAnswerChange(e, ai)}
                              />
                            </MDBRow>
                            {data.is_calculator ? (
                              <MDBRow>
                                <MDBInput
                                  label={`${formatMessage(
                                    messages.CalculatorValue
                                  )}`}
                                  className="form-control"
                                  type="number"
                                  size="sm"
                                  name="cal_val"
                                  value={answer.cal_val}
                                  onChange={(e) => handleAnswerChange(e, ai)}
                                />
                              </MDBRow>
                            ) : null}
                            <MDBRow>
                              <MDBBtn
                                outline
                                color="danger"
                                size="sm"
                                onClick={(e) => deleteAnswer(e, ai)}
                                disabled={
                                  answer.vote_count > 0 ||
                                  question.answers.length < 3 ||
                                  question.use_answers ||
                                  question.clone_answers_from
                                }
                              >
                                {formatMessage(messages.Delete)}
                              </MDBBtn>
                            </MDBRow>
                          </div>
                        </MDBRow>
                      ))}
                      <MDBBtn
                        color="secondary"
                        onClick={(e) => changeNumber(e, 'add')}
                        disabled={
                          question.use_answers || question.clone_answers_from
                        }
                      >
                        {formatMessage(messages.AddAnswer)}
                      </MDBBtn>
                    </MDBCol>
                    <MDBCol>
                      <MDBRow style={{ marginTop: '1.5rem' }}>
                        <MDBCol>
                          <label>{formatMessage(messages.AllowMultiple)}</label>
                        </MDBCol>
                        <MDBCol>
                          <InputSwitch
                            onChange={(e) =>
                              handleSwitchChange(e, 'allow_multiple_answers')
                            }
                            checked={question.allow_multiple_answers === 1}
                            labelLeft={formatMessage(messages.Off)}
                            labelRight={formatMessage(messages.On)}
                          />
                        </MDBCol>
                      </MDBRow>
                      {question.allow_multiple_answers ? (
                        <MDBRow style={{ marginTop: '1.5rem' }}>
                          <MDBCol md="4">
                            <label>{formatMessage(messages.MaxNumber)}:</label>
                            <MDBSelect
                              getValue={(val) =>
                                getSelectValue(val[0], 'max_multi_punch_answer')
                              }
                            >
                              <MDBSelectInput
                                selected={question.max_multi_punch_answer}
                              />
                              <MDBSelectOptions>
                                {[...Array(question.answers.length).keys()].map(
                                  (elem, index) => (
                                    <MDBSelectOption
                                      value={index + 1}
                                      key={index}
                                    >
                                      {index + 1}
                                    </MDBSelectOption>
                                  )
                                )}
                              </MDBSelectOptions>
                            </MDBSelect>
                          </MDBCol>
                        </MDBRow>
                      ) : null}
                      <MDBRow>
                        <MDBCol>
                          <label>{formatMessage(messages.Shuffle)}</label>
                        </MDBCol>
                        <MDBCol>
                          <InputSwitch
                            onChange={(e) =>
                              handleSwitchChange(e, 'rotate_answers')
                            }
                            checked={question.rotate_answers === 1}
                            labelLeft={formatMessage(messages.Off)}
                            labelRight={formatMessage(messages.On)}
                          />
                        </MDBCol>
                      </MDBRow>
                      <MDBRow>
                        <MDBCol>
                          <label>{formatMessage(messages.ShuffleLast)}</label>
                        </MDBCol>
                        <MDBCol>
                          <InputSwitch
                            onChange={(e) =>
                              handleSwitchChange(e, 'rotate_answers_last')
                            }
                            checked={question.rotate_answers_last === 1}
                            labelLeft={formatMessage(messages.Off)}
                            labelRight={formatMessage(messages.On)}
                          />
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>
                  </MDBCol>
                ) : null}
              </Fragment>
            )
        )}
      <MDBRow style={{ marginTop: '1.5rem' }}>
        <MDBCol className="text-center">
          <MDBBtn
            rounded
            color="primary"
            onClick={() => moveQuestions(-1)}
            disabled={questionIndex < 1}
            className="float-left"
          >
            <Fa icon="arrow-left" />
          </MDBBtn>
          <MDBBtn
            rounded
            color="primary"
            onClick={saveQuestion}
            disabled={
              savingQuestion ||
              (questions[questionIndex]?.use_answers &&
                !questions[questionIndex]?.clone_answers_from) ||
              (!answerBg && !answerTitle) ||
              questionTitleLength <= 0
            }
          >
            {`${formatMessage(messages.SaveQuestion)} #${questionIndex + 1}`}
          </MDBBtn>
          <MDBBtn
            rounded
            color="primary"
            onClick={addNewQuestionToTheEnd}
            disabled={
              questions[questionIndex] && !questions[questionIndex].id && !dirty
            }
          >
            {formatMessage(messages.AddQuestion)}
          </MDBBtn>
          <MDBBtn
            rounded
            color="primary"
            onClick={() => moveQuestions(1)}
            disabled={questionIndex === questions.length - 1}
            className="float-right"
          >
            <Fa icon="arrow-right" />
          </MDBBtn>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          <MDBBtn
            rounded
            color="primary"
            onClick={() => setStep(1)}
            className="float-left"
          >
            {formatMessage(messages.Back)}
          </MDBBtn>
          <MDBBtn
            rounded
            color="danger"
            onClick={finishSet}
            disabled={questionIndex < 1}
            className="float-right"
          >
            {formatMessage(messages.Finish)}
          </MDBBtn>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          {questionTitleLength <= 0 ? (
            <div className={'float-right text-danger'}>
              {formatMessage(messages.PleaseEnterQuestion)}
            </div>
          ) : null}
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          {!answerBg && !answerTitle && questions.length > 0 ? (
            <div className={'float-right text-danger'}>
              {formatMessage(messages.PleaseEnter)}
            </div>
          ) : null}
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          {questionIndex < 1 ? (
            <div className={'float-right text-danger'}>
              {formatMessage(messages.PleaseAddQuestion)}
            </div>
          ) : null}
        </MDBCol>
      </MDBRow>
    </MDBCol>
  );
};

export default injectIntl(Step2);
