import React, { useState, useEffect, useCallback } from 'react';
import { injectIntl } from 'react-intl';
import {
  MDBContainer,
  Row,
  Button,
  toast,
  Spinner,
  MDBBtn,
  MDBIcon,
} from 'mdbreact';
import { getCampaignLeads, getLeadsRequest } from '../../api';
import history from '../../core/history';
import messages from './messages';
import Pagination from '../Pagination';
import ReactTooltip from 'react-tooltip';

import SectionWithTitle from '../Common/SectionWithTitle';
import ImageModal from './ImageModal';

const IncomingLeads = ({ intl: { formatMessage }, ...props }) => {
  const [leads, setLeads] = useState([]);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [lastPage, setLastPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [modal, setModal] = useState(false);
  const [files, setFiles] = useState([]);

  const fetchData = useCallback(async () => {
    setLoading(true);
    const data = props.location.state.lead;
    const r = await getCampaignLeads(data.id, page, sizePerPage);
    setLeads(r.data.data);
    setLastPage(r.data.last_page);
    setLoading(false);
  }, [page, sizePerPage, props.location.state.lead]);

  useEffect(() => {
    const data = props.location.state.lead;
    if (data) {
      try {
        fetchData(page, sizePerPage);
      } catch (e) {
        history.push('/leads');
      }
    } else {
      history.push('/leads');
    }
  }, [props.location.state.lead, page, sizePerPage, fetchData]);

  const downloadRequest = async (page, sizePerPage) => {
    const data = props.location.state.lead;
    setDownloading(true);
    try {
      await getLeadsRequest(data.id, page, sizePerPage);
      setDownloading(false);
      toast.success(formatMessage(messages.Submitted));
    } catch (e) {
      toast.error('Error occured!');
    }
  };

  const handlePageChange = (page, sizePerPage) => {
    setPage(page);
    setSizePerPage(sizePerPage);
  };

  const data = props.location.state.lead || {};

  return (
    <MDBContainer fluid>
      <ImageModal
        isOpen={modal}
        toggleModal={() => setModal((m) => !m)}
        files={files}
      />
      <SectionWithTitle
        title={`${formatMessage(messages.IncomingLeads)}: #${data.id} - ${
          data.name
        }`}
      >
        <div>
          <Pagination
            last={lastPage}
            active={page}
            size={sizePerPage}
            handlePageChange={handlePageChange}
          />
          <div className="row">
            <div className="col-12">
              {loading ? (
                <Spinner blue big />
              ) : (
                <table
                  className="table table-striped table-bordered table-sm table-fixed-header"
                  style={
                    loading
                      ? { border: 'none', borderTop: '1px solid #dee2e6' }
                      : {}
                  }
                >
                  <thead>
                    <tr>
                      <th></th>
                      <th>{'User Id'}</th>
                      <th>{'Content Type'}</th>
                      <th>{'Content ID'}</th>
                      <th>{'Name'}</th>
                      <th>{'Email'}</th>
                      <th>{'Comment'}</th>
                      <th>{'Created At'}</th>
                      <th>{'Phone'}</th>
                      <th>{'Play Time'}</th>
                      <th>{'Correct Answer'}</th>
                      <th>{'Files'}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {leads.map((lead, i) => {
                      return (
                        <tr key={i}>
                          <td>{(page - 1) * sizePerPage + i + 1}</td>
                          <td>{lead.user_id}</td>
                          <td style={{ maxWidth: '30px' }}>
                            {lead.content_type}
                          </td>
                          <td style={{ maxWidth: '30px' }}>
                            {lead.content_id}
                          </td>
                          <td>{lead.name}</td>
                          <td>{lead.email}</td>
                          <td>{lead.comment}</td>
                          <td>{lead.created_at}</td>
                          <td>{lead.phone}</td>
                          <td>{lead.play_time}</td>
                          <td>{lead.quiz_ra}</td>
                          <td>
                            <ReactTooltip effect="solid" multiline={true} />
                            {lead.files && lead.files.length > 0 ? (
                              <MDBBtn
                                outline
                                color="default"
                                size="sm"
                                onClick={() => {
                                  setModal(true);
                                  setFiles(lead.files);
                                }}
                                data-tip={formatMessage(messages.Files)}
                              >
                                <MDBIcon far icon="keyboard" />
                              </MDBBtn>
                            ) : null}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
        <Row>
          <Button
            style={{ height: '50px' }}
            color="indigo"
            onClick={(e) => {
              e.preventDefault();
              downloadRequest();
            }}
            disabled={downloading}
          >
            {formatMessage(messages.Download)}
          </Button>
        </Row>
      </SectionWithTitle>
    </MDBContainer>
  );
};

export default injectIntl(IncomingLeads);
