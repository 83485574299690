import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import {
  Row,
  Col,
  MDBInput,
  Button,
  MDBRow,
  MDBTooltip,
  MDBSwitch,
  MDBCol,
} from 'mdbreact';
import messages from '../messages';
import PixelCodeSelector from '../PixelCodeSelector';
import LeadSelector from '../LeadSelector';
import ImageInput from '../../ImageInput';

class Step2 extends Component {
  render() {
    const { formatMessage } = this.props.intl;
    const {
      quizData,
      handleAnswerChange,
      handleResultSwitchChange,
      changeNumber,
      setResultImg,
      pixelCode,
      leadSelect,
    } = this.props;

    const resultSize = 255;

    return (
      <Col md="12">
        <Row>
          <Col sm="12">
            {quizData.results &&
              quizData.results.map((result, i) => (
                <Row
                  key={i}
                  className="collapse-media"
                  style={{ background: '#E8E8E8', padding: '10px' }}
                >
                  <div className="collapse-media__item col-5">
                    <ImageInput
                      title={formatMessage(messages.AddImage)}
                      setSRC={(s, t) => {
                        setResultImg(i, s);
                      }}
                      src={result.background}
                      answer
                    />
                  </div>
                  <div className="col-7">
                    <MDBInput
                      type="textarea"
                      className="p-0 w-100"
                      name="title"
                      label={`${formatMessage(messages.Result)} -${
                        resultSize - result.title.length
                      }`}
                      maxLength={resultSize}
                      value={result.title}
                      onChange={(e) => handleAnswerChange(e, i)}
                    />
                    <MDBInput
                      type="textarea"
                      className="p-0 w-100"
                      name="desc"
                      label={formatMessage(messages.ResultDesc)}
                      value={result.desc}
                      onChange={(e) => handleAnswerChange(e, i)}
                    />
                    <MDBInput
                      className={`form-control w-100 
                      ${
                        !result.dont_shorten &&
                        result.url &&
                        result.url.length > 0 &&
                        !result.url.startsWith('http') &&
                        result.dirty
                          ? 'is-invalid'
                          : ''
                      }
                      `}
                      name="url"
                      label={formatMessage(messages.Url)}
                      value={result.url || ''}
                      onChange={(e) => handleAnswerChange(e, i)}
                      type="textarea"
                    />
                    <MDBInput
                      className="form-control w-100"
                      name="url_text"
                      label={formatMessage(messages.UrlText)}
                      value={result.url_text}
                      onChange={(e) => handleAnswerChange(e, i)}
                      type="textarea"
                    />
                    <MDBRow>
                      <MDBCol md="6">
                        <label>{formatMessage(messages.DontShortenLink)}</label>
                      </MDBCol>
                      <MDBCol md="6">
                        <MDBTooltip placement="top" domElement>
                          <MDBSwitch
                            checked={result.dont_shorten === 1 ? true : false}
                            onChange={(e) => handleResultSwitchChange(e, i)}
                          />
                          <div>{formatMessage(messages.DontShortenDesc)}</div>
                        </MDBTooltip>
                      </MDBCol>
                    </MDBRow>
                    <Row>
                      <PixelCodeSelector
                        index={i}
                        pixelCode={pixelCode}
                        id={result.pixel_code_id}
                      />
                    </Row>
                    <Row>
                      <LeadSelector
                        index={i}
                        leadSelect={leadSelect}
                        id={result.lead_id}
                      />
                    </Row>
                  </div>
                </Row>
              ))}
            <Button color="secondary" onClick={(e) => changeNumber(e, 'add')}>
              {formatMessage(messages.AddResult)}
            </Button>
            {quizData.results && quizData.results.length > 2 && (
              <Button
                color="secondary"
                onClick={(e) => changeNumber(e, 'delete')}
              >
                {formatMessage(messages.DeleteResult)}
              </Button>
            )}
          </Col>
        </Row>
      </Col>
    );
  }
}

export default injectIntl(Step2);
