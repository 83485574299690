import React, { Component, Fragment } from 'react';
import { injectIntl } from 'react-intl';
import { Col, Row, MDBBtn } from 'mdbreact';

import messages from '../messages';
import ImageInput from '../../ImageInput';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import TextInput from '../../TextInput';

class Step1 extends Component {
  state = {
    expanded: false,
    waitingAPI: false,
  };

  render() {
    const { waitingAPI } = this.state;
    const { formatMessage } = this.props.intl;
    const {
      poll,
      handleTextChange,
      setSRC,
      getSRC,
      isEmbedly,
      handleTextChangeWithoutEmbedly,
      handleAnswerChange,
      changeNumber,
      setAnswerImg,
      pixelCode,
      leadSelect,
      deleteAnswer,
      createPoll,
    } = this.props;

    let canFinish = true;
    poll.answers.forEach((ans) => {
      canFinish = canFinish && ans.title.length >= 1;
    });

    return (
      <Col md="12">
        <div className="col-12 mb-3">
          <TextInput
            className="mt-3 mb-1"
            label={`${formatMessage(messages.YourQ)}`}
            maxLength={250}
            minLength={3}
            name="title"
            onChange={handleTextChange}
            rows={2}
            value={poll.title}
            required
            textarea
          />
        </div>
        <div className="col-12 mb-3">
          <ImageInput
            title={formatMessage(messages.QuestionImage)}
            disabled={isEmbedly}
            setSRC={setSRC}
            getSRC={getSRC}
          />
        </div>
        <Step2
          poll={poll}
          handleAnswerChange={handleAnswerChange}
          changeNumber={changeNumber}
          setAnswerImg={setAnswerImg}
          pixelCode={pixelCode}
          leadSelect={leadSelect}
          deleteAnswer={deleteAnswer}
        />
        {this.state.expanded ? (
          <Fragment>
            <Row style={{ margin: '1.5rem' }}>
              <Col className="text-center">
                <MDBBtn
                  color="primary"
                  onClick={() =>
                    this.setState({ expanded: !this.state.expanded })
                  }
                >
                  <strong>{`- ${formatMessage(messages.Collapse)}`}</strong>
                </MDBBtn>
              </Col>
            </Row>
            <Step3
              poll={this.props.poll}
              handleSwitchChange={this.props.handleSwitchChange}
              handleTextChangeWithoutEmbedly={handleTextChangeWithoutEmbedly}
              setSRC={setSRC}
            />
            <Step4
              poll={this.props.poll}
              handleSwitchChange={this.props.handleSwitchChange}
              getSelectValue={this.props.getSelectValue2}
              endDateEdited={this.props.endDateEdited}
              endDateEditClick={this.props.endDateEditClick}
            />
          </Fragment>
        ) : (
          <Row style={{ margin: '1.5rem' }}>
            <Col className="text-center">
              <MDBBtn
                color="primary"
                onClick={() =>
                  this.setState({ expanded: !this.state.expanded })
                }
              >
                <strong>{`+ ${formatMessage(messages.Expand)}`}</strong>
              </MDBBtn>
            </Col>
          </Row>
        )}
        <div className="col-12">
          <ImageInput
            title={formatMessage(messages.EmbedBg)}
            setSRC={setSRC}
            getSRC={getSRC}
            target={'embed_background'}
          />
          <ImageInput
            title={formatMessage(messages.CustomLogo)}
            ratio={2}
            setSRC={setSRC}
            getSRC={getSRC}
            target={'embed_footer_img'}
          />
          <TextInput
            maxLength={250}
            label={`${formatMessage(messages.FooterLink)}`}
            className="mt-3 mb-1"
            name="embed_footer_url"
            value={poll.embed_footer_url || ''}
            onChange={handleTextChangeWithoutEmbedly}
          />
        </div>
        <button
          type="button"
          className={`float-right btn btn-red ${
            canFinish && !waitingAPI ? '' : 'disabled'
          }`}
          onClick={() => {
            this.setState({ waitingAPI: true }, createPoll);
          }}
        >
          {formatMessage(messages.Finish)}
        </button>
      </Col>
    );
  }
}

export default injectIntl(Step1);
