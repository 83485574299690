import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import {
  toast,
  MDBBtn,
  MDBRow,
  MDBCol,
  MDBContainer,
  MDBInput,
  MDBIcon,
} from 'mdbreact';
import ReactTooltip from 'react-tooltip';
import { webhooksSave } from '../../api';
import history from '../../core/history';
import { isValidUrl } from '../../core/helper';

import messages from './messages';
import SectionWithTitle from '../Common/SectionWithTitle';
import TextInput from '../TextInput';

const WebhooksAdd = ({
  intl: { formatMessage },
  location: { state: { content_type, content_id } = {} },
}) => {
  const [saving, setSaving] = useState(false);
  const [data, setData] = useState({
    name: '',
    delay: 10,
    url: '',
    send_answers: 1,
    send_leads: 1,
    incomplete_send: 0,
    incomplete_delay: 0,
  });

  useEffect(() => {
    setData((d) => {
      return {
        ...d,
        incomplete_delay: data.incomplete_send
          ? Math.max(d.incomplete_delay, d.delay, 180)
          : 0,
      };
    });
  }, [data.incomplete_send]);

  useEffect(() => {
    setSaving(!isValidUrl(data.url));
  }, [data.url]);

  const onFormSubmit = async (e) => {
    e.preventDefault();
    if (formValid()) {
      try {
        setSaving(true);
        await webhooksSave({
          ...data,
          content_type,
          content_id: parseInt(content_id, 10),
        });
        history.push('/webhooks', { type: content_type, id: content_id });
        toast.success(formatMessage(messages.WebhookAdded));
      } catch (e) {
        setSaving(false);
        toast.error(formatMessage(messages.ErrorSaving));
      }
    }
  };

  const formValid = () => {
    const { incomplete_send, incomplete_delay, delay, name } = data;
    if (name?.length < 1) {
      toast.error(formatMessage(messages.NameEmpty));
      return false;
    }

    if (!!incomplete_send) {
      // if incomplete_send is true
      // value should be greater than 60 seconds
      if (!incomplete_delay || parseInt(incomplete_delay, 10) < 60) {
        toast.error(formatMessage(messages.IncompleteDelaySmaller));

        return false;
      }
      // incomplete delay cannot be smaller than delay
      if (parseInt(incomplete_delay, 10) < parseInt(delay, 10)) {
        toast.error(formatMessage(messages.IncompleteDelaySmaller));

        return false;
      }
    }

    return true;
  };

  const handleDataChange = (e) => {
    const { name, value, checked, type } = e.target;
    setData({
      ...data,
      [name]: type === 'checkbox' ? (checked ? 1 : 0) : value.trim(),
    });
  };

  return (
    <MDBContainer fluid>
      <MDBRow>
        <MDBCol className=".col-12 .col-sm-6 .col-md-8">
          <SectionWithTitle title={formatMessage(messages.AddNew)}>
            <form method="post" onSubmit={onFormSubmit}>
              <div className="grey-text">
                <ReactTooltip effect="solid" multiline={true} />
                <TextInput
                  label={formatMessage(messages.Type)}
                  group
                  type="text"
                  name="type"
                  value={content_type}
                  icon="disabled"
                  disabled
                />
                <TextInput
                  label={formatMessage(messages.ID)}
                  group
                  type="text"
                  name="id"
                  value={content_id}
                  icon="disabled"
                  disabled
                />
                <TextInput
                  label={formatMessage(messages.Name)}
                  group
                  type="text"
                  name="name"
                  onChange={handleDataChange}
                  value={data.name}
                  icon="signature"
                />
                <TextInput
                  label={formatMessage(messages.Delay)}
                  group
                  type="text"
                  name="delay"
                  onChange={handleDataChange}
                  value={data.delay}
                  icon="clock"
                />
                <TextInput
                  type="textarea"
                  label={formatMessage(messages.Url)}
                  group
                  name="url"
                  onChange={handleDataChange}
                  value={data.url}
                  rows="10"
                  icon="code"
                />
                <MDBRow style={{ marginLeft: '20px', marginTop: '20px' }}>
                  <MDBInput
                    label={formatMessage(messages.SendAnswers)}
                    type="checkbox"
                    id="checkbox1"
                    name="send_answers"
                    onChange={handleDataChange}
                    checked={data.send_answers}
                  />
                  <span
                    data-tip={formatMessage(messages.SendAnswersTip)}
                    data-place="bottom"
                  >
                    <MDBIcon
                      far
                      icon="question-circle"
                      style={{ marginLeft: '10px' }}
                    />
                  </span>
                </MDBRow>
                <MDBRow style={{ marginLeft: '20px', marginTop: '20px' }}>
                  <MDBInput
                    label={formatMessage(messages.SendLeads)}
                    type="checkbox"
                    id="checkbox2"
                    name="send_leads"
                    onChange={handleDataChange}
                    checked={data.send_leads}
                  />
                  <span
                    data-tip={formatMessage(messages.SendLeadsTip)}
                    data-place="bottom"
                  >
                    <MDBIcon
                      far
                      icon="question-circle"
                      style={{ marginLeft: '10px' }}
                    />
                  </span>
                </MDBRow>
                <MDBRow style={{ marginLeft: '20px', marginTop: '20px' }}>
                  <MDBInput
                    label={formatMessage(messages.IncompleteSend)}
                    type="checkbox"
                    id="checkbox3"
                    name="incomplete_send"
                    onChange={handleDataChange}
                    checked={data.incomplete_send}
                  />
                  <span
                    data-tip={formatMessage(messages.IncompleteDelayDesc)}
                    data-place="right"
                  >
                    <MDBIcon
                      far
                      icon="question-circle"
                      style={{ marginLeft: '10px' }}
                    />
                  </span>
                </MDBRow>
                {data.incomplete_send ? (
                  <MDBInput
                    label={formatMessage(messages.IncompleteDelay)}
                    group
                    type="text"
                    name="incomplete_delay"
                    onChange={handleDataChange}
                    value={data.incomplete_delay}
                    icon="clock"
                  />
                ) : null}
              </div>
              <div className="text-center" style={{ marginTop: '20px' }}>
                <MDBBtn color="primary" type="submit" disabled={saving}>
                  {formatMessage(messages.Save)}
                </MDBBtn>
              </div>
            </form>
          </SectionWithTitle>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default injectIntl(WebhooksAdd);
