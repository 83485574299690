import { defineMessages } from 'react-intl.macro';

export default defineMessages({
  SignIn: {
    id: 'Login.SignIn',
    defaultMessage: 'Sign in',
  },
  NotMember: {
    id: 'Login.NotMember',
    defaultMessage: 'Not a member?',
  },
  SignUp: {
    id: 'Login.SignUp',
    defaultMessage: 'Sign Up',
  },
  TypeEmail: {
    id: 'Login.TypeEmail',
    defaultMessage: 'Type your email',
  },
  TypePass: {
    id: 'Login.TypePass',
    defaultMessage: 'Type your password',
  },
  TypePassAgain: {
    id: 'Login.TypePassAgain',
    defaultMessage: 'Type your password (again)',
  },
  Login: {
    id: 'Login.Login',
    defaultMessage: 'Login',
  },
  BackToLogin: {
    id: 'Login.BackToLogin',
    defaultMessage: 'Back to login',
  },
  ResetPassword: {
    id: 'Login.ResetPassword',
    defaultMessage: 'Reset Password',
  },
  Forgot: {
    id: 'Login.Forgot',
    defaultMessage: 'Forgot your password?',
  },
  LoginText: {
    id: 'Login.LoginText',
    defaultMessage:
      'We at Poltio help brands access, engage with and learn from their users. Take your user engagement to the next level with Poltio PRO!',
  },
  LearnMore: {
    id: 'Login.LearnMore',
    defaultMessage: 'Learn More',
  },
  About: {
    id: 'Login.About',
    defaultMessage: 'About',
  },
  Social: {
    id: 'Login.Social',
    defaultMessage: 'Social',
  },
  Trivia: {
    id: 'Login.Trivia',
    defaultMessage: 'Trivia',
  },
  AcceptTC: {
    id: 'Login.AcceptTC',
    defaultMessage: 'Accept the Terms and Conditions',
  },
  EnterValidEmail: {
    id: 'Login.EnterValidEmail',
    defaultMessage: 'Please enter a valid email address!',
  },
  WrongCombination: {
    id: 'Login.WrongCombination',
    defaultMessage: 'Wrong email/password combination!',
  },
  EmailSent: {
    id: 'Login.EmailSent',
    defaultMessage: 'An email has been sent to your email adress!',
  },
  CouldNotFindEmail: {
    id: 'Login.CouldNotFindEmail',
    defaultMessage: 'Could not find email address!',
  },
  EmailTaken: {
    id: 'Login.EmailTaken',
    defaultMessage: 'This email address has already been taken!',
  },
  PasswordSix: {
    id: 'Login.PasswordSix',
    defaultMessage: 'Password must be at least 6 characters!',
  },
  PasswordMatch: {
    id: 'Login.PasswordMatch',
    defaultMessage: 'Password fields must match!',
  },
  PleaseAgree: {
    id: 'Login.PleaseAgree',
    defaultMessage: 'Please agree to terms and conditions!',
  },
});
