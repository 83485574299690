import { defineMessages } from 'react-intl.macro';

export default defineMessages({
  FQSessions: {
    id: 'FQSessions.FQSessions',
    defaultMessage: 'Quiz Sessions',
  },
  SessionPlayers: {
    id: 'FQSessions.SessionPlayers',
    defaultMessage: 'Session Players',
  },
  Back: {
    id: 'FQSessions.Back',
    defaultMessage: 'Back',
  },
});
