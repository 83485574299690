import React from 'react';
import {
  MDBCol,
  MDBCard,
  MDBIcon,
  MDBRow,
  MDBBtn,
  MDBCardBody,
  MDBProgress,
} from 'mdbreact';

const ComparisonMetric = ({
  icon,
  label,
  active,
  diffPercentage,
  diffPercentageText,
  direction,
}) => {
  return (
    <MDBCol xl="4" md="6" className="mb-5">
      <MDBCard cascade className="cascading-admin-card">
        <MDBRow className="mt-3">
          <MDBCol md="5" size="5" className="text-left pl-4">
            <MDBBtn
              tag="a"
              floating
              size="lg"
              color="primary"
              className="ml-4"
              style={{ padding: 0 }}
            >
              <MDBIcon icon={icon} size="2x" />
            </MDBBtn>
          </MDBCol>
          <MDBCol md="7" col="7" className="text-right pr-5">
            <h5 className="ml-4 mt-4 mb-2 font-weight-bold">{active} </h5>
            <p className="font-small grey-text">{label}</p>
          </MDBCol>
        </MDBRow>
        <MDBCardBody>
          <MDBProgress
            value={parseInt(diffPercentage, 10)}
            barClassName={`${direction === 'up' ? 'green' : 'red'}`}
            height="6px"
            wrapperStyle={{ opacity: '.7' }}
            className="mb-3"
          />
          <p className="card-text">{`${diffPercentageText} ${diffPercentage}%`}</p>
        </MDBCardBody>
      </MDBCard>
    </MDBCol>
  );
};

export default ComparisonMetric;
