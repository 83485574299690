import React, {
  useState,
  useEffect,
  useRef,
  Fragment,
  useCallback,
} from 'react';
import { injectIntl } from 'react-intl';
import {
  MDBContainer,
  MDBTooltip,
  MDBSpinner,
  MDBBtn,
  MDBRow,
  MDBIcon,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
  toast,
} from 'mdbreact';
import { getDomainsList, addDomain, deleteDomain } from '../../api';

import messages from './messages';
import SectionWithTitle from '../Common/SectionWithTitle';
import Pagination from '../Pagination';

const ManageDomains = ({ intl: { formatMessage } }) => {
  const [domainData, setDomainData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageData, setPageData] = useState({
    totalSize: 100,
    page: 1,
    sizePerPage: 50,
    lastPage: null,
  });
  const inputEl = useRef(null);

  const fetchDomainsList = useCallback(async () => {
    setLoading(true);
    const response = await getDomainsList(pageData.page, pageData.sizePerPage);
    setDomainData(response.data.data);
    setLoading(false);
    setPageData((p) => {
      return {
        page: p.page,
        sizePerPage: p.sizePerPage,
        totalSize: response.data.total,
        lastPage: response.data.last_page,
      };
    });
  }, [pageData.page, pageData.sizePerPage]);

  const handlePageChange = (page, sizePerPage) => {
    setPageData({
      ...pageData,
      page,
      sizePerPage,
    });
  };

  useEffect(() => {
    fetchDomainsList();
  }, [fetchDomainsList]);

  const handleClick = async () => {
    const domain = inputEl.current.state.innerValue;
    try {
      await addDomain(domain);
      toast.info(formatMessage(messages.Added));
      fetchDomainsList();
      inputEl.current.state.innerValue = '';
    } catch (e) {
      if (e.response && e.response.status === 400) {
        toast.error(formatMessage(messages.DomainNotValid));
      } else if (e.response && e.response.status === 403) {
        toast.error(formatMessage(messages.DomainInSystem));
      } else {
        toast.error('Error occured!');
      }
    }
  };

  const handleDeleteClick = async (id) => {
    if (window.confirm(formatMessage(messages.AreYouSure))) {
      try {
        await deleteDomain(id);
        toast.info(formatMessage(messages.Deleted));
        fetchDomainsList();
      } catch (e) {
        toast.error('Error occured!');
      }
    }
  };

  return (
    <MDBContainer fluid>
      <SectionWithTitle title={formatMessage(messages.Title)}>
        <MDBRow>
          <MDBCol md="8">
            <Fragment>
              <Pagination
                last={pageData.lastPage}
                active={pageData.page}
                size={pageData.sizePerPage}
                handlePageChange={handlePageChange}
              />
              {loading ? (
                <MDBSpinner />
              ) : (
                <div className="row text-center">
                  <div className="col-12">
                    <table className="table table-striped table-bordered table-sm table-fixed-header">
                      <thead>
                        <tr>
                          <th className="th-sm">{'ID'}</th>
                          <th className="th-sm">{'Domain'}</th>
                          <th className="th-sm">{'Is approved'}</th>
                          <th className="th-sm">{'Is in use'}</th>
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        {domainData.map((row, i) => (
                          <tr key={i} style={{ cursor: 'pointer' }}>
                            <td>
                              <MDBTooltip placement="top" domElement>
                                <span>{row.id}</span>
                                <div>{row.id}</div>
                              </MDBTooltip>
                            </td>
                            <td>
                              <MDBTooltip placement="top" domElement>
                                <span>{row.domain}</span>
                                <div>{row.domain}</div>
                              </MDBTooltip>
                            </td>
                            <td>
                              <MDBBtn
                                color={row.is_approved ? 'success' : 'danger'}
                                size="sm"
                              >
                                {row.is_approved
                                  ? formatMessage(messages.Yes)
                                  : formatMessage(messages.No)}
                              </MDBBtn>
                            </td>
                            <td>
                              <MDBBtn
                                color={row.is_inuse ? 'success' : 'danger'}
                                size="sm"
                              >
                                {row.is_inuse
                                  ? formatMessage(messages.Yes)
                                  : formatMessage(messages.No)}
                              </MDBBtn>
                            </td>
                            <td>
                              <MDBBtn
                                outline
                                color="danger"
                                size="sm"
                                onClick={() => handleDeleteClick(row.id)}
                                disabled={row.is_inuse ? true : false}
                              >
                                <MDBIcon icon="eraser" />
                              </MDBBtn>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </Fragment>
          </MDBCol>
          <MDBCol md="4" className="text-center">
            <MDBCard className="profile-card">
              <MDBCardBody className="pt-0 mt-0">
                <div className="text-center">
                  <h3 className="mb-3 font-weight-bold">
                    <strong>{formatMessage(messages.AddNewDomain)}</strong>
                  </h3>
                </div>
                <MDBInput
                  type="text"
                  label={formatMessage(messages.DomainName)}
                  hint="https://customdomain.com"
                  ref={inputEl}
                />
                <MDBBtn color="primary" size="sm" onClick={handleClick}>
                  {formatMessage(messages.Add)}
                </MDBBtn>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </SectionWithTitle>
    </MDBContainer>
  );
};

export default injectIntl(ManageDomains);
