import React, { Component } from 'react';

import { MDBContainer, MDBRow, MDBSpinner, toast } from 'mdbreact';
import {
  getContentMetrics,
  getVsMetricsContent,
  getFriendQuizMetrics,
  getEditQuestion,
  getEditTest,
  getEditQuiz,
  getEditSet,
  performContentAction,
  getEmbedSettings,
  setEmbedSettings,
} from '../../api';
import history from '../../core/history';
import {
  groupBy,
  groupByAddWeek,
  groupByAddMonth,
  groupByAddYear,
} from '../../core/helper';
import { injectIntl } from 'react-intl';
import makeComponentTrashable from 'trashable-react';
import * as clipboard from 'clipboard-polyfill';
import { embedUrlPrefix } from '../../core/constants';

import EmbedModal from '../Content/EmbedModal';
import messages from './messages';
import CompletionStats from './CompletionStats';
import VoteStats from './VoteStats';
import PollVoteGraphs from './PollVoteGraphs';
import InitialNumbers from './InitialNumbers';
import QuizStats from './QuizStats';
import VotersReport from './VotersReport';
import VoteSourcesReport from './VoteSourcesReport';
import SectionWithTitle from '../Common/SectionWithTitle';
import ComparisonMetric from '../Common/ComparisonMetric';
import SetSummaryReport from './SetSummaryReport';

class ContentDetail extends Component {
  state = {
    loading: true,
    dataDaily: null,
    dataWeekly: null,
    dataMonthly: null,
    dataYearly: null,
    data: {
      id: '',
      t: '',
      title: '',
      questions: [
        {
          answers: [
            {
              is_right_answer: 1,
            },
          ],
        },
      ],
      results: [],
      view_count: 0,
      vote_count: 0,
      unique_voter: 0,
      start: 0,
    },
    fQMetrics: {},
    dataDaily2: null,
    dataWeekly2: null,
    dataMonthly2: null,
    dataYearly2: null,
    vsResponseWeek: { vs: 0, active: 1 },
    vsResponseWeekVoter: { vs: 0, active: 1 },
    vsResponseDayStart: { vs: 0, active: 1 },
    modal: false,
    contentType: '',
    contentId: 0,
    displayShare: true,
    displayNotify: true,
    displayDisclaimer: true,
    lang: 'default',
  };

  async componentDidMount() {
    const { registerPromise } = this.props;
    const { formatMessage } = this.props.intl;
    const { type, id } = this.props.match.params;
    let response = {};
    let friendQuizMetrics = {};

    try {
      if (type === 'poll') {
        response = await registerPromise(getEditQuestion(id));
      } else if (type === 'test') {
        response = await registerPromise(getEditTest(id));
      } else if (type === 'quiz') {
        response = await registerPromise(getEditQuiz(id));
        if (response.data.friend_quiz) {
          friendQuizMetrics = await registerPromise(getFriendQuizMetrics(id));
        }
      } else if (type === 'set') {
        response = await registerPromise(getEditSet(id));
      } else {
        history.push('/content');
      }

      const r = await registerPromise(getEmbedSettings('poll'));

      this.setState({
        id,
        type,
        data: response.data,
        fQMetrics: friendQuizMetrics.data,
        displayShare: r.data.displayShare,
        displayNotify: r.data.displayNotify,
        displayDisclaimer: r.data.displayDisclaimer || true,
        lang: r.data.lang || 'default',
      });

      if (id && type) {
        try {
          await this.getVoteMetrics(type, id);
          if (type !== 'poll') {
            await this.getCompletionMetrics(type, id);
          }
          this.setState({ loading: false });
        } catch (e) {
          console.log(e);
          history.push('/content');
        }
      } else {
        history.push('/content');
      }
    } catch (e) {
      toast.error(formatMessage(messages.NotFound));
      history.push('/content');
    }
  }

  getVoteMetrics = async (type, id) => {
    const { registerPromise } = this.props;
    let dataDaily = [['Day', 'Vote', 'Voter']];
    let dataWeekly = [['Week', 'Vote', 'Voter']];
    let dataMonthly = [['Month', 'Vote', 'Voter']];
    let dataYearly = [['Year', 'Vote', 'Voter']];

    const response1 = await registerPromise(
      getContentMetrics(type, id, 'daily', 'vote,voter')
    );
    const grouped1 = groupBy(response1.data);
    const values1 = Object.values(grouped1);
    const datafinal1 = dataDaily.concat(values1);

    const response2 = await registerPromise(
      getContentMetrics(type, id, 'weekly', 'vote,voter')
    );
    const grouped2 = groupByAddWeek(response2.data);
    const values2 = Object.values(grouped2);
    const datafinal2 = dataWeekly.concat(values2);

    const response3 = await registerPromise(
      getContentMetrics(type, id, 'monthly', 'vote,voter')
    );
    const grouped3 = groupByAddMonth(response3.data, 'month');
    const values3 = Object.values(grouped3);
    const datafinal3 = dataMonthly.concat(values3);

    const response4 = await registerPromise(
      getContentMetrics(type, id, 'yearly', 'vote,voter')
    );
    const grouped4 = groupByAddYear(response4.data);
    const values4 = Object.values(grouped4);
    const datafinal4 = dataYearly.concat(values4);

    const vsResponseWeek = await registerPromise(
      getVsMetricsContent(type, id, 'vote', 'week')
    );
    const vsResponseWeekVoter = await registerPromise(
      getVsMetricsContent(type, id, 'voter', 'week')
    );
    const vsResponseDayStart = await registerPromise(
      getVsMetricsContent(type, id, 'start', 'day')
    );
    const vsResponseDayFinish = await registerPromise(
      getVsMetricsContent(type, id, 'finish', 'day')
    );

    this.setState({
      dataDaily: datafinal1,
      dataWeekly: datafinal2,
      dataMonthly: datafinal3,
      dataYearly: datafinal4,
      vsResponseWeek: vsResponseWeek.data,
      vsResponseWeekVoter: vsResponseWeekVoter.data,
      vsResponseDayStart: {
        vs: (
          vsResponseDayFinish.data.vs / (vsResponseDayStart.data.vs || 1)
        ).toFixed(2),
        active: (
          vsResponseDayFinish.data.active /
          (vsResponseDayStart.data.active || 1)
        ).toFixed(2),
      },
    });
  };

  getCompletionMetrics = async (type, id) => {
    const { registerPromise } = this.props;
    let dataDaily2 = [['Day', 'Start', 'Finish']];
    let dataWeekly2 = [['Week', 'Start', 'Finish']];
    let dataMonthly2 = [['Month', 'Start', 'Finish']];
    let dataYearly2 = [['Year', 'Start', 'Finish']];

    const response1 = await registerPromise(
      getContentMetrics(type, id, 'daily', 'start,finish')
    );
    const grouped1 = groupBy(response1.data);
    const values1 = Object.values(grouped1);
    const datafinal1 = dataDaily2.concat(values1);

    const response2 = await registerPromise(
      getContentMetrics(type, id, 'weekly', 'start,finish')
    );
    const grouped2 = groupByAddWeek(response2.data);
    const values2 = Object.values(grouped2);
    const datafinal2 = dataWeekly2.concat(values2);

    const response3 = await registerPromise(
      getContentMetrics(type, id, 'monthly', 'start,finish')
    );
    const grouped3 = groupByAddMonth(response3.data);
    const values3 = Object.values(grouped3);
    const datafinal3 = dataMonthly2.concat(values3);

    const response4 = await registerPromise(
      getContentMetrics(type, id, 'yearly', 'start,finish')
    );
    const grouped4 = groupByAddYear(response4.data);
    const values4 = Object.values(grouped4);
    const datafinal4 = dataYearly2.concat(values4);

    this.setState({
      dataDaily2: datafinal1,
      dataWeekly2: datafinal2,
      dataMonthly2: datafinal3,
      dataYearly2: datafinal4,
    });
  };

  copyLink = (e, content) => {
    e.preventDefault();
    const { formatMessage } = this.props.intl;
    try {
      clipboard.writeText(
        `https://${embedUrlPrefix}.poltio.com/e/${content.t}/${content.id}?align=center&share=off&notify=off`
      );
      toast.info(formatMessage(messages.Copied));
    } catch (e) {
      toast.error(formatMessage(messages.Error));
    }
  };

  deleteContent = async (e, content) => {
    e.preventDefault();
    const { formatMessage } = this.props.intl;

    if (window.confirm(formatMessage(messages.Sure))) {
      try {
        await performContentAction('delete', content.t, content.id);
        toast.success(formatMessage(messages.ContentDeleted));
        history.push('/content');
      } catch (e) {
        toast.error('Error occured!');
      }
    }
  };

  openEmbedModal = (e, content) => {
    e.preventDefault();
    this.setState({
      modal: true,
      contentType: content.t,
      contentId: content.id,
    });
  };

  handleSwitchChange = (e, name) => {
    this.setState({ [name]: !this.state[name] });
  };

  handleCheckboxChange = (lang) => {
    this.setState({ lang });
  };

  setDefault = async (e) => {
    e.preventDefault();
    const { displayNotify, displayShare, displayDisclaimer, lang } = this.state;
    const { formatMessage } = this.props.intl;
    try {
      await setEmbedSettings('poll', {
        displayNotify,
        displayShare,
        displayDisclaimer,
        lang,
      });
      toast.success(formatMessage(messages.SuccessSetting));
    } catch (e) {
      toast.error(formatMessage(messages.ErrorSetting));
    }
  };

  render() {
    const { formatMessage } = this.props.intl;
    const {
      loading,
      dataDaily,
      dataWeekly,
      dataMonthly,
      dataYearly,
      dataDaily2,
      dataWeekly2,
      dataMonthly2,
      dataYearly2,
      data,
      fQMetrics,
      id,
      type,
      vsResponseWeek,
      vsResponseWeekVoter,
      vsResponseDayStart,
      modal,
      contentType,
      contentId,
      displayShare,
      displayNotify,
      displayDisclaimer,
      lang,
    } = this.state;

    return loading ? (
      <MDBContainer>
        <MDBRow center>
          <MDBSpinner />
        </MDBRow>
      </MDBContainer>
    ) : (
      <MDBContainer fluid className="mb-5">
        <EmbedModal
          toggleModal={() => this.setState({ modal: !this.state.modal })}
          modal={modal}
          contentType={contentType}
          contentId={contentId}
          displayShare={displayShare}
          displayNotify={displayNotify}
          displayDisclaimer={displayDisclaimer}
          lang={lang}
          setDefault={this.setDefault}
          handleSwitchChange={this.handleSwitchChange}
          handleCheckboxChange={this.handleCheckboxChange}
        />
        <SectionWithTitle
          title={`${data.t.toUpperCase()} # ${data.id} - ${data.title}`}
        >
          <InitialNumbers
            messages={messages}
            formatMessage={formatMessage}
            data={data}
            type={type}
            copyLink={this.copyLink}
            deleteContent={this.deleteContent}
            openEmbedModal={this.openEmbedModal}
          />
        </SectionWithTitle>
        <MDBRow>
          <ComparisonMetric
            icon="chart-line"
            label={formatMessage(messages.ThisWeekVoteVsLastWeek)}
            active={vsResponseWeek.active}
            diffPercentage={Math.abs(
              ((vsResponseWeek.active - vsResponseWeek.vs) * 100) /
                (vsResponseWeek.vs || 1)
            ).toFixed(0)}
            diffPercentageText={
              vsResponseWeek.active >= vsResponseWeek.vs
                ? formatMessage(messages.BetterThanLastWeek)
                : formatMessage(messages.WorseThanLastWeek)
            }
            direction={
              vsResponseWeek.active >= vsResponseWeek.vs ? 'up' : 'down'
            }
          />
          <ComparisonMetric
            icon="chart-line"
            label={formatMessage(messages.ThisWeekVoterVsLastWeek)}
            active={vsResponseWeekVoter.active}
            diffPercentage={Math.abs(
              ((vsResponseWeekVoter.active - vsResponseWeekVoter.vs) * 100) /
                (vsResponseWeekVoter.vs || 1)
            ).toFixed(0)}
            diffPercentageText={
              vsResponseWeekVoter.active >= vsResponseWeekVoter.vs
                ? formatMessage(messages.BetterThanLastWeek)
                : formatMessage(messages.WorseThanLastWeek)
            }
            direction={
              vsResponseWeekVoter.active >= vsResponseWeekVoter.vs
                ? 'up'
                : 'down'
            }
          />
          {type !== 'poll' ? (
            <ComparisonMetric
              icon="chart-line"
              label={formatMessage(messages.TodayStartVsYesterday)}
              active={vsResponseDayStart.active}
              diffPercentage={Math.abs(
                ((vsResponseDayStart.active - vsResponseDayStart.vs) * 100) /
                  (vsResponseDayStart.vs || 1)
              ).toFixed(0)}
              diffPercentageText={
                vsResponseDayStart.active >= vsResponseDayStart.vs
                  ? formatMessage(messages.BetterThanYesterday)
                  : formatMessage(messages.WorseThanYesterday)
              }
              direction={
                vsResponseDayStart.active >= vsResponseDayStart.vs
                  ? 'up'
                  : 'down'
              }
            />
          ) : null}
        </MDBRow>

        {type === 'poll' ? (
          <PollVoteGraphs
            messages={messages}
            formatMessage={formatMessage}
            data={data}
          />
        ) : null}
        {type !== 'poll' ? (
          <QuizStats
            messages={messages}
            formatMessage={formatMessage}
            data={data}
            fQMetrics={fQMetrics}
          />
        ) : null}
        <VoteStats
          messages={messages}
          formatMessage={formatMessage}
          dataDaily={dataDaily}
          dataWeekly={dataWeekly}
          dataMonthly={dataMonthly}
          dataYearly={dataYearly}
        />
        {type !== 'poll' ? (
          <CompletionStats
            messages={messages}
            formatMessage={formatMessage}
            dataDaily2={dataDaily2}
            dataWeekly2={dataWeekly2}
            dataMonthly2={dataMonthly2}
            dataYearly2={dataYearly2}
          />
        ) : null}
        {id && type ? <VoteSourcesReport id={id} type={type} /> : null}
        {type === 'set' ? (
          <SetSummaryReport questionsProp={data.questions} />
        ) : null}
        {id && type ? <VotersReport id={id} type={type} /> : null}
      </MDBContainer>
    );
  }
}

export default makeComponentTrashable(injectIntl(ContentDetail));
