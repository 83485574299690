/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import {
  Row,
  Col,
  Input,
  MDBSwitch,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  MDBInput,
  MDBFormInline,
  MDBIcon,
} from 'mdbreact';
import ReactTooltip from 'react-tooltip';
import { Container } from 'mdbreact';
import { embedUrlPrefix } from '../../core/constants';
import * as clipboard from 'clipboard-polyfill';

import messages from './messages';

class EmbedModal extends Component {
  state = {
    width: '100%',
    height: '800',
    customUrl: '',
    embedCode: '',
    username: '',
    contentType: this.props.contentType,
    contentId: this.props.contentId,
    displayNotify: true,
    displayShare: true,
    alignment: 'center',
    isVertical: false,
    lang: 'default',
  };

  static getDerivedStateFromProps(props, state) {
    const { contentType, contentId } = props;
    const { username, width, height, alignment, isVertical } = state;

    return {
      displayShare: props.displayShare,
      displayNotify: props.displayNotify,
      displayDisclaimer: props.displayDisclaimer,
      lang: props.lang,
      embedCode: `<iframe id="poltio-${
        isVertical ? 'v' : ''
      }embed-${contentType}-${contentId}" class="poltio-embed" src="https://${embedUrlPrefix}.poltio.com/e/${
        isVertical ? 'vertical/' : ''
      }${contentType}/${contentId}?utm_source=embed2&utm_medium=${username}&utm_campaign=${contentType}-${contentId}&share=${
        props.displayShare ? 'on' : 'off'
      }&notify=${props.displayNotify ? 'on' : 'off'}${
        props.displayDisclaimer ? '' : '&disclaimer=off'
      }&align=${alignment}${
        props.lang === 'default' ? '' : `&loc=${props.lang}`
      }" width="${width}" height="${height}" frameBorder="0" allowFullScreen="allowfullscreen" scrolling="yes" title="Embed" allow="clipboard-write"></iframe><script src="https://platform.poltio.com/widget.js" defer></script>`,
    };
  }

  handleTextChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  copyEmbedCode = () => {
    try {
      clipboard.writeText(this.state.embedCode);
      //toast.info(formatMessage(messages.Copied));
    } catch (e) {
      console.log(e);
      //toast.info(formatMessage(messages.Error));
    }
  };

  render() {
    const {
      modal,
      toggleModal,
      contentType,
      contentId,
      displayNotify,
      displayShare,
      displayDisclaimer,
      handleSwitchChange,
      handleCheckboxChange,
    } = this.props;
    const { formatMessage } = this.props.intl;

    return (
      <Container md="12">
        <Modal
          isOpen={modal}
          toggle={toggleModal}
          centered
          style={{ maxWidth: '1000px' }}
        >
          <ModalHeader toggle={toggleModal}>
            {formatMessage(messages.EmbedCodeGenerator)}
          </ModalHeader>
          <ModalBody>
            <Container>
              <Row>
                <Col>
                  <a
                    href={`https://${embedUrlPrefix}.poltio.com/e/${contentType}/${contentId}?share=${
                      displayShare ? 'on' : 'off'
                    }&notify=${displayNotify ? 'on' : 'off'}${
                      displayDisclaimer ? '' : '&disclaimer=off'
                    }&align=${this.state.alignment}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {`${formatMessage(
                      messages.SingleView
                    )}: https://${embedUrlPrefix}.poltio.com/e/${contentType}/${contentId}?share=${
                      displayShare ? 'on' : 'off'
                    }&notify=${displayNotify ? 'on' : 'off'}${
                      displayDisclaimer ? '' : '&disclaimer=off'
                    }&align=${this.state.alignment}`}
                  </a>
                  <Input
                    label={formatMessage(messages.Width)}
                    name="width"
                    value={this.state.width}
                    onChange={this.handleTextChange}
                  />
                  <Input
                    label={formatMessage(messages.Height)}
                    name="height"
                    value={this.state.height}
                    onChange={this.handleTextChange}
                  />
                  <Row style={{ textAlign: 'center' }}>
                    <Col>
                      <label>{formatMessage(messages.Alignment)}</label>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <MDBFormInline>
                        <Col md="4">
                          <MDBInput
                            label={formatMessage(messages.Left)}
                            type="checkbox"
                            id="checkbox1"
                            value="left"
                            checked={this.state.alignment === 'left'}
                            onChange={(e) =>
                              this.setState({ alignment: 'left' })
                            }
                          />
                        </Col>
                        <Col md="4">
                          <MDBInput
                            label={formatMessage(messages.Center)}
                            type="checkbox"
                            id="checkbox2"
                            value="center"
                            checked={this.state.alignment === 'center'}
                            onChange={(e) =>
                              this.setState({ alignment: 'center' })
                            }
                          />
                        </Col>
                        <Col md="4">
                          <MDBInput
                            label={formatMessage(messages.Right)}
                            type="checkbox"
                            id="checkbox3"
                            value="right"
                            checked={this.state.alignment === 'right'}
                            onChange={(e) =>
                              this.setState({ alignment: 'right' })
                            }
                          />
                        </Col>
                      </MDBFormInline>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: '1.5rem' }}>
                    <Col md="7">
                      <label>
                        {formatMessage(messages.DisplayShare)}{' '}
                        <span
                          data-tip={formatMessage(messages.DisplayShareTip)}
                        >
                          <MDBIcon far icon="question-circle" />
                        </span>
                        <ReactTooltip effect="solid" />
                      </label>
                    </Col>
                    <Col md="5">
                      <MDBSwitch
                        onChange={(e) => handleSwitchChange(e, 'displayShare')}
                        checked={this.props.displayShare}
                        labelLeft={formatMessage(messages.Off)}
                        labelRight={formatMessage(messages.On)}
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginTop: '1.5rem' }}>
                    <Col md="7">
                      <label>
                        {formatMessage(messages.DisplayNotify)}{' '}
                        <span
                          data-tip={formatMessage(messages.DisplayNotifyTip)}
                        >
                          <MDBIcon far icon="question-circle" />
                        </span>
                        <ReactTooltip effect="solid" />
                      </label>
                    </Col>
                    <Col md="5">
                      <MDBSwitch
                        onChange={(e) => handleSwitchChange(e, 'displayNotify')}
                        checked={this.props.displayNotify}
                        labelLeft={formatMessage(messages.Off)}
                        labelRight={formatMessage(messages.On)}
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginTop: '1.5rem' }}>
                    <Col md="7">
                      <label>
                        {formatMessage(messages.DisplayDisclaimer)}{' '}
                        <span
                          data-tip={formatMessage(
                            messages.DisplayDisclaimerTip
                          )}
                        >
                          <MDBIcon far icon="question-circle" />
                        </span>
                        <ReactTooltip effect="solid" />
                      </label>
                    </Col>
                    <Col md="5">
                      <MDBSwitch
                        onChange={(e) =>
                          handleSwitchChange(e, 'displayDisclaimer')
                        }
                        checked={this.props.displayDisclaimer}
                        labelLeft={formatMessage(messages.Off)}
                        labelRight={formatMessage(messages.On)}
                      />
                    </Col>
                  </Row>
                  <Row style={{ textAlign: 'center' }}>
                    <Col>
                      <label>
                        {formatMessage(messages.Language)}{' '}
                        <span data-tip={formatMessage(messages.LanguageTip)}>
                          <MDBIcon far icon="question-circle" />
                        </span>
                        <ReactTooltip effect="solid" />
                      </label>
                    </Col>
                  </Row>
                  <Row style={{ paddingBottom: '20px' }}>
                    <Col>
                      <MDBFormInline>
                        <Col md="4">
                          <MDBInput
                            label={formatMessage(messages.Default)}
                            type="checkbox"
                            id="checkboxlang1"
                            value="default"
                            checked={this.state.lang === 'default'}
                            onChange={(e) => handleCheckboxChange('default')}
                          />
                        </Col>
                        <Col md="4">
                          <MDBInput
                            label={formatMessage(messages.English)}
                            type="checkbox"
                            id="checkboxlang2"
                            value="en"
                            checked={this.state.lang === 'en'}
                            onChange={(e) => handleCheckboxChange('en')}
                          />
                        </Col>
                        <Col md="4">
                          <MDBInput
                            label={formatMessage(messages.Turkish)}
                            type="checkbox"
                            id="checkboxlang3"
                            value="tr"
                            checked={this.state.lang === 'tr'}
                            onChange={(e) => handleCheckboxChange('tr')}
                          />
                        </Col>
                      </MDBFormInline>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: '20px' }}>
                    <Col>
                      <a href="" onClick={this.props.setDefault}>
                        <p>{formatMessage(messages.SetSettings)}</p>
                      </a>
                    </Col>
                  </Row>
                  <Input
                    type="textarea"
                    label={formatMessage(messages.EmbedCode)}
                    name="embedCode"
                    value={this.state.embedCode}
                    onChange={this.handleTextChange}
                  />
                  {contentType !== 'poll' && contentType !== 'set' ? (
                    <Row style={{ marginTop: '1.5rem' }}>
                      <Col>
                        <label>{formatMessage(messages.Vertical)}</label>
                      </Col>
                      <Col>
                        <MDBSwitch
                          onChange={() =>
                            this.setState((prevState) => {
                              return { isVertical: !prevState.isVertical };
                            })
                          }
                          checked={this.state.isVertical}
                          labelLeft={formatMessage(messages.Off)}
                          labelRight={formatMessage(messages.On)}
                        />
                      </Col>
                    </Row>
                  ) : null}
                  <Button color="indigo" onClick={this.copyEmbedCode}>
                    {formatMessage(messages.CopyCode)}
                  </Button>
                  <br />
                  <br />
                  <a
                    href="https://github.com/poltio/embed/blob/master/README.md"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {formatMessage(messages.ClickHere)}
                  </a>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div
                    dangerouslySetInnerHTML={{ __html: this.state.embedCode }}
                  />
                </Col>
              </Row>
            </Container>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleModal}>
              {formatMessage(messages.Close)}
            </Button>
          </ModalFooter>
        </Modal>
      </Container>
    );
  }
}

export default injectIntl(EmbedModal);
