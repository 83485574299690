import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { injectIntl } from 'react-intl';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
  toast,
  MDBSelect,
  MDBSelectInput,
  MDBSelectOptions,
  MDBSelectOption,
  Button,
  MDBSwitch,
  MDBSpinner,
} from 'mdbreact';
import { leadSave, leadEdit, leadDelete } from '../../api';
import history from '../../core/history';

import './style.css';
import messages from './messages';
import ImageInput from '../ImageInput';
import SectionWithTitle from '../Common/SectionWithTitle';

const initialState = {
  name: '',
  title: '',
  msg: '',
  button_value: '',
  image: null,
  fields: '',
  type: 'input',
  redirect_url: '',
  terms_conditions: '',
  tc_optional: 1,
  tc2_optional: 1,
  terms_conditions2: '',
  codes: '',
  single_use: 0,
  youtube_id: null,
  dont_shorten: 0,
  ios_link: null,
  android_link: null,
  stop_set: 0,
  mandatory: 0,
  codesArray: [{ single_use: 0 }],
};

const LeadsEdit = ({ intl: { formatMessage }, location }) => {
  const [state, setState] = useState(initialState);
  const [loading, setLoading] = useState(true);

  const getLeadData = useCallback(async () => {
    const id = location.state.id;
    if (id) {
      try {
        const { data } = await leadEdit(id);
        setState({ ...data, codes: '', codesArray: [{ single_use: 0 }] });
        if (data.codes && Array.isArray(data.codes)) {
          let codesToArray = [];
          data.codes.forEach((value) => codesToArray.push(value.code));
          setState({
            ...data,
            codes: codesToArray.join(','),
            codesArray: data.codes,
          });
        }
        setLoading(false);
      } catch (e) {
        history.goBack();
      }
    } else {
      history.goBack();
    }
  }, [location.state.id]);

  useEffect(() => {
    getLeadData();
  }, [getLeadData]);

  const onChange = (event, trim) => {
    const { name, value } = event.target;
    setState({ ...state, [name]: trim ? value.trim() : value });
  };

  const onDelete = async (e) => {
    e.preventDefault();
    try {
      if (!window.confirm(formatMessage(messages.AreYouSure))) return;
      await leadDelete(state.id);
      history.push('/leads');
    } catch (e) {
      toast.warn(formatMessage(messages.ErrorDeleting));
    }
  };

  const onFormSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    try {
      await leadSave(state);
      history.push('/leads');
    } catch (e) {
      toast.warn(formatMessage(messages.ErrorSaving));
    }
  };

  const validateForm = () => {
    /*     if (type === 'redirect' && !redirect_url.startsWith('http')) {
      toast.info(formatMessage(messages.StartWithHttp));
      return false;
    } */
    if (state.name.length < 3) {
      toast.info(formatMessage(messages.ShortLeadName));
      return false;
    }
    return true;
  };

  const setSRC = (src) => setState({ ...state, image: src });

  const handleSelectChange = (val) => {
    if (val && Array.isArray(val)) {
      setState({
        ...state,
        fields: val.reduce(
          (total, field, i) =>
            (total += `${field}${i === val.length - 1 ? '' : ','}`),
          ''
        ),
      });
    }
  };

  const handleTypeChange = (val) => {
    if (val) {
      setState({
        ...state,
        type: Array.isArray(val) ? val[0] : val,
        mandatory: 0,
      });
    }
  };

  const checkboxChange = (e, name) => {
    if (name === 'tc_optional' || name === 'tc2_optional') {
      setState({ ...state, [name]: e.target.checked ? 0 : 1 });
    } else {
      setState({ ...state, [name]: e.target.checked ? 1 : 0 });
    }
  };

  return loading ? (
    <MDBContainer>
      <MDBRow center>
        <MDBSpinner blue big />
      </MDBRow>
    </MDBContainer>
  ) : (
    <MDBContainer fluid>
      <SectionWithTitle
        title={`${formatMessage(messages.EditLead)}: #${state.id} - ${
          state.name
        }`}
      >
        <div className="grey-text">
          <MDBRow>
            <MDBCol md="6" className="mb-4">
              <div className="error-wrapper">
                <MDBInput
                  label={formatMessage(messages.TypeName)}
                  group
                  type="text"
                  name="name"
                  onChange={onChange}
                  value={state.name}
                />
                <div className={`invalid-feedback d-block`}>
                  {state.name && state.name.length < 3 ? (
                    formatMessage(messages.ShortLeadName)
                  ) : (
                    <br />
                  )}
                </div>
              </div>
              <MDBInput
                label={formatMessage(messages.Title)}
                group
                type="text"
                name="title"
                onChange={onChange}
                value={state.title}
              />
              <MDBInput
                type="textarea"
                label={formatMessage(messages.Message)}
                group
                name="msg"
                onChange={onChange}
                value={state.msg}
              />
              <MDBInput
                type="text"
                label={`${formatMessage(messages.ButtonText)} ${formatMessage(
                  messages.DefaultButton
                )}`}
                group
                name="button_value"
                onChange={onChange}
                value={state.button_value}
              />
            </MDBCol>
            <MDBCol md="6" className="mb-4">
              <ImageInput
                title={formatMessage(messages.LeadImage)}
                setSRC={setSRC}
                getSRC={() => state.image}
                target={'lead_image'}
              />
            </MDBCol>
          </MDBRow>

          <div className="mt-5">
            <label>{formatMessage(messages.LeadType)}</label>
            <MDBSelect getValue={handleTypeChange}>
              <MDBSelectInput />
              <MDBSelectOptions>
                <MDBSelectOption value="input" checked={state.type === 'input'}>
                  {formatMessage(messages.LeadInput)}
                </MDBSelectOption>
                <MDBSelectOption
                  value="redirect"
                  checked={state.type === 'redirect'}
                >
                  {formatMessage(messages.Redirect)}
                </MDBSelectOption>
                <MDBSelectOption
                  value="internal_redirect"
                  checked={state.type === 'internal_redirect'}
                >
                  {formatMessage(messages.InternalRedirect)}
                </MDBSelectOption>
                <MDBSelectOption value="empty" checked={state.type === 'empty'}>
                  {formatMessage(messages.TextImageDisplay)}
                </MDBSelectOption>
              </MDBSelectOptions>
            </MDBSelect>
          </div>

          {state.type === 'input' && (
            <div>
              <label>{formatMessage(messages.Fields)}</label>
              <MDBSelect multiple getValue={handleSelectChange}>
                <MDBSelectInput
                  selected={formatMessage(messages.ChooseFields)}
                />
                <MDBSelectOptions>
                  <MDBSelectOption
                    value="gsm"
                    checked={state.fields.includes('gsm')}
                  >
                    Gsm
                  </MDBSelectOption>
                  <MDBSelectOption
                    value="email"
                    checked={state.fields.includes('email')}
                  >
                    Email
                  </MDBSelectOption>
                  <MDBSelectOption
                    value="name"
                    checked={state.fields.includes('name')}
                  >
                    Name
                  </MDBSelectOption>
                  <MDBSelectOption
                    value="comment"
                    checked={state.fields.includes('comment')}
                  >
                    Comment
                  </MDBSelectOption>
                  <MDBSelectOption
                    value="comment"
                    checked={state.fields.includes('file')}
                  >
                    File
                  </MDBSelectOption>
                </MDBSelectOptions>
              </MDBSelect>
            </div>
          )}
          {state.type !== 'empty' && (
            <Fragment>
              <MDBInput
                type="textarea"
                label={formatMessage(messages.RedirectUrl)}
                group
                name="redirect_url"
                onChange={(e) => onChange(e, true)}
                value={state.redirect_url}
                hint={state.type === 'internal_redirect' ? 'poll:1234' : ''}
              />
              <MDBRow>
                <MDBCol md="2">
                  <label>{formatMessage(messages.DontShortenLink)}</label>
                </MDBCol>
                <MDBCol md="2">
                  <MDBSwitch
                    checked={state.dont_shorten === 1 ? true : false}
                    onChange={(e) => checkboxChange(e, 'dont_shorten')}
                  />
                </MDBCol>
              </MDBRow>
            </Fragment>
          )}
          <MDBInput
            label={formatMessage(messages.TcOp)}
            type="checkbox"
            id="checkbox1"
            containerClass="leftalign"
            onChange={(e) => checkboxChange(e, 'tc_optional')}
            checked={!state.tc_optional}
          />
          {!state.tc_optional ? (
            <Fragment>
              <MDBInput
                type="textarea"
                label={`${formatMessage(messages.Terms)} ( ${formatMessage(
                  messages.SupportsMarkdown
                )} )`}
                group
                name="terms_conditions"
                onChange={onChange}
                value={state.terms_conditions || ''}
              />
              <MDBInput
                label={formatMessage(messages.TcOp2)}
                type="checkbox"
                id="checkbox2"
                containerClass="leftalign"
                onChange={(e) => checkboxChange(e, 'tc2_optional')}
                checked={!state.tc2_optional}
              />
              {!state.tc2_optional ? (
                <MDBInput
                  type="textarea"
                  label={`${formatMessage(
                    messages.SecondTerms
                  )} ( ${formatMessage(messages.SupportsMarkdown)} )`}
                  group
                  name="terms_conditions2"
                  onChange={onChange}
                  value={state.terms_conditions2 || ''}
                />
              ) : null}
            </Fragment>
          ) : null}

          <MDBInput
            type="textarea"
            label={formatMessage(messages.Codes)}
            group
            name="codes"
            onChange={onChange}
            value={state.codes}
          />
          <MDBInput
            label={formatMessage(messages.SingleUse)}
            type="checkbox"
            id="checkbox2"
            containerClass="leftalign"
            onChange={(e) => checkboxChange(e, 'single_use')}
            checked={
              state.codesArray[0] ? state.codesArray[0].single_use : false
            }
          />
          <MDBInput
            type="text"
            label={'Youtube ID'}
            name="youtube_id"
            onChange={onChange}
            value={state.youtube_id || ''}
          />
          {state.type === 'redirect' ? (
            <Fragment>
              <MDBInput
                type="textarea"
                label={'iOS Link'}
                name="ios_link"
                onChange={onChange}
                value={state.ios_link || ''}
              />
              <MDBInput
                type="textarea"
                label={'Android Link'}
                name="android_link"
                onChange={onChange}
                value={state.android_link || ''}
              />
            </Fragment>
          ) : null}
          <MDBRow>
            <MDBCol md="2">
              <label>{formatMessage(messages.StopSet)}</label>
            </MDBCol>
            <MDBCol md="3">
              <MDBSwitch
                checked={state.stop_set === 1 ? true : false}
                onChange={(e) => checkboxChange(e, 'stop_set')}
              />
            </MDBCol>
          </MDBRow>
          {state.type === 'input' && (
            <MDBRow>
              <MDBCol md="2">
                <label>{formatMessage(messages.Mandatory)}</label>
              </MDBCol>
              <MDBCol md="3">
                <MDBSwitch
                  checked={state.mandatory === 1 ? true : false}
                  onChange={(e) => checkboxChange(e, 'mandatory')}
                />
              </MDBCol>
            </MDBRow>
          )}
        </div>
        <div className="text-center">
          <Button color="primary" type="submit" onClick={onFormSubmit}>
            {formatMessage(messages.Save)}
          </Button>
          <Button color="danger" onClick={onDelete}>
            {formatMessage(messages.Delete)}
          </Button>
        </div>
      </SectionWithTitle>
    </MDBContainer>
  );
};

export default injectIntl(LeadsEdit);
