/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import {
  Row,
  Col,
  Container,
  Stepper,
  Step,
  toast,
  Card,
  CardBody,
  MDBBtn,
  MDBIcon,
} from 'mdbreact';
import ReactTooltip from 'react-tooltip';
import {
  createQuiz,
  finishQuiz,
  getPublisherSettings,
  setPublisherSettings,
} from '../../../api';
import history from '../../../core/history';

import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Prompt from '../Prompt';
import messages from '../messages';

const initialState = {
  formActivePanel: 1,
  prompt: false,
  quizData: {
    title: '',
    background: '',
    desc: '',
    desc_source: '',
    result_source: '',
    privacy: 'private',
    gives_feedback: 1,
    show_timer: 1,
    hide_results: 0,
    hide_counter: 0,
    display_results: 1,
    requires_login: 0,
    time_per_question: '10',
    time_limit: '0',
    display_repeat: 0,
    friend_quiz: 0,
    play_once: 0,
    play_once_strategy: 'result',
    play_once_msg: null,
    play_once_img: null,
    play_once_btn: null,
    play_once_link: null,
    embed_background: '',
    embed_footer_img: '',
    embed_footer_url: null,
    end_date: 0,
    end_date_day: '00',
    end_date_hour: '00',
    end_date_minute: '00',
    pool_question_count: null,
  },
  questions: [],
  results: [
    {
      title: '',
      background: '',
      desc: '',
      url: '',
      min_c: 0,
      max_c: 0,
      pixel_code_id: '',
    },
  ],
  src: null,
  crop: {
    x: 0,
    y: 0,
    width: 50,
    aspect: 9 / 5,
  },
  pixelCrop: null,
  cropped: false,
  questionCount: 0,
};

class QuizCreate extends Component {
  state = initialState;

  async componentDidMount() {
    const response = await getPublisherSettings('quiz');

    this.setState({
      quizData: {
        ...this.state.quizData,
        ...response.data,
        end_date: 0,
      },
    });
  }

  pixelCode = (index, pc) => {
    if (pc) {
      this.setState({
        results: this.state.results.map((a, i) => {
          if (i === index) {
            return {
              ...a,
              pixel_code_id: pc.id,
              pixel_code_name: pc.name,
            };
          }
          return a;
        }),
      });
    }
  };

  leadSelect = (index, lead) => {
    if (lead) {
      this.setState({
        results: this.state.results.map((a, i) => {
          if (i === index) {
            return {
              ...a,
              lead_id: lead.id,
              lead_name: lead.name,
            };
          }
          return a;
        }),
      });
    }
  };

  togglePrompt = () => {
    this.setState({
      prompt: !this.state.prompt,
    });
  };

  handleNextPrevClick = (param) => (e) => {
    const { quizData, questions, optionsDirty } = this.state;
    const { formatMessage } = this.props.intl;
    if (param === 3) {
      if (parseInt(quizData.pool_question_count, 10) > questions.length) {
        toast.error(formatMessage(messages.PoolGreaterThan));
        return;
      }
    }
    if (param === 2 && optionsDirty) {
      this.setState({ prompt: true });
    }
    if (param === 2) {
      this.createQuiz();
      this.setState({
        formActivePanel: param,
      });
    } else {
      this.setState({
        formActivePanel: param,
      });
    }
  };

  onYesClick = async () => {
    const { quizData } = this.state;
    this.togglePrompt();

    await setPublisherSettings('quiz', {
      privacy: quizData.privacy,
      gives_feedback: quizData.gives_feedback,
      show_timer: quizData.show_timer,
      hide_results: quizData.hide_results,
      display_results: quizData.display_results,
      requires_login: quizData.requires_login,
      time_per_question: quizData.time_per_question,
      display_repeat: quizData.display_repeat,
      hide_counter: quizData.hide_counter,
    });
  };

  createQuiz = async () => {
    const { quizData, src, quizCreated, createdTestId } = this.state;
    try {
      const r = await createQuiz(
        { ...quizData, pool_question_count: quizData.pool_question_count || 0 },
        src,
        quizCreated,
        createdTestId
      );
      this.setState({
        createdTestId: r.data.quiz.id,
        quizFinished: false,
        quizCreated: true,
      });
    } catch (e) {
      console.log(e);
    }
  };

  finishQuiz = async () => {
    const { formatMessage } = this.props.intl;

    let validated = true;
    this.state.results.forEach((r) => {
      if (!r.dont_shorten && r.url && !r.url.startsWith('http')) {
        toast.error(formatMessage(messages.ValidUrl));
        validated = false;
      }
    });
    if (validated) {
      try {
        await finishQuiz(this.state.createdTestId, this.state.results);
        toast.success(formatMessage(messages.ContentCreated));
        history.push('/content');
      } catch (e) {
        toast.error('Error occured!');
      }
    }
  };

  handleTextChange = async (e) => {
    const { name, value } = e.target;
    this.setState({
      quizData: {
        ...this.state.quizData,
        [name]: value,
        friend_quiz:
          name === 'time_limit' ? 0 : this.state.quizData.friend_quiz,
        show_timer: name === 'time_limit' ? 0 : this.state.quizData.show_timer,
      },
    });
  };

  handleTextChangeWithoutEmbedly = (e) => {
    this.setState({
      quizData: {
        ...this.state.quizData,
        [e.target.name]: e.target.value === '' ? null : e.target.value,
      },
    });
  };

  setSRC = (src, target) => {
    if (target && target.length > 0) {
      this.setState({
        quizData: {
          ...this.state.quizData,
          [target]: src,
        },
      });
    } else {
      this.setState({ src: src });
    }
  };

  getSelectValue = (value, name) => {
    if (value) {
      this.setState({
        quizData: {
          ...this.state.quizData,
          [name]: value,
        },
      });
    }
  };

  handleSwitchChange = (e, name) => {
    if (name === 'privacy') {
      this.setState({
        quizData: {
          ...this.state.quizData,
          [name]: e.target.value === 'true' ? 'public' : 'private',
        },
      });
    } else if (name === 'play_once') {
      this.setState({
        quizData: {
          ...this.state.quizData,
          [name]: e.target.checked ? 1 : 0,
        },
      });
    } else if (name === 'hide_counter') {
      this.setState({
        quizData: {
          ...this.state.quizData,
          [name]: e.target.value === 'true' ? 1 : 0,
        },
      });
    } else if (name === 'end_date') {
      this.setState({
        quizData: {
          ...this.state.quizData,
          [name]: e.target.value === 'true' ? 0 : 1,
          end_date_day: e.target.value === 'true' ? '00' : '30',
          end_date_hour: '00',
          end_date_minute: '00',
        },
      });
    } else if (name === 'friend_quiz' || name === 'show_timer') {
      this.setState({
        quizData: {
          ...this.state.quizData,
          [name]: e.target.value === 'true' ? 0 : 1,
          time_limit: 0,
        },
      });
    } else {
      this.setState({
        quizData: {
          ...this.state.quizData,
          [name]: e.target.value === 'true' ? 0 : 1,
        },
      });
    }
    if (name !== 'end_date') {
      this.setState({ optionsDirty: true });
    }
  };

  setResultImg = (index, value) => {
    const { results } = this.state;
    this.setState({
      results: results.map((a, i) => {
        if (index === i) {
          return { ...a, background: value };
        }
        return a;
      }),
    });
  };

  changeNumber = (e, direction) => {
    e.preventDefault();
    if (direction === 'add') {
      this.setState({
        results: [
          ...this.state.results,
          {
            title: '',
            background: '',
            desc: '',
            url: '',
            min_c: 0,
            max_c: 0,
            pixel_code_id: '',
          },
        ],
      });
    } else if (direction === 'delete') {
      this.setState({
        results: this.state.results.slice(0, -1),
      });
    }
  };

  handleAnswerChange = (e, i) => {
    const { name, value } = e.target;
    this.setState({
      results: this.state.results.map((a, index) => {
        if (index === i) {
          return { ...a, [name]: name === 'url' ? value.trim() : value };
        }
        return a;
      }),
    });
  };

  handleResultSwitchChange = (e, i) => {
    this.setState({
      results: this.state.results.map((a, index) => {
        if (index === i) {
          return { ...a, dont_shorten: e.target.checked ? 1 : 0 };
        }
        return a;
      }),
    });
  };

  getSelectValue2 = (value, name, i) => {
    if (
      (typeof value === 'string' && value !== '0') ||
      typeof value === 'undefined'
    )
      return;
    this.setState({
      results: this.state.results.map((a, index) => {
        if (index === i) {
          return { ...a, [name]: parseInt(value, 10) };
        }
        return a;
      }),
    });
  };

  getSelectValue3 = (value, name) => {
    if (value === '3' || value === '0' || !value) return;
    this.setState({
      quizData: {
        ...this.state.quizData,
        [name]: value,
      },
      optionsDirty: true,
    });
  };

  /*
  bgFileUpload = (files, name) => {
    console.log('asfasfsa');
    const reader = new window.FileReader();
    if (files[0]) {
      reader.readAsDataURL(files[0]);
    }
    reader.onload = async () => {
      if (!/\.(gif|jpg|jpeg|tiff|png)$/i.test(files[0].name)) {
        return;
      }

      try {
        const response = await uploadImage(
          reader.result,
          files[0].name.split('.').pop()
        );
        this.setState({
          quizData: {
            ...this.state.quizData,
            [name]: response.data.file
          }
        });
      } catch (e) {
        console.log(e);
      }
    };
  };
  */

  setQuestions = (questions) => this.setState({ questions });

  copyQuestion = (qi) => {
    const { questions } = this.state;
    const qu = questions[qi];
    this.setState({
      questions: [
        ...questions,
        {
          ...qu,
          id: null,
          answers: qu.answers.map((a) => {
            return {
              ...a,
              id: null,
            };
          }),
        },
      ],
    });
  };

  leadSelectSet = (i, lead) => {
    if (lead) {
      this.setState({
        quizData: {
          ...this.state.quizData,
          lead_id: lead.id,
          lead_name: lead.name,
        },
      });
    }
  };

  render() {
    const {
      quizData,
      src,
      createdTestId,
      quizFinished,
      prompt,
      results,
      questionCount,
      questions,
      quizCreated,
    } = this.state;
    const { formatMessage } = this.props.intl;

    return (
      <Container>
        <Prompt
          toggleModal={this.togglePrompt}
          modal={prompt}
          onYesClick={this.onYesClick}
        />
        <Row>
          <Col md="7">
            <Card>
              <CardBody>
                <h2 className="text-center font-weight-bold pt-4 pb-5">
                  <strong>{formatMessage(messages.CreateQuiz)}</strong>
                </h2>

                <Stepper form>
                  <Step form>
                    <a href="" onClick={(e) => e.preventDefault()}>
                      <button
                        type="button"
                        className={`btn btn-${
                          this.state.formActivePanel === 1
                            ? 'indigo'
                            : 'default'
                        } btn-circle Ripple-parent`}
                      >
                        1
                        <div
                          className="Ripple "
                          style={{
                            top: '0px',
                            left: '0px',
                            width: '0px',
                            height: '0px',
                          }}
                        />
                      </button>
                    </a>
                    <p>{formatMessage(messages.QuizInfo)}</p>
                  </Step>
                  <Step form>
                    <a href="" onClick={(e) => e.preventDefault()}>
                      <button
                        type="button"
                        className={`btn btn-${
                          this.state.formActivePanel === 2
                            ? 'indigo'
                            : 'default'
                        } btn-circle Ripple-parent`}
                      >
                        2
                        <div
                          className="Ripple "
                          style={{
                            top: '0px',
                            left: '0px',
                            width: '0px',
                            height: '0px',
                          }}
                        />
                      </button>
                    </a>
                    <p>{formatMessage(messages.QuizQuestions)}</p>
                  </Step>
                  <Step form>
                    <a href="" onClick={(e) => e.preventDefault()}>
                      <button
                        type="button"
                        className={`btn btn-${
                          this.state.formActivePanel === 3
                            ? 'indigo'
                            : 'default'
                        } btn-circle Ripple-parent`}
                      >
                        3
                        <div
                          className="Ripple "
                          style={{
                            top: '0px',
                            left: '0px',
                            width: '0px',
                            height: '0px',
                          }}
                        />
                      </button>
                    </a>
                    <p>{formatMessage(messages.QuizResults)}</p>
                  </Step>
                </Stepper>

                <Row>
                  {this.state.formActivePanel === 1 && (
                    <Step1
                      handleNextPrevClick={this.handleNextPrevClick}
                      handleTextChange={this.handleTextChange}
                      poll={quizData}
                      src={src}
                      setSRC={this.setSRC}
                      getSelectValue={this.getSelectValue}
                      getSelectValue3={this.getSelectValue3}
                      handleSwitchChange={this.handleSwitchChange}
                      handleTextChangeWithoutEmbedly={
                        this.handleTextChangeWithoutEmbedly
                      }
                      leadSelect={this.leadSelectSet}
                    />
                  )}
                  {this.state.formActivePanel === 2 && (
                    <Step2
                      quizData={quizData}
                      createdTestId={createdTestId}
                      handleNextPrevClick={this.handleNextPrevClick}
                      setQuestionCount={(count) =>
                        this.setState({ questionCount: count })
                      }
                      setQuestions={this.setQuestions}
                      quizCreated={quizCreated}
                    />
                  )}
                  {this.state.formActivePanel === 3 && (
                    <Step3
                      quizData={quizData}
                      results={results}
                      setResultImg={this.setResultImg}
                      changeNumber={this.changeNumber}
                      handleAnswerChange={this.handleAnswerChange}
                      handleResultSwitchChange={this.handleResultSwitchChange}
                      finishQuiz={this.finishQuiz}
                      questionCount={questionCount}
                      getSelectValue={this.getSelectValue2}
                      pixelCode={this.pixelCode}
                      leadSelect={this.leadSelect}
                    />
                  )}
                </Row>
              </CardBody>
            </Card>
          </Col>

          <Col md="5">
            {questions && questions.length > 0 ? (
              <Card>
                <CardBody>
                  <h2 className="text-center font-weight-bold pt-4 pb-5">
                    <strong>{formatMessage(messages.YourQuestions)}</strong>
                  </h2>
                  <table style={{ width: '100%' }}>
                    {questions.map((q, i) => (
                      <tr key={i}>
                        <td style={{ width: '25%' }}>
                          <ReactTooltip effect="solid" multiline={true} />
                          <span
                            data-tip={formatMessage(messages.Copy)}
                            data-place="bottom"
                          >
                            <MDBBtn
                              rounded
                              color="info"
                              size="sm"
                              onClick={() => this.copyQuestion(i)}
                              disabled={!q.id}
                            >
                              <MDBIcon icon="copy" />
                            </MDBBtn>
                          </span>
                        </td>
                        <td>
                          <h6>{`${i + 1}. ${q.title}`}</h6>
                        </td>
                      </tr>
                    ))}
                  </table>
                </CardBody>
              </Card>
            ) : null}
            <h2 className="text-center font-weight-bold pt-4 pb-5">
              <strong>{formatMessage(messages.QuizPreview)}</strong>
            </h2>
            {quizFinished ? (
              <iframe
                src={`//www.poltio.com/e/quiz/${createdTestId}`}
                width="100%"
                height="650"
                scrolling="no"
                frameBorder="0"
                title={`quiz${createdTestId}`}
              />
            ) : (
              <h6 className="text-center pt-4 pb-5">
                {formatMessage(messages.YourContent)}
              </h6>
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default injectIntl(QuizCreate);
