import React from 'react';
import { injectIntl } from 'react-intl';
import { Container, Row, Col, Input, Card, CardBody, Button } from 'mdbreact';
import messages from './messages';

const ChangePass = ({
  pass,
  handlePassInputChange,
  submitPass,
  buttonLoading,
  resultStatus,
  intl,
}) => (
  <Container>
    <Row>
      <Col className="container col-md-4 col-md-offset-4">
        <form>
          <Card>
            <CardBody>
              <div className="grey-text">
                <Input
                  label={intl.formatMessage(messages.Old)}
                  icon="lock"
                  group
                  type="password"
                  validate
                  error="wrong"
                  success="right"
                  name="old"
                  onChange={handlePassInputChange}
                  value={pass.old}
                />
                <Input
                  label={intl.formatMessage(messages.New)}
                  icon="lock"
                  group
                  type="password"
                  validate
                  name="new"
                  onChange={handlePassInputChange}
                  value={pass.new}
                />
                <Input
                  label={intl.formatMessage(messages.NewAgain)}
                  icon="lock"
                  group
                  type="password"
                  validate
                  name="new_again"
                  onChange={handlePassInputChange}
                  value={pass.new_again}
                />
              </div>
              <div className="text-center">
                <Button
                  color="primary"
                  type="submit"
                  onClick={submitPass}
                  disabled={buttonLoading}
                >
                  {intl.formatMessage(messages.Submit)}
                </Button>
              </div>
              {resultStatus === 'success' ? (
                <div className="valid-feedback" style={{ display: 'block' }}>
                  {'Profile updated successfully!'}
                </div>
              ) : null}
              {resultStatus === 'error' ? (
                <div className="invalid-feedback" style={{ display: 'block' }}>
                  {'Error updating profile!'}
                </div>
              ) : null}
              {resultStatus === 'notmatch' ? (
                <div className="invalid-feedback" style={{ display: 'block' }}>
                  {'Passwords do not match!'}
                </div>
              ) : null}
            </CardBody>
          </Card>
        </form>
      </Col>
    </Row>
  </Container>
);

export default injectIntl(ChangePass);
