import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { getSettings, uploadImage, submitSettings } from '../../api';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  InputFile,
  Spinner,
  Fa,
  Input,
  toast,
  MDBBtn,
} from 'mdbreact';

import UserCard from './UserCard';
import messages from './messages';

class SettingsForm extends Component {
  state = {
    originalFields: {},
    settingsData: {},
    settingsStatus: {},
    errorState: false,
    settingsLoading: true,
    submitButtonDisabled: false,
  };

  async componentDidMount() {
    try {
      const response = await getSettings();
      this.setState({
        settingsData: response.data,
        originalSettings: response.data,
        settingsLoading: false,
      });
    } catch (error) {
      console.log(error);
      this.setState({ errorState: true, settingsLoading: false });
    }
  }

  fileInputHandler = (files, inputName) => {
    const reader = new window.FileReader();
    if (files[0]) {
      reader.readAsDataURL(files[0]);
    }

    reader.onload = async () => {
      if (!/\.(gif|jpg|jpeg|tiff|png)$/i.test(files[0].name)) {
        return;
      }

      try {
        this.setState({
          settingsStatus: {
            ...this.state.settingsStatus,
            [`${inputName}_status`]: 'loading',
          },
        });
        const response = await uploadImage(
          reader.result,
          files[0].name.split('.').pop()
        );
        const response2 = await submitSettings({
          [inputName]: response.data.file,
        });

        window.localStorage.setItem('user', JSON.stringify(response2.data));

        this.setState({
          settingsData: {
            ...this.state.settingsData,
            [inputName]: response.data.file,
          },
          originalSettings: {
            ...this.state.settingsData,
            [inputName]: response.data.file,
          },
          settingsStatus: {
            ...this.state.settingsStatus,
            [`${inputName}_status`]: 'success',
          },
        });
      } catch (e) {
        console.log(e);
        this.setState({
          settingsStatus: {
            ...this.state.settingsStatus,
            [`${inputName}_status`]: 'error',
          },
        });
      }
    };
  };

  handleTextChange = (e) => {
    this.setState({
      settingsData: {
        ...this.state.settingsData,
        [e.target.name]: e.target.value,
      },
    });
  };

  handleSwitchChange = (e, name) => {
    e.preventDefault();
    this.setState({
      settingsData: {
        ...this.state.settingsData,
        [name]: e.target.value === 'true' ? 0 : 1,
      },
    });

    const event = {
      target: { name, value: e.target.value === 'true' ? 0 : 1 },
    };
    this.submitField(event);
  };

  getSelectValue = (val, name) => {
    if (this.state.settingsData[name] === val[0]) return;

    if (val && Array.isArray(val)) {
      this.setState({
        settingsData: {
          ...this.state.settingsData,
          [name]: val[0],
        },
      });

      const event = { target: { name, value: val[0] } };
      this.submitField(event);
    }
  };

  submitField = async (e) => {
    const eventName = e.target.name;
    const eventValue = e.target.value;
    const { originalSettings } = this.state;

    if (originalSettings[eventName] === eventValue) return;

    if (eventName === 'username') {
      const regex = /^([a-zA-Z0-9-]+)$/;
      const result = regex.test(eventValue);
      if (!result) {
        this.setState({
          settingsStatus: {
            ...this.state.settingsStatus,
            username_status: 'error',
          },
        });
        return;
      }
    }

    try {
      this.setState({
        settingsStatus: {
          ...this.state.settingsStatus,
          [`${eventName}_status`]: 'loading',
        },
      });

      const response = await submitSettings({ [eventName]: eventValue });
      window.localStorage.setItem('user', JSON.stringify(response.data));

      this.setState({
        settingsStatus: {
          ...this.state.settingsStatus,
          [`${eventName}_status`]: 'success',
        },
        originalSettings: response.data,
      });
    } catch (e) {
      this.setState({
        settingsStatus: {
          ...this.state.settingsStatus,
          [`${eventName}_status`]: 'error',
        },
      });
    }
  };

  saveSettings = () => {
    const { formatMessage } = this.props.intl;
    this.setState({ submitButtonDisabled: true });
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      toast.success(formatMessage(messages.SuccessSubmit));
      this.setState({ submitButtonDisabled: false });
    }, 1000);
  };

  render() {
    const {
      settingsData,
      originalSettings,
      settingsStatus,
      settingsLoading,
    } = this.state;
    const { formatMessage } = this.props.intl;

    return (
      <Container>
        {settingsLoading ? (
          <Col className="container col-md-2 col-md-offset-5">
            <Spinner blue big />
          </Col>
        ) : (
          <Row>
            <Col className="container col-md-4">
              <UserCard settingsData={originalSettings} />
            </Col>
            <Col className="container col-md-8">
              <Card>
                <CardBody>
                  <CardTitle>{formatMessage(messages.UserInfo)}</CardTitle>
                  <h6>{formatMessage(messages.UploadProfilePic)}</h6>
                  <Row>
                    <Col>
                      <InputFile
                        getValue={(file) =>
                          this.fileInputHandler(file, 'photo')
                        }
                      />
                    </Col>
                    <Col style={{ marginTop: '1.5rem' }}>
                      {settingsStatus.photo_status ? (
                        settingsStatus.photo_status === 'loading' ? (
                          <Spinner green small />
                        ) : settingsStatus.photo_status === 'success' ? (
                          <Fa
                            icon="check"
                            stack="2x"
                            style={{ color: 'green' }}
                          />
                        ) : (
                          <Fa
                            icon="times"
                            stack="2x"
                            style={{ color: 'red' }}
                          />
                        )
                      ) : null}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Input
                        label={formatMessage(messages.FirstName)}
                        group
                        type="text"
                        validate
                        name="first_name"
                        onChange={this.handleTextChange}
                        value={settingsData.first_name}
                        onBlur={this.submitField}
                      />
                    </Col>
                    <Col style={{ marginTop: '1.5rem' }}>
                      {settingsStatus.first_name_status ? (
                        settingsStatus.first_name_status === 'loading' ? (
                          <Spinner green small />
                        ) : settingsStatus.first_name_status === 'success' ? (
                          <Fa
                            icon="check"
                            stack="2x"
                            style={{ color: 'green' }}
                          />
                        ) : (
                          <Fa
                            icon="times"
                            stack="2x"
                            style={{ color: 'red' }}
                          />
                        )
                      ) : null}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Input
                        label={formatMessage(messages.LastName)}
                        group
                        type="text"
                        validate
                        name="last_name"
                        onChange={this.handleTextChange}
                        value={settingsData.last_name}
                        onBlur={this.submitField}
                      />
                    </Col>
                    <Col style={{ marginTop: '1.5rem' }}>
                      {settingsStatus.last_name_status ? (
                        settingsStatus.last_name_status === 'loading' ? (
                          <Spinner green small />
                        ) : settingsStatus.last_name_status === 'success' ? (
                          <Fa
                            icon="check"
                            stack="2x"
                            style={{ color: 'green' }}
                          />
                        ) : (
                          <Fa
                            icon="times"
                            stack="2x"
                            style={{ color: 'red' }}
                          />
                        )
                      ) : null}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Input
                        label={formatMessage(messages.Username)}
                        group
                        type="text"
                        validate
                        name="username"
                        onChange={this.handleTextChange}
                        value={settingsData.username}
                        onBlur={this.submitField}
                      />
                    </Col>
                    <Col style={{ marginTop: '1.5rem' }}>
                      {settingsStatus.username_status ? (
                        settingsStatus.username_status === 'loading' ? (
                          <Spinner green small />
                        ) : settingsStatus.username_status === 'success' ? (
                          <Fa
                            icon="check"
                            stack="2x"
                            style={{ color: 'green' }}
                          />
                        ) : (
                          <Fa
                            icon="times"
                            stack="2x"
                            style={{ color: 'red' }}
                          />
                        )
                      ) : null}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Input
                        label={formatMessage(messages.Email)}
                        group
                        type="text"
                        validate
                        name="email"
                        onChange={this.handleTextChange}
                        value={settingsData.email}
                        onBlur={this.submitField}
                      />
                    </Col>
                    <Col style={{ marginTop: '1.5rem' }}>
                      {settingsStatus.email_status ? (
                        settingsStatus.email_status === 'loading' ? (
                          <Spinner green small />
                        ) : settingsStatus.email_status === 'success' ? (
                          <Fa
                            icon="check"
                            stack="2x"
                            style={{ color: 'green' }}
                          />
                        ) : (
                          <Fa
                            icon="times"
                            stack="2x"
                            style={{ color: 'red' }}
                          />
                        )
                      ) : null}
                    </Col>
                  </Row>

                  <Row style={{ marginTop: '1.5rem' }}>
                    <MDBBtn
                      size="lg"
                      color="primary"
                      className="ml-4"
                      onClick={this.saveSettings}
                      disabled={this.state.submitButtonDisabled}
                    >
                      {formatMessage(messages.Submit)}
                    </MDBBtn>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
      </Container>
    );
  }
}

export default injectIntl(SettingsForm);
