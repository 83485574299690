/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBBtn,
  MDBModalFooter,
} from 'mdbreact';
import history from '../../core/history';

const Signup = ({
  messages,
  formatMessage,
  locale,
  formData,
  setFormData,
  onFormSubmit,
  sending,
}) => (
  <MDBCard>
    <MDBCardBody className="mx-4">
      <form method="post" onSubmit={onFormSubmit}>
        <div className="text-center">
          <h3 className="dark-grey-text mb-5">
            <strong>{formatMessage(messages.SignUp)}</strong>
          </h3>
        </div>
        <MDBInput
          label={formatMessage(messages.TypeEmail)}
          group
          type="email"
          autoComplete="new-password"
          name="email"
          onChange={(e) => {
            setFormData({
              ...formData,
              email: e.target.value,
              errorMessage: null,
            });
          }}
          value={formData.email}
        />
        <MDBInput
          label={formatMessage(messages.TypePass)}
          group
          type="password"
          autoComplete="new-password"
          name="pass"
          onChange={(e) => {
            setFormData({
              ...formData,
              pass: e.target.value,
              errorMessage: null,
            });
          }}
          value={formData.pass}
        />
        <MDBInput
          label={formatMessage(messages.TypePassAgain)}
          group
          type="password"
          autoComplete="new-password-again"
          name="passAgain"
          onChange={(e) => {
            setFormData({
              ...formData,
              passAgain: e.target.value,
              errorMessage: null,
            });
          }}
          value={formData.passAgain}
        />
        <MDBInput
          label={
            <a
              href={`/terms-and-conditions?loc=${locale}`}
              target="_blank"
              rel="noopener noreferrer"
              className="blue-text"
            >
              {formatMessage(messages.AcceptTC)}
            </a>
          }
          type="checkbox"
          id="checkbox1"
          onChange={(e) => {
            setFormData({
              ...formData,
              termsAccepted: e.target.checked,
              errorMessage: null,
            });
          }}
        />
        {formData.errorMessage ? (
          <div
            className="invalid-feedback"
            style={{ display: 'block', marginTop: '20px' }}
          >
            {formData.errorMessage}
          </div>
        ) : null}
        <div className="text-center" style={{ marginTop: '20px' }}>
          <MDBBtn
            gradient="blue"
            rounded
            className="btn-block z-depth-1a"
            type="submit"
            disabled={sending}
          >
            {formatMessage(messages.SignUp)}
          </MDBBtn>
        </div>
      </form>
    </MDBCardBody>
    <MDBModalFooter className="mx-5 pt-3 mb-1">
      <p className="font-small grey-text d-flex justify-content-end">
        <a
          href=""
          className="blue-text ml-1"
          onClick={(e) => {
            e.preventDefault();
            history.push('/login');
          }}
        >
          {formatMessage(messages.BackToLogin)}
        </a>
      </p>
    </MDBModalFooter>
  </MDBCard>
);

export default Signup;
