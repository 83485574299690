import React, { Fragment, useEffect, useState } from 'react';

import { Spinner, MDBContainer, MDBRow, MDBSpinner } from 'mdbreact';
import { Chart } from 'react-google-charts';
import { getSankeyMetrics } from '../../api';
import makeComponentTrashable from 'trashable-react';

import SankeyModal from './SankeyModal';

const SankeyChart = ({
  formatMessage,
  messages,
  type,
  id,
  registerPromise,
}) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState(['', '', '']);

  useEffect(() => {
    let ignore = false;

    async function fetchData() {
      const response = await registerPromise(getSankeyMetrics(type, id));
      if (!ignore) {
        setData([['From', 'To', 'Vote'], ...response.data.rows]);
        setLoading(false);
      }
    }

    fetchData();
    return () => {
      ignore = true;
    };
  }, [type, id, registerPromise]);

  const openSankeyModal = (e, data) => {
    if (e) e.preventDefault();
    if (data) setModalData(data);
    setModal((prevModal) => !prevModal);
  };

  return loading ? (
    <MDBContainer>
      <MDBRow center>
        <MDBSpinner />
      </MDBRow>
    </MDBContainer>
  ) : (
    <Fragment>
      <SankeyModal
        data={{ type, id }}
        modalData={modalData}
        isOpen={modal}
        toggle={openSankeyModal}
        formatMessage={formatMessage}
        messages={messages}
      />

      <Chart
        chartType="Sankey"
        loader={<Spinner big blue />}
        width="100%"
        height="700px"
        data={data}
        options={{
          sankey: {
            link: {
              interactivity: true,
            },
          },
        }}
        chartEvents={[
          {
            eventName: 'select',
            callback({ chartWrapper }) {
              const rowNumber = chartWrapper.getChart().getSelection()[0].row;
              openSankeyModal(null, data[rowNumber + 1]);
            },
          },
        ]}
      />
    </Fragment>
  );
};

export default makeComponentTrashable(SankeyChart);
