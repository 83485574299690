import React from 'react';
import { injectIntl } from 'react-intl';
import {
  Col,
  Row,
  InputSwitch,
  Select,
  SelectInput,
  SelectOption,
  SelectOptions,
  MDBBtn,
  MDBIcon,
} from 'mdbreact';
import ReactTooltip from 'react-tooltip';

import messages from '../messages';

let Step4 = ({
  poll,
  handleSwitchChange,
  getSelectValue,
  endDateEdited,
  endDateEditClick,
  intl,
}) => {
  const { formatMessage } = intl;

  return (
    <Col md="12">
      <ReactTooltip effect="solid" multiline={true} />
      <Row style={{ marginTop: '1.5rem' }}>
        <Col>
          <label>
            {formatMessage(messages.EndDate)}{' '}
            <span data-tip={formatMessage(messages.EndDateTip)}>
              <MDBIcon far icon="question-circle" />
            </span>
          </label>
        </Col>
        <Col>
          <InputSwitch
            onChange={(e) => handleSwitchChange(e, 'end_date')}
            checked={!!poll.end_date}
            labelLeft={formatMessage(messages.Off)}
            labelRight={formatMessage(messages.On)}
          />
        </Col>
      </Row>
      {poll.end_date ? (
        endDateEdited ? (
          <Row style={{ marginTop: '1.5rem' }}>
            <Col md="4">
              <label>{formatMessage(messages.Days)}:</label>
              <Select
                getValue={(val) => getSelectValue(val[0], 'end_date_day')}
              >
                <SelectInput selected={poll.end_date_day} />
                <SelectOptions>
                  {[...Array(32).keys()].map((elem, index) => (
                    <SelectOption
                      value={`0${index}`.slice(-2)}
                      key={`day${index}`}
                    >
                      {`0${index}`.slice(-2)}
                    </SelectOption>
                  ))}
                </SelectOptions>
              </Select>
            </Col>
            <Col md="4">
              <label>{formatMessage(messages.Hours)}:</label>
              <Select
                getValue={(val) => getSelectValue(val[0], 'end_date_hour')}
              >
                <SelectInput selected={poll.end_date_hour} />
                <SelectOptions>
                  {[...Array(24).keys()].map((elem, index) => (
                    <SelectOption
                      value={`0${index}`.slice(-2)}
                      key={`hour${index}`}
                    >
                      {`0${index}`.slice(-2)}
                    </SelectOption>
                  ))}
                </SelectOptions>
              </Select>
            </Col>
            <Col md="4">
              <label>{formatMessage(messages.Minutes)}:</label>
              <Select
                getValue={(val) => getSelectValue(val[0], 'end_date_minute')}
              >
                <SelectInput selected={poll.end_date_minute} />
                <SelectOptions>
                  {[...Array(60).keys()].map((elem, index) => (
                    <SelectOption
                      value={`0${index}`.slice(-2)}
                      key={`minute${index}`}
                    >
                      {`0${index}`.slice(-2)}
                    </SelectOption>
                  ))}
                </SelectOptions>
              </Select>
            </Col>
          </Row>
        ) : (
          <MDBBtn size="sm" onClick={endDateEditClick}>
            {formatMessage(messages.EditEndDate)}
          </MDBBtn>
        )
      ) : null}
    </Col>
  );
};

export default injectIntl(Step4);
