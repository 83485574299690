import React, { Fragment } from 'react';
import { injectIntl } from 'react-intl';
import { Col, Row, InputSwitch, MDBIcon } from 'mdbreact';
import ReactTooltip from 'react-tooltip';

import messages from '../messages';
import ImageInput from '../../ImageInput';
import TextInput from '../../TextInput';

let Step3 = ({
  poll,
  handleSwitchChange,
  handleTextChangeWithoutEmbedly,
  setSRC,
  intl,
}) => {
  const { formatMessage } = intl;

  return (
    <Col md="12">
      <ReactTooltip effect="solid" multiline={true} />
      <Row>
        <Col>
          <label>
            {formatMessage(messages.HidePerc)}{' '}
            <span data-tip={formatMessage(messages.HidePercTip)}>
              <MDBIcon far icon="question-circle" />
            </span>
          </label>
        </Col>
        <Col>
          <InputSwitch
            onChange={(e) => handleSwitchChange(e, 'hide_results')}
            checked={poll.hide_results === 1}
            labelLeft={formatMessage(messages.Off)}
            labelRight={formatMessage(messages.On)}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <label>
            {formatMessage(messages.HideVoteCounter)}{' '}
            <span data-tip={formatMessage(messages.HideVoteCounterTip)}>
              <MDBIcon far icon="question-circle" />
            </span>
          </label>
        </Col>
        <Col>
          <InputSwitch
            onChange={(e) => handleSwitchChange(e, 'hide_counter')}
            checked={poll.hide_counter !== 1}
            labelLeft={formatMessage(messages.Off)}
            labelRight={formatMessage(messages.On)}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <label>
            {formatMessage(messages.Instant)}{' '}
            <span data-tip={formatMessage(messages.InstantTip)}>
              <MDBIcon far icon="question-circle" />
            </span>
          </label>
        </Col>
        <Col>
          <InputSwitch
            onChange={(e) => handleSwitchChange(e, 'answer_time_limit')}
            checked={poll.answer_time_limit < 0}
            labelLeft={formatMessage(messages.Off)}
            labelRight={formatMessage(messages.On)}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <label>
            {formatMessage(messages.Shuffle)}{' '}
            <span data-tip={formatMessage(messages.ShuffleTip)}>
              <MDBIcon far icon="question-circle" />
            </span>
          </label>
        </Col>
        <Col>
          <InputSwitch
            onChange={(e) => handleSwitchChange(e, 'rotate_answers')}
            checked={poll.rotate_answers === 1}
            labelLeft={formatMessage(messages.Off)}
            labelRight={formatMessage(messages.On)}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <label>
            {formatMessage(messages.ShuffleLast)}{' '}
            <span data-tip={formatMessage(messages.ShuffleLastTip)}>
              <MDBIcon far icon="question-circle" />
            </span>
          </label>
        </Col>
        <Col>
          <InputSwitch
            onChange={(e) => handleSwitchChange(e, 'rotate_answers_last')}
            checked={poll.rotate_answers_last === 1}
            labelLeft={formatMessage(messages.Off)}
            labelRight={formatMessage(messages.On)}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <label>{formatMessage(messages.VoteNotifs)}</label>
        </Col>
        <Col>
          <InputSwitch
            onChange={(e) => handleSwitchChange(e, 'auto_reports_enabled')}
            checked={poll.auto_reports_enabled === 1}
            labelLeft={formatMessage(messages.Off)}
            labelRight={formatMessage(messages.On)}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <label>
            {formatMessage(messages.Hidden)}{' '}
            <span data-tip={formatMessage(messages.HiddenTip)}>
              <MDBIcon far icon="question-circle" />
            </span>
          </label>
        </Col>
        <Col>
          <InputSwitch
            onChange={(e) => handleSwitchChange(e, 'privacy')}
            checked={poll.privacy !== 'public'}
            labelLeft={formatMessage(messages.Off)}
            labelRight={formatMessage(messages.On)}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <label>
            {formatMessage(messages.PlayOnce)}{' '}
            <span data-tip={formatMessage(messages.PlayOnceTip)}>
              <MDBIcon far icon="question-circle" />
            </span>
          </label>
        </Col>
        <Col>
          <InputSwitch
            onChange={(e) => handleSwitchChange(e, 'play_once')}
            checked={!!poll.play_once}
            labelLeft={formatMessage(messages.Off)}
            labelRight={formatMessage(messages.On)}
          />
        </Col>
      </Row>
      {poll.play_once ? (
        <Fragment>
          <Row>
            <Col>
              <TextInput
                type="text"
                maxLength={250}
                label={`${formatMessage(messages.PlayOnceMsg)}`}
                className="mt-3 mb-1"
                name="play_once_msg"
                value={poll.play_once_msg}
                onChange={handleTextChangeWithoutEmbedly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextInput
                type="text"
                maxLength={250}
                label={`${formatMessage(messages.PlayOnceLink)}`}
                className="mt-3 mb-1"
                name="play_once_link"
                value={poll.play_once_link}
                onChange={handleTextChangeWithoutEmbedly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextInput
                type="text"
                maxLength={250}
                label={`${formatMessage(messages.PlayOnceBtn)}`}
                className="mt-3 mb-1"
                name="play_once_btn"
                value={poll.play_once_btn}
                onChange={handleTextChangeWithoutEmbedly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <ImageInput
                title={formatMessage(messages.PlayOnceImg)}
                setSRC={setSRC}
                target={'play_once_img'}
              />
            </Col>
          </Row>
        </Fragment>
      ) : null}
    </Col>
  );
};

export default injectIntl(Step3);
