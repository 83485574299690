import React, { Fragment } from 'react';
import { injectIntl } from 'react-intl';
import {
  MDBCol,
  MDBRow,
  MDBSelect,
  MDBSelectInput,
  MDBSelectOption,
  MDBSelectOptions,
  MDBInput,
  MDBSwitch,
  MDBIcon,
  MDBBtn,
} from 'mdbreact';
import ReactTooltip from 'react-tooltip';

import messages from '../messages';
import ImageInput from '../../ImageInput';
import LeadSelector from '../LeadSelector';

const Step1 = ({
  intl: { formatMessage },
  data,
  getSelectValue,
  handleTextChange,
  setSRC,
  expanded,
  toggle,
  handleSwitchChange,
  leadSelect,
}) => {
  let remTitleLimit =
    250 -
    (data.title && data.title.length && data.title.length > 0
      ? data.title.length
      : 0);

  const inputSwitcher = (text, target, value, tooltip) => (
    <MDBRow style={{ marginTop: '1.5rem' }}>
      <MDBCol>
        <label>
          {text}{' '}
          <span data-tip={tooltip}>
            <MDBIcon far icon="question-circle" />
          </span>
          <ReactTooltip effect="solid" multiline={true} />
        </label>
      </MDBCol>
      <MDBCol>
        <MDBSwitch
          onChange={(e) => handleSwitchChange(e, target)}
          checked={value}
          labelLeft={formatMessage(messages.Off)}
          labelRight={formatMessage(messages.On)}
        />
      </MDBCol>
    </MDBRow>
  );

  return (
    <MDBCol md="12">
      <ReactTooltip effect="solid" multiline={true} id="step1" />
      <MDBCol md="12">
        <MDBInput
          type="textarea"
          maxLength={250}
          label={`${formatMessage(messages.SetTitle)} - ${remTitleLimit}`}
          className="mt-3"
          name="title"
          value={data.title}
          onChange={handleTextChange}
        />
      </MDBCol>
      <MDBCol md="12">
        <MDBInput
          label={formatMessage(messages.Description)}
          className="mt-3"
          name="desc"
          value={data.desc}
          onChange={handleTextChange}
          type="textarea"
        />
      </MDBCol>
      <ImageInput
        title={formatMessage(messages.SetImage)}
        setSRC={setSRC}
        src={data.background}
        target={'background'}
      />
      <MDBCol md="4">
        <LeadSelector index={0} leadSelect={leadSelect} id={data.lead_id} />
      </MDBCol>
      {expanded ? (
        <MDBCol md="12">
          <MDBRow style={{ margin: '1.5rem' }}>
            <MDBCol className="text-center">
              <MDBBtn color="primary" onClick={toggle}>
                <strong>{`- ${formatMessage(messages.Collapse)}`}</strong>
              </MDBBtn>
            </MDBCol>
          </MDBRow>
          {inputSwitcher(
            formatMessage(messages.Hidden),
            'privacy',
            data.privacy !== 'public',
            formatMessage(messages.HiddenTip)
          )}
          {inputSwitcher(
            formatMessage(messages.HideVoteCounter),
            'hide_counter',
            data.hide_counter === 0,
            formatMessage(messages.HideVoteCounterTip)
          )}
          {inputSwitcher(
            formatMessage(messages.HidePerc),
            'hide_results',
            data.hide_results === 1,
            formatMessage(messages.HidePercTip)
          )}
          {inputSwitcher(
            formatMessage(messages.SkipStart),
            'skip_start',
            data.skip_start === 1,
            formatMessage(messages.SkipStartTip)
          )}
          {inputSwitcher(
            formatMessage(messages.SkipResult),
            'skip_result',
            data.skip_result === 1,
            formatMessage(messages.SkipResultTip)
          )}
          {inputSwitcher(
            formatMessage(messages.CalculatorSet),
            'is_calculator',
            data.is_calculator === 1,
            formatMessage(messages.CalculatorSetTip)
          )}
          {inputSwitcher(
            formatMessage(messages.PlayOnce),
            'play_once',
            data.play_once === 1,
            formatMessage(messages.PlayOnceTip)
          )}
          {inputSwitcher(
            formatMessage(messages.DisplayRepeat),
            'display_repeat',
            data.display_repeat === 1,
            formatMessage(messages.DisplayRepeatTip)
          )}
          {data.play_once ? (
            <Fragment>
              <MDBRow>
                <MDBCol>
                  <MDBSelect
                    getValue={(val) =>
                      getSelectValue(val[0], 'play_once_strategy')
                    }
                    label={
                      data.play_once_strategy === 'result'
                        ? formatMessage(messages.ResultOptionDesc)
                        : formatMessage(messages.StartOptionDesc)
                    }
                  >
                    <MDBSelectInput />
                    <MDBSelectOptions>
                      <MDBSelectOption
                        value={'result'}
                        checked={data.play_once_strategy === 'result'}
                      >
                        {formatMessage(messages.ResultOption)}
                      </MDBSelectOption>
                      <MDBSelectOption
                        value={'start'}
                        checked={data.play_once_strategy === 'start'}
                      >
                        {formatMessage(messages.StartOption)}
                      </MDBSelectOption>
                    </MDBSelectOptions>
                  </MDBSelect>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <MDBInput
                    type="text"
                    maxLength={250}
                    label={`${formatMessage(messages.PlayOnceMsg)}`}
                    className="mt-3 mb-1"
                    name="play_once_msg"
                    value={data.play_once_msg}
                    onChange={handleTextChange}
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <MDBInput
                    type="text"
                    maxLength={250}
                    label={`${formatMessage(messages.PlayOnceLink)}`}
                    className="mt-3 mb-1"
                    name="play_once_link"
                    value={data.play_once_link}
                    onChange={handleTextChange}
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <MDBInput
                    type="text"
                    maxLength={250}
                    label={`${formatMessage(messages.PlayOnceBtn)}`}
                    className="mt-3 mb-1"
                    name="play_once_btn"
                    value={data.play_once_btn}
                    onChange={handleTextChange}
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <ImageInput
                    title={formatMessage(messages.PlayOnceImg)}
                    setSRC={setSRC}
                    target={'play_once_img'}
                  />
                </MDBCol>
              </MDBRow>
            </Fragment>
          ) : null}
          <MDBRow style={{ marginTop: '1.5rem' }}>
            <MDBCol>
              <label>
                {formatMessage(messages.EndDate)}{' '}
                <span
                  data-tip={formatMessage(messages.EndDateTip)}
                  data-for="step1"
                >
                  <MDBIcon far icon="question-circle" />
                </span>
              </label>
            </MDBCol>
            <MDBCol>
              <MDBSwitch
                onChange={(e) => handleSwitchChange(e, 'end_date')}
                checked={data.end_date ? true : false}
                labelLeft={formatMessage(messages.Off)}
                labelRight={formatMessage(messages.On)}
              />
            </MDBCol>
          </MDBRow>
          {data.end_date ? (
            <MDBRow style={{ marginTop: '1.5rem' }}>
              <MDBCol md="4">
                <label>{formatMessage(messages.Days)}:</label>
                <MDBSelect
                  getValue={(val) => getSelectValue(val[0], 'end_date_day')}
                >
                  <MDBSelectInput selected={data.end_date_day} />
                  <MDBSelectOptions>
                    {[...Array(32).keys()].map((elem, index) => (
                      <MDBSelectOption
                        value={`0${index}`.slice(-2)}
                        key={`day${index}`}
                      >
                        {`0${index}`.slice(-2)}
                      </MDBSelectOption>
                    ))}
                  </MDBSelectOptions>
                </MDBSelect>
              </MDBCol>
              <MDBCol md="4">
                <label>{formatMessage(messages.Hours)}:</label>
                <MDBSelect
                  getValue={(val) => getSelectValue(val[0], 'end_date_hour')}
                >
                  <MDBSelectInput selected={data.end_date_hour} />
                  <MDBSelectOptions>
                    {[...Array(24).keys()].map((elem, index) => (
                      <MDBSelectOption
                        value={`0${index}`.slice(-2)}
                        key={`hour${index}`}
                      >
                        {`0${index}`.slice(-2)}
                      </MDBSelectOption>
                    ))}
                  </MDBSelectOptions>
                </MDBSelect>
              </MDBCol>
              <MDBCol md="4">
                <label>{formatMessage(messages.Minutes)}:</label>
                <MDBSelect
                  getValue={(val) => getSelectValue(val[0], 'end_date_minute')}
                >
                  <MDBSelectInput selected={data.end_date_minute} />
                  <MDBSelectOptions>
                    {[...Array(60).keys()].map((elem, index) => (
                      <MDBSelectOption
                        value={`0${index}`.slice(-2)}
                        key={`minute${index}`}
                      >
                        {`0${index}`.slice(-2)}
                      </MDBSelectOption>
                    ))}
                  </MDBSelectOptions>
                </MDBSelect>
              </MDBCol>
            </MDBRow>
          ) : null}
          <MDBRow>
            <MDBCol md="12">
              <MDBInput
                label={formatMessage(messages.LoadingNextQuestionLabel)}
                className="mt-3"
                name="loading_next_question_label"
                value={data.loading_next_question_label ?? ''}
                onChange={handleTextChange}
                type="text"
              />
            </MDBCol>
            <MDBCol md="12">
              <MDBInput
                label={formatMessage(messages.LoadingResultLabel)}
                className="mt-3"
                name="loading_result_label"
                value={data.loading_result_label ?? ''}
                onChange={handleTextChange}
                type="text"
              />
            </MDBCol>
          </MDBRow>
        </MDBCol>
      ) : (
        <MDBRow style={{ margin: '1.5rem' }}>
          <MDBCol className="text-center">
            <MDBBtn color="primary" onClick={toggle}>
              <strong>{`+ ${formatMessage(messages.Expand)}`}</strong>
            </MDBBtn>
          </MDBCol>
        </MDBRow>
      )}
      <ImageInput
        title={formatMessage(messages.EmbedBg)}
        setSRC={setSRC}
        src={data.embed_background}
        target={'embed_background'}
      />
      <ImageInput
        title={formatMessage(messages.CustomLogo)}
        ratio={2}
        setSRC={setSRC}
        src={data.embed_footer_img}
        target={'embed_footer_img'}
      />
      <MDBInput
        type="text"
        maxLength={250}
        label={`${formatMessage(messages.FooterLink)}`}
        className="mt-3 mb-1"
        name="embed_footer_url"
        value={data.embed_footer_url || ''}
        onChange={handleTextChange}
      />
    </MDBCol>
  );
};

export default injectIntl(Step1);
