import React, { useState, Fragment } from 'react';
import { injectIntl } from 'react-intl';
import {
  MDBBtn,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBContainer,
  MDBInput,
} from 'mdbreact';
import history from '../../core/history';

import messages from './messages';

const AnswerVotersModal = ({
  modal,
  toggleModal,
  data,
  intl: { formatMessage },
}) => {
  const [checkedAnswers, setCheckedAnswers] = useState([]);

  const questions = data.t === 'poll' ? [data] : data.questions;

  const getReport = () => {
    history.push(`/reports`, {
      id: checkedAnswers,
      type: 'answer-voters',
    });
  };

  const checkboxChanged = (checked, id) => {
    if (checked) {
      setCheckedAnswers([...checkedAnswers, id]);
    } else {
      setCheckedAnswers(checkedAnswers.filter((a) => a !== id));
    }
  };

  return (
    <MDBContainer>
      <MDBModal isOpen={modal} toggle={toggleModal} centered size="lg">
        <MDBModalHeader toggle={toggleModal}>
          {formatMessage(messages.AnswerVotersReport)}
        </MDBModalHeader>
        <MDBModalBody>
          <MDBContainer>
            {questions.map((q, i) => (
              <Fragment key={i}>
                <h6 style={{ marginTop: '10px' }}>{`${i + 1}. ${q.title}`}</h6>
                {q.answers.map((a, ind) => (
                  <MDBInput
                    key={ind}
                    label={a.title}
                    type="checkbox"
                    id={`checkbox-${a.id}`}
                    checked={checkedAnswers.includes(a.id)}
                    onChange={(e) => checkboxChanged(e.target.checked, a.id)}
                  />
                ))}
              </Fragment>
            ))}
          </MDBContainer>
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn color="secondary" onClick={toggleModal}>
            {formatMessage(messages.Cancel)}
          </MDBBtn>
          <MDBBtn
            color="secondary"
            onClick={getReport}
            disabled={checkedAnswers.length === 0}
          >
            {formatMessage(messages.GetReport)}
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal>
    </MDBContainer>
  );
};

export default injectIntl(AnswerVotersModal);
