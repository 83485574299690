import React, { useRef, useState } from 'react';
import { Col, InputFile, Row, Spinner } from 'mdbreact';
import ReactCrop from 'react-image-crop';
import { FormattedMessage } from 'react-intl';

import 'react-image-crop/dist/ReactCrop.css';

import './ImageInput.css';
import messages from './messages';

let CardInput = ({
  onDelete,
  disabled,
  src,
  setSrc: upload,
  title,
  fileChange,
  formatMessage,
  isLoading,
}) => {
  const cropContainerRef = useRef();

  const [isCropping, setIsCropping] = useState(false);
  const [crop, setCrop] = useState({
    x: 10,
    y: 10,
    aspect: 9 / 5,
    width: 100,
    height: 100,
  });
  const [scale, setScale] = useState({ x: 1, y: 1 });

  const onCrop = (event) => {
    event.preventDefault();

    // do crop
    const canvas = document.createElement('canvas');
    canvas.width = crop.width * scale.x;
    canvas.height = crop.height * scale.y;
    const ctx = canvas.getContext('2d');

    let img = new Image();
    img.setAttribute('crossOrigin', 'anonymous');
    img.src = src;
    img.onload = async () => {
      ctx.drawImage(
        img,
        crop.x * scale.x,
        crop.y * scale.y,
        crop.width * scale.x,
        crop.height * scale.y,
        0,
        0,
        crop.width * scale.x,
        crop.height * scale.y
      );

      const b64 = canvas.toDataURL('image/jpeg');
      upload(b64);
      setIsCropping(false);
    };
  };

  const onLoaded = (image) => {
    if (image.width <= 0) {
      // scale is INF if image.width is 0
      const width = cropContainerRef.current.offsetWidth; // as image.width
      const scale = image.naturalWidth / width;

      setScale({
        x: scale,
        y: scale,
      });
    } else {
      setScale({
        x: image.naturalWidth / image.width,
        y: image.naturalHeight / image.height,
      });
    }
  };

  return (
    <Col md="12">
      <Row>
        <InputFile
          getValue={fileChange}
          btnTitle={formatMessage(messages.ChooseImage)}
          textFieldTitle={title}
        />
      </Row>
      {isLoading && (
        <div className="text-center">
          <Spinner big blue />
        </div>
      )}
      {src && (
        <div>
          <div className="row" ref={cropContainerRef}>
            {!isLoading && (
              <ReactCrop
                className="w-100"
                src={src}
                crop={isCropping ? crop : {}}
                onChange={(crop) => setCrop(crop)}
                onImageLoaded={onLoaded}
                disabled={disabled}
              />
            )}
          </div>
          <div className="row mt-2">
            <div className="btn-group col-6 px-0 d-block">
              {src && isCropping ? (
                <button
                  className="btn btn-default btn-sm btn-rounded"
                  onClick={onCrop}
                >
                  <FormattedMessage {...messages.CropImage} />
                </button>
              ) : (
                <button
                  className="btn btn-primary btn-sm btn-rounded"
                  onClick={() => setIsCropping(true)}
                >
                  <FormattedMessage {...messages.CropImage} />
                </button>
              )}
              {src && isCropping && (
                <button
                  className="btn btn-warning btn-sm btn-rounded"
                  onClick={() => setIsCropping(false)}
                >
                  <FormattedMessage {...messages.Cancel} />
                </button>
              )}
            </div>
            <div className="btn-group col-6 px-0 d-block">
              {src && (
                <button
                  className="btn btn-danger btn-sm btn-rounded float-right"
                  onClick={onDelete}
                >
                  <FormattedMessage {...messages.DeleteImage} />
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </Col>
  );
};

export default CardInput;
