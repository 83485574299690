import { defineMessages } from 'react-intl.macro';

export default defineMessages({
  Settings: {
    id: 'Settings.Settings',
    defaultMessage: 'Settings',
  },
  ChangePass: {
    id: 'Settings.ChangePass',
    defaultMessage: 'Change Password',
  },
  UserInfo: {
    id: 'Settings.UserInfo',
    defaultMessage: 'User Information',
  },
  UploadProfilePic: {
    id: 'Settings.UploadProfilePic',
    defaultMessage: 'Upload Profile Picture',
  },
  UploadCoverPic: {
    id: 'Settings.UploadCoverPic',
    defaultMessage: 'Upload Cover Picture',
  },
  FirstName: {
    id: 'Settings.FirstName',
    defaultMessage: 'First Name',
  },
  LastName: {
    id: 'Settings.LastName',
    defaultMessage: 'Last Name',
  },
  Username: {
    id: 'Settings.Username',
    defaultMessage: 'Username',
  },
  Email: {
    id: 'Settings.Email',
    defaultMessage: 'Email',
  },
  Bio: {
    id: 'Settings.Bio',
    defaultMessage: 'Bio',
  },
  Location: {
    id: 'Settings.Location',
    defaultMessage: 'Location',
  },
  BirthDate: {
    id: 'Settings.BirthDate',
    defaultMessage: 'Birth Date',
  },
  Gender: {
    id: 'Settings.Gender',
    defaultMessage: 'Gender',
  },
  Male: {
    id: 'Settings.Male',
    defaultMessage: 'Male',
  },
  Female: {
    id: 'Settings.Female',
    defaultMessage: 'Female',
  },
  ShowMyVotes: {
    id: 'Settings.ShowMyVotes',
    defaultMessage: 'Show My Votes',
  },
  HideVoteCounts: {
    id: 'Settings.HideVoteCounts',
    defaultMessage: 'Hide Vote Counts',
  },
  Follower: {
    id: 'Settings.Follower',
    defaultMessage: 'Follower Notifications',
  },
  Vote: {
    id: 'Settings.Vote',
    defaultMessage: 'Vote Notifications',
  },
  Status: {
    id: 'Settings.Status',
    defaultMessage: 'Status Notifications',
  },
  Comment: {
    id: 'Settings.Comment',
    defaultMessage: 'Comment Notifications',
  },
  Thread: {
    id: 'Settings.Thread',
    defaultMessage: 'Thread Notifications',
  },
  Old: {
    id: 'Settings.Old',
    defaultMessage: 'Old Password',
  },
  New: {
    id: 'Settings.New',
    defaultMessage: 'New Password',
  },
  NewAgain: {
    id: 'Settings.NewAgain',
    defaultMessage: 'New Password (Again)',
  },
  Submit: {
    id: 'Settings.Submit',
    defaultMessage: 'Submit',
  },
  Off: {
    id: 'Settings.Off',
    defaultMessage: 'Off',
  },
  On: {
    id: 'Settings.On',
    defaultMessage: 'On',
  },
  SuccessSubmit: {
    id: 'Settings.SuccessSubmit',
    defaultMessage: 'Settings are saved successfully.',
  },
});
