import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { injectIntl } from 'react-intl';
import {
  MDBContainer,
  MDBTooltip,
  MDBSpinner,
  MDBIcon,
  MDBCard,
  MDBCardBody,
  MDBBtn,
  MDBSwitch,
  MDBRow,
  MDBCol,
} from 'mdbreact';
import { getPlayerList, getTriviaActions } from '../../api';
import Pagination from '../Pagination';
import history from '../../core/history';

import messages from './messages';
import SectionWithTitle from '../Common/SectionWithTitle';

const PlayerList = ({
  intl: { formatMessage },
  match: {
    params: { id },
  },
}) => {
  const [playerList, setPlayerList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageData, setPageData] = useState({
    totalSize: 100,
    page: 1,
    sizePerPage: 50,
    lastPage: null,
  });
  const [actions, setActions] = useState([]);
  const [winnersOnly, setWinnersOnly] = useState(0);

  const fetchPlayerListAndActions = useCallback(async () => {
    setLoading(true);
    const response = await getPlayerList(
      id,
      pageData.page,
      pageData.sizePerPage,
      winnersOnly
    );
    const response2 = await getTriviaActions(id);
    setPlayerList(response.data.data);
    setLoading(false);
    setPageData((p) => {
      return {
        page: p.page,
        sizePerPage: p.sizePerPage,
        totalSize: response.data.total,
        lastPage: response.data.last_page,
      };
    });
    setActions(response2.data);
  }, [pageData.page, pageData.sizePerPage, id, winnersOnly]);

  const handlePageChange = (page, sizePerPage) => {
    setPageData({
      ...pageData,
      page,
      sizePerPage,
    });
  };

  useEffect(() => {
    fetchPlayerListAndActions();
  }, [fetchPlayerListAndActions]);

  return (
    <MDBContainer fluid>
      <section className="mb-5">
        <MDBCard>
          <MDBCardBody className="d-flex justify-content-between">
            <h4 className="h4-responsive mt-3">Game Details: #{id}</h4>
            <div>
              <MDBBtn color="default" onClick={() => history.push('/trivia')}>
                <MDBIcon icon="arrow-left" className="left" /> Back To Game List
              </MDBBtn>
              <MDBBtn
                color="primary"
                onClick={() => history.push('/manage-trivia')}
              >
                {formatMessage(messages.CreateNewTrivia)}
              </MDBBtn>
            </div>
          </MDBCardBody>
        </MDBCard>
      </section>
      <SectionWithTitle title={`${formatMessage(messages.Actions)} (#${id})`}>
        <div className="row">
          <div className="col-12">
            <table className="table table-striped table-bordered table-sm table-fixed-header">
              <thead>
                <tr>
                  <th className="th-sm">{'Action'}</th>
                  <th className="th-sm">{'Created At'}</th>
                </tr>
              </thead>
              <tbody>
                {actions.map((row, i) => (
                  <tr key={`actions${i}`}>
                    <td>
                      <MDBTooltip placement="top" domElement>
                        <span>{row.action}</span>
                        <div>{row.action}</div>
                      </MDBTooltip>
                    </td>
                    <td>
                      <MDBTooltip placement="top" domElement>
                        <span>{row.created_at}</span>
                        <div>{row.created_at}</div>
                      </MDBTooltip>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </SectionWithTitle>

      <SectionWithTitle
        title={`${formatMessage(messages.PlayerList)} (#${id})`}
      >
        {loading && playerList.length === 0 && <MDBSpinner />}
        {playerList.length > 0 && (
          <Fragment>
            <MDBRow>
              <MDBCol md="2">
                <label>Winners Only</label>
              </MDBCol>
              <MDBCol md="2">
                <MDBSwitch
                  checked={winnersOnly === 1 ? true : false}
                  onChange={(e) => setWinnersOnly(e.target.checked ? 1 : 0)}
                />
              </MDBCol>
            </MDBRow>
            <Pagination
              last={pageData.lastPage}
              active={pageData.page}
              size={pageData.sizePerPage}
              handlePageChange={handlePageChange}
            />
            <div className="row">
              {loading ? (
                <MDBSpinner />
              ) : (
                <div className="col-12">
                  <table className="table table-striped table-bordered table-sm table-fixed-header">
                    <thead>
                      <tr>
                        <th className="th-sm">{'User ID'}</th>
                        <th className="th-sm">{'User Name'}</th>
                        <th className="th-sm">{'Points'}</th>
                        <th className="th-sm">{'Email'}</th>
                        <th className="th-sm">{'Phone'}</th>
                        <th className="th-sm">{'Donation ID'}</th>
                        <th className="th-sm">{'Donation Amount'}</th>
                        <th className="th-sm">{'Donation Currency'}</th>
                        <th className="th-sm">{'Comment'}</th>
                        <th className="th-sm">{'TC Accepted'}</th>
                        <th className="th-sm">{'Created At'}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {playerList.map((row, i) => (
                        <tr key={i}>
                          <td>
                            <MDBTooltip placement="top" domElement>
                              <span>{row.user_id}</span>
                              <div>{row.user_id}</div>
                            </MDBTooltip>
                          </td>
                          <td>
                            <MDBTooltip placement="top" domElement>
                              <span>{row.nick}</span>
                              <div>{row.nick}</div>
                            </MDBTooltip>
                          </td>
                          <td>
                            <MDBTooltip placement="top" domElement>
                              <span>{row.points}</span>
                              <div>{row.points}</div>
                            </MDBTooltip>
                          </td>
                          <td>
                            <MDBTooltip placement="top" domElement>
                              <span>{row.email}</span>
                              <div>{row.email}</div>
                            </MDBTooltip>
                          </td>
                          <td>
                            <MDBTooltip placement="top" domElement>
                              <span>{row.phone}</span>
                              <div>{row.phone}</div>
                            </MDBTooltip>
                          </td>
                          <td>
                            <MDBTooltip placement="top" domElement>
                              <span>{row.donation_id}</span>
                              <div>{row.donation_id}</div>
                            </MDBTooltip>
                          </td>
                          <td>
                            <MDBTooltip placement="top" domElement>
                              <span>{row.donation_amount}</span>
                              <div>{row.donation_amount}</div>
                            </MDBTooltip>
                          </td>
                          <td>
                            <MDBTooltip placement="top" domElement>
                              <span>{row.donation_currency}</span>
                              <div>{row.donation_currency}</div>
                            </MDBTooltip>
                          </td>
                          <td>
                            <MDBTooltip placement="top" domElement>
                              <span>{row.comment}</span>
                              <div>{row.comment}</div>
                            </MDBTooltip>
                          </td>
                          <td>
                            <MDBTooltip placement="top" domElement>
                              <span>{row.tc}</span>
                              <div>{row.tc}</div>
                            </MDBTooltip>
                          </td>
                          <td>
                            <MDBTooltip placement="top" domElement>
                              <span>{row.created_at}</span>
                              <div>{row.created_at}</div>
                            </MDBTooltip>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </Fragment>
        )}
      </SectionWithTitle>
    </MDBContainer>
  );
};

export default injectIntl(PlayerList);
