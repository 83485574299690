import React, { Fragment, useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  toast,
  Container,
  MDBNav,
  MDBNavItem,
  MDBLink,
  Fa,
  MDBIcon,
} from 'mdbreact';
import api from '../../../api/client';
import ReactTooltip from 'react-tooltip';

import history from '../../../core/history';

import messages from '../messages';
import Step1 from './Step1';
import Result from './Result';
import Step2 from './Step2';
import ViewConditions from './ViewConditions';
import Prompt from '../Prompt';

const initialState = {
  title: '',
  background: '',
  desc: '',
  loading_next_question_label: '',
  loading_result_label: '',
  results: [
    {
      title: '',
    },
  ],
  privacy: 'private',
  hide_counter: 0,
  hide_results: 0,
  play_once: 0,
  play_once_strategy: 'result',
  play_once_msg: null,
  play_once_img: null,
  play_once_btn: null,
  play_once_link: null,
  embed_background: '',
  embed_footer_img: '',
  embed_footer_url: null,
  end_date: 0,
  end_date_day: '00',
  end_date_hour: '00',
  end_date_minute: '00',
  skip_start: 0,
  skip_result: 0,
  display_repeat: 0,
  lead_id: null,
};

const initialQuestion = {
  title: '',
  answer_type: 'text',
  answers: [
    {
      title: '',
      background: '',
    },
    {
      title: '',
      background: '',
    },
  ],
  set_conditions: '',
};

const initialScoreAnswers = [
  {
    title: '1',
    background: '',
  },
  {
    title: '2',
    background: '',
  },
  {
    title: '3',
    background: '',
  },
  {
    title: '4',
    background: '',
  },
  {
    title: '5',
    background: '',
  },
];

const SetCreateEdit = ({
  intl: { formatMessage },
  user,
  match: {
    params: { id },
  },
}) => {
  const [step, setStep] = useState(1);
  const [data, setData] = useState(initialState);
  const [expanded, setExpanded] = useState(false);
  const [saving, setSaving] = useState(false);
  const [questions, setQuestions] = useState([initialQuestion]);
  const [questionIndex, setQI] = useState(0);
  const [savingQuestion, setSavingQuestion] = useState(false);
  const [deletingQuestion, setDeletingQuestion] = useState(false);
  const [showEveryone, setShowEveryone] = useState(true);
  const [dirty, setDirty] = useState(false);
  const [prompt, setPrompt] = useState(false);
  const [settingsChanged, setSettingsChanged] = useState(false);
  const [changingAnswer, setChangingAnswer] = useState({});

  useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await api.get(`/edit/set/${id}`);
        setData(data);
        setQuestions(data.questions);
      } catch (e) {
        if (e && e.response && e.response.status === 404) {
          history.push('/content');
          toast.error(formatMessage(messages.NoContentFound));
        }
      }
    }

    async function fetchSettings() {
      const { data: settings } = await api.get(
        `/publisher-content-settings/set`
      );
      setData((data) => {
        return { ...data, ...settings, end_date: 0 };
      });
    }

    if (id) {
      fetchData();
    } else {
      if (user?.trivia_credit < 1) {
        history.push('/');
      }
      fetchSettings();
    }
  }, [id, formatMessage, user.trivia_credit]);

  useEffect(() => {
    const activeQ = questions[questionIndex];

    if (activeQ && activeQ.id && activeQ.set_conditions.length > 0) {
      setShowEveryone(false);
    }
  }, [questions, questionIndex]);

  useEffect(() => {
    const { results } = data;
    if (data.is_calculator) {
      setSaving(
        results.some(
          (r) => parseInt(r.min_c || 0, 10) > parseInt(r.max_c || 0, 10)
        )
      );
    }
  }, [data, data.results]);

  const saveSet = async (e, finish) => {
    if (settingsChanged) {
      togglePrompt();
    }
    setSaving(true);
    let dataWoQ = Object.assign({}, data);
    delete dataWoQ.questions;
    try {
      const { data: res } = await api.post(
        dataWoQ.id ? `/edit/set` : `/create/set`,
        dataWoQ
      );
      setData(data.id ? res : res.set);
      setSaving(false);
      if (finish) {
        history.push('/content');
      } else {
        setStep(2);
      }
      toast.success(formatMessage(messages.SetSaved));
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } catch (e) {
      toast.error('Error occured!');
      setSaving(false);
    }
  };

  const saveQuestion = async () => {
    const question = questions[questionIndex];
    setSavingQuestion(true);
    if (dirty) {
      try {
        const { data: res } = await api.post(
          question.id ? `/edit/question` : `/create/set-question`,
          { ...question, parent_id: data.id }
        );
        let newQuestions = questions.map((q, i) => {
          if (questionIndex === i) {
            return {
              ...q,
              id: res.id,
              answers: q.answers.map((a, ai) => {
                return {
                  ...a,
                  id: res.answers[ai].id,
                };
              }),
            };
          }
          return q;
        });
        if (questionIndex === questions.length - 1) {
          newQuestions = [...newQuestions, initialQuestion];
        }
        setSavingQuestion(false);
        setQuestions(newQuestions);
        setQI(questionIndex + 1);
        setDirty(false);
        setShowEveryone(true);
        toast.success(formatMessage(messages.QuestionSaved));
        if (!id) {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }
      } catch (e) {
        toast.error('Error occured!');
        setSavingQuestion(false);
      }
    } else {
      if (questionIndex + 1 === questions.length) {
        addNewQuestionToTheEnd();
      } else {
        setQI(questionIndex + 1);
      }
      toast.success(formatMessage(messages.QuestionSaved));
      if (!id) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
      setShowEveryone(true);
      setSavingQuestion(false);
    }
  };

  const addNewQuestionToTheEnd = () => {
    if (dirty && !window.confirm(formatMessage(messages.DidntSaveCurrent)))
      return;
    const newQuestions = [...questions, initialQuestion];
    setQuestions(newQuestions);
    setQI(questions.length);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const getSelectValue = (value, name) => {
    if (value) {
      setData({ ...data, [name]: value });
      setSettingsChanged(true);
    }
  };

  const handleTextChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSwitchChange = (e, name) => {
    if (name !== 'end_date') {
      setSettingsChanged(true);
    }

    if (name === 'privacy') {
      setData({
        ...data,
        [name]: e.target.value === 'true' ? 'public' : 'private',
      });
    } else if (name === 'hide_counter') {
      setData({ ...data, [name]: e.target.value === 'true' ? 1 : 0 });
    } else if (name === 'hide_results') {
      setData({ ...data, [name]: e.target.value === 'true' ? 0 : 1 });
    } else if (
      name === 'play_once' ||
      name === 'skip_start' ||
      name === 'skip_result' ||
      name === 'is_calculator' ||
      name === 'display_repeat'
    ) {
      setData({ ...data, [name]: e.target.value === 'true' ? 0 : 1 });
    } else if (name === 'end_date') {
      setData({
        ...data,
        [name]: e.target.value === 'true' ? 0 : 1,
        end_date_day: e.target.value === 'true' ? '00' : '30',
        end_date_hour: '00',
        end_date_minute: '00',
      });
    }
  };

  const setSRC = (src, target) => {
    if (target && target.length > 0) {
      setData({ ...data, [target]: src });
    } else {
      setData({ ...data, src });
    }
  };

  const setResultImg = (value, index) => {
    setData({
      ...data,
      results: data.results.map((r, i) => {
        if (index === i) {
          return {
            ...r,
            background: value,
          };
        }
        return r;
      }),
    });
    setDirty(true);
  };

  const handleResultTextChange = (e, index) => {
    setData({
      ...data,
      results: data.results.map((r, i) => {
        if (index === i) {
          return {
            ...r,
            [e.target.name]: e.target.value,
          };
        }
        return r;
      }),
    });
    setDirty(true);
  };

  const handleResultSwitchChange = (e, index) => {
    setData({
      ...data,
      results: data.results.map((r, i) => {
        if (index === i) {
          return {
            ...r,
            dont_shorten: e.target.checked ? 1 : 0,
          };
        }
        return r;
      }),
    });
    setDirty(true);
  };

  const pixelCodeSelect = (i, pc) => {
    if (pc) {
      setData({
        ...data,
        results: data.results.map((r, ind) => {
          if (ind === i) {
            return {
              ...r,
              pixel_code_id: pc.id,
              pixel_code_name: pc.name,
            };
          }
          return r;
        }),
      });
    }
  };

  const clickPixelCodeSelect = (i, pc) => {
    if (pc) {
      setData({
        ...data,
        results: data.results.map((r, ind) => {
          if (ind === i) {
            return {
              ...r,
              click_pixel_code_id: pc.id,
              click_pixel_code_name: pc.name,
            };
          }
          return r;
        }),
      });
    }
  };

  const leadSelectSet = (i, lead) => {
    if (lead) {
      setData({
        ...data,
        lead_id: lead.id,
        lead_name: lead.name,
      });
    }
  };

  const leadSelect = (i, lead) => {
    if (lead) {
      setData({
        ...data,
        results: data.results.map((r, ind) => {
          if (ind === i) {
            return {
              ...r,
              lead_id: lead.id,
              lead_name: lead.name,
            };
          }
          return r;
        }),
      });
    }
  };

  const handleQuestionTitleChange = async (e) => {
    setQuestions(
      questions.map((q, i) => {
        if (questionIndex === i) {
          return {
            ...q,
            [e.target.name]: e.target.value,
          };
        }
        return q;
      })
    );
    setDirty(true);
  };

  const handleQuestionSwitchChange = async (e, name) => {
    if (name === 'allow_multiple_answers') {
      setQuestions(
        questions.map((q, i) => {
          if (questionIndex === i) {
            return {
              ...q,
              [name]: e.target.value === 'true' ? 0 : 1,
              max_multi_punch_answer: questions[questionIndex].answers.length,
            };
          }
          return q;
        })
      );
    } else if (name === 'rotate_answers') {
      setQuestions(
        questions.map((q, i) => {
          if (questionIndex === i) {
            return {
              ...q,
              rotate_answers: e.target.value === 'true' ? 0 : 1,
              rotate_answers_last: 0,
            };
          }
          return q;
        })
      );
    } else if (name === 'rotate_answers_last') {
      setQuestions(
        questions.map((q, i) => {
          if (questionIndex === i) {
            return {
              ...q,
              rotate_answers_last: e.target.value === 'true' ? 0 : 1,
              rotate_answers: 0,
            };
          }
          return q;
        })
      );
    } else if (name === 'free_text' || name === 'free_number') {
      setQuestions(
        questions.map((q, i) => {
          if (questionIndex === i) {
            return {
              ...q,
              answer_type: e.target.value === 'true' ? 'text' : name,
              answers: e.target.value === 'true' ? initialQuestion.answers : [],
            };
          }
          return q;
        })
      );
    } else if (name === 'autocomplete') {
      setQuestions(
        questions.map((q, i) => {
          if (questionIndex === i) {
            return {
              ...q,
              answer_type: e.target.value === 'true' ? 'text' : name,
            };
          }
          return q;
        })
      );
    }
    setDirty(true);
  };

  const getQuestionSelectValue = (value, name) => {
    if (value) {
      setQuestions(
        questions.map((q, i) => {
          if (questionIndex === i) {
            return {
              ...q,
              [name]: value,
            };
          }
          return q;
        })
      );
    }
    setDirty(true);
  };

  const setQuestionSRC = (src, target) => {
    if (src) {
      setQuestions(
        questions.map((q, i) => {
          if (questionIndex === i) {
            return {
              ...q,
              background: src,
            };
          }
          return q;
        })
      );
    } else {
      setQuestions(
        questions.map((q, i) => {
          if (questionIndex === i) {
            return {
              ...q,
              background: questions[questionIndex].originalFile,
            };
          }
          return q;
        })
      );
    }
    setDirty(true);
  };

  const setAnswerImg = (index, value) => {
    setQuestions(
      questions.map((q, i) => {
        if (questionIndex === i) {
          return {
            ...q,
            answer_type:
              value.length > 0 ||
              questions[questionIndex].answers
                .filter((a, i) => i !== index)
                .some((item) => item.background.length > 0)
                ? 'media'
                : 'text',
            answers: questions[questionIndex].answers.map((a, j) => {
              if (index === j) {
                return { ...a, background: value };
              }
              return a;
            }),
          };
        }
        return q;
      })
    );
    setDirty(true);
  };

  useEffect(() => {
    setQuestions((qus) =>
      qus?.map((q, i) => {
        if (q.clone_answers_from) {
          const questionToCopy =
            qus[qus.findIndex((qu) => qu.id === q.clone_answers_from)];
          return {
            ...q,
            answers: questionToCopy?.answers?.map((a, ai) => {
              return {
                ...qus[i].answers[ai],
                title: a.title,
                background: a.background,
              };
            }),
          };
        }
        return q;
      })
    );
  }, [changingAnswer, questionIndex]);

  const handleAnswerChange = (e, index) => {
    setQuestions(
      questions.map((q, i) => {
        if (questionIndex === i) {
          return {
            ...q,
            answers: questions[questionIndex].answers.map((a, ind) => {
              if (index === ind) {
                return { ...a, [e.target.name]: e.target.value };
              }
              return a;
            }),
          };
        }
        return q;
      })
    );
    setDirty(true);
    setChangingAnswer(`${e.target.name} ${e.target.value}`);
  };

  const setAnswerPixelCode = (index, pc) => {
    if (pc) {
      setDirty(true);
      setQuestions(
        questions.map((q, i) => {
          if (i === questionIndex) {
            return {
              ...q,
              question_pixel_code_id: index === -1 ? pc.id : null,
              answers: q.answers.map((a, j) => {
                if (index === j || index === -1) {
                  return {
                    ...a,
                    pixel_code_id: pc.id,
                    pixel_code_name: pc.name,
                  };
                }
                return a;
              }),
            };
          }
          return q;
        })
      );
    }
  };

  const leadAnswerSelect = (index, lead) => {
    if (lead) {
      setDirty(true);
      setQuestions(
        questions.map((q, i) => {
          if (i === questionIndex) {
            return {
              ...q,
              question_lead_id: index === -1 ? lead.id : null,
              answers: q.answers.map((a, j) => {
                if (index === j || index === -1) {
                  return {
                    ...a,
                    lead_id: lead.id,
                    lead_name: lead.name,
                  };
                }
                return a;
              }),
            };
          }
          return q;
        })
      );
    }
  };

  const changeNumber = (e, direction) => {
    if (direction === 'add') {
      setQuestions(
        questions.map((q, i) => {
          if (questionIndex === i) {
            return {
              ...q,
              answers: [
                ...questions[questionIndex].answers,
                {
                  title: '',
                  background: '',
                  pixel_code_id: q.question_pixel_code_id,
                  lead_id: q.question_lead_id,
                },
              ],
            };
          }
          return q;
        })
      );
    } else if (direction === 'delete') {
      setQuestions(
        questions.map((q, i) => {
          if (questionIndex === i) {
            return {
              ...q,
              answers: questions[questionIndex].answers.slice(0, -1),
            };
          }
          return q;
        })
      );
    }
    setDirty(true);
  };

  const selectRelation = (val) => {
    if (val === 'everyone') {
      setShowEveryone(true);
      setQuestions(
        questions.map((q, i) => {
          if (questionIndex === i) {
            return {
              ...q,
              set_conditions: '',
            };
          }
          return q;
        })
      );
    } else {
      setShowEveryone(false);
      setQuestions(
        questions.map((q, i) => {
          if (questionIndex === i) {
            return {
              ...q,
              set_condition_reverse: val === 'onlynot',
            };
          }
          return q;
        })
      );
    }
    setDirty(true);
  };

  const checkboxChanged = (checked, val) => {
    setQuestions(
      questions.map((q, i) => {
        if (questionIndex === i) {
          return {
            ...q,
            set_conditions: checked
              ? (questions[i].set_conditions || '').concat(
                  questions[i].set_conditions &&
                    questions[i].set_conditions.length > 0 &&
                    questions[i].set_conditions.slice(-1) !== ','
                    ? `,${val},`
                    : `${val},`
                )
              : questions[i].set_conditions
                  .replace(`${val},`, '')
                  .replace(`${val}`, ''),
          };
        }
        return q;
      })
    );
    setDirty(true);
  };

  const checkboxAllChanged = (checked, qi) => {
    setQuestions(
      questions.map((q, i) => {
        if (questionIndex === i) {
          return {
            ...q,
            set_conditions: checked
              ? (questions[i].set_conditions || '').concat(
                  questions[qi].answers.reduce((prev, cur, idx) => {
                    return idx === 0 ? cur.id : prev + ',' + cur.id;
                  }, '')
                )
              : questions[qi].answers.reduce((prev, cur, idx) => {
                  return prev
                    .replace(`${cur.id},`, '')
                    .replace(`${cur.id}`, '');
                }, questions[i].set_conditions),
          };
        }
        return q;
      })
    );
    setDirty(true);
  };

  const scoreCheckboxChanged = (e) => {
    const checked = e.target.checked;
    setQuestions(
      questions.map((q, i) => {
        if (questionIndex === i) {
          return {
            ...q,
            question_lead_id: null,
            question_pixel_code_id: null,
            answers: checked ? initialScoreAnswers : initialQuestion.answers,
            answer_type: checked ? 'score' : initialQuestion.answer_type,
          };
        }
        return q;
      })
    );
    setDirty(true);
  };

  const previousCheckboxChanged = (e) => {
    const checked = e.target.checked;
    setQuestions(
      questions.map((q, i) => {
        if (questionIndex === i) {
          return {
            ...q,
            use_answers: checked ? 1 : 0,
            clone_answers_from: checked ? q.clone_answers_from : null,
          };
        }
        return q;
      })
    );
    setDirty(true);
  };

  const getUseAnswersValue = (val, qi) => {
    if (val[0]) {
      const questionToCopy = questions[val[0] - 1];
      setQuestions(
        questions?.map((q, i) => {
          if (qi === i) {
            return {
              ...q,
              answers: questionToCopy?.answers?.map((a) => {
                return {
                  title: a.title,
                  background: a.background,
                };
              }),
              clone_answers_from: questionToCopy.id,
            };
          }
          return q;
        })
      );
    }
  };

  const moveQuestions = (number) => {
    if (dirty && !window.confirm(formatMessage(messages.DidntSaveCurrent))) {
      return;
    } else {
      setDirty(false);
    }
    setQI(questionIndex + number);
    setShowEveryone(true);
  };

  const finishSet = () => {
    if (dirty && !window.confirm(formatMessage(messages.DidntSaveCurrent)))
      return;
    history.push('/content');
  };

  const deleteQuestion = async (qi) => {
    const qu = questions[qi];
    const answerIds = qu.answers.map((a) => a.id);
    const cantDelete = questions.find((q) =>
      answerIds.some((id) => q.set_conditions.includes(id))
    );
    const cloneIds = questions.map((q) => q.clone_answers_from);
    const cantDeleteClone = cloneIds.includes(qu.id);

    if (cantDelete) {
      toast.error(formatMessage(messages.HasConditions));
      return;
    }
    if (cantDeleteClone) {
      toast.error(formatMessage(messages.HasClones));
      return;
    }
    setDeletingQuestion(true);
    try {
      await api.get(`/content/delete/poll/${qu.id}`);
      if (qi === questions.length - 1) {
        setQI(questions.length - 2);
      }
      setQuestions(questions.filter((q) => q.id !== qu.id));
      setDeletingQuestion(false);
      toast.success(formatMessage(messages.QuestionDeleted));
    } catch (e) {
      setDeletingQuestion(false);
      toast.error(formatMessage(messages.ErrorDeletingQuestion));
    }
  };

  const copyQuestion = (qi) => {
    const qu = questions[qi];
    setQuestions([
      ...questions,
      {
        ...qu,
        id: null,
        answers: qu.answers.map((a) => {
          return {
            ...a,
            id: null,
          };
        }),
      },
    ]);
    setQI(questions.length);
    setDirty(true);
  };

  const editQuestion = (qi) => {
    setQI(qi);
  };

  const deleteAnswer = (e, index) => {
    const qu = questions[questionIndex];
    const cantDelete = questions.find((q) =>
      q.set_conditions.includes(qu.answers[index].id)
    );

    if (cantDelete) {
      toast.error(formatMessage(messages.HasConditionsAnswer));
      return;
    }

    setQuestions(
      questions.map((q, i) => {
        if (questionIndex === i) {
          return {
            ...q,
            answers: q.answers.filter((a, i) => i !== index),
          };
        }
        return q;
      })
    );
    setDirty(true);
  };

  const togglePrompt = () => {
    setPrompt((prompt) => !prompt);
  };

  const onYesClick = async () => {
    togglePrompt();

    await api.post(`/publisher-content-settings/set`, {
      settings: {
        privacy: data.privacy,
        hide_results: data.hide_results,
        hide_counter: data.hide_counter,
        skip_start: data.skip_start,
        skip_result: data.skip_result,
        display_repeat: data.display_repeat,
      },
    });
  };

  const changeResultNumber = (e, direction) => {
    e.preventDefault();
    if (direction === 'add') {
      setData({
        ...data,
        results: [...data.results, { title: '' }],
      });
    } else if (direction === 'delete') {
      setData({
        ...data,
        results: data.results.slice(0, -1),
      });
    }
  };

  return (
    <MDBContainer style={{ maxWidth: 1600 }}>
      {!data.id ? (
        <Container>
          <MDBNav tabs className="nav-justified">
            <ReactTooltip effect="solid" multiline={true} />
            <MDBNavItem>
              <MDBLink to="#" active>
                <Fa icon="pencil-alt" /> {formatMessage(messages.Set)}
                <span
                  data-tip={formatMessage(messages.SetTip)}
                  data-place="bottom"
                >
                  <MDBIcon far icon="question-circle" />
                </span>
              </MDBLink>
            </MDBNavItem>
            <MDBNavItem>
              <MDBLink
                to="#"
                onClick={(e) => {
                  e.preventDefault();
                  history.push('/content/create', {
                    activeItem: '2',
                  });
                }}
              >
                <Fa icon="pencil-alt" /> {formatMessage(messages.Test)}
                <span
                  data-tip={formatMessage(messages.TestTip)}
                  data-place="bottom"
                >
                  <MDBIcon far icon="question-circle" />
                </span>
              </MDBLink>
            </MDBNavItem>
            <MDBNavItem>
              <MDBLink
                to="#"
                onClick={(e) => {
                  e.preventDefault();
                  history.push('/content/create', {
                    activeItem: '3',
                  });
                }}
              >
                <Fa icon="pencil-alt" /> {formatMessage(messages.Quiz)}
                <span
                  data-tip={formatMessage(messages.QuizTip)}
                  data-place="bottom"
                >
                  <MDBIcon far icon="question-circle" />
                </span>
              </MDBLink>
            </MDBNavItem>
            <MDBNavItem>
              <MDBLink
                to="#"
                onClick={(e) => {
                  e.preventDefault();
                  history.push('/content/create', {
                    activeItem: '4',
                  });
                }}
              >
                <Fa icon="pencil-alt" /> {formatMessage(messages.Poll)}
                <span
                  data-tip={formatMessage(messages.PollTip)}
                  data-place="bottom"
                >
                  <MDBIcon far icon="question-circle" />
                </span>
              </MDBLink>
            </MDBNavItem>
          </MDBNav>
        </Container>
      ) : null}

      <Prompt
        toggleModal={togglePrompt}
        modal={prompt}
        onYesClick={onYesClick}
      />
      <MDBRow>
        <MDBCol md="7" className="pt-5">
          <MDBCard>
            <MDBCardBody>
              <h2 className="text-center font-weight-bold pt-4 pb-5">
                <strong>
                  {data.id
                    ? `${formatMessage(messages.EditSet)} - #${data.id}`
                    : formatMessage(messages.CreateSet)}
                </strong>
              </h2>
              {step === 1 && (
                <Fragment>
                  <Step1
                    data={data}
                    getSelectValue={getSelectValue}
                    handleTextChange={handleTextChange}
                    setSRC={setSRC}
                    expanded={expanded}
                    toggle={() => setExpanded((e) => !e)}
                    handleSwitchChange={handleSwitchChange}
                    leadSelect={leadSelectSet}
                  />
                  <Result
                    data={data}
                    results={data.results}
                    setResultImg={setResultImg}
                    handleResultTextChange={handleResultTextChange}
                    handleResultSwitchChange={handleResultSwitchChange}
                    leadSelect={leadSelect}
                    pixelCodeSelect={pixelCodeSelect}
                    clickPixelCodeSelect={clickPixelCodeSelect}
                    saveSet={saveSet}
                    saving={saving}
                    changeResultNumber={changeResultNumber}
                  />
                </Fragment>
              )}
              {step === 2 && (
                <Step2
                  data={data}
                  questions={questions}
                  setStep={setStep}
                  questionIndex={questionIndex}
                  handleTitleChange={handleQuestionTitleChange}
                  setSRC={setQuestionSRC}
                  setAnswerImg={setAnswerImg}
                  handleAnswerChange={handleAnswerChange}
                  pixelCode={setAnswerPixelCode}
                  leadSelect={leadAnswerSelect}
                  changeNumber={changeNumber}
                  saveQuestion={saveQuestion}
                  savingQuestion={savingQuestion}
                  handleSwitchChange={handleQuestionSwitchChange}
                  getSelectValue={getQuestionSelectValue}
                  moveQuestions={moveQuestions}
                  finishSet={finishSet}
                  addNewQuestionToTheEnd={addNewQuestionToTheEnd}
                  dirty={dirty}
                  scoreCheckboxChanged={scoreCheckboxChanged}
                  previousCheckboxChanged={previousCheckboxChanged}
                  deleteAnswer={deleteAnswer}
                  getUseAnswersValue={getUseAnswersValue}
                />
              )}
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
        <MDBCol md="5">
          {questions.length > 1 && (
            <ViewConditions
              step={step}
              data={data}
              handleTextChange={handleTextChange}
              questions={questions}
              questionIndex={questionIndex}
              selectRelation={selectRelation}
              showEveryone={showEveryone}
              checkboxChanged={checkboxChanged}
              deleteQuestion={deleteQuestion}
              deletingQuestion={deletingQuestion}
              copyQuestion={copyQuestion}
              editQuestion={editQuestion}
              checkboxAllChanged={checkboxAllChanged}
              saveSet={saveSet}
              saving={saving}
            />
          )}
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default injectIntl(SetCreateEdit);
