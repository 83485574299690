import React, { Fragment } from 'react';
import { MDBRow, MDBCol, MDBSwitch } from 'mdbreact';

const SwitchOptions = ({ formatMessage, messages, triviaState, dispatch }) => (
  <Fragment>
    <MDBRow className="border-top border-dark" style={{ paddingTop: '20px' }}>
      <MDBCol>{formatMessage(messages.ShowBoard)}</MDBCol>
      <MDBCol>
        <MDBSwitch
          checked={triviaState.player_leaderboard ? true : false}
          onChange={(e) =>
            dispatch({
              type: 'switch_change',
              data: {
                checked: e.target.checked,
                name: 'player_leaderboard',
              },
            })
          }
        />
      </MDBCol>
    </MDBRow>
    <MDBRow>
      <MDBCol>{formatMessage(messages.Elimination)}</MDBCol>
      <MDBCol>
        <MDBSwitch
          checked={triviaState.elimination ? true : false}
          onChange={(e) =>
            dispatch({
              type: 'switch_change',
              data: {
                checked: e.target.checked,
                name: 'elimination',
              },
            })
          }
        />
      </MDBCol>
    </MDBRow>
  </Fragment>
);

export default SwitchOptions;
