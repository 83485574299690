import React from 'react';
import { Card, CardBody, CardText, CardUp, Fa } from 'mdbreact';
import { feedDailyImagePathRetina } from '../../../core/constants';
import messages from '../messages';

let Front = ({ content, intl, flip }) => {
  const { formatMessage, formatDate, formatTime } = intl;
  const end_date = new Date(content.end_date);

  return (
    <Card className="face front">
      <CardUp>
        <a href={`/content-detail/${content.t}/${content.id}`}>
          <img
            className="card-img-top"
            src={
              content.background
                ? `${feedDailyImagePathRetina}/${content.background}`
                : `${feedDailyImagePathRetina}/placeholder/question/1.png`
            }
            alt=""
            style={{ cursor: 'pointer' }}
          />
        </a>
      </CardUp>
      <CardBody cascade>
        <h6 className="card-title">
          {content.title && content.title.length > 100
            ? `${content.title.substring(0, 100)}...`
            : content.title}
        </h6>
        <hr />
        <CardText>
          <a href={`/content-detail/${content.t}/${content.id}`}>
            {`${content.t.toUpperCase()} ID: ${content.id}`}
          </a>
          <br />
          <span>{`${content.view_count} ${formatMessage(
            messages.Views
          )}`}</span>
          <br />
          <a href={`/content-detail/${content.t}/${content.id}`}>
            {`${content.vote_count}  ${formatMessage(messages.Votes)}`}
          </a>
          <br />
          <span>
            {`${content.unique_voter}  ${formatMessage(messages.UniqueVoters)}`}
          </span>
          <br />
          {content.t !== 'poll' ? (
            <span>
              {`${((content.finish / (content.start || 1)) * 100).toFixed(0)}%
              ${formatMessage(messages.CompletionRate)}`}
            </span>
          ) : null}
          <br />
          {content.end_date ? (
            <span>
              {`${formatMessage(messages.EndDate)}: ${formatDate(
                end_date
              )} ${formatTime(end_date)}`}
            </span>
          ) : null}
        </CardText>
        <a href="#!" className="rotate-btn" data-card="card-1" onClick={flip}>
          <Fa icon="redo" /> {formatMessage(messages.Options)}
        </a>
      </CardBody>
    </Card>
  );
};

export default Front;
