import React, { useState, useEffect } from 'react';
import {
  MDBContainer,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBSpinner,
} from 'mdbreact';
import { getSankey } from '../../api';
import Pagination from '../Pagination';

const SankeyModal = ({
  data,
  modalData,
  toggle,
  isOpen,
  formatMessage,
  messages,
}) => {
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [lastPage, setLastPage] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let ignore = false;

    async function fetchData() {
      setLoading(true);
      const id1 = modalData[0].split(' ')[0].substr(1);
      const id2 = modalData[1].split(' ')[0].substr(1);
      if (id1 && id2) {
        const r = await getSankey(
          data.type,
          data.id,
          id1,
          id2,
          page,
          sizePerPage
        );

        if (!ignore) {
          setUsers(r.data.data);
          setPage(page);
          setSizePerPage(sizePerPage);
          setLastPage(r.data.last_page);
          setLoading(false);
        }
      }
    }

    fetchData();
    return () => {
      ignore = true;
    };
  }, [data, modalData, page, sizePerPage]);

  const resetPageData = (e) => {
    setPage(1);
    toggle(e);
  };

  return (
    <MDBContainer>
      <MDBModal isOpen={isOpen} toggle={resetPageData} size="lg">
        <MDBModalHeader toggle={resetPageData}>Users</MDBModalHeader>
        <MDBModalBody>
          <Pagination
            last={lastPage}
            active={page}
            size={sizePerPage}
            handlePageChange={(page, sizePerPage) => {
              setPage(page);
              setSizePerPage(sizePerPage);
            }}
          />
          <div className="row">
            <div className="col-12">
              {loading ? (
                <MDBSpinner blue big />
              ) : (
                <table
                  className="table table-striped table-bordered table-sm table-fixed-header"
                  style={
                    loading
                      ? { border: 'none', borderTop: '1px solid #dee2e6' }
                      : {}
                  }
                >
                  <thead>
                    <tr>
                      <th className="th-sm">{'User ID'}</th>
                      <th className="th-sm">{'UUID'}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.map((log, i) => {
                      return (
                        <tr key={i}>
                          <td>{log.user_id}</td>
                          <td>{log.uuid}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn color="secondary" onClick={resetPageData}>
            {formatMessage(messages.Close)}
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal>
    </MDBContainer>
  );
};

export default SankeyModal;
