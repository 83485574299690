import axios from 'axios';
import { apiURL } from '../core/constants';
import { toast } from 'mdbreact';

const api = axios.create({
  baseURL: apiURL,
});

const apiWithoutToken = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

try {
  let token = null;
  token = JSON.parse(localStorage.getItem('token'));
  if (token) {
    api.defaults.headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };
  } else {
    localStorage.removeItem('token');
    api.defaults.headers = apiWithoutToken;
  }
} catch (e) {
  api.defaults.headers = apiWithoutToken;
}

const sleepRequest = (milliseconds, originalRequest) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => resolve(api(originalRequest)), milliseconds);
  });
};

api.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error?.response?.status === 503) {
      window.location.href = '/error';
    } else if (error?.response?.status === 401) {
      if (
        window.location.pathname !== '/login' ||
        window.location.pathname !== '/signup'
      ) {
        window.localStorage.removeItem('user');
        window.localStorage.removeItem('token');
        window.location.href = '/login';
      }
    } else if (error?.response?.status === 429) {
      toast.error('Too many attempts. Try again later.');
    } else if (!error?.response || error?.response?.status === 502) {
      return sleepRequest(2000, error.config);
    }
    return Promise.reject(error);
  }
);

export default api;
