import React, { Component, Fragment } from 'react';

import { injectIntl } from 'react-intl';
import { Input, Col, Row, toast, Fa, Button } from 'mdbreact';
import { createQuizQuestion } from '../../../api';

import messages from '../messages';
import ImageInput from '../../ImageInput';
import PixelCodeSelector from '../PixelCodeSelector';

const defaultAnswersArray = [
  {
    title: '',
    background: '',
    is_right_answer: 0,
  },
  {
    title: '',
    background: '',
    is_right_answer: 0,
  },
];

class Step2 extends Component {
  state = {
    questions: [
      {
        title: '',
        background: '',
        answer_type: 'text',
        answers: defaultAnswersArray,
      },
    ],
    questionIndex: 0,
    modal: false,
  };

  addQuestion = async () => {
    try {
      const { questions, questionIndex } = this.state;
      const { createdTestId, setQuestions, setQuestionCount } = this.props;
      this.setState({ creating: true });

      const response = await createQuizQuestion({
        ...questions[questionIndex],
        parent_id: createdTestId,
      });

      const newQuestions = this.state.questions.map((q, i) => {
        if (this.state.questionIndex === i) {
          return {
            ...q,
            id: response.data.id,
            answers: q.answers.map((a, ai) => {
              return {
                ...a,
                id: response.data.answers[ai].id,
              };
            }),
          };
        }
        return q;
      });

      setQuestionCount(questionIndex);
      setQuestions(newQuestions);

      this.setState({
        creating: false,
        questionIndex: this.state.questionIndex + 1,
        questions: newQuestions,
      });

      if (questionIndex === questions.length - 1) {
        this.setState({
          questions: [
            ...this.state.questions,
            {
              title: '',
              background: '',
              answer_type: 'text',
              answers: defaultAnswersArray,
            },
          ],
        });
      }

      const element = document.getElementById(
        `questionInput${questionIndex + 1}`
      );
      setTimeout(() => {
        window.scrollTo({ top: 100, left: 0, behavior: 'smooth' });
        if (element) {
          element.focus();
        }
      }, 1000);
    } catch (e) {
      toast.error('Error occured!');
      this.setState({ creating: false });
    }
  };

  prevQuestion = () => {
    this.setState({
      questionIndex: this.state.questionIndex - 1,
    });
  };

  nextQuestion = () => {
    this.setState({
      questionIndex: this.state.questionIndex + 1,
    });
  };

  handleTitleChange = async (e) => {
    const inputText = e.target.value;
    this.setState({
      questions: this.state.questions.map((q, i) => {
        if (this.state.questionIndex === i) {
          return {
            ...q,
            title: inputText,
          };
        }
        return q;
      }),
    });
  };

  setSRC = (src, target) => {
    if (src) {
      this.setState({
        questions: this.state.questions.map((q, i) => {
          if (this.state.questionIndex === i) {
            return {
              ...q,
              background: src,
            };
          }
          return q;
        }),
      });
    } else {
      this.setState({
        questions: this.state.questions.map((q, i) => {
          if (this.state.questionIndex === i) {
            return {
              ...q,
              background: this.state.questions[this.state.questionIndex]
                .originalFile,
            };
          }
          return q;
        }),
      });
    }
  };

  setAnswerImg = (index, value) => {
    const { questions, questionIndex: qi } = this.state;
    this.setState({
      questions: questions.map((q, i) => {
        if (qi === i) {
          return {
            ...q,
            answer_type:
              value.length > 0 ||
              questions[qi].answers
                .filter((a, i) => i !== index)
                .some((item) => item.background.length > 0)
                ? 'media'
                : 'text',
            answers: questions[qi].answers.map((a, j) => {
              if (index === j) {
                return { ...a, background: value };
              }
              return a;
            }),
          };
        }
        return q;
      }),
    });
  };

  changeNumber = (e, direction) => {
    e.preventDefault();
    if (direction === 'add') {
      this.setState({
        questions: this.state.questions.map((q, i) => {
          if (this.state.questionIndex === i) {
            return {
              ...q,
              answers: [
                ...this.state.questions[this.state.questionIndex].answers,
                {
                  title: '',
                  background: '',
                  is_right_answer: 0,
                },
              ],
            };
          }
          return q;
        }),
      });
    } else if (direction === 'delete') {
      this.setState({
        questions: this.state.questions.map((q, i) => {
          if (this.state.questionIndex === i) {
            return {
              ...q,
              answers: this.state.questions[
                this.state.questionIndex
              ].answers.slice(0, -1),
            };
          }
          return q;
        }),
      });
    }
  };

  handleAnswerChange = (e, index) => {
    this.setState({
      questions: this.state.questions.map((q, i) => {
        if (this.state.questionIndex === i) {
          return {
            ...q,
            answers: this.state.questions[this.state.questionIndex].answers.map(
              (a, ind) => {
                if (index === ind) {
                  return { ...a, title: e.target.value };
                }
                return a;
              }
            ),
          };
        }
        return q;
      }),
    });
  };

  selectRightAnswer = (event, index) => {
    this.setState({
      questions: this.state.questions.map((q, i) => {
        if (this.state.questionIndex === i) {
          return {
            ...q,
            answers: this.state.questions[this.state.questionIndex].answers.map(
              (answer, i) => {
                if (index === i) {
                  return {
                    ...answer,
                    is_right_answer: event.target.checked ? 1 : 0,
                  };
                }
                return {
                  ...answer,
                  is_right_answer: 0,
                };
              }
            ),
          };
        }
        return q;
      }),
    });
  };

  pixelCode = (index, pc) => {
    if (pc) {
      this.setState({
        questions: this.state.questions.map((q, i) => {
          if (i === this.state.questionIndex) {
            return {
              ...q,
              answers: q.answers.map((a, j) => {
                if (j === index) {
                  return {
                    ...a,
                    pixel_code_id: pc.id,
                    pixel_code_name: pc.name,
                  };
                }
                return a;
              }),
            };
          }
          return q;
        }),
      });
    }
  };

  render() {
    const { formatMessage } = this.props.intl;
    const { handleNextPrevClick, quizData } = this.props;
    const { questions, questionIndex, creating } = this.state;

    const titleSize = 255;
    const answerSize = 255;

    return (
      <Col md="12">
        {questions.map(
          (question, i) =>
            questionIndex === i && (
              <Fragment key={i}>
                <h4>{`${formatMessage(messages.Question)} ${i + 1}`}</h4>
                <Col md="12">
                  <Input
                    label={`${formatMessage(messages.QuestionTitle)} -${
                      titleSize - question.title.length
                    }`}
                    className="mt-3"
                    maxLenght={titleSize}
                    type="textarea"
                    name="title"
                    id={`questionInput${i}`}
                    value={question.title}
                    onChange={this.handleTitleChange}
                  />
                </Col>
                <ImageInput title={''} setSRC={this.setSRC} />
                <Col md="12">
                  <h4>{formatMessage(messages.Answers)}</h4>
                  <Col sm="12">
                    {question.answers.map((answer, ai) => (
                      <Row
                        key={ai}
                        className="collapse-media"
                        style={{ background: '#E8E8E8', padding: '10px' }}
                      >
                        <div
                          className="collapse-media__item col-5"
                          style={{ overflow: 'visible' }}
                        >
                          <ImageInput
                            title={formatMessage(messages.AddImage)}
                            setSRC={(s, t) => {
                              this.setAnswerImg(ai, s);
                            }}
                            src={answer.background}
                            answer
                          />
                        </div>
                        <div className="col-7">
                          <Input
                            className="form-control"
                            type="textarea"
                            maxLenght={answerSize}
                            name={`answer${i}`}
                            label={`${formatMessage(messages.Answer)} ${
                              ai + 1
                            } -${answerSize - answer.title.length}`}
                            value={answer.title}
                            onChange={(e) => this.handleAnswerChange(e, ai)}
                          />
                          {!quizData.friend_quiz && (
                            <Input
                              label={formatMessage(messages.RightAnswer)}
                              type="checkbox"
                              id={`rightAnswer${ai}`}
                              onChange={(event) =>
                                this.selectRightAnswer(event, ai)
                              }
                              checked={!!answer.is_right_answer}
                            />
                          )}
                          <Row>
                            <PixelCodeSelector
                              index={ai}
                              pixelCode={this.pixelCode}
                              id={answer.pixel_code_id}
                            />
                          </Row>
                        </div>
                      </Row>
                    ))}
                    <Button
                      color="secondary"
                      onClick={(e) => this.changeNumber(e, 'add')}
                    >
                      {formatMessage(messages.AddAnswer)}
                    </Button>
                    {question.answers.length > 2 && (
                      <Button
                        color="secondary"
                        onClick={(e) => this.changeNumber(e, 'delete')}
                      >
                        {formatMessage(messages.DeleteAnswer)}
                      </Button>
                    )}
                  </Col>
                </Col>
              </Fragment>
            )
        )}
        <Row>
          <button
            type="button"
            className="btn btn-indigo btn-rounded Ripple-parent float-left"
            onClick={handleNextPrevClick(1)}
          >
            {formatMessage(messages.Prev)}
          </button>
          <button
            type="button"
            className={`btn btn-indigo btn-rounded Ripple-parent float-left`}
            onClick={this.prevQuestion}
            disabled={questionIndex === 0}
          >
            <Fa icon="arrow-left" />
          </button>
          <button
            type="button"
            className={`btn btn-indigo btn-rounded Ripple-parent float-right`}
            onClick={this.nextQuestion}
            disabled={questionIndex === questions.length - 1}
          >
            <Fa icon="arrow-right" />
          </button>
          <button
            type="button"
            className={`btn btn-indigo btn-rounded Ripple-parent float-right`}
            onClick={this.addQuestion}
            disabled={
              creating ||
              (questions[questionIndex] &&
                questions[questionIndex].title.length < 1) ||
              (questions[questionIndex] &&
                questions[questionIndex].answers.filter(
                  (a) => a.title.length < 1 && a.background.length < 1
                ).length > 0) ||
              (!quizData.friend_quiz &&
                questions[questionIndex] &&
                questions[questionIndex].answers.filter(
                  (a) => a.is_right_answer === 1
                ).length === 0)
            }
          >
            {formatMessage(messages.SaveQuestion)}
          </button>
          <button
            type="button"
            className={`btn btn-indigo btn-rounded Ripple-parent float-right ${
              questions.length > 1 ? '' : 'disabled'
            }`}
            disabled={questions.length < 2}
            onClick={handleNextPrevClick(3)}
          >
            {`${formatMessage(messages.Next)} (${formatMessage(
              messages.Results
            )})`}
          </button>
        </Row>
        <Row style={{ marginRight: '15px' }}>
          <Col>
            <div className={'float-right text-danger'}>
              {questions[questionIndex] &&
              questions[questionIndex].title.length < 1
                ? `${formatMessage(messages.PleaseEnterTitle)}`
                : null}
            </div>
          </Col>
        </Row>
        <Row style={{ marginRight: '15px' }}>
          <Col>
            <div className={'float-right text-danger'}>
              {questions[questionIndex] &&
              questions[questionIndex].answers.filter(
                (a) => a.title.length < 1 && a.background.length < 1
              ).length > 0
                ? `${formatMessage(messages.PleaseEnter)}`
                : null}
            </div>
          </Col>
        </Row>
        <Row style={{ marginRight: '15px' }}>
          <Col>
            <div className={'float-right text-danger'}>
              {!quizData.friend_quiz &&
              questions[questionIndex] &&
              questions[questionIndex].answers.filter(
                (a) => a.is_right_answer === 1
              ).length === 0
                ? `${formatMessage(messages.PleaseCorrectAnswer)}`
                : null}
            </div>
          </Col>
        </Row>
      </Col>
    );
  }
}

export default injectIntl(Step2);
