import { defineMessages } from 'react-intl.macro';

export default defineMessages({
  Id: {
    id: 'Sheethooks.Id',
    defaultMessage: 'ID',
  },
  Name: {
    id: 'Sheethooks.Name',
    defaultMessage: 'Name',
  },
  NameInfo: {
    id: 'Sheethooks.NameInfo',
    defaultMessage:
      'Please name your sheet so it will be easier to track in the future',
  },
  SheetId: {
    id: 'Sheethooks.SheetId',
    defaultMessage: 'SheetId',
  },
  SheetIdInfo: {
    id: 'Sheethooks.SheetIdInfo',
    defaultMessage:
      'Your Google Sheet documents ID. You can find this from the URL bar once you open your sheet',
  },
  IsActive: {
    id: 'Sheethooks.IsActive',
    defaultMessage: 'Is Active',
  },
  SheethookAdded: {
    id: 'Sheethooks.WebhookAdded',
    defaultMessage: 'Sheethook Added',
  },
  Yes: {
    id: 'Sheethooks.Yes',
    defaultMessage: 'Yes',
  },
  No: {
    id: 'Sheethooks.No',
    defaultMessage: 'No',
  },
  Loading: {
    id: 'Sheethooks.Loading',
    defaultMessage: 'Loading...',
  },
  Edit: {
    id: 'Sheethooks.Edit',
    defaultMessage: 'Edit',
  },
  ErrorSaving: {
    id: 'Sheethooks.ErrorSaving',
    defaultMessage: 'Error Saving Sheet Hook',
  },
  AddNew: {
    id: 'Sheethooks.AddNew',
    defaultMessage: 'Add New Sheet Hook',
  },
  Save: {
    id: 'Sheethooks.Save',
    defaultMessage: 'Save',
  },
  Delete: {
    id: 'Sheethooks.Delete',
    defaultMessage: 'Delete',
  },
  ViewLogs: {
    id: 'Sheethooks.ViewLogs',
    defaultMessage: 'View Logs',
  },
  Sheethooks: {
    id: 'Sheethooks.Webhooks',
    defaultMessage: 'Sheet Hooks',
  },
  AreYouSure: {
    id: 'Sheethooks.AreYouSure',
    defaultMessage: 'Are you sure you want to delete this sheet hook?',
  },
  Deleted: {
    id: 'Sheethooks.WebhookDeleted',
    defaultMessage: 'Sheethook Deleted',
  },
  StatusSet: {
    id: 'Sheethooks.StatusSet',
    defaultMessage: 'Sheethook Status Set',
  },
  ViewPayload: {
    id: 'Sheethooks.ViewPayload',
    defaultMessage: 'View Payload',
  },
  HookDesc: {
    id: 'Sheethooks.HookDesc',
    defaultMessage:
      'Poltio will send a POST request with a JSON body to your URL with the following format.',
  },
  Info: {
    id: 'Sheethooks.Info',
    defaultMessage: 'Info',
  },
  Close: {
    id: 'Sheethooks.Close',
    defaultMessage: 'Close',
  },
  SeeAll: {
    id: 'Sheethooks.SeeAll',
    defaultMessage: 'See All Hooks',
  },
  EditWebhook: {
    id: 'Sheethooks.EditWebhook',
    defaultMessage: 'Edit Sheet Hook',
  },
  Show: {
    id: 'Sheethooks.Show',
    defaultMessage: 'Show',
  },
  EnterId: {
    id: 'Sheethooks.EnterId',
    defaultMessage: 'Enter Id',
  },
  Type: {
    id: 'Sheethooks.Type',
    defaultMessage: 'Type',
  },
  ID: {
    id: 'Sheethooks.ID',
    defaultMessage: 'ID',
  },
  ShareEmail: {
    id: 'Sheethooks.ShareEmail',
    defaultMessage: 'Please share your sheet with this email address:',
  },
});
