import { defineMessages } from 'react-intl.macro';

export default defineMessages({
  Title: {
    id: 'ManagePools.Title',
    defaultMessage: 'Manage Pools',
  },
  AddNewDomain: {
    id: 'ManagePools.AddNewDomain',
    defaultMessage: 'Add New Pool',
  },
  DomainName: {
    id: 'ManagePools.DomainName',
    defaultMessage: 'Pool Name',
  },
  Add: {
    id: 'ManagePools.Add',
    defaultMessage: 'Add',
  },
  Added: {
    id: 'ManagePools.Added',
    defaultMessage: 'Pool was added successfully!',
  },
  DomainNotValid: {
    id: 'ManagePools.DomainNotValid',
    defaultMessage: 'Pool is not valid!',
  },
  DomainInSystem: {
    id: 'ManagePools.DomainInSystem',
    defaultMessage: 'Pool is already on the system!',
  },
  Delete: {
    id: 'ManagePools.Delete',
    defaultMessage: 'Delete',
  },
  Deleted: {
    id: 'ManagePools.Deleted',
    defaultMessage: 'Pool deleted',
  },
  AreYouSure: {
    id: 'ManagePools.AreYouSure',
    defaultMessage: 'Are you sure you want to delete this pool?',
  },
  Yes: {
    id: 'ManagePools.Yes',
    defaultMessage: 'Yes',
  },
  No: {
    id: 'ManagePools.No',
    defaultMessage: 'No',
  },
  Optional: {
    id: 'ManagePools.Optional',
    defaultMessage: 'Optional',
  },
  RegisterFields: {
    id: 'ManagePools.RegisterFields',
    defaultMessage: 'Register Fields',
  },
  PoolNotEmpty: {
    id: 'ManagePools.PoolNotEmpty',
    defaultMessage: 'You are not authorized!',
  },
});
