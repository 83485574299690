import React from 'react';
import { MDBRow, MDBCol, MDBCard, MDBIcon, MDBBtn } from 'mdbreact';

const SingleMetric = ({ icon, label, value }) => {
  return (
    <MDBCol xl="6" md="6" className="mb-4 mb-4" style={{ minHeight: '120px' }}>
      <MDBCard>
        <MDBRow className="mt-3">
          <MDBCol md="5" size="5" className="text-left pl-4">
            <MDBBtn
              tag="a"
              floating
              size="lg"
              color="primary"
              className="ml-4"
              style={{ padding: 0 }}
            >
              <MDBIcon icon={icon} size="2x" />
            </MDBBtn>
          </MDBCol>
          <MDBCol md="7" col="7" className="text-right pr-5">
            <h5 className="ml-4 mt-4 mb-2 font-weight-bold">{value}</h5>
            <p className="font-small grey-text">{label}</p>
          </MDBCol>
        </MDBRow>
      </MDBCard>
    </MDBCol>
  );
};

export default SingleMetric;
