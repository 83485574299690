import React, { Component, Fragment } from 'react';

import {
  Container,
  Row,
  Col,
  Spinner,
  MDBContainer,
  MDBCard,
  MDBRow,
  MDBCol,
  MDBSelect,
  MDBCardBody,
  MDBTable,
  MDBSpinner,
} from 'mdbreact';
import { Chart } from 'react-google-charts';
import {
  getMetricsDaily,
  getMetricsMonthly,
  getPublisherDashboard,
  getVsMetricsDashboard,
} from '../../api';
import {
  groupBy,
  getUser,
  groupByAddMonth,
  groupByAddDay,
  groupByMonthlyVotes,
} from '../../core/helper';
import { injectIntl } from 'react-intl';
import makeComponentTrashable from 'trashable-react';

import messages from './messages';

import SingleMetric from '../Common/SingleMetric';
import ComparisonMetric from '../Common/ComparisonMetric';
import SectionWithTitle from '../Common/SectionWithTitle';
import ContentStats from './ContentStats';
import InfoModal from './InfoModal';

class Home extends Component {
  state = {
    loading: true,
    data0: null,
    data1: null,
    data2: null,
    data3: null,
    vsResponseDay: { vs: 0, active: 1 },
    vsResponseMonth: { vs: 0, active: 1 },
    vsResponseContent: { vs: 0, active: 1 },
    data0filterSelectOptions: [],
    selectedOptions: {},
    modal: false,
  };

  setMultiSelectOptions = (data) => {
    const thisYear = new Date(Date.now()).getFullYear();
    const data0filterSelectOptions = Array.from(
      new Set(data.slice(1).map((element) => element[0].split('-')[0]))
    ).map((element, i) => {
      return {
        checked: thisYear === Number(element),
        text: `${element}`,
        value: `${i % 11}`,
      };
    });
    this.setState({
      ...this.state,
      data0filterSelectOptions,
    });
  };
  updateSelectedOptions = (e) => {
    const selectedOptions = {};
    for (const el of this.state.data0filterSelectOptions) {
      if (el.checked) selectedOptions[el.text] = el;
    }
    this.setState({
      ...this.state,
      selectedOptions,
    });
  };
  async componentDidMount() {
    const user = getUser();
    const { registerPromise } = this.props;
    const { formatMessage, locale } = this.props.intl;
    let data0 = [
      [formatMessage(messages.Month), formatMessage(messages.VoteCount)],
    ];
    let data1 = [[formatMessage(messages.Day), formatMessage(messages.Total)]];
    let data2 = [
      [formatMessage(messages.Month), formatMessage(messages.Total)],
    ];

    let data3 = [
      [
        { type: 'date', id: 'Date' },
        { type: 'number', id: 'Content Count' },
        { type: 'string', role: 'tooltip', p: { html: true } },
      ],
    ];

    const pdResponse = await registerPromise(getPublisherDashboard());
    const vsResponseDay = await registerPromise(
      getVsMetricsDashboard('vote', 'day')
    );
    const vsResponseMonth = await registerPromise(
      getVsMetricsDashboard('vote', 'month')
    );

    // lets calculate how many months we want.
    const mCount =
      (new Date().getFullYear() - 2018) * 12 + (new Date().getMonth() + 1);
    const response0 = await registerPromise(
      getMetricsMonthly('vote', 'desc', mCount)
    );
    const grouped0 = groupByMonthlyVotes(response0.data);
    let values0 = Object.values(grouped0);
    const data0final = data0.concat(values0);

    const response1 = await registerPromise(getMetricsDaily('vote', 7));
    const grouped = groupBy(response1.data);
    const values = Object.values(grouped);
    const data1final = data1.concat(values);

    const response2 = await registerPromise(getMetricsMonthly('vote'));
    const grouped2 = groupByAddMonth(response2.data);
    const values2 = Object.values(grouped2);
    const data2final = data2.concat(values2);

    const response3 = await registerPromise(
      getMetricsDaily('content,content_poll,content_quiz,content_test', 365)
    );
    const grouped3 = groupByAddDay(response3.data, locale);
    const values3 = Object.values(grouped3);
    const data3final = data3.concat(values3);
    this.setMultiSelectOptions(data0final);
    this.updateSelectedOptions();
    this.setState({
      ...this.state,
      loading: false,
      data0: data0final,
      data1: data1final,
      data2: data2final,
      data3: data3final,
      voteCount: user.vc,
      contentCount: pdResponse.data.total_content,
      vsResponseDay: vsResponseDay.data,
      vsResponseMonth: vsResponseMonth.data,
    });
  }

  render() {
    const { formatMessage, locale } = this.props.intl;
    const {
      loading,
      data0,
      data1,
      data2,
      data3,
      voteCount,
      contentCount,
      vsResponseDay,
      vsResponseMonth,
      data0filterSelectOptions,
      selectedOptions,
      modal,
    } = this.state;

    return (
      <Container>
        {loading ? (
          <MDBContainer>
            <MDBRow center>
              <MDBSpinner />
            </MDBRow>
          </MDBContainer>
        ) : (
          <Fragment>
            <InfoModal
              toggleModal={() => this.setState({ modal: !this.state.modal })}
              modal={modal}
            />
            <section>
              <MDBRow>
                <SingleMetric
                  value={voteCount ? voteCount.toLocaleString(locale) : null}
                  label={`${formatMessage(messages.TotalVote)}:`}
                  icon="vote-yea"
                />

                <SingleMetric
                  value={
                    contentCount ? contentCount.toLocaleString(locale) : null
                  }
                  label={`${formatMessage(messages.TotalContent)}:`}
                  icon="file-alt"
                />
              </MDBRow>
              <MDBRow>
                <ComparisonMetric
                  icon="chart-line"
                  label={formatMessage(messages.TodayVoteVsYesterday)}
                  active={vsResponseDay.active}
                  diffPercentage={Math.ceil(
                    Math.abs(
                      ((vsResponseDay.active - vsResponseDay.vs) * 100) /
                        (vsResponseDay.vs || 1)
                    )
                  )}
                  diffPercentageText={
                    vsResponseDay.active >= vsResponseDay.vs
                      ? formatMessage(messages.BetterThanYesterday)
                      : formatMessage(messages.WorseThanYesterday)
                  }
                  direction={
                    vsResponseDay.active >= vsResponseDay.vs ? 'up' : 'down'
                  }
                />
                <ComparisonMetric
                  icon="chart-line"
                  label={formatMessage(messages.ThisMonthVoteVsLastMonth)}
                  active={vsResponseMonth.active}
                  diffPercentage={Math.ceil(
                    Math.abs(
                      ((vsResponseMonth.active - vsResponseMonth.vs) * 100) /
                        (vsResponseMonth.vs || 1)
                    )
                  )}
                  diffPercentageText={
                    vsResponseMonth.active >= vsResponseMonth.vs
                      ? formatMessage(messages.BetterThanLastMonth)
                      : formatMessage(messages.WorseThanLastMonth)
                  }
                  direction={
                    vsResponseMonth.active >= vsResponseMonth.vs ? 'up' : 'down'
                  }
                />
              </MDBRow>
            </section>

            <SectionWithTitle title={formatMessage(messages.VoteCounts)}>
              {data0 && data0.length > 2 ? (
                <MDBRow>
                  <MDBCol lg="4" md="12" className="mx-lg-auto">
                    <MDBSelect
                      multiple
                      options={data0filterSelectOptions}
                      selected=""
                      label={formatMessage(messages.SelectDate)}
                      getTextContent={this.updateSelectedOptions}
                    />
                  </MDBCol>
                  <MDBCol lg="12" md="12">
                    <MDBCard className="mb-4">
                      <MDBCardBody>
                        <MDBTable responsive>
                          <thead>
                            <tr>
                              <th className="font-weight-bold dark-grey-text">
                                <strong>{data0[0][0]}</strong>
                              </th>
                              <th className="font-weight-bold dark-grey-text">
                                <strong>{data0[0][1]}</strong>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {data0
                              .filter(
                                (row) => selectedOptions[row[0].split('-')[0]]
                              )
                              .map((row, i) => {
                                return (
                                  <tr key={i}>
                                    <td>{row[0]}</td>
                                    <td>{row[1]}</td>
                                  </tr>
                                );
                              })}
                          </tbody>
                          <tfoot>
                            <tr>
                              <td className="font-weight-bold dark-grey-text">
                                {formatMessage(messages.TotalSelectedVote)}
                              </td>
                              <td className="font-weight-bold dark-grey-text">
                                {((d) => {
                                  if (!d.length) return 0;
                                  const filteredD = d.filter(
                                    (row) =>
                                      selectedOptions[row[0].split('-')[0]]
                                  );
                                  if (!filteredD.length) return 0;
                                  return filteredD
                                    .map((tuple) => tuple[1])
                                    .reduce((acc, curr) => acc + curr, 0);
                                })(data0)}
                              </td>
                            </tr>
                          </tfoot>
                        </MDBTable>
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCol>
                </MDBRow>
              ) : (
                formatMessage(messages.NoData)
              )}
            </SectionWithTitle>

            <SectionWithTitle title={formatMessage(messages.VoteGraphs)}>
              <Row className="text-center">
                <Col md="6" style={{ minHeight: '300px' }}>
                  {data1 && data1.length > 2 ? (
                    <Chart
                      width={'100%'}
                      height={'400px'}
                      chartType="AreaChart"
                      loader={<Spinner blue big />}
                      data={data1}
                      options={{
                        title: formatMessage(messages.Graph1),
                      }}
                      rootProps={{ 'data-testid': '1' }}
                    />
                  ) : (
                    formatMessage(messages.NoData)
                  )}
                </Col>

                <Col md="6" style={{ minHeight: '300px' }}>
                  {data2 && data2.length > 2 ? (
                    <Chart
                      width={'100%'}
                      height={'400px'}
                      chartType="Bar"
                      loader={<Spinner blue big />}
                      data={data2}
                      options={{
                        chart: {
                          title: formatMessage(messages.Graph3),
                        },
                      }}
                      rootProps={{ 'data-testid': '2' }}
                    />
                  ) : (
                    formatMessage(messages.NoData)
                  )}
                </Col>
              </Row>
            </SectionWithTitle>

            <SectionWithTitle title={formatMessage(messages.Contents)}>
              <Row className="text-center" style={{ marginBottom: '100px' }}>
                <MDBCol md="12" lg="12" style={{ paddingLeft: '50px' }}>
                  {data2 && data2.length > 2 ? (
                    <Chart
                      height={350}
                      chartType="Calendar"
                      loader={<Spinner blue big />}
                      data={data3}
                      options={{
                        title: formatMessage(messages.GraphCalendar),
                        tooltip: { isHtml: true },
                        width: '100%',
                      }}
                      rootProps={{ 'data-testid': '3' }}
                    />
                  ) : null}
                </MDBCol>
              </Row>
            </SectionWithTitle>

            <ContentStats />
          </Fragment>
        )}
      </Container>
    );
  }
}

export default makeComponentTrashable(injectIntl(Home));
