import React, { useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { injectIntl } from 'react-intl';

import messages from '../messages';
import { getLeads, leadEdit } from '../../../api';

const LeadSelector = ({ id, index, intl, leadSelect }) => {
  const { formatMessage } = intl;

  const clickRef = useRef({ contains: () => {} });

  const [value, setValue] = useState('');
  const [leads, setLeads] = useState([]);
  const [display, setDisplay] = useState(false);

  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingID, setLoadingID] = useState(false);
  const [codeName, setCodeName] = useState('');
  const [nextOffset, setNextOffset] = useState(null);

  const handleClickOutside = () => {
    const click = (event) => {
      if (clickRef.current && !clickRef.current.contains(event.target)) {
        setDisplay(false);
      }
    };

    document.addEventListener('mousedown', click);

    return () => {
      document.removeEventListener('mousedown', click);
    };
  };
  useEffect(handleClickOutside, []);

  useEffect(() => {
    if (id) {
      setLoadingID(true);
      leadEdit(id).then((response) => {
        setValue(response.data.name);
        setLoadingID(false);
      });
    } else {
      setValue(null);
    }
  }, [id]);

  const loadMore = async () => {
    if (hasMore && !loading) {
      setLoading(true);

      const response = await getLeads(nextOffset, codeName);

      setLeads([...leads, ...response.data]);
      setNextOffset(
        response.data.length > 0
          ? response.data[response.data.length - 1].id
          : nextOffset
      );
      setHasMore(response.data.length > 0);

      setLoading(false);
    }
  };

  const searchText = (event) => {
    event.preventDefault();

    setLeads([]);
    setHasMore(true);
    setLoading(false);
    setNextOffset(null);
  };

  const onSelect = (event, pc) => {
    event.preventDefault();

    leadSelect(index, pc);

    setDisplay(false);
    setValue(pc.name);
  };

  const onInputClick = () => {
    setDisplay(true);
    setHasMore(true);
  };

  return (
    <div className="select-wrapper mdb-select mt-4" key={index} ref={clickRef}>
      <span className="caret">{'▼'}</span>
      <input
        className="select-dropdown text-dark"
        type="text"
        name=""
        value={(loadingID && '...') || value || formatMessage(messages.AddLead)}
        style={{ marginLeft: 10 }}
        onClick={onInputClick}
        readOnly
      />
      <ul
        className={`dropdown-content select-dropdown`}
        style={{
          display: display ? 'block' : 'none',
          opacity: 1,
          top: 0,
          left: 0,
          position: 'absolute',
          width: '22.5rem',
        }}
      >
        <span className="search-wrap form-inline md-form mb-4 mx-2">
          <div className="row md-form m-0 p-0">
            <input
              type="text"
              className="form-control"
              placeholder={formatMessage(messages.EnterLeadName)}
              name="codeName"
              value={codeName}
              onChange={(event) => setCodeName(event.target.value)}
              style={{ width: '73%' }}
            />
            <div
              className="btn btn-elegant btn-indigo Ripple-parent mr-0 px-3"
              onClick={searchText}
              style={{ width: '25%' }}
            >
              {formatMessage(messages.Search)}
            </div>
          </div>
        </span>
        <InfiniteScroll
          element={'div'}
          hasMore={hasMore}
          initialLoad
          pageStart={0}
          loadMore={loadMore}
          useWindow={false}
          loader={
            <li key="s0">
              <span className="text-center text-info">
                {formatMessage(messages.Loading)}
              </span>
            </li>
          }
        >
          {value && (
            <li key={-1}>
              <span
                className="text-center text-danger"
                onClick={(e) => onSelect(e, { id: null, name: null })}
              >
                {'Remove Lead'}
              </span>
            </li>
          )}
          {leads.map((lead, i) => {
            return (
              <li key={i}>
                <span
                  className="text-center text-dark"
                  onClick={(e) => onSelect(e, lead)}
                >
                  {lead.name}
                </span>
              </li>
            );
          })}
        </InfiniteScroll>
      </ul>
    </div>
  );
};

export default injectIntl(LeadSelector);
