import React, { Fragment } from 'react';
import { Row, Col, Spinner, MDBTooltip, MDBBtn, MDBIcon } from 'mdbreact';
import { Chart } from 'react-google-charts';

import history from '../../core/history';
import SectionWithTitle from '../Common/SectionWithTitle';
import SingleMetric from '../Common/SingleMetric';
import SankeyChart from './SankeyChart';

const QuizStats = ({ messages, formatMessage, data, fQMetrics }) => {
  //1st
  const dataCalculated = data.friend_quiz
    ? []
    : data.t === 'quiz'
    ? data.questions.map((q) => [
        q.title,
        q.answers.filter((a) => a.is_right_answer === 1)[0].vote_count /
          q.vote_count,
        (q.vote_count -
          q.answers.filter((a) => a.is_right_answer === 1)[0].vote_count) /
          q.vote_count,
      ])
    : null;

  const dataCalculatedWithHeaders =
    data.t === 'quiz'
      ? [
          ['', formatMessage(messages.Correct), formatMessage(messages.Wrong)],
          ...dataCalculated,
        ]
      : null;

  //2nd
  const maxNumAnswers = Math.max(
    ...data.questions.map((q) => q.answers.length),
    0
  );
  const dataAnswerNumbers2 = data.questions.map((q) => [
    q.title,
    ...q.answers.reduce(
      (r, a) => [
        ...r,
        (a.vote_count / q.vote_count) * 100,
        `<big><b>${q.title}:</b></big><br /> ${a.title}: ${
          (a.vote_count / q.vote_count).toFixed(2) * 100
        }%`,
      ],
      []
    ),
    ...Array(maxNumAnswers * 2 - q.answers.length * 2),
  ]);

  const dataAnswerNumbersWithHeaders2 = [
    [...Array(maxNumAnswers).keys()].reduce(
      (r, e) => {
        return [
          ...r,
          '',
          { type: 'string', role: 'tooltip', p: { html: true } },
        ];
      },
      ['']
    ),
    ...dataAnswerNumbers2,
  ];

  //3rd
  const dataAnswerNumbers = data.questions.map((q) => [
    q.title,
    ...q.answers.reduce(
      (r, a) => [
        ...r,
        a.vote_count,
        `<big><b>${q.title}:</b></big><br /> ${a.title}: ${a.vote_count}`,
      ],
      []
    ),
    ...Array(maxNumAnswers * 2 - q.answers.length * 2).fill(0),
    q.vote_count,
    '',
  ]);

  const dataAnswerNumbersWithHeaders = [
    [
      ...[...Array(maxNumAnswers).keys()].reduce(
        (r, e) => {
          return [
            ...r,
            '',
            { type: 'string', role: 'tooltip', p: { html: true } },
          ];
        },
        ['']
      ),
      { role: 'annotation' },
      { role: 'annotation' },
    ],
    ...dataAnswerNumbers,
  ];

  //4th
  const completionData = [
    [formatMessage(messages.Finish), data.finish],
    [formatMessage(messages.NotFinish), data.start - data.finish],
  ];

  //5th
  let totalResults = null;
  let resultsDataCalculated = null;
  let resultsDataCalculatedWithHeaders = null;
  let resultsCalculated = [];

  totalResults = data.results.reduce(
    (result, item) => +result + +item.counter,
    0
  );
  resultsDataCalculated = data.results.map((r) => [
    r.title,
    r.counter / totalResults,
  ]);
  resultsDataCalculatedWithHeaders = [['', ''], ...resultsDataCalculated];

  //6th
  resultsCalculated = data.results.map((r) => {
    return {
      title: r.title,
      url: r.url,
      counter: r.counter,
      url_counter: r.url_counter,
      perc:
        r.url_counter > r.counter
          ? 100
          : ((r.url_counter * 100) / (r.counter || 1)).toFixed(0),
    };
  });

  return (
    <Fragment>
      {data.friend_quiz ? (
        <SectionWithTitle title={'Friend Quiz Metrics'}>
          <Row className="text-center">
            <MDBBtn
              color="primary"
              outline
              rounded
              onClick={() => history.push(`/fqsessions/${data.id}`)}
            >
              <MDBIcon icon="sort-amount-up" size="lg" />
              {' List Sessions'}
            </MDBBtn>
          </Row>
          <Row className="text-center">
            <SingleMetric
              value={fQMetrics.session_count}
              label={'Session Count (Source)'}
              icon={'user'}
            />

            <SingleMetric
              value={fQMetrics.player_count}
              label={'Player Count (Friend)'}
              icon={'user'}
            />

            <SingleMetric
              value={fQMetrics.avg}
              label={'Avg Play (Friend)'}
              icon={'user'}
            />
          </Row>
        </SectionWithTitle>
      ) : null}
      <SectionWithTitle title={formatMessage(messages.QuizStats)}>
        {data.t === 'quiz' && !data.friend_quiz ? (
          <Row>
            <Col md="12">
              <Chart
                chartType="BarChart"
                data={dataCalculatedWithHeaders}
                loader={<Spinner big blue />}
                width="100%"
                height="600px"
                options={{
                  legend: 'bottom',
                  isStacked: true,
                }}
                formatters={[
                  {
                    type: 'NumberFormat',
                    column: 1,
                    options: {
                      pattern: '##%',
                    },
                  },
                  {
                    type: 'NumberFormat',
                    column: 2,
                    options: {
                      pattern: '##%',
                    },
                  },
                ]}
              />
            </Col>
          </Row>
        ) : null}
        <Row>
          <Col md="12">
            <Chart
              chartType="BarChart"
              data={dataAnswerNumbersWithHeaders2}
              loader={<Spinner big blue />}
              width="100%"
              height="600px"
              options={{
                legend: 'none',
                isStacked: true,
                tooltip: { isHtml: true },
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Chart
              chartType="BarChart"
              data={dataAnswerNumbersWithHeaders}
              loader={<Spinner big blue />}
              width="100%"
              height="600px"
              options={{
                legend: 'none',
                isStacked: true,
                tooltip: { isHtml: true },
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <Chart
              chartType="PieChart"
              columns={[{ type: 'string' }, { type: 'number', label: '' }]}
              rows={completionData}
              loader={<Spinner big blue />}
              width="100%"
              height="700px"
              options={{
                title: formatMessage(messages.QuizCompletionRate),
                legend: 'bottom',
              }}
            />
          </Col>
          {data.t !== 'set' && (
            <Col md="6">
              <Chart
                chartType="BarChart"
                data={resultsDataCalculatedWithHeaders}
                loader={<Spinner big blue />}
                width="100%"
                height="700px"
                options={{
                  title: formatMessage(messages.ResultsSplit),
                  legend: 'none',
                }}
                formatters={[
                  {
                    type: 'NumberFormat',
                    column: 1,
                    options: {
                      pattern: '##%',
                    },
                  },
                ]}
              />
            </Col>
          )}
        </Row>

        <Row className="text-center">
          <Col md="12" className="text-center">
            <h6 className="text-center font-weight-bold pt-4 pb-5">
              <strong>{formatMessage(messages.ResultUrls)}</strong>
            </h6>
            <table className="table table-striped table-bordered table-sm table-fixed-header">
              <thead>
                <tr>
                  <th className="th-sm">{'Title'}</th>
                  <th className="th-sm">{'Result Counter'}</th>
                  <th className="th-sm">{'Result Url'}</th>
                  <th className="th-sm">{'Url Counter'}</th>
                  <th className="th-sm">{'Click %'}</th>
                </tr>
              </thead>
              <tbody>
                {resultsCalculated.map((row, i) => (
                  <tr key={`results${i}`}>
                    <td>
                      <MDBTooltip placement="top" domElement>
                        <span>{row.title}</span>
                        <div>{row.title}</div>
                      </MDBTooltip>
                    </td>
                    <td>
                      <MDBTooltip placement="top" domElement>
                        <span>{row.counter}</span>
                        <div>{row.counter}</div>
                      </MDBTooltip>
                    </td>
                    <td>
                      <MDBTooltip placement="top" domElement>
                        <span>{row.url}</span>
                        <div>{row.url}</div>
                      </MDBTooltip>
                    </td>
                    <td>
                      <MDBTooltip placement="top" domElement>
                        <span>{row.url_counter}</span>
                        <div>{row.url_counter}</div>
                      </MDBTooltip>
                    </td>
                    <td>
                      <MDBTooltip placement="top" domElement>
                        <span>{row.perc}</span>
                        <div>{row.perc}</div>
                      </MDBTooltip>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Col>
        </Row>

        {(data.results && totalResults > data.results.length) || data.result ? (
          <Row className="text-center">
            <Col md="12" className="text-center">
              <h6 className="text-center font-weight-bold pt-4 pb-5">
                <strong>Flow</strong>
              </h6>
              <SankeyChart
                type={data.t}
                id={data.id}
                formatMessage={formatMessage}
                messages={messages}
              />
            </Col>
          </Row>
        ) : null}
      </SectionWithTitle>
    </Fragment>
  );
};

export default QuizStats;
