import React, { Fragment } from 'react';
import { injectIntl } from 'react-intl';
import {
  MDBCol,
  MDBRow,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBBtn,
  MDBIcon,
} from 'mdbreact';
import ReactTooltip from 'react-tooltip';

import messages from '../messages';
import CalculatorFormula from './CalculatorFormula';

const ViewConditions = ({
  intl: { formatMessage },
  step,
  data,
  handleTextChange,
  questions,
  questionIndex,
  selectRelation,
  showEveryone,
  checkboxChanged,
  deleteQuestion,
  deletingQuestion,
  copyQuestion,
  editQuestion,
  checkboxAllChanged,
  saveSet,
  saving,
}) => {
  const onTextChange = (e) => {
    if (e.target.value.length < 10000) {
      handleTextChange(e);
    }
  };

  return (
    <Fragment>
      {data.is_calculator ? (
        <CalculatorFormula
          questions={questions}
          value={data.cal_formula}
          onChange={onTextChange}
          onClick={(e) => saveSet(e, true)}
          disabled={saving}
        />
      ) : null}
      <h2 className="text-center font-weight-bold pt-4 pb-5">
        <strong>{formatMessage(messages.ViewConditions)}</strong>
      </h2>
      <MDBCard>
        <MDBCardBody>
          {questionIndex > 0 && (
            <MDBRow style={{ marginBottom: '20px' }}>
              <MDBCol md="12">
                <label>{formatMessage(messages.DisplayThis)}</label>
                <MDBInput
                  label="Everyone"
                  type="checkbox"
                  id="checkbox1"
                  onChange={() => selectRelation('everyone')}
                  checked={showEveryone}
                />
                <MDBInput
                  label="Only people voted for"
                  type="checkbox"
                  id="checkbox2"
                  onChange={() => selectRelation('only')}
                  checked={
                    !showEveryone &&
                    !questions[questionIndex].set_condition_reverse
                  }
                />
                <MDBInput
                  label="Only people did not vote for"
                  type="checkbox"
                  id="checkbox3"
                  onChange={() => selectRelation('onlynot')}
                  checked={
                    !showEveryone &&
                    questions[questionIndex].set_condition_reverse
                  }
                />
              </MDBCol>
            </MDBRow>
          )}

          {questions.map((q, i) => (
            <Fragment key={i}>
              <table style={{ width: '100%' }}>
                <tbody>
                  <tr
                    style={{
                      backgroundColor:
                        questionIndex === i && step === 2 ? '#E8E8E8' : 'white',
                    }}
                  >
                    {step === 2 ? (
                      <Fragment>
                        <td style={{ width: '50%' }}>
                          <ReactTooltip effect="solid" multiline={true} />
                          <span
                            data-tip={formatMessage(messages.Delete)}
                            data-place="bottom"
                          >
                            <MDBBtn
                              rounded
                              color="danger"
                              size="sm"
                              onClick={() => deleteQuestion(i)}
                              disabled={
                                deletingQuestion ||
                                !q.id ||
                                questions.length < 3
                              }
                            >
                              <MDBIcon icon="trash-alt" />
                            </MDBBtn>
                          </span>
                          <span
                            data-tip={formatMessage(messages.Copy)}
                            data-place="bottom"
                          >
                            <MDBBtn
                              rounded
                              color="info"
                              size="sm"
                              onClick={() => copyQuestion(i)}
                              disabled={!q.id}
                            >
                              <MDBIcon icon="copy" />
                            </MDBBtn>
                          </span>
                          <span
                            data-tip={formatMessage(messages.Edit)}
                            data-place="bottom"
                          >
                            <MDBBtn
                              rounded
                              color="warning"
                              size="sm"
                              onClick={() => editQuestion(i)}
                              disabled={!q.id}
                            >
                              <MDBIcon icon="pencil-alt" />
                            </MDBBtn>
                          </span>
                        </td>
                      </Fragment>
                    ) : null}
                    <td>
                      <h6>{`${i + 1}. ${q.title}`}</h6>
                    </td>
                  </tr>
                </tbody>
              </table>
              {!showEveryone && questionIndex > i && questions[questionIndex] && (
                <Fragment>
                  <MDBInput
                    label={'Select All'}
                    type="checkbox"
                    id={`checkboxall${i}`}
                    onChange={(e) => checkboxAllChanged(e.target.checked, i)}
                  />
                  {q.answers.map((a, ind) => (
                    <MDBInput
                      key={ind}
                      label={a.title}
                      type="checkbox"
                      id={`checkbox${i}-${a.id}`}
                      checked={
                        questions[questionIndex].set_conditions &&
                        questions[questionIndex].set_conditions.includes(a.id)
                      }
                      onChange={(e) => checkboxChanged(e.target.checked, a.id)}
                    />
                  ))}
                </Fragment>
              )}
            </Fragment>
          ))}
        </MDBCardBody>
      </MDBCard>
    </Fragment>
  );
};

export default injectIntl(ViewConditions);
