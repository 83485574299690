/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Row, Col, Container, Card, CardBody, toast } from 'mdbreact';
import {
  createPoll,
  getPublisherSettings,
  setPublisherSettings,
} from '../../../api';
import history from '../../../core/history';

import Step1 from './Step1';
import Prompt from '../Prompt';
import messages from '../messages';

const initialState = {
  formActivePanel: 1,
  pollData: {
    title: '',
    answer_type: 'yesno',
    answers: [
      { title: '', background: '' },
      { title: '', background: '' },
    ],
    require_login: 0,
    privacy: 'private',
    play_once: 0,
    play_once_msg: null,
    play_once_img: null,
    play_once_link: null,
    play_once_btn: null,
    answer_time_limit: -1,
    hide_counter: 0,
    hide_results: 0,
    auto_reports_enabled: 0,
    rotate_answers: 0,
    rotate_answers_last: 0,
    end_date: 0,
    end_date_day: '00',
    end_date_hour: '00',
    end_date_minute: '00',
    allow_multiple_answers: 0,
    max_multi_punch_answer: 0,
    embed_background: '',
    embed_footer_img: '',
    embed_footer_url: null,
  },
  src: null,
  image: null,
  createdPollId: null,
  prompt: false,
  isEmbedly: false,
};

class PollCreate extends Component {
  state = initialState;

  static getDerivedStateFromProps(props, state) {
    if (state.pollData.answer_type === 'yesno' && state.formActivePanel === 1) {
      return {
        pollData: {
          ...state.pollData,
          answers: [
            { title: props.intl.formatMessage(messages.Yes), background: '' },
            { title: props.intl.formatMessage(messages.No), background: '' },
          ],
        },
      };
    }
    return null;
  }

  async componentDidMount() {
    const response = await getPublisherSettings('poll');

    this.setState({
      pollData: {
        ...this.state.pollData,
        ...response.data,
        end_date: 0,
      },
    });
  }

  pixelCode = (index, pc) => {
    if (pc) {
      this.setState({
        pollData: {
          ...this.state.pollData,
          answers: this.state.pollData.answers.map((a, i) => {
            if (i === index) {
              return {
                ...a,
                pixel_code_id: pc.id,
                pixel_code_name: pc.name,
              };
            }
            return a;
          }),
        },
      });
    }
  };

  leadSelect = (index, lead) => {
    if (lead) {
      this.setState({
        pollData: {
          ...this.state.pollData,
          answers: this.state.pollData.answers.map((a, i) => {
            if (i === index) {
              return {
                ...a,
                lead_id: lead.id,
                lead_name: lead.name,
              };
            }
            return a;
          }),
        },
      });
    }
  };

  togglePrompt = () => {
    this.setState({
      prompt: !this.state.prompt,
    });
  };

  handleNextPrevClick = (param) => (e) => {
    if (param === 2 && this.state.optionsDirty) {
      this.setState({ prompt: true });
    }

    this.setState({
      formActivePanel: param,
      formActivePanelChanged: true,
    });
  };

  onYesClick = async () => {
    const { pollData } = this.state;
    this.togglePrompt();

    await setPublisherSettings('poll', {
      require_login: pollData.require_login,
      privacy: pollData.privacy,
      answer_time_limit: pollData.answer_time_limit,
      hide_counter: pollData.hide_counter,
      hide_results: pollData.hide_results,
      auto_reports_enabled: pollData.auto_reports_enabled,
      rotate_answers: pollData.rotate_answers,
      rotate_answers_last: pollData.rotate_answers_last,
      allow_multiple_answers: pollData.allow_multiple_answers,
      max_multi_punch_answer: pollData.max_multi_punch_answer,
    });
  };

  handleTextChange = async (e) => {
    const inputText = e.target.value;
    this.setState({
      pollData: {
        ...this.state.pollData,
        [e.target.name]: inputText,
      },
    });
  };

  handleTextChangeWithoutEmbedly = (e) => {
    this.setState({
      pollData: {
        ...this.state.pollData,
        [e.target.name]: e.target.value === '' ? null : e.target.value,
      },
    });
  };

  setSRC = (src, target) => {
    if (target && target.length > 0) {
      this.setState({
        pollData: {
          ...this.state.pollData,
          [target]: src,
        },
      });
    } else {
      this.setState({ src: src });
    }
  };

  getSelectValue = (value, name) => {
    if (value) {
      this.setState({
        pollData: {
          ...this.state.pollData,
          [name]: value,
        },
      });
    }
  };

  getSelectValue2 = (value, name) => {
    if (value === '3' || value === '0' || !value) return;
    this.setState({
      pollData: {
        ...this.state.pollData,
        [name]: value,
      },
    });
  };

  changePollType = (type) => {
    switch (type) {
      case '1':
        this.setState({
          pollData: {
            ...this.state.pollData,
            answer_type: 'yesno',
            answers: [
              { title: 'yes', background: '' },
              { title: 'no', background: '' },
            ],
          },
        });
        break;
      case '2':
        this.setState({
          pollData: {
            ...this.state.pollData,
            answer_type: 'text',
            answers: [
              { title: '', background: '' },
              { title: '', background: '' },
            ],
          },
        });
        break;
      case '3':
        this.setState({
          pollData: {
            ...this.state.pollData,
            answer_type: 'media',
            answers: [
              { title: '', background: '' },
              { title: '', background: '' },
            ],
          },
        });
        break;
      case '4':
        this.setState({
          pollData: {
            ...this.state.pollData,
            answer_type: 'score',
            answers: [
              {
                title: '1',
                background: '',
              },
              {
                title: '2',
                background: '',
              },
              {
                title: '3',
                background: '',
              },
              {
                title: '4',
                background: '',
              },
              {
                title: '5',
                background: '',
              },
            ],
          },
        });
        break;
      default:
        this.setState({
          pollData: {
            ...this.state.pollData,
            answer_type: 'yesno',
            answers: [
              { title: 'yes', background: '' },
              { title: 'no', background: '' },
            ],
          },
        });
    }
  };

  handleAnswerChange = (e, i) => {
    this.setState({
      pollData: {
        ...this.state.pollData,
        answers: this.state.pollData.answers.map((a, index) => {
          if (index === i) {
            return { ...a, title: e.target.value };
          }
          return a;
        }),
      },
    });
  };

  setAnswerImg = (index, value) => {
    const { pollData } = this.state;

    this.setState({
      pollData: {
        ...pollData,
        answers: pollData.answers.map((a, i) => {
          if (index === i) {
            return { ...a, background: value };
          }
          return a;
        }),
      },
    });
  };

  changeNumber = (e, direction) => {
    e.preventDefault();
    if (direction === 'add') {
      this.setState({
        pollData: {
          ...this.state.pollData,
          answers: [
            ...this.state.pollData.answers,
            { title: '', background: '' },
          ],
          max_multi_punch_answer: this.state.pollData.answers.length + 1,
        },
      });
    } else if (direction === 'delete') {
      this.setState({
        pollData: {
          ...this.state.pollData,
          answers: this.state.pollData.answers.slice(0, -1),
          max_multi_punch_answer: this.state.pollData.answers.length - 1,
        },
      });
    }
  };

  handleSwitchChange = (e, name) => {
    this.setState({ optionsDirty: true });
    if (name === 'privacy') {
      this.setState({
        pollData: {
          ...this.state.pollData,
          [name]: e.target.value === 'true' ? 'public' : 'private',
        },
      });
    } else if (name === 'play_once') {
      this.setState({
        pollData: {
          ...this.state.pollData,
          [name]: e.target.checked ? 1 : 0,
        },
      });
    } else if (name === 'rotate_answers') {
      this.setState({
        pollData: {
          ...this.state.pollData,
          [name]: e.target.checked ? 1 : 0,
          rotate_answers_last: 0,
        },
      });
    } else if (name === 'rotate_answers_last') {
      this.setState({
        pollData: {
          ...this.state.pollData,
          [name]: e.target.checked ? 1 : 0,
          rotate_answers: 0,
        },
      });
    } else if (name === 'answer_time_limit') {
      this.setState({
        pollData: {
          ...this.state.pollData,
          [name]: e.target.value === 'false' ? -1 : 0,
        },
      });
    } else if (name === 'allow_multiple_answers') {
      this.setState({
        pollData: {
          ...this.state.pollData,
          [name]: e.target.value === 'true' ? 0 : 1,
          max_multi_punch_answer: this.state.pollData.answers.length,
        },
      });
    } else if (name === 'hide_counter') {
      this.setState({
        pollData: {
          ...this.state.pollData,
          [name]: e.target.value === 'true' ? 1 : 0,
        },
      });
    } else if (name === 'end_date') {
      this.setState({
        pollData: {
          ...this.state.pollData,
          [name]: e.target.value === 'true' ? 0 : 1,
          end_date_day: e.target.value === 'true' ? '00' : '30',
          end_date_hour: '00',
          end_date_minute: '00',
        },
      });
    } else {
      this.setState({
        pollData: {
          ...this.state.pollData,
          [name]: e.target.value === 'true' ? 0 : 1,
        },
      });
    }
    if (name !== 'end_date') {
      this.setState({ prompt: true });
    }
  };

  createPoll = async (event) => {
    if (event) event.preventDefault();
    const { formatMessage } = this.props.intl;
    const { pollData, src, isEmbedly } = this.state;

    try {
      if (isEmbedly) {
        const r2 = await createPoll(pollData, src);
        this.setState({
          ...initialState,
          createdPollId: r2.data.id,
        });
      } else {
        await createPoll(pollData, src);
      }

      toast.success(formatMessage(messages.ContentCreated));
      history.push('/content');
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    const { pollData, src, createdPollId, prompt, isEmbedly } = this.state;
    const { formatMessage } = this.props.intl;

    return (
      <Container>
        <Prompt
          toggleModal={this.togglePrompt}
          modal={prompt}
          onYesClick={this.onYesClick}
        />
        <Row>
          <Col md="7">
            <Card>
              <CardBody>
                <h2 className="text-center font-weight-bold pt-4 pb-5">
                  <strong>{formatMessage(messages.CreatePoll)}</strong>
                </h2>

                <Row>
                  <Step1
                    handleNextPrevClick={this.handleNextPrevClick}
                    handleTextChange={this.handleTextChange}
                    poll={pollData}
                    src={src}
                    setSRC={this.setSRC}
                    getSelectValue={this.getSelectValue}
                    getSelectValue2={this.getSelectValue2}
                    isEmbedly={isEmbedly}
                    handleSwitchChange={this.handleSwitchChange}
                    handleTextChangeWithoutEmbedly={
                      this.handleTextChangeWithoutEmbedly
                    }
                    changePollType={this.changePollType}
                    handleAnswerChange={this.handleAnswerChange}
                    changeNumber={this.changeNumber}
                    setAnswerImg={this.setAnswerImg}
                    pixelCode={this.pixelCode}
                    leadSelect={this.leadSelect}
                    createPoll={this.createPoll}
                  />
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col md="5">
            <h2 className="text-center font-weight-bold pt-4 pb-5">
              <strong>{formatMessage(messages.PollPreview)}</strong>
            </h2>
            {createdPollId ? (
              <iframe
                src={`//www.poltio.com/e/poll/${createdPollId}`}
                width="100%"
                height="650"
                scrolling="no"
                frameBorder="0"
                title={`poll${createdPollId}`}
              />
            ) : (
              <h6 className="text-center pt-4 pb-5">
                {formatMessage(messages.YourContent)}
              </h6>
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default injectIntl(PollCreate);
