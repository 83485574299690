import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import {
  Row,
  Col,
  MDBInput,
  Select,
  SelectInput,
  SelectOption,
  SelectOptions,
  Button,
  MDBRow,
  MDBTooltip,
  MDBSwitch,
  MDBCol,
  MDBBtn,
} from 'mdbreact';
import messages from '../messages';
import PixelCodeSelector from '../PixelCodeSelector';
import LeadSelector from '../LeadSelector';
import ImageInput from '../../ImageInput';

class Step3 extends Component {
  render() {
    const { formatMessage } = this.props.intl;
    const {
      quizData,
      results,
      handleAnswerChange,
      handleResultSwitchChange,
      changeNumber,
      setResultImg,
      finishQuiz,
      questionCount,
      getSelectValue,
      pixelCode,
      leadSelect,
    } = this.props;

    const resultSize = 255;
    const maxAnswers =
      parseInt(quizData.pool_question_count, 10) < questionCount + 1
        ? parseInt(quizData.pool_question_count, 10) + 1
        : questionCount + 2;

    return (
      <Col md="12">
        <Row>
          <Col sm="12">
            {results.map((result, i) => (
              <Row
                key={i}
                className="collapse-media"
                style={{ background: '#E8E8E8', padding: '10px' }}
              >
                <div
                  className="collapse-media__item col-5"
                  style={{ overflow: 'visible' }}
                >
                  <ImageInput
                    title={formatMessage(messages.AddImage)}
                    setSRC={(s, t) => {
                      setResultImg(i, s);
                    }}
                    src={result.background}
                    answer
                  />
                </div>
                <div className="col-7">
                  <MDBInput
                    className="form-control"
                    type="textarea"
                    name="title"
                    label={`${formatMessage(messages.Result)} -${
                      resultSize - result.title.length
                    }`}
                    maxLenght={resultSize}
                    value={result.title}
                    onChange={(e) => handleAnswerChange(e, i)}
                  />
                  <MDBInput
                    className="form-control"
                    type="textarea"
                    name="desc"
                    label={formatMessage(messages.ResultDesc)}
                    value={result.desc}
                    onChange={(e) => handleAnswerChange(e, i)}
                  />
                  <MDBInput
                    className={`form-control w-100 
                    ${
                      !result.dont_shorten &&
                      result.url &&
                      result.url.length > 0 &&
                      !result.url.startsWith('http')
                        ? 'is-invalid'
                        : ''
                    }
                    `}
                    name="url"
                    label={formatMessage(messages.Url)}
                    value={result.url}
                    onChange={(e) => handleAnswerChange(e, i)}
                    type="textarea"
                  />
                  <MDBInput
                    className="form-control w-100"
                    name="url_text"
                    label={formatMessage(messages.UrlText)}
                    value={result.url_text}
                    onChange={(e) => handleAnswerChange(e, i)}
                    type="textarea"
                  />
                  <MDBRow>
                    <MDBCol md="6">
                      <label>{formatMessage(messages.DontShortenLink)}</label>
                    </MDBCol>
                    <MDBCol md="6">
                      <MDBTooltip placement="top" domElement>
                        <MDBSwitch
                          checked={result.dont_shorten === 1 ? true : false}
                          onChange={(e) => handleResultSwitchChange(e, i)}
                        />
                        <div>{formatMessage(messages.DontShortenDesc)}</div>
                      </MDBTooltip>
                    </MDBCol>
                  </MDBRow>
                  <Row>
                    <label>{formatMessage(messages.MinAnswers)}:</label>
                    <Select
                      getValue={(val) => getSelectValue(val[0], 'min_c', i)}
                    >
                      <SelectInput selected={result.min_c} />
                      <SelectOptions>
                        {[...Array(maxAnswers).keys()].map((elem, index) => (
                          <SelectOption value={index} key={`a${index}`}>
                            {index}
                          </SelectOption>
                        ))}
                      </SelectOptions>
                    </Select>
                  </Row>
                  <Row>
                    <label>{formatMessage(messages.MaxAnswers)}:</label>
                    <Select
                      getValue={(val) => getSelectValue(val[0], 'max_c', i)}
                    >
                      <SelectInput selected={result.max_c} />
                      <SelectOptions>
                        {[...Array(maxAnswers).keys()].map(
                          (elem, index) =>
                            index >= result.min_c && (
                              <SelectOption value={index} key={`a${index}`}>
                                {index}
                              </SelectOption>
                            )
                        )}
                      </SelectOptions>
                    </Select>
                  </Row>
                  <Row>
                    <PixelCodeSelector
                      index={i}
                      pixelCode={pixelCode}
                      id={result.pixel_code_id}
                    />
                  </Row>
                  <Row>
                    <LeadSelector
                      index={i}
                      leadSelect={leadSelect}
                      id={result.lead_id}
                    />
                  </Row>
                </div>
              </Row>
            ))}
            <Button color="secondary" onClick={(e) => changeNumber(e, 'add')}>
              {formatMessage(messages.AddResult)}
            </Button>
            {results.length > 1 && (
              <button
                color="secondary"
                onClick={(e) => changeNumber(e, 'delete')}
              >
                {formatMessage(messages.DeleteResult)}
              </button>
            )}
          </Col>
        </Row>
        <MDBBtn
          type="button"
          className={`btn btn-danger btn-rounded Ripple-parent float-right`}
          color="danger"
          onClick={finishQuiz}
          disabled={results[0].title.length <= 0 || results[0].desc.length <= 0}
        >
          {formatMessage(messages.FinishQuiz)}
        </MDBBtn>
        <MDBRow style={{ marginRight: '15px' }}>
          <MDBCol>
            {results[0].title.length <= 0 ? (
              <div className={'float-right text-danger'}>
                {formatMessage(messages.PleaseEnterResult)}
              </div>
            ) : null}
          </MDBCol>
        </MDBRow>
        <MDBRow style={{ marginRight: '15px' }}>
          <MDBCol>
            {results[0].desc.length <= 0 ? (
              <div className={'float-right text-danger'}>
                {formatMessage(messages.PleaseEnterResultDesc)}
              </div>
            ) : null}
          </MDBCol>
        </MDBRow>
      </Col>
    );
  }
}

export default injectIntl(Step3);
