import React from 'react';
import { useIntl } from 'react-intl';
import { MDBContainer, MDBNavbar, MDBNavbarBrand } from 'mdbreact';
import history from '../../core/history';
import logo from '../../images/poltio-logo.svg';

const Privacy = () => {
  const intl = useIntl();

  return (
    <div>
      <header>
        <MDBNavbar color="indigo" dark expand="md" scrolling fixed="top">
          <MDBNavbarBrand
            style={{ cursor: 'pointer' }}
            to="/"
            onClick={() => {
              history.push('/');
            }}
          >
            <img src={logo} alt="" />
          </MDBNavbarBrand>
        </MDBNavbar>
      </header>
      <MDBContainer>
        <div className="row mt-5 pt-5">
          <div className="col">
            {intl.locale === 'tr' ? (
              <div>
                <p>
                  Poltio; anket, bilgi yarışması, ürün bulucu, kişilik testi
                  gibi soru / cevap tabanlı interaktif içerikler oluşturmaya ve
                  oluşturulan içeriklerin yayınlanmasına imkan sağlayan yazılım
                  tabanlı bir teknoloji platformudur.
                </p>
                <p>
                  Poltio altyapısını kullanarak firmalar soru cevap tabanlı
                  içerikler oluşturup, Poltio aracılığıyla sağlanan Poltio.com
                  uzantılı bir internet sayfası aracılığıyla ya da kendi
                  websiteleri, mobil uygulamaları, sosyal medya, reklam
                  alanları, dijital terminaller ya da farklı dijital kanallar
                  içerisinde gömülü olarak yayınlayabilirler.
                </p>
                <p>
                  Poltio aracılığıyla oluşturulan içerikler çoktan seçmeli ya da
                  açık uçlu sorulardan oluşur.
                </p>
                <p>
                  İçeriklerdeki soruları cevaplandıran katılımcılara, verdikleri
                  cevaplara göre özelleştirilmiş sonuçlar gösterilebilir. Hangi
                  koşullarda, hangi sonuçların gösterileceği ve yapılan iletişim
                  Poltio platformu aracılığıyla içerik üreten tarafından
                  belirlenmektedir. Poltio’nun gösterilecek sonuçlara erişimi
                  yahut etkisi bulunmamaktadır.
                </p>
                <p>
                  Oluşturulan içeriklerde kullanılan font, renk, logo gibi
                  alanlar içeriği oluşturan tarafından özelleştirilebilir.
                  İçeriklerde kullanılan yazı, fotoğraf, video, vb. materyaller,
                  bunlarla ilgili yükümlülükler ve içeriğin genel ahlak ve
                  yasalara uygunluğu Poltio platformu aracılığıyla içeriği
                  oluşturan firmanın yükümlülüğündedir ve Poltio bunlardan
                  sorumlu değildir.
                </p>
                <p>
                  Poltio platformu üzerinde kullanıcıların içerikler içerisinde
                  yer alan sorulara verdikleri çoktan seçmeli ve açık uçlu
                  cevaplar, erişim tarihi, içeriğin gösterildiği internet
                  sitesinin adresi, kendilerine gösterilen sonuç ve kullanıcının
                  bu sonuçla ilgili aldığı aksiyon gibi bilgiler raporlama ve
                  analiz amaçlı olarak saklanır.
                </p>
                <p>
                  Poltio platformunu kullanan firma bu bilgileri rapor olarak
                  çekebileceği ve bu bilgilere erişim sağlayabileceği gibi, eş
                  zamanlı olarak kendi platformlarına yazılmasını sağlayabilir.
                </p>
                <p>
                  Yasal sebeplerle, Poltio tarafından, içeriklere erişen
                  kullanıcıların internet protokol (IP) adresi de saklanır. IP
                  adres bilgisi Poltio platformu aracılığıyla içerik oluşturan
                  firmayla paylaşılmaz. Poltio raporlama amaçlı olarak IP adresi
                  aracılığıyla şehir bazında lokasyon bilgisini de saklar.
                </p>
                <p>
                  Kullanıcı içerik içerisinde ayrıca paylaşmadığı sürece Poltio
                  soruları cevaplayan kullanıcıların isim, soyisim, email, cep
                  telefonu gibi kişisel bilgilerine erişemez yahut bu bilgileri
                  haiz değildir; ve bu bilgiler soru cevaplarıyla eşlenemez.
                  Kullanıcı Poltio aracılığıyla oluşturulmuş içeriği, üye olduğu
                  ya da kendisiyle ilgili kişisel bilgilere sahip bir sitede
                  cevaplasa dahi Poltio’nun bu bilgilere erişimi
                  bulunmamaktadır.
                </p>
                <p>
                  Poltio içeriğini kendi sitesinde yayınlayan firma, hali
                  hazırda bildiği kullanıcı bilgileriyle, kullanıcının Poltio
                  içeriklerinde verdiği cevap bilgilerini eşlemek isterse, bunu
                  Poltio platformuna her kullanıcı için özel olarak yükleyeceği
                  tekil kodlar aracılığıyla yapabilir. Poltio oy bilgilerini
                  raporlarında bu tekil kod ile eşler, firma da analizlerinde bu
                  kodu, kendi tarafındaki bilgilerle eşlemek için kullanabilir.
                  Bu kullanım şekli için kullanıcıdan gerekli izinleri almak
                  firmanın sorumluluğundadır. Poltio’nun erişimi bu tekil kod
                  ile sınırlıdır; firmanın sahip olduğu farklı bilgilere
                  erişemez.
                </p>
                <p>
                  İçerikler içerisinde, belirli kurgularda kullanıcılardan isim,
                  soyisim, cep telefonu vb. kişisel bilgiler paylaşması
                  istenebilir. Poltio platformu bu durumda kullanıcıdan gerekli
                  ek onayları almak için bilgilendirme metni ekleme ve onay
                  kutucuğu yerleştirmeyi zorunlu tutmaktadır. Gerekli onayları
                  almak içeriği oluş turan firmanın sorumluluğundadır. Poltio
                  raporlama ve firmanın erişebilmesi için bu bilgileri saklar.
                  Bu bilgiler Poltio tarafından kullanılmaz, bu bilgiler içeriği
                  oluşturan firmaya aittir.
                </p>
                <p>
                  Poltio üzerinden içerik oluşturan firmalar, oluşturdukları
                  içerikleri kendilerine ait olan dijital platformlar dışında,
                  farklı mecralarda da yayınlayabilirler.
                </p>
                <p>
                  İçerik sahibinin, içeriğini kendi sahibi olmadığı bir mecrada
                  yayınladığı durumlarda, mecra sahibi Poltio platformuna tekil
                  kodlar yükleyerek, verilen cevapları kullanıcılarının
                  bilgileriyle eşleyebilir. Bu noktada içeriğe katılan
                  kullanıcılardan gerekli izinleri almak mecra sahibinin
                  sorumluluğunda, firmalar arasındaki bilgi paylaşımıysa
                  firmaların kendi arasındaki anlaşmaya tabidir.
                </p>
                <p>
                  İçerik oluşturan firmalar, içerik içerisine, içerik, soru ya
                  da cevap seçeneği bazından kendilerine ya da 3. parti
                  platformlara ait çerezler bırakmaya imkan sağlayan piksel
                  kodları yerleştirebilirler. Bu kullanımlarla ilgili gerekli
                  bilgilendirmeleri yapmak yine içerik sahibinin
                  sorumluluğundadır.
                </p>
                <p>
                  Yüksek performans odaklı altyapımızı bulut tabanlı ve alanında
                  lider veri merkezlerinde barındırıyoruz. Verilerimiz yüksek
                  güvenlik önlemleriyle birlikte yurtdışındaki veri
                  merkezlerinde barındırılmaktadır.
                </p>
                <p>
                  <b>Poltio Çerez kullanımı</b>
                </p>
                <p>Çerez Nedir?</p>
                <p>
                  Çerezler, Poltio platformu aracılığıyla oluşturulmuş
                  içerikleri görüntülediğinizde ve tarayıcınızın izin verdiği
                  durumlarda, mobil veya masaüstü cihazınıza kaydedilen küçük
                  metin dosyaları veya bilgilerdir. Çerezler genellikle
                  kullanıcıların geldikleri internet site isimlerini, kullanım
                  ömürlerini (cihazınızda ne kadar süre ile kalacağı gibi) ve
                  rastgele verilen sayılardan oluşan değerler içerir.
                </p>
                <p>Ne için Kullanıyoruz?</p>
                <p>
                  Çerezleri, size daha kişiselleştirilmiş bir hizmet sunabilmek
                  ve aynı zamanda platformumuz üzerinde oluşturulmuş anketlere
                  verilebilecek mükerrer oyları engelleyebilmek ve tekrar bir
                  içerik görüntülediğinizde daha önce oy kullanmış olduğunu
                  anketlerin bilgilerini sizin ile paylaşabilmek veya ilgili
                  içerik gösterebilmek için kullanıyoruz. Çerezleri,
                  kullanıcılarımızın altyapımız aracılığıyla oluşturulan
                  içerikleri nasıl kullandıklarını anlamamızı sağlayan isimsiz
                  ve toplu istatistiki verileri bir araya getirmemize ve
                  sitelerimizin yapılarını ve içeriklerini geliştirmemize
                  yardımcı olmaları amacıyla kullanmaktayız. Bu veriler, sizin
                  kimliğinizi tanımlamamızı sağlayabilecek bilgiler değildir.
                </p>
                <p>Hangi Türlerini Kullanıyoruz?</p>
                <p>
                  Oturum çerezleri ve kalıcı çerezler olmak üzere platformumuzda
                  genelinde iki tür çerez kullanmaktayız. Oturum çerezleri
                  geçici çerezler olup Poltio aracılığıyla oluşturulan bir
                  içerikle etkileşiminiz tamamlanıncaya kadar geçerlidirler.
                  Kalıcı çerezler ise siz silinceye veya süreleri doluncaya
                  (çerezlerin cihazınızda ne kadar kalacağı, cerezlerin kullanım
                  ömürlerine bağlı olarak değişir) kadar sabit diskinizde
                  kalırlar.
                </p>
                <p>Üçüncü Parti Çerezleri</p>
                <p>
                  Sağladıkları servisleri size sunabilmeleri için, Poltio’yu
                  ziyaret ettiğinizde cihazınızda sizin adınıza çerez ayarları
                  yapan bazı üçüncü parti sağlayıcılarla çalışıyoruz. Bu
                  çerezler hakkında daha fazla bilgi edinmek ve bu çerezlerin
                  nasıl kontrol edileceğine ilişkin ayrıntılı bilgi için, lütfen
                  bu üçüncü parti sağlayıcıların gizlilik politikalarını veya
                  çerez politikalarını inceleyiniz.
                </p>
                <p>Çerezleri nasıl kontrol edebilir veya silebilirsiniz?</p>
                <p>
                  Birçok internet tarayıcısı, varsayılan olarak çerezleri
                  otomatik olarak kabul etmeye ayarlıdır. Bu ayarları, çerezleri
                  engelleyecek veya cihazınıza çerez gönderildiğinde uyarı
                  verecek şekilde değiştirebilirsiniz. Çerezleri yönetmenin
                  birkaç yolu bulunmaktadır. Tarayıcı ayarlarınızı nasıl
                  düzenleyeceğiniz hakkında ayrıntılı bilgi almak için lütfen
                  tarayıcınızın talimat veya yardım ekranına başvurun. Eğer
                  kullandığımız çerezleri devre dışı bırakırsanız, bu eylem
                  Poltio’daki kullanıcı deneyiminizi etkileyebilir; örneğin
                  Poltio aracılığıyla oluşturulmuş belirli içerikleri
                  görüntüleyemeyebilir veya ziyaret ettiğinizde sizin için
                  özelleştirilmiş olan bilgilere ulaşamayabilirsiniz. Poltio
                  aracılığıyla oluşturulmuş içerikleri görüntülemek için farklı
                  cihazlar kullanıyorsanız (ör. bilgisayar, akıllı telefon,
                  tablet vb.), bu cihazların her birindeki her tarayıcının çerez
                  tercihlerinize uygun şekilde ayarlanmış olduğundan emin
                  olmanız gerekir.
                </p>
                <p>
                  Poltio, bir Cana Bilişim Hizmetleri ve Tic. AŞ markasıdır.
                  Şirketin temsilcisi Ahmet Tosun’dur. Tüm ilgili kişiler
                  Şirkete ve temsilcisine ulaşarak kişisel verilerin işlenip
                  işlenmediğini öğrenme, Kişisel verileri işlenmişse buna
                  ilişkin bilgi talep etme, kişisel verilerin işlenme amacını ve
                  bunların amacına uygun kullanılıp kullanılmadığını öğrenme,
                  yurt içinde veya yurt dışında kişisel verilerin aktarıldığı
                  üçüncü kişileri bilme, kişisel verilerin eksik veya yanlış
                  işlenmiş olması hâlinde bunların düzeltilmesini isteme, KVKK
                  m. 7de öngörülen şartlar çerçevesinde kişisel verilerin
                  silinmesini veya yok edilmesini isteme, KVKK m. 11’in (d) ve
                  (e) bentleri uyarınca yapılan işlemlerin (düzeltme ve
                  silinme), kişisel verilerin aktarıldığı üçüncü kişilere
                  bildirilmesini isteme, işlenen verilerin münhasıran otomatik
                  sistemler vasıtasıyla analiz edilmesi suretiyle kişinin
                  kendisi aleyhine bir sonucun ortaya çıkmasına itiraz etme,
                  kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle
                  zarara uğraması hâlinde zararın giderilmesini talep etme
                  haklarına sahiptir.
                </p>
                <p>
                  Cana Bilişim Hizmetleri ve Tic. AŞ Adres: ESENTEPE
                  MAH.TALATPAŞA CAD. NO:5 İletişim: destek@poltio.com
                </p>
              </div>
            ) : (
              <div>
                <p>
                  <h3>Privacy Policy</h3>
                </p>
                <p>
                  Poltio, Inc. (“<b>Poltio</b>”, “<b>we</b>” or “<b>us</b>”)
                  operates the Poltio mobile application (the “<b>App</b>”) and
                  the website located at www.poltio.com (the “<b>Website</b>”).
                </p>
                <p>
                  We respect the privacy of our users and are committed to
                  protecting it through our compliance with this privacy policy
                  (this “<b>Policy</b>”). This Policy describes the types of
                  information we may collect from you or that you may provide
                  when you access or use the App, the Website or any of our
                  other products or services (the App, the Website and any of
                  our other products or services, collectively, referred to as
                  the “<b>Services</b>”) and our practices for collecting,
                  using, maintaining, protecting and disclosing that
                  information.
                </p>
                <p>
                  <h3>Use of the Services</h3>
                </p>
                <p>
                  By accessing our Services, you agree that we can collect,
                  transfer, store, disclose and use your information as
                  described in this Policy. This Policy applies to information
                  we collect (i) on or through your use of the Services; (ii) in
                  email and other electronic messages between you and Poltio;
                  and (iii) through any mobile and desktop applications you use
                  in connection with the Services.
                </p>
                <p>
                  This Policy does not apply to information you provide to or is
                  collected by any third party whether through or in connection
                  with your use of the Services or otherwise, including through
                  any third party application or content (including advertising)
                  that may link to or be accessible from or on the Services. You
                  agree and acknowledge that Poltio has no duty or
                  responsibility to maintain and safeguard any information you
                  may provide to, or is collected by, any third party whether
                  through or in connection with your use of the Services or
                  otherwise.
                </p>
                <p>
                  This Policy is incorporated by reference into our Terms of
                  Service, found at www.poltio.com.
                </p>
                <p>
                  Please read this Policy carefully to understand our policies
                  and practices regarding your information and how we will treat
                  it. If you do not agree with our policies and practices, do
                  not access or use the Services. By accessing or using the
                  Services, you agree to this Policy.
                </p>
                <p>
                  This Policy may change from time to time (see{' '}
                  <u>Changes to Our Privacy Policy</u> below). Your continued
                  use of the Services after we make changes is deemed to be
                  acceptance of those changes, so please check this Policy
                  periodically for updates.
                </p>
                <p>
                  <h3>
                    <u>Children Under the Age of 13</u>
                  </h3>
                </p>
                <p>
                  Poltio is intended for general audiences and is not directed
                  at children under 13 years of age. We do not knowingly collect
                  any type of personal information from children under 13 years
                  of age. If you are under 13 years of age, you are not
                  permitted to (i) use the Services or (ii) provide any
                  information to or on the Services. If you become aware that a
                  child under 13 years of age has provided us with personal
                  information without parental consent, please contact us at
                  info@poltio.com. If we learn we have collected or received
                  personal information from a child under 13 years of age, we
                  will delete that information and terminate any associated user
                  account.
                </p>
                <p>
                  <h3>
                    <u>Collection of Information</u>
                  </h3>
                </p>
                <p>
                  <h4>
                    <b>Information We Collect About You</b>
                  </h4>
                </p>
                <p>
                  We only collect information relevant to the purpose of the
                  Services. This information allows us to provide you with a
                  personalized and streamlined experience on Poltio. We collect
                  several types of information from and about users of the
                  Services, some of which is personally identifiable and some of
                  which is non-identifying or aggregated, including information:
                </p>
                <p>
                  <ul>
                    <li>
                      by which you may be personally identified, such as name,
                      postal address, email address, telephone number, or any
                      other identifier by which you may be contacted online or
                      offline (“Personal Information”);
                    </li>
                    <li>
                      that is about you but individually does not identify you;
                      and/or
                    </li>
                    <li>
                      about your internet connection, the equipment you use to
                      access the Services and usage details.
                    </li>
                  </ul>
                </p>
                <p>
                  We collect this information (i) directly from you when you
                  provide it to us, (ii) automatically as you use the Services
                  (which information may include usage details, Internet
                  Protocol (IP) addresses and information collected through
                  cookies, web beacons and other tracking technologies), and
                  (iii) from third parties (such as our business partners).
                </p>
                <p>
                  <h4>
                    <u>Information You Provide to Us</u>
                  </h4>
                </p>
                <p>
                  The information we collect on or through the App, the Website
                  or otherwise through your use of the Services may include (i)
                  information that you provide about yourself or others by using
                  our Services, including information provided at the time of
                  registering or subscribing to use our Services (such as
                  username, password, email address, telephone number and other
                  information that you add to your profile), posting material or
                  requesting further Services; (ii) records and copies of your
                  correspondence (including email addresses), if you contact us;
                  (iii) your responses to surveys that we might ask you to
                  complete for research purposes; and (iv) details of any usage
                  of the Services.
                </p>
                <p>
                  You also may provide information to be published or displayed
                  on public areas of the Services and on third party sites
                  through the Services’ embedment functionality, or transmitted
                  to other users of the Services or third parties, including
                  polls, questions and answers (collectively, “User Data”). User
                  Data is posted on and transmitted to others at your own risk.
                  Although we limit access to certain information, please be
                  aware that no security measures are perfect or impenetrable.
                  Additionally, we cannot control the actions of other users of
                  the Services. Therefore, we cannot and do not guarantee that
                  User Data will not be viewed by unauthorized persons. You
                  should not submit or upload Personal Information or any other
                  information about yourself or others that you are not
                  comfortable disclosing to the general public. This information
                  will be viewable by other members of the Poltio community, may
                  be searchable on indexing websites such as Google and may be
                  disclosed to third parties under certain circumstances as
                  provided herein. Notwithstanding the foregoing, Poltio will
                  not make any individual votes or poll answers viewable by any
                  members of the Poltio community or any third party without
                  such user’s consent.
                </p>
                <p>
                  We do not verify the accuracy of information provided by any
                  users, and we do not do background checks on users. You are
                  solely responsible for verifying any information before
                  relying on it and for determining the suitability of any
                  information you obtain through the Services. We do not review
                  or endorse the advice or content provided by any user. By
                  using the Services, you acknowledge and agree that we are not
                  responsible for validating or vetting the expertise or
                  qualification of any particular user and that we have no
                  liability to you with respect to any content, communication,
                  or advice provided through interactions with users that are
                  facilitated through the Services. We specifically do not
                  represent or warrant that any such communications will meet
                  your expectations.
                </p>
                <p>
                  <h4>
                    <u>
                      Information Collected Through Data Tracking Technologies
                    </u>
                  </h4>
                </p>
                <p>
                  As you use the Services, we may use automatic data collection
                  technologies to collect certain information about your
                  devices, browsing actions and patterns, including details of
                  your use of the Services (such as traffic data, location data,
                  logs and other communication data) and information about your
                  devices and internet connection (including your IP address,
                  operating system and browser type).
                </p>
                <p>
                  The technologies we use for this automatic data collection may
                  include:
                </p>
                <ul>
                  <li>
                    <p>
                      <b>Cookies and other similar technologies.</b> We may
                      place cookies on your device to collect anonymous data
                      related to your use of the Services. In general, a cookie
                      is a small file placed on your device that can be used to
                      help recognize your browser across different web pages,
                      websites, and browsing sessions. We may use cookies to
                      provide certain features of the Services. It may be
                      possible to refuse to accept cookies by activating the
                      appropriate setting in your browser or on your device.
                      However, if you select this setting you may be unable to
                      access certain features of the Services. We may also use
                      other technologies to tailor the Services to you, such as
                      web beacons (also referred to as clear gifs, pixel tags
                      and single-pixel gifs) that permit Poltio, for example, to
                      track how visitors interact with the Services and for
                      other statistics related to the Services (such as
                      recording the popularity of certain content and verifying
                      system and server integrity).
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Log Information.</b> We may automatically collect and
                      store certain information in our server logs when you use
                      the Services, including details about how you used the
                      Services, IP address, and information specific to your
                      device (such as system activity, hardware settings,
                      browser type, and browser language).
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Device Identifiers.</b> We may collect information
                      about your device, such as your operating system, hardware
                      version, device settings, file and software names and
                      types, mobile network information and device identifiers.
                      This helps us measure how the Services are performing and
                      improve the Services for you on your particular device.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Location-Based Data.</b> We may collect and process
                      information about your geographic location (for example,
                      through GPS signals) when you use the Services. We will
                      not store or track your device location on an on-going
                      basis or without your permission. We do not share precise
                      geolocation data with third parties, other than our
                      service providers as necessary to provide the Services.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Analytics Data.</b> We may also collect analytics data,
                      or use third-party analytics tools, to help us measure
                      traffic and usage trends for the Services. These
                      third-party analytics do not collect direct personal
                      information about any user.
                    </p>
                  </li>
                </ul>
                <p>
                  <h4>
                    <b>Why We Collect Information</b>
                  </h4>
                </p>
                <p>
                  <b>
                    <u>
                      The information we collect helps us to improve the
                      Services and to deliver a better and more personalized
                      experience, including by enabling us to estimate our user
                      size and usage patterns, store information about your
                      preferences, allowing us to customize our Services
                      according to your individual interests, and recognize you
                      when you use the Services.
                    </u>
                  </b>
                </p>
                <p>
                  <h4>
                    <b>Third-party Collection of Information</b>
                  </h4>
                </p>
                <p>
                  Some content or applications, including advertisements, on the
                  Services may be served by third-parties, including
                  advertisers, ad networks and servers, content and application
                  providers. These third parties may use tracking technologies
                  to collect information about you when you use the Services.
                  The information they collect may be associated with your
                  Personal Information or they may collect information,
                  including Personal Information, about your online activities
                  over time and across different websites and other online
                  services. They may use this information to provide you with
                  interest-based (behavioral) advertising or other targeted
                  content.
                </p>
                <p>
                  Poltio does not control these third parties’ tracking
                  technologies or how they may be used. Poltio also does not
                  disclose any of your Personal Information to any third parties
                  without your consent. If you have any questions about an
                  advertisement or other targeted content, you should contact
                  the responsible provider directly. For information about how
                  you can opt out of receiving targeted advertising from many
                  providers, see{' '}
                  <u>
                    Your Choices About Our Use and Disclosure of Your
                    Information
                  </u>{' '}
                  below.
                </p>
                <p>
                  <h3>
                    <u>How We Use Your Information</u>
                  </h3>
                </p>
                <p>
                  We use information that we collect about you or that you
                  provide to us, including any Personal Information, to:
                </p>
                <p>
                  <ul>
                    <li>
                      Provide you with the Services, and any other information,
                      products or services that you request from us.
                    </li>
                    <li>
                      Present our App and Website and their contents to you.
                    </li>
                    <li>Fulfill any other purpose for which you provide it.</li>
                    <li>Give you notices about your account.</li>
                    <li>
                      Ensure that all polls, quizzes and surveys are secure and
                      not manipulated through the use of any multiple voting
                      schemes or any other form of fraudulent activity.
                    </li>
                    <li>
                      Carry out our obligations and enforce our rights arising
                      from any contracts entered into between you and us,
                      including, if applicable, for billing and collection.
                    </li>
                    <li>
                      Notify you when updates to the Services are available, and
                      about changes to any products or services we offer or
                      provide though our Services.
                    </li>
                    <li>
                      Contact you about our own and third parties’ goods and
                      services that may be of interest to you.
                    </li>
                    <li>
                      Enable us to display advertisements to our advertisers’
                      target audiences. Even though we do not disclose your
                      Personal Information for these purposes without your
                      consent, if you click on or otherwise interact with an
                      advertisement, the advertiser may assume that you meet its
                      target criteria.
                    </li>
                  </ul>
                </p>
                <p>
                  Additionally, we may use information that we collect about you
                  or that you provide to us in any other way we may describe
                  when you provide the information or for any other purposes
                  with your consent.
                </p>
                <p>
                  <h4>
                    <b>
                      <u>
                        Following termination or deactivation of an account, we
                        may retain information and content for backup, archival,
                        audit or other business purposes, or otherwise in
                        accordance with applicable laws. We may maintain
                        anonymized, de-identified or aggregated data, including
                        usage data, for analytics purposes. If you have any
                        questions about data retention or deletion, please email
                        info@poltio.com.
                      </u>
                    </b>
                  </h4>
                </p>
                <p>
                  <h4>
                    <b>
                      <u>
                        For information about how you can opt out of certain
                        uses of your information, see{' '}
                      </u>
                    </b>{' '}
                    <b>
                      Your Choices About Our Use and Disclosure of Your
                      Information
                    </b>{' '}
                    <u>
                      <b>below.</b>
                    </u>
                  </h4>
                </p>
                <p>
                  <h3>
                    <u>Disclosure of Your Information</u>
                  </h3>
                </p>
                <p>
                  We may disclose anonymized, de-identified or aggregated
                  information about our users, and information that does not
                  identify any individual, without restriction.
                </p>
                <p>
                  We may disclose Personal Information that we collect or you
                  provide:
                </p>
                <p>
                  <ul>
                    <li>To our subsidiaries and affiliates.</li>
                    <li>
                      o contractors, service providers and other third parties
                      we use to support our business and who are bound by
                      contractual obligations to keep Personal Information
                      confidential and use it only for the purposes for which we
                      disclose it to them.
                    </li>
                    <li>
                      To a buyer or other successor in the event of a merger,
                      divestiture, restructuring, reorganization, dissolution or
                      other sale or transfer of some or all of our assets,
                      whether as a going concern or as part of bankruptcy,
                      liquidation or similar proceeding, in which Personal
                      Information held by us is among the assets transferred.
                    </li>
                    <li>
                      To third parties to market their products or services to
                      you if you have consented to or not opted out of these
                      disclosures. We contractually require these third parties
                      to keep Personal Information confidential and use it only
                      for the purposes for which we disclose it to them.
                    </li>
                    <li>To fulfill the purpose for which you provide it.</li>
                    <li>
                      For any other purpose disclosed by us when you provide the
                      information.
                    </li>
                    <li>With your consent.</li>
                    <li>
                      To comply with any court order, law or legal process,
                      including to respond to any government or regulatory
                      request.
                    </li>
                    <li>
                      To enforce or apply our Terms of Service, found at
                      www.poltio.com, and other agreements, including for
                      billing and collection purposes.
                    </li>
                    <li>
                      If we believe disclosure is necessary or appropriate to
                      protect the rights, property, or safety of Poltio, our
                      customers or others. This includes exchanging information
                      with other companies and organizations for the purposes of
                      fraud protection and credit risk reduction.
                    </li>
                  </ul>
                </p>
                <p>
                  We may also share aggregated, non-personally identifiable
                  information publicly, including with users, partners or the
                  press in order to, for example, demonstrate how our Services
                  are used, evaluate industry trends, or to create our marketing
                  materials. Any aggregated information shared this way will not
                  contain any Personal Information.
                </p>
                <p>
                  Poltio disclaims all liability for the disclosure of
                  information that you choose to submit voluntarily. You assume
                  all such risks with regards to your use of the Services.
                </p>
                <p>
                  <h3>
                    <u>
                      Your Choices About Our Use and Disclosure of Your
                      Information
                    </u>
                  </h3>
                </p>
                <p>
                  We strive to provide you with choices regarding the Personal
                  Information you provide to us. This section describes
                  mechanisms we provide for you to control certain uses and
                  disclosures of your information.
                </p>
                <ul>
                  <li>
                    <p>
                      <b>Tracking Technologies.</b> You can set your browser to
                      refuse all or some browser cookies, or to alert you when
                      cookies are being sent. If you disable or refuse cookies
                      or block the use of other tracking technologies, some
                      parts of the Services may then be inaccessible or not
                      function properly.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Third-Party Advertising.</b> If you do not want us to
                      share your Personal Information with unaffiliated or
                      non-agent third parties for advertising and marketing
                      purposes as provided herein, you can opt-out by sending us
                      an email stating your request to info@poltio.com.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Location Information.</b> You can choose whether or not
                      to allow the App to collect and use real-time information
                      about your device’s location through the device’s privacy
                      settings. If you block the use of location information,
                      some parts of the App may then be inaccessible or not
                      function properly.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Targeted Advertising.</b> If you do not want us to use
                      information that we collect or that you provide to us to
                      deliver advertisements according to our advertisers’
                      target-audience preferences as provided herein, you can
                      opt-out by sending us an email stating your request to
                      info@poltio.com.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Embedded Conent.</b> If you do not want any content
                      that you produce to be available to be embedded on third
                      party sites, you may elect to keep such content “private”
                      or to remove any Personal Information associated with such
                      content prior to such content being posted.
                    </p>
                  </li>
                </ul>
                <p>
                  <h3>
                    <u>Where Information is Processed</u>
                  </h3>
                </p>
                <p>
                  <h4>
                    <b>
                      <u>
                        We may process your information in the U.S. and other
                        countries. No matter where you are located, you consent
                        to the processing and transferring of your information
                        in and to the U.S. and other countries. The laws of the
                        U.S. and other countries governing data collection and
                        use may not be as comprehensive or protective as the
                        laws of the country where you live. Your information may
                        be made available to government or law enforcement
                        requests.
                      </u>
                    </b>
                  </h4>
                </p>
                <p>
                  <h4>
                    <b>
                      <u>
                        If you are visiting our Services from outside of the
                        U.S., please be aware that you are sending information
                        to the U.S. That information may then be transferred
                        within the U.S. or back out of the U.S. to other
                        countries outside of your country of residence,
                        depending on the type of information and how it is
                        stored by us. These countries (including the U.S.) may
                        not necessarily have data protection laws as
                        comprehensive or protective as those in your country of
                        residence; however, our collection, storage and use of
                        your personal data will at all times continue to be
                        governed by this Policy.
                      </u>
                    </b>
                  </h4>
                </p>
                <p>
                  <h3>
                    <u>Accessing and Correcting Your Information</u>
                  </h3>
                </p>
                <p>
                  You may review and update your account information by logging
                  into your account and visiting your account profile or
                  settings page. You may also send us an email at
                  info@poltio.com to request access to, correct or delete any
                  Personal Information that you have provided to us. We may not
                  accommodate a request to change information if we believe the
                  change would violate any law or legal requirement or cause the
                  information to be incorrect.
                </p>
                <p>
                  If you delete User Data from the Services, copies of the User
                  Data may remain viewable in cached and archived pages, or
                  might have been copied or stored by other users of the
                  Services. Proper access and use of information provided on the
                  Services, including User Data, is governed by our Terms of
                  Service, found at www.poltio.com.
                </p>
                <p>
                  <h3>
                    <u>Your California Privacy Rights</u>
                  </h3>
                </p>
                <p>
                  California Civil Code Section 1798.83 permits users of the
                  Services that are California residents to request certain
                  information regarding our disclosure of Personal Information
                  to third parties for their direct marketing purposes.
                </p>
                <p>
                  To make such a request, please send an email to
                  info@poltio.com.
                </p>
                <p>
                  <h3>
                    <u>Data Security</u>
                  </h3>
                </p>
                <p>
                  We have implemented measures designed to secure your Personal
                  Information from accidental loss and from unauthorized access,
                  use, alteration and disclosure. All information you provide to
                  us is stored on our secure servers behind firewalls. Any
                  information you provide to us through your use of the Services
                  will be encrypted using industry standard technology.
                </p>
                <p>
                  The safety and security of your information also depends on
                  you. Where we have given you (or where you have chosen) a
                  password for access to the Services, you are responsible for
                  keeping this password confidential. We ask you not to share
                  your password with anyone.
                </p>
                <p>
                  Unfortunately, the transmission of information via the
                  internet is not completely secure. Although we do our best to
                  protect your Personal Information, we cannot guarantee the
                  security of your Personal Information transmitted through the
                  Services. Any transmission of Personal Information is at your
                  own risk. We are not responsible for circumvention of any
                  privacy settings or security measures we provide.
                </p>
                <p>
                  <h3>
                    <u>Changes to Our Privacy Policy</u>
                  </h3>
                </p>
                <p>
                  Any changes we make to this Policy will be posted on this
                  page. If we make material changes to how we treat our users’
                  Personal Information, we will notify you through a notice on
                  the Website, the App and/or by any other reasonable means. Any
                  such change, update, or modification will be effective
                  immediately upon posting on the Website and/or the App. This
                  Policy was last modified as of the date listed at the bottom
                  of this page. You are responsible for ensuring we have an
                  up-to-date active and deliverable email address for you, and
                  for periodically visiting our Website and this Policy to check
                  for any changes.
                </p>
                <p>
                  <h3>
                    <u>Contact Information</u>
                  </h3>
                </p>
                <p>
                  To ask questions or comment about this Policy and our privacy
                  practices, please contact us at:
                </p>
                <p>Poltio, Inc.</p>
                <p>12 E. 49th Street, 11th Floor</p>
                <p>New York, NY 10017</p>
                <p>{'Email: info@poltio.com'}</p>
                <p>Last Modified Date: 10.24.1017</p>
              </div>
            )}
          </div>
        </div>
      </MDBContainer>
    </div>
  );
};

export default Privacy;
