/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { MDBRow, MDBInput } from 'mdbreact';
import 'react-datepicker/dist/react-datepicker.css';
import tr from 'date-fns/locale/tr';

import messages from '../messages';
import Timezone from './Timezone';

registerLocale('tr', tr);

const StartDate = ({ startDate, setStartDate, timezone, dispatch, intl }) => {
  const minD = new Date();
  const maxD = new Date(Date.now() + 30 * 86400000);

  return (
    <Fragment>
      <MDBRow
        center
        className="border-top border-dark"
        style={{ paddingTop: '20px' }}
      >
        <h5 className="font-weight-bold mb-3">
          {`${intl.formatMessage(messages.StartDate)}`}
        </h5>
      </MDBRow>
      <div
        className="md-form"
        style={{ marginTop: '0rem', marginBottom: '0rem' }}
      >
        <DatePicker
          className="form-control"
          customInput={<MDBInput style={{ width: '20rem' }} />}
          locale={intl.locale.substring(0, 2)}
          selected={startDate}
          onChange={(date) => {
            dispatch({ type: 'date_change', data: date });
            setStartDate(date);
          }}
          showTimeSelect
          timeFormat="p"
          timeIntervals={15}
          dateFormat="Pp"
          minDate={minD}
          maxDate={maxD}
        />
      </div>
      <MDBRow center style={{ paddingBottom: '20px' }}>
        <Timezone
          timezone={timezone}
          onTimezoneChange={(e) =>
            dispatch({
              type: 'timezone_change',
              data: parseFloat(e.target.value, 10),
            })
          }
        />
      </MDBRow>
    </Fragment>
  );
};

export default StartDate;
