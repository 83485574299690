export const apiURL = process.env.REACT_APP_API_URL;
export const embedUrlPrefix = process.env.REACT_APP_EMBED_PREFIX;

export const clientId = 9;
export const clientSecret = 'dUx8bcOdatoswA64WpLZCcPQ53aqnVXAkGe3TzG12';

export const feedDailyImagePathRetina = '//cdn.poltio.com/560x380';
export const feedAvatarPathRetina = '//cdn.poltio.com/160x160';
export const feedAnswerImagePathRetina = '//cdn.poltio.com/800x800';
export const feedImagePathRetina = '//cdn.poltio.com/1600x748';
