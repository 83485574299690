import React, { useEffect, useRef } from 'react';
import {
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBIcon,
  MDBInput,
  MDBProgress,
} from 'mdbreact';
import './style.css';

const Questions = ({
  messages,
  formatMessage,
  data,
  socketData,
  triviaState,
  performTriviaAction,
  topPlayers,
  redirectUrlMessage,
  onChange,
  onSubmit,
}) => {
  let btnRef = useRef();

  useEffect(() => {
    if (socketData.timer_remaining > 0) {
      if (btnRef.current) {
        btnRef.current.removeAttribute('disabled');
      }
    }
  }, [socketData.timer_remaining]);

  const btnClick = (e) => {
    if (btnRef.current) {
      btnRef.current.setAttribute('disabled', 'disabled');
    }
    performTriviaAction(e, 'next');
  };

  return triviaState.status === 'active' ? (
    <MDBCol md="10">
      <MDBRow center>
        <MDBCol>
          <h5 className="font-weight-bold mb-3">
            {`${formatMessage(messages.ActiveAt)} `}
            <big>#{socketData.current_question}</big>
          </h5>
        </MDBCol>
        <h5>
          <span>
            {data.questions[socketData.current_question - 1]
              ? data.questions[socketData.current_question - 1].title
              : ''}
          </span>
        </h5>
      </MDBRow>
      <MDBRow>
        <MDBProgress
          material
          value={
            (socketData.timer_remaining * 100) /
            (socketData.current_question === 1
              ? socketData.time_per_question + 10
              : socketData.time_per_question + 5)
          }
          height="20px"
        >
          {socketData.timer_remaining}
        </MDBProgress>
      </MDBRow>
      <MDBRow center>
        {socketData.current_question < socketData.total_questions && (
          <MDBCol>
            <MDBRow center>
              <button className="button" onClick={btnClick} ref={btnRef}>
                {`${formatMessage(messages.NextQ)}: 
              ${socketData.current_question + 1}/${socketData.total_questions}`}
                <MDBIcon icon="arrow-right" size="sm" className="ml-2" />
              </button>
            </MDBRow>
          </MDBCol>
        )}
      </MDBRow>
      <MDBRow center>
        <MDBCol>
          <MDBRow center>
            <h6 className="font-weight-bold mb-3">
              <p>
                {data.questions[socketData.current_question]
                  ? data.questions[socketData.current_question].title
                  : ''}
              </p>
            </h6>
          </MDBRow>
        </MDBCol>
      </MDBRow>
      <MDBRow center>
        {socketData.current_question === socketData.total_questions &&
          socketData.timer_remaining === 0 && (
            <MDBCol>
              <MDBRow center>{`${formatMessage(messages.TopPlayers)}`}</MDBRow>
              {topPlayers.map(
                (p, i) =>
                  i < 3 && (
                    <MDBRow center>
                      <h5 key={i}>
                        <span className="participant__name">
                          {p.player.split('-')[1]}
                        </span>
                        <span
                          style={{
                            fontWeight: 'normal',
                            fontSize: '10px',
                          }}
                        >
                          {' '}
                          (#
                          {p.player.split('-')[0]})
                        </span>
                        <span className="participant__name">{` - ${p.score}`}</span>
                      </h5>
                    </MDBRow>
                  )
              )}
              <MDBRow style={{ marginTop: '30px' }}>
                <MDBCol>
                  <h4>
                    {redirectUrlMessage ||
                      `${formatMessage(messages.RedirectText)}.`}
                  </h4>
                  <MDBInput
                    hint="https://www.poltio.com"
                    type="text"
                    name="url"
                    onChange={onChange}
                  />
                  <MDBBtn color="primary" rounded onClick={onSubmit}>
                    {formatMessage(messages.Redirect)}
                  </MDBBtn>
                  <p
                    style={{
                      fontSize: '12px',
                      color: 'grey',
                      padding: '3px',
                      margin: '5px',
                    }}
                  >
                    {`${formatMessage(messages.RedirectLink)}`}
                  </p>
                </MDBCol>
              </MDBRow>
            </MDBCol>
          )}
      </MDBRow>
    </MDBCol>
  ) : null;
};

export default Questions;
