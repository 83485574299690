import { defineMessages } from 'react-intl.macro';

export default defineMessages({
  Title: {
    id: 'Trivia.Title',
    defaultMessage: 'Trivia History',
  },
  PlayerList: {
    id: 'Trivia.PlayerList',
    defaultMessage: 'Player List',
  },
  Actions: {
    id: 'Trivia.Actions',
    defaultMessage: 'Actions',
  },
  TriviaCredit: {
    id: 'Trivia.TriviaCredit',
    defaultMessage: 'Trivia Credit',
  },
  BuyNewCredit: {
    id: 'Trivia.BuyNewCredit',
    defaultMessage: 'Buy Credit',
  },
  CreateNewTrivia: {
    id: 'Trivia.CreateNewTrivia',
    defaultMessage: 'Create New Trivia',
  },
  EnterQuizId: {
    id: 'Trivia.EnterQuizId',
    defaultMessage: 'Enter Quiz Id',
  },
  SetTriviaMode: {
    id: 'Trivia.SetTriviaMode',
    defaultMessage: 'You can set the Trivia Game Mode before pressing ready.',
  },
  Normal: {
    id: 'Trivia.Normal',
    defaultMessage: 'normal',
  },
  Login: {
    id: 'Trivia.Login',
    defaultMessage: 'login',
  },
  Donate: {
    id: 'Trivia.Donate',
    defaultMessage: 'Donate',
  },
  SelectTier: {
    id: 'Trivia.SelectTier',
    defaultMessage: 'Select the trivia tier',
  },
  Demo: {
    id: 'Trivia.Demo',
    defaultMessage: 'demo',
  },
  Paid: {
    id: 'Trivia.Paid',
    defaultMessage: 'paid',
  },
  MoreOptions: {
    id: 'Trivia.MoreOptions',
    defaultMessage: 'More Options',
  },
  Back: {
    id: 'Trivia.Back',
    defaultMessage: 'Back',
  },
  Create: {
    id: 'Trivia.Create',
    defaultMessage: 'Create',
  },
  AvailableCredits: {
    id: 'Trivia.AvailableCredits',
    defaultMessage: 'Available Credits',
  },
  ShowBoard: {
    id: 'Trivia.ShowBoard',
    defaultMessage: 'Show Leaderboard to Players',
  },
  Elimination: {
    id: 'Trivia.Elimination',
    defaultMessage: 'Elimination',
  },
  StartDate: {
    id: 'Trivia.StartDate',
    defaultMessage: 'Select start date',
  },
  RegisterFields: {
    id: 'Trivia.RegisterFields',
    defaultMessage: 'Register Fields',
  },
  QuizNotFound: {
    id: 'Trivia.QuizNotFound',
    defaultMessage: 'Quiz ID is not found!',
  },
  QuizNotYours: {
    id: 'Trivia.QuizNotYours',
    defaultMessage: 'You can start a trivia only with your own quizzes!',
  },
  ManageTrivia: {
    id: 'Trivia.ManageTrivia',
    defaultMessage: 'Manage Trivia',
  },
  Reset: {
    id: 'Trivia.Reset',
    defaultMessage: 'Reset',
  },
  Start: {
    id: 'Trivia.Start',
    defaultMessage: 'Start',
  },
  Url: {
    id: 'Trivia.Url',
    defaultMessage: 'Url',
  },
  Code: {
    id: 'Trivia.Code',
    defaultMessage: 'Code',
  },
  Mode: {
    id: 'Trivia.Mode',
    defaultMessage: 'Mode',
  },
  PeopleRegistered: {
    id: 'Trivia.PeopleRegistered',
    defaultMessage: 'People Registered',
  },
  ConnectedPlayers: {
    id: 'Trivia.ConnectedPlayers',
    defaultMessage: 'Connected Players',
  },
  ActiveAt: {
    id: 'Trivia.ActiveAt',
    defaultMessage: 'Trivia is active at question',
  },
  NextQ: {
    id: 'Trivia.NextQ',
    defaultMessage: 'Next Question',
  },
  TopPlayers: {
    id: 'Trivia.TopPlayers',
    defaultMessage: 'Top Players',
  },
  Redirect: {
    id: 'Trivia.Redirect',
    defaultMessage: 'Redirect',
  },
  RedirectText: {
    id: 'Trivia.RedirectText',
    defaultMessage: 'Redirect players to some url',
  },
  RedirectLink: {
    id: 'Trivia.RedirectLink',
    defaultMessage:
      'If you set this, trivia players will be redirected to this link after they see their results.',
  },
  RedirectSet: {
    id: 'Trivia.RedirectSet',
    defaultMessage: 'Redirect url is set',
  },
  Error: {
    id: 'Trivia.Error',
    defaultMessage: 'Error occurred',
  },
  TimerActive: {
    id: 'Trivia.TimerActive',
    defaultMessage: 'Timer is still active. Are you sure you want to continue?',
  },
  ResetConfirm: {
    id: 'Trivia.ResetConfirm',
    defaultMessage:
      'Are you sure you want to reset all player progress and everything about this trivia?',
  },
  ManageDomains: {
    id: 'Trivia.ManageDomains',
    defaultMessage: 'Manage Domains',
  },
  BoardUrl: {
    id: 'Trivia.BoardUrl',
    defaultMessage: 'Board Url',
  },
  DomainName: {
    id: 'Trivia.DomainName',
    defaultMessage: 'Domain Name',
  },
  Select: {
    id: 'Trivia.Select',
    defaultMessage: 'Select',
  },
  Optional: {
    id: 'Trivia.Optional',
    defaultMessage: 'Optional',
  },
  ManagePools: {
    id: 'Trivia.ManagePools',
    defaultMessage: 'Manage Pools',
  },
  QuizUnpublished: {
    id: 'Trivia.QuizUnpublished',
    defaultMessage: 'Quiz Unpublished.',
  },
});
