import React, { Fragment, useState } from 'react';
import { MDBRow, MDBCol, MDBBtn, MDBIcon } from 'mdbreact';
import history from '../../core/history';

import SingleMetric from '../Common/SingleMetric';
import AnswerVotersModal from './AnswerVotersModal';

const InitialNumbers = ({
  messages,
  formatMessage,
  data,
  type,
  copyLink,
  deleteContent,
  openEmbedModal,
}) => {
  const correctAnswers = data.friend_quiz
    ? 0
    : data.t === 'quiz'
    ? data.questions.reduce(
        (result, q) =>
          (result += q.answers.filter((a) => a.is_right_answer === 1)[0]
            .vote_count),
        0
      )
    : null;
  const totalAnswers =
    data.t === 'quiz'
      ? data.questions.reduce((result, q) => (result += q.vote_count), 0)
      : null;

  const [modal, setModal] = useState(false);

  return (
    <Fragment>
      <AnswerVotersModal
        toggleModal={() => setModal(!modal)}
        modal={modal}
        data={data}
      />
      <MDBRow className="text-center" style={{ marginBottom: '20px' }}>
        <MDBCol xl="12" md="12" className="mr-0">
          <MDBBtn
            color="primary"
            outline
            rounded
            onClick={() =>
              history.push(
                `/content/${type === 'set' ? '' : 'edit/'}${type}/${data.id}`
              )
            }
          >
            <MDBIcon icon="pencil-alt" size="lg" />{' '}
            {formatMessage(messages.EditContent)}
          </MDBBtn>
          <MDBBtn
            color="primary"
            outline
            rounded
            onClick={(e) => copyLink(e, data)}
          >
            <MDBIcon icon="sticky-note" size="lg" />{' '}
            {formatMessage(messages.CopyLink)}
          </MDBBtn>
          <MDBBtn
            color="primary"
            outline
            rounded
            onClick={(e) => deleteContent(e, data)}
          >
            <MDBIcon icon="eraser" size="lg" /> {formatMessage(messages.Delete)}
          </MDBBtn>
          <MDBBtn
            color="primary"
            outline
            rounded
            onClick={(e) => openEmbedModal(e, data)}
          >
            <MDBIcon icon="code" size="lg" />{' '}
            {formatMessage(messages.EmbedCode)}
          </MDBBtn>
          <MDBBtn
            color="primary"
            outline
            rounded
            onClick={() =>
              history.push(`/reports`, { id: data.id, type: data.t })
            }
          >
            <MDBIcon icon="sort-amount-up" size="lg" />{' '}
            {formatMessage(messages.VotesReport)}
          </MDBBtn>
          {data.t !== 'poll' ? (
            <Fragment>
              <MDBBtn
                color="primary"
                outline
                rounded
                onClick={() =>
                  history.push(`/reports`, {
                    id: data.id,
                    type: `${data.t}-answers`,
                  })
                }
              >
                <MDBIcon icon="sort-amount-up" size="lg" />{' '}
                {data.t === 'quiz'
                  ? formatMessage(messages.AnswersReport)
                  : data.t === 'test'
                  ? formatMessage(messages.TestReport)
                  : formatMessage(messages.SetReport)}
              </MDBBtn>
              <MDBBtn
                color="primary"
                outline
                rounded
                onClick={() =>
                  history.push('/sheethooks', { id: data.id, type: data.t })
                }
              >
                <MDBIcon icon="file-excel" size="lg" />{' '}
                {formatMessage(messages.SheetHooks)}
              </MDBBtn>
            </Fragment>
          ) : null}
          <MDBBtn
            color="primary"
            outline
            rounded
            onClick={() =>
              history.push('/webhooks', { id: data.id, type: data.t })
            }
          >
            <MDBIcon icon="file-export" size="lg" />{' '}
            {formatMessage(messages.WebHooks)}
          </MDBBtn>
          <MDBBtn
            color="primary"
            outline
            rounded
            onClick={() => setModal(!modal)}
          >
            <MDBIcon icon="sort-amount-up" size="lg" />{' '}
            {formatMessage(messages.AnswerVotersReport)}
          </MDBBtn>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <SingleMetric
          value={data.view_count.toLocaleString()}
          label={`${formatMessage(messages.ViewCount)}:`}
          icon="eye"
        />
        <SingleMetric
          value={data.vote_count.toLocaleString()}
          label={`${formatMessage(messages.TotalVote)}:`}
          icon="vote-yea"
        />
        <SingleMetric
          value={data.unique_voter.toLocaleString()}
          label={`${formatMessage(messages.UniqueVote)}:`}
          icon="user"
        />

        {type !== 'poll' ? (
          <SingleMetric
            value={`${((data.finish / (data.start || 1)) * 100).toFixed(0)}%`}
            label={`${formatMessage(messages.CompletionRate)}:`}
            icon="list"
          />
        ) : null}

        {type !== 'poll' ? (
          <SingleMetric
            value={data.start.toLocaleString()}
            label={`${formatMessage(messages.TimesTaken)}:`}
            icon="play"
          />
        ) : null}
        {type !== 'poll' ? (
          <SingleMetric
            value={data.finish.toLocaleString()}
            label={`${formatMessage(messages.TimesFinished)}:`}
            icon="play"
          />
        ) : null}
        {type !== 'poll' ? (
          <SingleMetric
            value={(data.start / data.unique_voter).toFixed(2)}
            label={`${formatMessage(messages.AveragePlayPerUser)}:`}
            icon="percent"
          />
        ) : null}
        {type !== 'poll' ? (
          <SingleMetric
            value={data.pool_question_count || data.questions.length}
            label={`${formatMessage(messages.NumberQuestions)}:`}
            icon="database"
          />
        ) : null}

        {type === 'quiz' && !data.friend_quiz ? (
          <SingleMetric
            value={(
              (correctAnswers / totalAnswers) *
              (data.pool_question_count || data.questions.length)
            ).toFixed(2)}
            label={`${formatMessage(messages.AverageNumberCorrect)}:`}
            icon="check"
          />
        ) : null}
      </MDBRow>
    </Fragment>
  );
};

export default InitialNumbers;
