import React from 'react';
import { MDBRow, MDBIcon } from 'mdbreact';

const RotateButton = ({ formatMessage, messages, rotated, setRotated }) => (
  <MDBRow center style={{ marginTop: '40px' }}>
    <a
      href="#!"
      className="rotate-btn"
      data-card="card-1"
      onClick={() => setRotated(!rotated)}
    >
      <MDBIcon icon="undo" />{' '}
      {rotated
        ? formatMessage(messages.Back)
        : formatMessage(messages.RegisterFields)}
    </a>
  </MDBRow>
);

export default RotateButton;
