import React, { Component, Fragment } from 'react';

import { injectIntl } from 'react-intl';
import {
  Input,
  Col,
  Row,
  Select,
  SelectInput,
  SelectOption,
  SelectOptions,
  toast,
  Fa,
  Button,
} from 'mdbreact';
import { createTestQuestion } from '../../../api';

import messages from '../messages';
import ImageInput from '../../ImageInput';
import PixelCodeSelector from '../PixelCodeSelector';
import { FormattedMessage } from 'react-intl.macro';

const defaultAnswersArray = [
  {
    title: '',
    background: '',
    rel: [
      {
        result_id: 0,
        point: 0,
      },
    ],
  },
  {
    title: '',
    background: '',
    rel: [
      {
        result_id: 0,
        point: 0,
      },
    ],
  },
];

class Step3 extends Component {
  state = {
    dirty: false,
    questions: [
      {
        title: '',
        background: '',
        answer_type: 'text',
        answers: defaultAnswersArray,
      },
    ],
    questionIndex: 0,
  };

  static getDerivedStateFromProps(props, state) {
    if (props.testResults.length > 0 && !state.relChanged) {
      return {
        relChanged: true,
        questions:
          props.questions.length > 0
            ? props.questions
            : state.questions.map((q) => {
                return {
                  ...q,
                  answers: q.answers.map((a) => {
                    return {
                      ...a,
                      rel: props.testResults.map((r) => {
                        return {
                          point: 0,
                          result_id: r.id,
                        };
                      }),
                    };
                  }),
                };
              }),
      };
    } else if (props.questions.length === state.questions.length + 1) {
      return {
        questions: props.questions,
        questionIndex: props.questions.length - 1,
      };
    }
    return null;
  }

  addQuestion = async () => {
    try {
      const { questions, questionIndex } = this.state;
      const { createdTestId, setQuestions, testResults } = this.props;
      const { formatMessage } = this.props.intl;

      const relGreaterThanZero = questions[questionIndex].answers.filter(
        (a) => a.rel.filter((r) => r.point).length > 0
      ).length;
      if (!relGreaterThanZero) {
        toast.error(formatMessage(messages.SelectRel));
        return;
      }

      this.setState({ creating: true });

      const response = await createTestQuestion({
        ...questions[questionIndex],
        parent_id: createdTestId,
      });

      const newQuestions = this.state.questions.map((q, i) => {
        if (this.state.questionIndex === i) {
          return {
            ...q,
            id: response.data.id,
            answers: q.answers.map((a, ai) => {
              return {
                ...a,
                id: response.data.answers[ai].id,
              };
            }),
          };
        }
        return q;
      });

      setQuestions(newQuestions);

      this.setState({
        dirty: false,
        creating: false,
        questionIndex: this.state.questionIndex + 1,
        questions: newQuestions,
      });

      if (questionIndex === questions.length - 1) {
        this.setState({
          questions: [
            ...this.state.questions,
            {
              title: '',
              background: '',
              answer_type: 'text',
              answers: [
                {
                  title: '',
                  background: '',
                  rel: testResults.map((t) => {
                    return {
                      result_id: t.id,
                      point: 0,
                    };
                  }),
                },
                {
                  title: '',
                  background: '',
                  rel: testResults.map((t) => {
                    return {
                      result_id: t.id,
                      point: 0,
                    };
                  }),
                },
              ],
            },
          ],
        });
      }

      const element = document.getElementById(
        `questionInput${questionIndex + 1}`
      );
      setTimeout(() => {
        window.scrollTo({ top: 100, left: 0, behavior: 'smooth' });
        if (element) {
          element.focus();
        }
      }, 1000);
    } catch (e) {
      toast.error('Error occured!');
      this.setState({ creating: false });
    }
  };

  prevQuestion = () => {
    this.setState({
      questionIndex: this.state.questionIndex - 1,
    });
  };

  nextQuestion = () => {
    this.setState({
      questionIndex: this.state.questionIndex + 1,
    });
  };

  handleTitleChange = async (e) => {
    const inputText = e.target.value;
    this.setState({
      dirty: true,
      questions: this.state.questions.map((q, i) => {
        if (this.state.questionIndex === i) {
          return {
            ...q,
            title: inputText,
          };
        }
        return q;
      }),
    });
  };

  setSRC = (src, target) => {
    if (src) {
      this.setState({
        dirty: true,
        questions: this.state.questions.map((q, i) => {
          if (this.state.questionIndex === i) {
            return { ...q, background: src };
          }
          return q;
        }),
      });
    } else {
      this.setState({
        dirty: true,
        questions: this.state.questions.map((q, i) => {
          if (this.state.questionIndex === i) {
            return {
              ...q,
              background: this.state.questions[this.state.questionIndex]
                .originalFile,
            };
          }
          return q;
        }),
      });
    }
  };

  setAnswerImg = (index, value) => {
    const { questions, questionIndex: qi } = this.state;

    this.setState({
      dirty: true,
      questions: questions.map((q, i) => {
        if (qi === i) {
          return {
            ...q,
            answer_type:
              value.length > 0 ||
              questions[qi].answers
                .filter((a, i) => i !== index)
                .some((item) => item.background.length > 0)
                ? 'media'
                : 'text',
            answers: questions[qi].answers.map((a, j) => {
              if (index === j) {
                return { ...a, background: value };
              }
              return a;
            }),
          };
        }
        return q;
      }),
    });
  };

  changeNumber = (e, direction) => {
    e.preventDefault();
    if (direction === 'add') {
      this.setState({
        questions: this.state.questions.map((q, i) => {
          if (this.state.questionIndex === i) {
            return {
              ...q,
              answers: [
                ...this.state.questions[this.state.questionIndex].answers,
                {
                  title: '',
                  background: '',
                  rel: this.props.testResults.map((tr) => {
                    return {
                      result_id: tr.id,
                      point: 0,
                    };
                  }),
                },
              ],
            };
          }
          return q;
        }),
      });
    } else if (direction === 'delete') {
      this.setState({
        questions: this.state.questions.map((q, i) => {
          if (this.state.questionIndex === i) {
            return {
              ...q,
              answers: this.state.questions[
                this.state.questionIndex
              ].answers.slice(0, -1),
            };
          }
          return q;
        }),
      });
    }
  };

  handleAnswerChange = (e, index) => {
    this.setState({
      dirty: true,
      questions: this.state.questions.map((q, i) => {
        if (this.state.questionIndex === i) {
          return {
            ...q,
            answers: this.state.questions[this.state.questionIndex].answers.map(
              (a, ind) => {
                if (index === ind) {
                  return { ...a, title: e.target.value };
                }
                return a;
              }
            ),
          };
        }
        return q;
      }),
    });
  };

  getSelectValue = (value, relIndex, answerIndex, relId) => {
    const parsedValue = parseInt(value, 10);
    if (typeof parsedValue !== 'number') return;

    this.setState({
      questions: this.state.questions.map((q, i) => {
        if (this.state.questionIndex === i) {
          return {
            ...q,
            answers: this.state.questions[this.state.questionIndex].answers.map(
              (a, ind) => {
                if (answerIndex === ind) {
                  return {
                    ...a,
                    rel: a.rel.map((e) => {
                      if (e.result_id === relId) {
                        return {
                          result_id: relId,
                          point: parsedValue,
                        };
                      }
                      return e;
                    }),
                  };
                }
                return a;
              }
            ),
          };
        }
        return q;
      }),
    });
  };

  finishTest = () => {
    const { formatMessage } = this.props.intl;
    if (
      (this.state.dirty &&
        window.confirm(formatMessage(messages.DidntSaveCurrent))) ||
      !this.state.dirty
    ) {
      this.setState({
        questions: [
          {
            title: '',
            background: '',
            answer_type: 'text',
            answers: [
              {
                title: '',
                background: '',
                rel: [
                  {
                    result_id: 0,
                    point: 0,
                  },
                ],
              },
              {
                title: '',
                background: '',
                rel: [
                  {
                    result_id: 0,
                    point: 0,
                  },
                ],
              },
            ],
          },
        ],
        questionIndex: 0,
      });
      this.props.finishTest();
    }
  };

  pixelCode = (index, pc) => {
    if (pc) {
      this.setState({
        questions: this.state.questions.map((q, i) => {
          if (i === this.state.questionIndex) {
            return {
              ...q,
              answers: q.answers.map((a, j) => {
                if (j === index) {
                  return {
                    ...a,
                    pixel_code_id: pc.id,
                    pixel_code_name: pc.name,
                  };
                }
                return a;
              }),
            };
          }
          return q;
        }),
      });
    }
  };

  render() {
    const { formatMessage } = this.props.intl;
    const { testResults, handleNextPrevClick } = this.props;
    const { questions, questionIndex, creating } = this.state;

    const questionSize = 255;
    const answerSize = 255;

    return (
      <Col md="12">
        {questions.map(
          (question, i) =>
            questionIndex === i && (
              <Fragment key={i}>
                <h4>{`${formatMessage(messages.Question)} ${i + 1}`}</h4>
                <Col md="12">
                  <Input
                    label={`${formatMessage(messages.QuestionTitle)} -${
                      questionSize - question.title.length
                    }`}
                    maxLength={questionSize}
                    className="mt-3"
                    name="title"
                    id={`questionInput${i}`}
                    value={question.title}
                    onChange={this.handleTitleChange}
                    type="textarea"
                  />
                </Col>
                <ImageInput title={``} setSRC={this.setSRC} />
                <Col md="12">
                  <h4>{formatMessage(messages.Answers)}</h4>
                  <Col sm="12">
                    {question.answers.map((answer, ai) => (
                      <Row
                        key={ai}
                        className="collapse-media"
                        style={{ background: '#E8E8E8', padding: '10px' }}
                      >
                        <div
                          className="collapse-media__item col-5"
                          style={{ overflow: 'visible' }}
                        >
                          <ImageInput
                            title={formatMessage(messages.AddImage)}
                            setSRC={(s, t) => {
                              this.setAnswerImg(ai, s);
                            }}
                            src={answer.background}
                            answer
                          />
                        </div>
                        <div className="col-7">
                          <Input
                            className="form-control"
                            type="textarea"
                            name={`answer${i}`}
                            label={`${formatMessage(messages.Answer)} ${
                              ai + 1
                            } -${answerSize - answer.title.length}`}
                            maxLength={answerSize}
                            value={answer.title}
                            onChange={(e) => this.handleAnswerChange(e, ai)}
                          />
                          <label style={{ textDecoration: 'underline' }}>
                            {formatMessage(messages.Relation)}
                          </label>
                          {testResults.map((r, ri) => (
                            <div key={ri}>
                              <label>{r.title}</label>
                              <Select
                                getValue={(val) =>
                                  this.getSelectValue(val[0], ri, ai, r.id)
                                }
                              >
                                <SelectInput
                                  selected={
                                    answer.rel[ri] && answer.rel[ri].point
                                      ? answer.rel[ri].point
                                      : 0
                                  }
                                />
                                <SelectOptions>
                                  {[...Array(10).keys()].map((elem, index) => (
                                    <SelectOption value={index} key={index}>
                                      {index}
                                    </SelectOption>
                                  ))}
                                </SelectOptions>
                              </Select>
                            </div>
                          ))}
                          <Row>
                            <PixelCodeSelector
                              index={ai}
                              pixelCode={this.pixelCode}
                              id={answer.pixel_code_id}
                            />
                          </Row>
                        </div>
                      </Row>
                    ))}
                    <Button
                      color="secondary"
                      onClick={(e) => this.changeNumber(e, 'add')}
                    >
                      {formatMessage(messages.AddAnswer)}
                    </Button>
                    {question.answers.length > 2 && (
                      <Button
                        color="secondary"
                        onClick={(e) => this.changeNumber(e, 'delete')}
                      >
                        {formatMessage(messages.DeleteAnswer)}
                      </Button>
                    )}
                  </Col>
                </Col>
              </Fragment>
            )
        )}
        <Row>
          <button
            type="button"
            className="btn btn-indigo btn-rounded Ripple-parent float-left"
            onClick={handleNextPrevClick(2)}
          >
            {formatMessage(messages.Prev)}
          </button>
          <button
            type="button"
            className={`btn btn-indigo btn-rounded Ripple-parent float-left`}
            onClick={this.prevQuestion}
            disabled={questionIndex === 0}
          >
            <Fa icon="arrow-left" />
          </button>
          <button
            type="button"
            className={`btn btn-indigo btn-rounded Ripple-parent float-right`}
            onClick={this.nextQuestion}
            disabled={questionIndex === questions.length - 1}
          >
            <Fa icon="arrow-right" />
          </button>
          <button
            type="button"
            className={`btn btn-indigo btn-rounded Ripple-parent float-right`}
            onClick={this.addQuestion}
            disabled={
              creating ||
              (questions[questionIndex] &&
                questions[questionIndex].title.length < 1) ||
              (questions[questionIndex] &&
                questions[questionIndex].answers.filter(
                  (a) => a.title.length < 1 && a.background.length < 1
                ).length > 0) ||
              (questions[questionIndex] &&
                questions[questionIndex].answers.filter(
                  (a) => a.rel.filter((r) => r.point).length > 0
                ).length === 0)
            }
          >
            {formatMessage(messages.SaveQuestion)}
          </button>
          <button
            type="button"
            className={`btn btn-danger btn-rounded Ripple-parent float-right`}
            onClick={this.finishTest}
            disabled={
              questions.length < 3 ||
              (questions[questionIndex] &&
                questions[questionIndex].answers.filter(
                  (a) => a.rel.filter((r) => r.point).length > 0
                ).length === 0)
            }
          >
            {formatMessage(messages.FinishTest)}
          </button>
        </Row>
        <Row style={{ marginRight: '15px' }}>
          <Col>
            <div className={'float-right text-danger'}>
              {questions[questionIndex] &&
              questions[questionIndex].title.length < 1
                ? `${formatMessage(messages.PleaseEnterTitle)}`
                : null}
            </div>
          </Col>
        </Row>
        <Row style={{ marginRight: '15px' }}>
          <Col>
            <div className={'float-right text-danger'}>
              {questions[questionIndex] &&
              questions[questionIndex].answers.filter(
                (a) => a.title.length < 1 && a.background.length < 1
              ).length > 0
                ? `${formatMessage(messages.PleaseEnter)}`
                : null}
            </div>
          </Col>
        </Row>
        <Row style={{ marginRight: '15px' }}>
          <Col>
            <div className={'float-right text-danger'}>
              {questions.length < 3 ? (
                <FormattedMessage
                  values={{ count: 3 - questions.length }}
                  id="Content.QuestionsCount"
                  defaultMessage="{count, plural, one {Must add # more question} other {Must add # more questions}}"
                />
              ) : null}
            </div>
          </Col>
        </Row>
        <Row style={{ marginRight: '15px' }}>
          <Col>
            <div className={'float-right text-danger'}>
              {questions[questionIndex] &&
              questions[questionIndex].answers.filter(
                (a) => a.rel.filter((r) => r.point).length > 0
              ).length === 0
                ? `${formatMessage(messages.PleaseEnterScore)}`
                : null}
            </div>
          </Col>
        </Row>
      </Col>
    );
  }
}

export default injectIntl(Step3);
