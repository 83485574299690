import React, { useState, useEffect, useCallback } from 'react';
import { injectIntl } from 'react-intl';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBSpinner,
  toast,
  MDBSelect,
  MDBSelectInput,
  MDBSelectOptions,
  MDBSelectOption,
  MDBInput,
  MDBBtn,
} from 'mdbreact';
import { getReportRequests, getVotersRequest } from '../../api';
import history from '../../core/history';

import messages from './messages';
import Pagination from '../Pagination';
import SectionWithTitle from '../Common/SectionWithTitle';

const Reports = ({
  intl: { formatMessage, formatDate, formatTime },
  location: { state: locationState },
  location,
}) => {
  const { type, id } = locationState || {};
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    totalSize: 100,
    page: 1,
    sizePerPage: 10,
    lastPage: 0,
  });
  const [typeValue, setType] = useState(type || null);
  const [idValue, setID] = useState(id || null);
  const [downloading, setDownloading] = useState(false);
  const [initialDownload, setInitialDownload] = useState(
    type && id ? true : false
  );

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const r = await getReportRequests(state.page, state.sizePerPage);
      setReports(r.data.data);
      setState((s) => {
        return {
          ...s,
          totalSize: r.data.total,
          lastPage: r.data.last_page,
        };
      });
      setLoading(false);
    } catch (e) {
      toast.error('Error occured!');
      setLoading(false);
    }
  }, [state.page, state.sizePerPage]);

  const downloadRequest = useCallback(async () => {
    if (!idValue || !typeValue) return;
    setDownloading(true);
    setInitialDownload(false);
    try {
      await getVotersRequest(idValue, typeValue, state.page, state.sizePerPage);
      toast.success(formatMessage(messages.Submitted));
      setDownloading(false);
    } catch (e) {
      toast.error('Error occured!');
      setDownloading(false);
    }
  }, [state.page, state.sizePerPage, formatMessage, idValue, typeValue]);

  useEffect(() => {
    if (!initialDownload && !downloading) {
      fetchData();
    } else {
      const locationState = { ...location.state };
      delete locationState.type;
      delete locationState.id;
      history.replace({ ...location, state: locationState });
    }
  }, [
    state.page,
    state.sizePerPage,
    fetchData,
    downloading,
    initialDownload,
    location,
  ]);

  useEffect(() => {
    if (initialDownload && !downloading) {
      downloadRequest();
    }
  }, [initialDownload, downloading, downloadRequest]);

  const handlePageChange = (page, sizePerPage) => {
    setState({ ...state, page, sizePerPage });
  };

  const linkFormatter = (cell) => {
    if (cell) {
      return (
        <a
          href={`https://s3-eu-west-1.amazonaws.com/poltio-reports/${cell}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {cell}
        </a>
      );
    }
    return null;
  };

  return (
    <MDBContainer fluid className="mb-5">
      <div className="row justify-content-center">
        <MDBSelect
          getValue={(val) => setType(val[0] || typeValue)}
          style={{ marginRight: '1rem' }}
        >
          <MDBSelectInput selected={typeValue || ''} />
          <MDBSelectOptions>
            <MDBSelectOption value="poll">Poll Votes</MDBSelectOption>
            <MDBSelectOption value="test">Test Votes</MDBSelectOption>
            <MDBSelectOption value="test-answers">Test Answers</MDBSelectOption>
            <MDBSelectOption value="quiz">Quiz Votes</MDBSelectOption>
            <MDBSelectOption value="quiz-answers">Quiz Answers</MDBSelectOption>
            <MDBSelectOption value="set-answers">Set Answers</MDBSelectOption>
            <MDBSelectOption value="answer-voters">
              Answer Voters
            </MDBSelectOption>
            <MDBSelectOption value="puser">PUser</MDBSelectOption>
          </MDBSelectOptions>
        </MDBSelect>
        <MDBInput
          label={formatMessage(messages.EnterId)}
          name="pollid"
          value={idValue || ''}
          onChange={(e) => setID(e.target.value)}
        />
        <MDBBtn
          style={{ height: '50px' }}
          color="indigo"
          onClick={downloadRequest}
          disabled={downloading}
        >
          {formatMessage(messages.Download)}
        </MDBBtn>
      </div>
      <SectionWithTitle title={formatMessage(messages.YourRequests)}>
        <MDBRow>
          <MDBCol md="12">
            <div>
              <Pagination
                last={state.lastPage}
                active={state.page}
                size={state.sizePerPage}
                handlePageChange={handlePageChange}
              />
              <div className="row">
                {loading ? (
                  <MDBSpinner big blue />
                ) : (
                  <div className="col-12">
                    <table className="table table-striped table-bordered table-sm table-fixed-header">
                      <thead>
                        <tr>
                          <th className="th-sm">{'ID'}</th>
                          <th className="th-sm">{'File'}</th>
                          <th className="th-sm">{'Status'}</th>
                          <th className="th-sm">{'Run Time (s)'}</th>
                          <th className="th-sm">{'Created At'}</th>
                          <th className="th-sm">{'Updated At'}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {reports.map((report, i) => {
                          return (
                            <tr key={i}>
                              <td>{report.id}</td>
                              <td style={{ textDecoration: 'underline' }}>
                                {linkFormatter(report.report_file)}
                              </td>
                              <td>{report.status}</td>
                              <td>{report.runtime}</td>
                              <td>{`${formatDate(
                                report.created_at
                              )} ${formatTime(report.created_at)}`}</td>
                              <td>{`${formatDate(
                                report.updated_at
                              )} ${formatTime(report.updated_at)}`}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </MDBCol>
        </MDBRow>
      </SectionWithTitle>
    </MDBContainer>
  );
};

export default injectIntl(Reports);
