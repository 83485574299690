import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { injectIntl } from 'react-intl';
import {
  MDBContainer,
  MDBTooltip,
  MDBSpinner,
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBIcon,
} from 'mdbreact';
import { getTriviaHistory } from '../../api';
import Pagination from '../Pagination';
import history from '../../core/history';

import messages from './messages';
import SectionWithTitle from '../Common/SectionWithTitle';

const Trivia = ({ intl: { formatMessage }, user }) => {
  const [historyData, setHistoryData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageData, setPageData] = useState({
    totalSize: 100,
    page: 1,
    sizePerPage: 50,
    lastPage: null,
  });

  const fetchTriviaHistory = useCallback(async () => {
    setLoading(true);
    const response = await getTriviaHistory(
      pageData.page,
      pageData.sizePerPage
    );
    setHistoryData(response.data.data);
    setLoading(false);
    setPageData((p) => {
      return {
        page: p.page,
        sizePerPage: p.sizePerPage,
        totalSize: response.data.total,
        lastPage: response.data.last_page,
      };
    });
  }, [pageData.page, pageData.sizePerPage]);

  const handlePageChange = (page, sizePerPage) => {
    setPageData({
      ...pageData,
      page,
      sizePerPage,
    });
  };

  useEffect(() => {
    fetchTriviaHistory();
  }, [fetchTriviaHistory]);

  return (
    <MDBContainer fluid>
      <section className="mb-5">
        <MDBCard>
          <MDBCardBody className="d-flex justify-content-between">
            <h4 className="h4-responsive mt-3">
              {`${formatMessage(messages.TriviaCredit)}:`}
              {user ? user.trivia_credit : null}
            </h4>
            <div>
              <MDBBtn
                color="primary"
                onClick={() => history.push('/manage-trivia')}
              >
                {formatMessage(messages.CreateNewTrivia)}
              </MDBBtn>
            </div>
          </MDBCardBody>
        </MDBCard>
      </section>

      <SectionWithTitle title="Game History">
        {loading && historyData.length === 0 && <MDBSpinner />}
        {historyData.length > 0 && (
          <Fragment>
            <Pagination
              last={pageData.lastPage}
              active={pageData.page}
              size={pageData.sizePerPage}
              handlePageChange={handlePageChange}
            />
            <div className="row">
              {loading ? (
                <MDBSpinner />
              ) : (
                <div className="col-12">
                  <table className="table table-striped table-bordered table-sm table-fixed-header">
                    <thead>
                      <tr>
                        <th className="th-sm">{'ID'}</th>
                        <th className="th-sm">{'Quiz ID'}</th>
                        <th className="th-sm">{'Player Count'}</th>
                        <th className="th-sm">{'Game Type'}</th>
                        <th className="th-sm">{'Game Tier'}</th>
                        <th className="th-sm">{'Credit Cost'}</th>
                        <th className="th-sm">{'Donate'}</th>
                        <th className="th-sm">{'Elimination'}</th>
                        <th className="th-sm">{'Created At'}</th>
                        <th className="th-sm">{'Status'}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {historyData.map((row, i) => (
                        <tr key={i} style={{ cursor: 'pointer' }}>
                          <td>
                            <MDBBtn
                              outline
                              color="default"
                              size="sm"
                              onClick={() =>
                                history.push(`/trivia/player-list/${row.id}`)
                              }
                            >
                              <MDBIcon icon="barcode" /> {row.id}
                            </MDBBtn>
                          </td>
                          <td>
                            <MDBTooltip placement="top" domElement>
                              <span>{row.quiz_id}</span>
                              <div>{row.quiz_id}</div>
                            </MDBTooltip>
                          </td>
                          <td>
                            <MDBTooltip placement="top" domElement>
                              <span>{row.player_count}</span>
                              <div>{row.player_count}</div>
                            </MDBTooltip>
                          </td>
                          <td>
                            <MDBTooltip placement="top" domElement>
                              <span>{row.game_type}</span>
                              <div>{row.game_type}</div>
                            </MDBTooltip>
                          </td>
                          <td>
                            <MDBTooltip placement="top" domElement>
                              <span>{row.game_tier}</span>
                              <div>{row.game_tier}</div>
                            </MDBTooltip>
                          </td>
                          <td>
                            <MDBTooltip placement="top" domElement>
                              <span>{row.credit_cost}</span>
                              <div>{row.credit_cost}</div>
                            </MDBTooltip>
                          </td>
                          <td>
                            <MDBTooltip placement="top" domElement>
                              <span>{row.donate}</span>
                              <div>{row.donate}</div>
                            </MDBTooltip>
                          </td>
                          <td>
                            <MDBTooltip placement="top" domElement>
                              <span>{row.elimination}</span>
                              <div>{row.elimination}</div>
                            </MDBTooltip>
                          </td>
                          <td>
                            <MDBTooltip placement="top" domElement>
                              <span>{row.created_at}</span>
                              <div>{row.created_at}</div>
                            </MDBTooltip>
                          </td>
                          <td>
                            {row.status === 'finished' ? (
                              <MDBBtn outline color={'black'} size="sm">
                                {row.status}
                              </MDBBtn>
                            ) : (
                              <MDBBtn
                                color={
                                  row.status === 'ready' ? 'indigo' : 'success'
                                }
                                size="sm"
                                onClick={() =>
                                  history.push(`/manage-trivia/${row.id}`)
                                }
                              >
                                {row.status}{' '}
                                <MDBIcon icon="angle-double-right" />
                              </MDBBtn>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
            {!loading && (
              <Pagination
                last={pageData.lastPage}
                active={pageData.page}
                size={pageData.sizePerPage}
                handlePageChange={handlePageChange}
              />
            )}
          </Fragment>
        )}
      </SectionWithTitle>
    </MDBContainer>
  );
};

export default injectIntl(Trivia);
