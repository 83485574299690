import React from 'react';
import { injectIntl } from 'react-intl';
import {
  Col,
  Row,
  InputSwitch,
  Select,
  SelectInput,
  SelectOption,
  SelectOptions,
  MDBIcon,
} from 'mdbreact';
import ReactTooltip from 'react-tooltip';

import messages from '../messages';

let PollCreateAdvancedOptions = ({
  poll,
  handleSwitchChange,
  getSelectValue,
  intl,
}) => {
  const { formatMessage } = intl;

  return (
    <Col md="12">
      <ReactTooltip effect="solid" multiline={true} />
      <Row style={{ marginTop: '1.5rem' }}>
        <Col>
          <label>
            {formatMessage(messages.EndDate)}{' '}
            <span data-tip={formatMessage(messages.EndDateTip)}>
              <MDBIcon far icon="question-circle" />
            </span>
          </label>
        </Col>
        <Col>
          <InputSwitch
            onChange={(e) => handleSwitchChange(e, 'end_date')}
            checked={poll.end_date === 1}
            labelLeft={formatMessage(messages.Off)}
            labelRight={formatMessage(messages.On)}
          />
        </Col>
      </Row>
      {poll.end_date ? (
        <Row style={{ marginTop: '1.5rem' }}>
          <Col md="4">
            <label>{formatMessage(messages.Days)}:</label>
            <Select getValue={(val) => getSelectValue(val[0], 'end_date_day')}>
              <SelectInput selected={'30'} />
              <SelectOptions>
                {[...Array(32).keys()].map((elem, index) => (
                  <SelectOption
                    value={`0${index}`.slice(-2)}
                    key={`day${index}`}
                  >
                    {`0${index}`.slice(-2)}
                  </SelectOption>
                ))}
              </SelectOptions>
            </Select>
          </Col>
          <Col md="4">
            <label>{formatMessage(messages.Hours)}:</label>
            <Select getValue={(val) => getSelectValue(val[0], 'end_date_hour')}>
              <SelectInput selected={'00'} />
              <SelectOptions>
                {[...Array(24).keys()].map((elem, index) => (
                  <SelectOption
                    value={`0${index}`.slice(-2)}
                    key={`hour${index}`}
                  >
                    {`0${index}`.slice(-2)}
                  </SelectOption>
                ))}
              </SelectOptions>
            </Select>
          </Col>
          <Col md="4">
            <label>{formatMessage(messages.Minutes)}:</label>
            <Select
              getValue={(val) => getSelectValue(val[0], 'end_date_minute')}
            >
              <SelectInput selected={'00'} />
              <SelectOptions>
                {[...Array(60).keys()].map((elem, index) => (
                  <SelectOption
                    value={`0${index}`.slice(-2)}
                    key={`minute${index}`}
                  >
                    {`0${index}`.slice(-2)}
                  </SelectOption>
                ))}
              </SelectOptions>
            </Select>
          </Col>
        </Row>
      ) : null}
    </Col>
  );
};

export default injectIntl(PollCreateAdvancedOptions);
