import React, { useState, useEffect, Fragment } from 'react';
import { injectIntl } from 'react-intl';
import {
  MDBRow,
  MDBContainer,
  MDBMask,
  MDBView,
  MDBBtn,
  MDBSpinner,
} from 'mdbreact';
import { verify } from '../../../api';

import Footer from '../Footer';
import messages from './messages';
import '../style.css';

const VerifyAccount = ({ intl: { formatMessage }, history, match }) => {
  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    async function verifyEmail() {
      const { email, resetCode } = match.params;
      if (email && resetCode) {
        try {
          await verify(email, resetCode);
          setLoading(false);
        } catch (e) {
          setLoading(false);
          setHasError(true);
        }
      }
    }
    verifyEmail();
  }, [match.params]);

  return (
    <div id="loginpage">
      <MDBView>
        <MDBMask
          className="d-flex justify-content-center align-items-center"
          overlay="indigo-strong"
        >
          <MDBContainer>
            <MDBRow>
              <div className="white-text text-center text-md-center col-md-12 mt-xl-5 mb-5">
                <h1 className="display-4 font-weight-bold">Poltio PRO </h1>
                <hr className="hr-light" />
                {loading ? (
                  <MDBSpinner yellow />
                ) : hasError ? (
                  <h6 className="mb-4">
                    {formatMessage(messages.ErrorOccured)}
                  </h6>
                ) : (
                  <Fragment>
                    <h6 className="mb-4">
                      {formatMessage(messages.VerifyMessage)}
                    </h6>
                    <MDBBtn
                      outline
                      color="white"
                      onClick={() => history.push('/login')}
                    >
                      {formatMessage(messages.Login)}
                    </MDBBtn>
                  </Fragment>
                )}
              </div>
            </MDBRow>
          </MDBContainer>
        </MDBMask>
      </MDBView>
      <Footer />
    </div>
  );
};

export default injectIntl(VerifyAccount);
