import React, { Component } from 'react';

import { Container, Spinner, Col, toast } from 'mdbreact';
import {
  getProfileFeed,
  performContentAction,
  getEmbedSettings,
  setEmbedSettings,
} from '../../api';
import InfiniteScroll from 'react-infinite-scroller';
import { injectIntl } from 'react-intl';
import makeComponentTrashable from 'trashable-react';
import history from '../../core/history';
import * as clipboard from 'clipboard-polyfill';
import { embedUrlPrefix } from '../../core/constants';

import ContentCard from './Card';
import EmbedModal from './EmbedModal';
import messages from './messages';

class Content extends Component {
  state = {
    contents: [],
    nextOffset: null,
    hasMore: true,
    loading: false,
    modal: false,
    contentType: '',
    contentId: 0,
    displayShare: true,
    displayNotify: true,
    displayDisclaimer: true,
    lang: 'default',
  };

  async componentDidMount() {
    const { registerPromise } = this.props;
    const response = await registerPromise(getEmbedSettings('poll'));

    this.setState({
      displayShare: response.data.displayShare,
      displayNotify: response.data.displayNotify,
      displayDisclaimer: response.data.displayDisclaimer || true,
      lang: response.data.lang || 'default',
    });
  }

  loadMore = async () => {
    const { hasMore, loading, nextOffset } = this.state;
    if (hasMore && !loading) {
      this.setState({ loading: true });
      const response = await getProfileFeed(nextOffset);
      this.setState({
        contents: [...this.state.contents, ...response.data.data],
        nextOffset: response.data.nextOffset,
        hasMore: response.data.data.length > 0,
        loading: false,
      });
    }
  };

  openEmbedModal = (e, content) => {
    e.preventDefault();
    this.setState({
      modal: true,
      contentType: content.t,
      contentId: content.id,
    });
  };

  deleteContent = async (e, content) => {
    e.preventDefault();
    const { formatMessage } = this.props.intl;

    if (window.confirm(formatMessage(messages.Sure))) {
      try {
        await performContentAction('delete', content.t, content.id);
        toast.success(formatMessage(messages.ContentDeleted));
        this.setState({
          contents: this.state.contents.filter(
            (c) => !(c.id === content.id && c.t === content.t)
          ),
        });
      } catch (e) {
        toast.error('Error occured!');
      }
    }
  };

  duplicateContent = async (e, content) => {
    e.preventDefault();
    const { formatMessage } = this.props.intl;

    try {
      const { data } = await performContentAction(
        'duplicate',
        content.t,
        content.id
      );
      toast.success(formatMessage(messages.ContentDuplicated));
      this.setState({
        contents: [data, ...this.state.contents],
      });
    } catch (e) {
      toast.error('Error occured!');
    }
  };

  goToReport = (e, content) => {
    e.preventDefault();
    history.push(`/reports`, { id: content.id, type: `${content.t}-answers` });
  };

  copyLink = (e, content) => {
    e.preventDefault();
    const { formatMessage } = this.props.intl;
    try {
      clipboard.writeText(
        `https://${embedUrlPrefix}.poltio.com/e/${content.t}/${content.id}?align=center&share=off&notify=off`
      );
      toast.info(formatMessage(messages.Copied));
    } catch (e) {
      toast.error(formatMessage(messages.Error));
    }
  };

  handleSwitchChange = (e, name) => {
    this.setState({ [name]: !this.state[name] });
  };

  handleSwitchChange2 = async (e, action, content) => {
    const { formatMessage } = this.props.intl;
    try {
      this.setState({
        contents: this.state.contents.map((c) => {
          if (c.id === content.id && c.t === content.t) {
            return {
              ...c,
              status: c.status === 'published' ? 'unpublished' : 'published',
            };
          }
          return c;
        }),
      });
      await performContentAction(action, content.t, content.id);
      toast.success(formatMessage(messages.SuccessPublish));
    } catch (e) {
      toast.error(formatMessage(messages.ErrorPublish));
    }
  };

  handleCheckboxChange = (lang) => {
    this.setState({ lang });
  };

  setDefault = async (e) => {
    e.preventDefault();
    const { displayNotify, displayShare, displayDisclaimer, lang } = this.state;
    const { formatMessage } = this.props.intl;
    try {
      await setEmbedSettings('poll', {
        displayNotify,
        displayShare,
        displayDisclaimer,
        lang,
      });
      toast.success(formatMessage(messages.SuccessSetting));
    } catch (e) {
      toast.error(formatMessage(messages.ErrorSetting));
    }
  };

  render() {
    const {
      contents,
      hasMore,
      modal,
      contentType,
      contentId,
      displayShare,
      displayNotify,
      displayDisclaimer,
      lang,
    } = this.state;

    return (
      <Container sm="12">
        <EmbedModal
          toggleModal={() => this.setState({ modal: !this.state.modal })}
          modal={modal}
          contentType={contentType}
          contentId={contentId}
          displayShare={displayShare}
          displayNotify={displayNotify}
          displayDisclaimer={displayDisclaimer}
          lang={lang}
          setDefault={this.setDefault}
          handleSwitchChange={this.handleSwitchChange}
          handleCheckboxChange={this.handleCheckboxChange}
        />
        <InfiniteScroll
          className="row"
          element={'div'}
          hasMore={hasMore}
          initialLoad
          useWindow
          pageStart={0}
          loadMore={this.loadMore}
          loader={<Spinner blue big key="c0" />}
        >
          {contents.map((content, i) => {
            return (
              <Col
                key={`content${content.id}`}
                className="card__col col-12 col-md-4"
                style={{ minHeight: '28rem' }}
              >
                <ContentCard
                  content={content}
                  openEmbedModal={this.openEmbedModal}
                  deleteContent={this.deleteContent}
                  duplicateContent={this.duplicateContent}
                  copyLink={this.copyLink}
                  goToReport={this.goToReport}
                  handleSwitchChange2={this.handleSwitchChange2}
                />
              </Col>
            );
          })}
        </InfiniteScroll>
      </Container>
    );
  }
}

export default makeComponentTrashable(injectIntl(Content));
