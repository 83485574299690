import React, { useState, Fragment } from 'react';
import { compareVoteCounts, calculatePercentage } from '../../core/helper';
import SectionWithTitle from '../Common/SectionWithTitle';

const SetSummaryReport = ({ questionsProp }) => {
  const [questions, setQuestions] = useState(questionsProp);

  const toggleAnswers = (index) => {
    setQuestions(
      questions.map((q, i) => {
        if (i === index) {
          return {
            ...q,
            expanded: !q.expanded,
          };
        }
        return q;
      })
    );
  };

  return (
    <SectionWithTitle title="Set Summary">
      <div className="row">
        <div className="col-12">
          <table className="table table-bordered table-sm table-fixed-header">
            <thead>
              <tr>
                <th className="th-sm">{'Question'}</th>
                <th className="th-sm">{'Total Vote Count'}</th>
                <th className="th-sm">{'Answer'}</th>
                <th className="th-sm">{'Vote Count'}</th>
                <th className="th-sm">{'%'}</th>
                <th className="th-sm">{'Connected'}</th>
                <th className="th-sm">{'Multi Punch'}</th>
              </tr>
            </thead>
            <tbody>
              {questions.map((question, i) => {
                const sortedAnswers = question.answers.sort(compareVoteCounts);
                return (
                  <Fragment key={i}>
                    <tr
                      onClick={() => toggleAnswers(i)}
                      style={{
                        cursor: 'pointer',
                        backgroundColor: i % 2 === 0 ? 'rgba(0,0,0,.05)' : '',
                      }}
                    >
                      <td>{question.title}</td>
                      <td>{question.vote_count}</td>
                      <td>{sortedAnswers[0]?.title}</td>
                      <td>{sortedAnswers[0]?.vote_count}</td>
                      <td>
                        {calculatePercentage(
                          question.vote_count,
                          sortedAnswers[0]?.vote_count
                        )}
                      </td>
                      <td>{question.is_connected ? 'Yes' : 'No'}</td>
                      <td>{question.max_multi_punch_answer}</td>
                    </tr>
                    {question.expanded
                      ? sortedAnswers.slice(1).map((sa, si) => (
                          <tr
                            key={si}
                            style={{
                              backgroundColor:
                                i % 2 === 0 ? 'rgba(0,0,0,.05)' : '',
                            }}
                          >
                            <td></td>
                            <td></td>
                            <td>{sa.title}</td>
                            <td>{sa.vote_count}</td>
                            <td>
                              {calculatePercentage(
                                question.vote_count,
                                sa.vote_count
                              )}
                            </td>
                            <td></td>
                            <td></td>
                          </tr>
                        ))
                      : null}
                  </Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </SectionWithTitle>
  );
};

export default SetSummaryReport;
