/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBBtn,
  MDBModalFooter,
} from 'mdbreact';
import history from '../../core/history';

const Login = ({
  messages,
  formatMessage,
  formData,
  setFormData,
  onFormSubmit,
  sending,
}) => (
  <MDBCard>
    <MDBCardBody className="mx-4">
      <form method="post" onSubmit={onFormSubmit}>
        <div className="text-center">
          <h3 className="dark-grey-text mb-5">
            <strong>{formatMessage(messages.SignIn)}</strong>
          </h3>
        </div>
        <MDBInput
          label={formatMessage(messages.TypeEmail)}
          group
          type="email"
          autoComplete="new-password"
          name="email"
          onChange={(e) => {
            setFormData({
              ...formData,
              email: e.target.value,
              errorMessage: null,
            });
          }}
          value={formData.email}
        />
        <MDBInput
          label={formatMessage(messages.TypePass)}
          group
          type="password"
          autoComplete="new-password"
          name="pass"
          onChange={(e) => {
            setFormData({
              ...formData,
              pass: e.target.value,
              errorMessage: null,
            });
          }}
          value={formData.pass}
        />
        <p className="font-small blue-text d-flex justify-content-end pb-3">
          <a
            href=""
            className="blue-text ml-1"
            onClick={(e) => {
              e.preventDefault();
              history.push('/forgot-password');
            }}
          >
            {formatMessage(messages.Forgot)}
          </a>
        </p>
        {formData.errorMessage ? (
          <div className="invalid-feedback" style={{ display: 'block' }}>
            {formData.errorMessage}
          </div>
        ) : null}
        <div className="text-center">
          <MDBBtn
            gradient="blue"
            rounded
            className="btn-block z-depth-1a"
            type="submit"
            disabled={sending}
          >
            {formatMessage(messages.Login)}
          </MDBBtn>
        </div>
      </form>
    </MDBCardBody>
    <MDBModalFooter className="mx-5 pt-3 mb-1">
      <p className="font-small grey-text d-flex justify-content-end">
        {formatMessage(messages.NotMember)}
        <a
          href=""
          className="blue-text ml-1"
          onClick={(e) => {
            e.preventDefault();
            history.push('/signup');
          }}
        >
          {formatMessage(messages.SignUp)}
        </a>
      </p>
    </MDBModalFooter>
  </MDBCard>
);

export default Login;
