/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import {
  Row,
  Col,
  Container,
  Stepper,
  Step,
  toast,
  Card,
  CardBody,
  MDBBtn,
  MDBTypography,
  MDBBox,
  MDBIcon,
} from 'mdbreact';
import ReactTooltip from 'react-tooltip';
import history from '../../../core/history';
import {
  uploadImage,
  createQuiz,
  getEditQuiz,
  editQuizResults,
  deleteContent,
} from '../../../api';

import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import messages from '../messages';

const initialState = {
  formActivePanel: 1,
  quizData: {},
  questions: [],
  results: [
    {
      title: '',
      background: '',
      desc: '',
      url: '',
      min_c: 0,
      max_c: 0,
      pixel_code_id: '',
    },
  ],
  src: null,
  crop: {
    x: 0,
    y: 0,
    width: 50,
    aspect: 9 / 5,
  },
  pixelCrop: null,
  cropped: false,
  questionCount: 0,
  endDateEdited: false,
};

class QuizEdit extends Component {
  state = initialState;

  async componentDidMount() {
    try {
      const { id } = this.props.match.params;
      const testResponse = await getEditQuiz(id);
      this.setState({
        createdTestId: testResponse.data.id,
        quizData: {
          ...testResponse.data,
          pool_question_count: testResponse.data.pool_question_count || null,
        },
        results: testResponse.data.results,
        questions: testResponse.data.questions,
        src: testResponse.data.background ? testResponse.data.background : null,
        cropped: true,
      });
    } catch (e) {
      if (e && e.response && e.response.status === 404) {
        const { formatMessage } = this.props.intl;
        history.push('/content');
        toast.error(formatMessage(messages.NoContentFound));
      }
    }
  }

  pixelCode = (index, pc) => {
    if (pc) {
      this.setState({
        results: this.state.results.map((a, i) => {
          if (i === index) {
            return {
              ...a,
              pixel_code_id: pc.id,
              pixel_code_name: pc.name,
            };
          }
          return a;
        }),
      });
    }
  };

  leadSelect = (index, lead) => {
    if (lead) {
      this.setState({
        results: this.state.results.map((a, i) => {
          if (i === index) {
            return {
              ...a,
              lead_id: lead.id,
              lead_name: lead.name,
            };
          }
          return a;
        }),
      });
    }
  };

  handleNextPrevClick = (param) => (e) => {
    const { quizData, questions } = this.state;
    const { formatMessage } = this.props.intl;
    if (param === 3) {
      if (parseInt(quizData.pool_question_count, 10) > questions.length) {
        toast.error(formatMessage(messages.PoolGreaterThan));
        return;
      }
    }
    if (param === 2) {
      this.createQuiz();
      this.setState({
        formActivePanel: param,
      });
    } else {
      this.setState({
        formActivePanel: param,
      });
    }
  };

  createQuiz = async () => {
    const { quizData, src, fileExt, createdTestId, endDateEdited } = this.state;
    if (!endDateEdited) {
      delete quizData.end_date;
      delete quizData.end_date_day;
      delete quizData.end_date_hour;
      delete quizData.end_date_minute;
    }
    try {
      let r;
      if (src && src.includes('data:image')) {
        r = await uploadImage(src, fileExt);
      }
      await createQuiz(
        { ...quizData, pool_question_count: quizData.pool_question_count || 0 },
        src ? (src.includes('data:image') ? r.data.file : src) : '',
        true,
        createdTestId
      );
      window.scrollTo({ top: 100, left: 0, behavior: 'smooth' });
    } catch (e) {
      console.log(e);
    }
  };

  saveQuizEdit = async (finish) => {
    const { formatMessage } = this.props.intl;
    const {
      quizData: data,
      src: bg,
      createdTestId,
      results,
      endDateEdited,
    } = this.state;
    let result = false;
    if (!endDateEdited) {
      delete data.end_date;
      delete data.end_date_day;
      delete data.end_date_hour;
      delete data.end_date_minute;
    }

    let validated = true;
    results.forEach((r) => {
      if (!r.dont_shorten && r.url && !r.url.startsWith('http') && r.dirty) {
        toast.error(formatMessage(messages.ValidUrl));
        validated = false;
      }
    });

    if (validated) {
      try {
        await createQuiz(
          { ...data, pool_question_count: data.pool_question_count || 0 },
          bg,
          true,
          createdTestId
        );

        if (this.refs.questionStep) {
          result = await this.refs.questionStep.addQuestion();
        }

        if (!result) {
          await editQuizResults(createdTestId, results);
          if (finish) {
            history.push('/content');
          }
          toast.success(formatMessage(messages.FinishEditingSuccess));
        }
      } catch (e) {
        toast.error('Error occured!');
      }
    }
  };

  handleTextChange = async (e) => {
    const { name, value } = e.target;
    this.setState({
      quizData: {
        ...this.state.quizData,
        [name]: value,
        friend_quiz:
          name === 'time_limit' ? 0 : this.state.quizData.friend_quiz,
        show_timer: name === 'time_limit' ? 0 : this.state.quizData.show_timer,
      },
    });
  };

  handleTextChangeWithoutEmbedly = (e) => {
    this.setState({
      quizData: {
        ...this.state.quizData,
        [e.target.name]: e.target.value === '' ? null : e.target.value,
      },
    });
  };

  setSRC = (src, target) => {
    if (target && target.length > 0) {
      this.setState({
        quizData: {
          ...this.state.quizData,
          [target]: src,
        },
      });
    } else {
      this.setState({ src: src });
    }
  };

  getSRC = (target) => {
    if (target && target.length > 0) {
      return this.state.quizData[target];
    }
    return this.state.src;
  };

  getSelectValue = (value, name) => {
    if (value) {
      this.setState({
        quizData: {
          ...this.state.quizData,
          [name]: value,
        },
      });
    }
  };

  handleSwitchChange = (e, name) => {
    if (name === 'privacy') {
      this.setState({
        quizData: {
          ...this.state.quizData,
          [name]: e.target.value === 'true' ? 'public' : 'private',
        },
      });
    } else if (name === 'play_once') {
      this.setState({
        quizData: {
          ...this.state.quizData,
          [name]: e.target.checked ? 1 : 0,
        },
      });
    } else if (name === 'hide_counter') {
      this.setState({
        quizData: {
          ...this.state.quizData,
          [name]: e.target.value === 'true' ? 1 : 0,
        },
      });
    } else if (name === 'end_date') {
      this.setState({
        quizData: {
          ...this.state.quizData,
          [name]: e.target.value === 'true' ? 0 : 1,
          end_date_day: e.target.value === 'true' ? '00' : '30',
          end_date_hour: '00',
          end_date_minute: '00',
        },
        endDateEdited: true,
      });
    } else if (name === 'friend_quiz' || name === 'show_timer') {
      this.setState({
        quizData: {
          ...this.state.quizData,
          [name]: e.target.value === 'true' ? 0 : 1,
          time_limit: 0,
        },
      });
    } else {
      this.setState({
        quizData: {
          ...this.state.quizData,
          [name]: e.target.value === 'true' ? 0 : 1,
        },
      });
    }
  };

  setResultImg = (index, value) => {
    const { results } = this.state;

    this.setState({
      results: results.map((a, i) => {
        if (index === i) {
          return { ...a, background: value };
        }
        return a;
      }),
    });
  };

  changeNumber = (e, direction) => {
    e.preventDefault();
    if (direction === 'add') {
      this.setState({
        results: [
          ...this.state.results,
          {
            title: '',
            background: '',
            desc: '',
            url: '',
            min_c: 0,
            max_c: 0,
            pixel_code_id: '',
          },
        ],
      });
    } else if (direction === 'delete') {
      this.setState({
        results: this.state.results.slice(0, -1),
      });
    }
  };

  handleAnswerChange = (e, i) => {
    const { name, value } = e.target;
    this.setState({
      results: this.state.results.map((a, index) => {
        if (index === i) {
          return {
            ...a,
            [name]: name === 'url' ? value.trim() : value,
            dirty: name === 'url' ? true : a.dirty,
          };
        }
        return a;
      }),
    });
  };

  handleResultSwitchChange = (e, i) => {
    this.setState({
      results: this.state.results.map((a, index) => {
        if (index === i) {
          return { ...a, dont_shorten: e.target.checked ? 1 : 0 };
        }
        return a;
      }),
    });
  };

  getSelectValue2 = (value, name, i) => {
    if (
      (typeof value === 'string' && value !== '0') ||
      typeof value === 'undefined'
    )
      return;
    this.setState({
      results: this.state.results.map((a, index) => {
        if (index === i) {
          return { ...a, [name]: parseInt(value, 10) };
        }
        return a;
      }),
    });
  };

  getSelectValue3 = (value, name) => {
    if (value === '3' || value === '0' || !value) return;
    this.setState({
      quizData: {
        ...this.state.quizData,
        [name]: value,
      },
    });
  };

  setQuestions = (questions) => this.setState({ questions });

  deleteQuestion = async (e, id) => {
    e.preventDefault();
    const { formatMessage } = this.props.intl;
    if (!window.confirm(formatMessage(messages.SureDeleteQuestion))) return;

    try {
      this.setState({ deleting: true });
      await deleteContent('question', id);
      const { data } = await getEditQuiz(this.state.quizData.id);
      this.setState({
        quizData: data,
        results: data.results,
        questions: data.questions,
      });
      toast.success(formatMessage(messages.ContentDeleted));
      this.setState({ deleting: false });
    } catch (e) {
      toast.error(formatMessage(messages.ErrorDeletingQuestion));
      this.setState({ deleting: false });
    }
  };

  endDateEditClick = () => {
    this.setState({ endDateEdited: true });
  };

  copyQuestion = (qi) => {
    const { questions } = this.state;
    const qu = questions[qi];
    this.setState({
      questions: [
        ...questions,
        {
          ...qu,
          id: null,
          answers: qu.answers.map((a) => {
            return {
              ...a,
              id: null,
            };
          }),
        },
      ],
    });
  };

  leadSelectSet = (i, lead) => {
    if (lead) {
      this.setState({
        quizData: {
          ...this.state.quizData,
          lead_id: lead.id,
          lead_name: lead.name,
        },
      });
    }
  };

  render() {
    const {
      quizData: poll,
      src,
      createdTestId,
      results,
      questions,
      quizCreated,
      formActivePanel,
      deleting,
      endDateEdited,
    } = this.state;
    const { formatMessage } = this.props.intl;

    const buttonEnabled =
      poll.title &&
      poll.title.length > 0 &&
      (!src || src.length <= 0 || (src && src.length > 0));

    const navButtonText = [
      formatMessage(messages.Info),
      formatMessage(messages.Questions),
      formatMessage(messages.Results),
    ];

    return (
      <Container>
        <Row>
          <Col md="7">
            <Card>
              <CardBody>
                <h2 className="text-center font-weight-bold pt-4 pb-5">
                  <strong>{formatMessage(messages.EditQuiz)}</strong>
                </h2>

                <Stepper form>
                  <Step form>
                    <a href="" onClick={(e) => e.preventDefault()}>
                      <button
                        type="button"
                        className={`btn btn-${
                          formActivePanel === 1 ? 'indigo' : 'default'
                        } btn-circle Ripple-parent`}
                      >
                        1
                        <div
                          className="Ripple "
                          style={{
                            top: '0px',
                            left: '0px',
                            width: '0px',
                            height: '0px',
                          }}
                        />
                      </button>
                    </a>
                    <p>{formatMessage(messages.QuizInfo)}</p>
                  </Step>
                  <Step form>
                    <a href="" onClick={(e) => e.preventDefault()}>
                      <button
                        type="button"
                        className={`btn btn-${
                          formActivePanel === 2 ? 'indigo' : 'default'
                        } btn-circle Ripple-parent`}
                      >
                        2
                        <div
                          className="Ripple "
                          style={{
                            top: '0px',
                            left: '0px',
                            width: '0px',
                            height: '0px',
                          }}
                        />
                      </button>
                    </a>
                    <p>{formatMessage(messages.QuizQuestions)}</p>
                  </Step>
                  <Step form>
                    <a href="" onClick={(e) => e.preventDefault()}>
                      <button
                        type="button"
                        className={`btn btn-${
                          formActivePanel === 3 ? 'indigo' : 'default'
                        } btn-circle Ripple-parent`}
                      >
                        3
                        <div
                          className="Ripple "
                          style={{
                            top: '0px',
                            left: '0px',
                            width: '0px',
                            height: '0px',
                          }}
                        />
                      </button>
                    </a>
                    <p>{formatMessage(messages.QuizResults)}</p>
                  </Step>
                </Stepper>

                {poll.friend_quiz ? (
                  <MDBTypography blockquote bqColor="danger">
                    <MDBBox tag="p" mb={0} className="bq-title">
                      {formatMessage(messages.FQEditWarning)}
                    </MDBBox>
                  </MDBTypography>
                ) : null}

                <Row>
                  {formActivePanel === 1 && (
                    <Step1
                      handleTextChange={this.handleTextChange}
                      poll={poll}
                      setSRC={this.setSRC}
                      getSRC={this.getSRC}
                      getSelectValue={this.getSelectValue}
                      getSelectValue3={this.getSelectValue3}
                      handleSwitchChange={this.handleSwitchChange}
                      finishQuiz={this.createQuiz}
                      handleTextChangeWithoutEmbedly={
                        this.handleTextChangeWithoutEmbedly
                      }
                      endDateEdited={endDateEdited}
                      endDateEditClick={this.endDateEditClick}
                      leadSelect={this.leadSelectSet}
                    />
                  )}
                  {formActivePanel === 2 && (
                    <Step2
                      ref="questionStep"
                      intl={this.props.intl}
                      quizData={poll}
                      createdTestId={createdTestId}
                      setQuestionCount={(count) =>
                        this.setState({ questionCount: count })
                      }
                      setQuestions={this.setQuestions}
                      quizCreated={quizCreated}
                      questions={questions}
                      deleting={deleting}
                    />
                  )}
                  {formActivePanel === 3 && (
                    <Step3
                      quizData={poll}
                      results={results}
                      setResultImg={this.setResultImg}
                      changeNumber={this.changeNumber}
                      handleAnswerChange={this.handleAnswerChange}
                      handleResultSwitchChange={this.handleResultSwitchChange}
                      getSelectValue={this.getSelectValue2}
                      pixelCode={this.pixelCode}
                      leadSelect={this.leadSelect}
                      questions={poll.questions}
                    />
                  )}
                  <div className="btn-group col-md-8 px-0 d-block">
                    {formActivePanel > 1 && (
                      <button
                        type="button"
                        className="btn btn-indigo btn-rounded Ripple-parent float-left"
                        onClick={this.handleNextPrevClick(formActivePanel - 1)}
                      >
                        {`${formatMessage(messages.Prev)} (${
                          navButtonText[formActivePanel - 2]
                        })`}
                      </button>
                    )}
                    {formActivePanel < 3 && (
                      <button
                        type="button"
                        className={`btn btn-indigo btn-rounded Ripple-parent float-left ${
                          buttonEnabled ? '' : 'disabled'
                        }`}
                        disabled={!buttonEnabled}
                        onClick={this.handleNextPrevClick(formActivePanel + 1)}
                      >
                        {`${formatMessage(messages.Next)} (${
                          navButtonText[formActivePanel]
                        })`}
                      </button>
                    )}
                  </div>
                  <div className="btn-group col-md-4 px-0 d-block">
                    <button
                      type="button"
                      className={`btn btn-danger btn-rounded Ripple-parent float-right ${
                        buttonEnabled ? '' : 'disabled'
                      }`}
                      onClick={() => this.saveQuizEdit(false)}
                    >
                      {formatMessage(messages.Save)}
                    </button>
                  </div>
                </Row>
                <Row className="text-center" style={{ marginTop: '30px' }}>
                  <Col md="12">
                    <button
                      type="button"
                      className={`btn btn-danger btn-rounded Ripple-parent ${
                        buttonEnabled ? '' : 'disabled'
                      }`}
                      onClick={() => this.saveQuizEdit(true)}
                    >
                      {formatMessage(messages.FinishEditing)}
                    </button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>

          <Col md="5">
            {questions && questions.length > 0 ? (
              <Card>
                <CardBody>
                  <h2 className="text-center font-weight-bold pt-4 pb-5">
                    <strong>{formatMessage(messages.YourQuestions)}</strong>
                  </h2>
                  <table style={{ width: '100%' }}>
                    <tbody>
                      {questions.map((q, i) => (
                        <tr key={i}>
                          {formActivePanel === 2 ? (
                            <td style={{ width: '40%' }}>
                              <ReactTooltip effect="solid" multiline={true} />
                              <span
                                data-tip={formatMessage(messages.Delete)}
                                data-place="bottom"
                              >
                                <MDBBtn
                                  rounded
                                  color="danger"
                                  size="sm"
                                  onClick={(e) => this.deleteQuestion(e, q.id)}
                                  disabled={questions.length < 2 || !q.id}
                                >
                                  <MDBIcon icon="trash-alt" />
                                </MDBBtn>
                              </span>
                              <span
                                data-tip={formatMessage(messages.Copy)}
                                data-place="bottom"
                              >
                                <MDBBtn
                                  rounded
                                  color="info"
                                  size="sm"
                                  onClick={() => this.copyQuestion(i)}
                                  disabled={!q.id}
                                >
                                  <MDBIcon icon="copy" />
                                </MDBBtn>
                              </span>
                            </td>
                          ) : null}
                          <td>
                            <h6>{`${i + 1}. ${q.title}`}</h6>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {questions.length < 2 && (
                    <p className="text-center pt-4 pb-5">
                      {`* ${formatMessage(messages.AtLeastTwo)}`}
                    </p>
                  )}
                </CardBody>
              </Card>
            ) : null}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default injectIntl(QuizEdit);
