/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from 'react';
import { MDBRow, MDBInput } from 'mdbreact';

const RegisterFields = ({ fields = [], dispatch, messages, formatMessage }) => (
  <Fragment>
    <MDBRow center style={{ marginTop: '10px' }}>
      <h5 className="font-weight-bold mb-3">
        {`${formatMessage(messages.RegisterFields)}`}
      </h5>
    </MDBRow>
    <div>
      <table style={{ margin: 'auto' }}>
        <tbody>
          {fields.map((field, i) => (
            <tr key={i}>
              <td style={{ textAlign: 'left', width: '25%' }}>
                <MDBInput
                  label={field.label}
                  type="checkbox"
                  size="sm"
                  id={`checkbox${field.name}`}
                  value={field.name}
                  checked={field.active}
                  onChange={(e) =>
                    dispatch({
                      type: 'register_field_change',
                      data: { name: e.target.value },
                    })
                  }
                />
              </td>
              <td style={{ textAlign: 'left', width: '25%' }}>
                {field.name !== 'username' ? (
                  <MDBInput
                    label={formatMessage(messages.Optional)}
                    type="checkbox"
                    size="sm"
                    id={`checkbox${field.name}optional`}
                    value={field.name}
                    checked={field.is_optional}
                    onChange={(e) =>
                      dispatch({
                        type: 'register_optional_field_change',
                        data: { name: e.target.value },
                      })
                    }
                  />
                ) : null}
              </td>
              <td>
                <MDBInput
                  hint={field.label}
                  name={field.name}
                  type="text"
                  size="sm"
                  onChange={(e) =>
                    dispatch({
                      type: 'register_input_change',
                      data: { name: e.target.name, value: e.target.value },
                    })
                  }
                  disabled={!field.active}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </Fragment>
);

export default RegisterFields;
