import { defineMessages } from 'react-intl.macro';

export default defineMessages({
  Title: {
    id: 'ManageDomains.Title',
    defaultMessage: 'Manage Domains',
  },
  AddNewDomain: {
    id: 'ManageDomains.AddNewDomain',
    defaultMessage: 'Add New Domain',
  },
  DomainName: {
    id: 'ManageDomains.DomainName',
    defaultMessage: 'Domain Name',
  },
  Add: {
    id: 'ManageDomains.Add',
    defaultMessage: 'Add',
  },
  Added: {
    id: 'ManageDomains.Added',
    defaultMessage: 'Domain was added successfully!',
  },
  DomainNotValid: {
    id: 'ManageDomains.DomainNotValid',
    defaultMessage: 'Domain is not valid!',
  },
  DomainInSystem: {
    id: 'ManageDomains.DomainInSystem',
    defaultMessage: 'Domain is already on the system!',
  },
  Delete: {
    id: 'ManageDomains.Delete',
    defaultMessage: 'Delete',
  },
  Deleted: {
    id: 'ManageDomains.Deleted',
    defaultMessage: 'Domain deleted',
  },
  AreYouSure: {
    id: 'ManageDomains.AreYouSure',
    defaultMessage: 'Are you sure you want to delete this domain?',
  },
  Yes: {
    id: 'ManageDomains.Yes',
    defaultMessage: 'Yes',
  },
  No: {
    id: 'ManageDomains.No',
    defaultMessage: 'No',
  },
});
