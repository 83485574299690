import { defineMessages } from 'react-intl.macro';

export default defineMessages({
  ChooseImage: {
    id: 'ImageInput.ChooseImage',
    defaultMessage: 'Choose Image',
  },
  CropImage: {
    id: 'ImageInput.CropImage',
    defaultMessage: 'Crop Image',
  },
  DeleteImage: {
    id: 'ImageInput.DeleteImage',
    defaultMessage: 'Delete Image',
  },
  Cancel: {
    id: 'ImageInput.Cancel',
    defaultMessage: 'Cancel',
  },
  ErrorUploading: {
    id: 'ImageInput.ErrorUploading',
    defaultMessage: 'Error uploading image. Please try again!',
  },
  FileSizeError: {
    id: 'ImageInput.FileSizeError',
    defaultMessage: 'File size can not be bigger than 5 MB',
  },
});
