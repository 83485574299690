import React from 'react';
import {
  Card,
  CardBody,
  Fa,
  MDBContainer,
  MDBInput,
  MDBListGroup,
  MDBListGroupItem,
} from 'mdbreact';
import messages from '../messages';

let Back = ({
  content,
  copyLink,
  deleteContent,
  duplicateContent,
  flip,
  goToReport,
  handleSwitchChange2,
  intl,
  openEmbedModal,
}) => {
  const { formatMessage } = intl;

  return (
    <Card className="face back">
      <CardBody>
        <MDBContainer>
          <MDBListGroup style={{ width: '100%' }}>
            <MDBListGroupItem
              href={
                content.t === 'set'
                  ? `/content/${content.t}/${content.id}`
                  : `/content/edit/${content.t}/${content.id}`
              }
              hover
              style={{ border: 'none', padding: '0.5rem' }}
            >
              <Fa icon="pencil-alt" /> {formatMessage(messages.Edit)}
            </MDBListGroupItem>
            <MDBListGroupItem
              href="#"
              onClick={(e) => deleteContent(e, content)}
              hover
              style={{ border: 'none', padding: '0.5rem' }}
            >
              <Fa icon="eraser" /> {formatMessage(messages.Delete)}
            </MDBListGroupItem>
            <MDBListGroupItem
              href="#"
              onClick={(e) => openEmbedModal(e, content)}
              hover
              style={{ border: 'none', padding: '0.5rem' }}
            >
              <Fa icon="code" /> {formatMessage(messages.EmbedCode)}
            </MDBListGroupItem>
            <MDBListGroupItem
              href="#"
              onClick={(e) => copyLink(e, content)}
              hover
              style={{ border: 'none', padding: '0.5rem' }}
            >
              <Fa icon="sticky-note" /> {formatMessage(messages.CopyLink)}
            </MDBListGroupItem>
            <MDBListGroupItem
              href={`/content-detail/${content.t}/${content.id}`}
              hover
              style={{ border: 'none', padding: '0.5rem' }}
            >
              <Fa icon="chart-bar" /> {formatMessage(messages.ViewStats)}
            </MDBListGroupItem>
            <MDBListGroupItem
              href="#"
              onClick={(e) => goToReport(e, content)}
              hover
              style={{ border: 'none', padding: '0.5rem' }}
            >
              <Fa icon="sort-amount-up" /> {formatMessage(messages.ViewReport)}
            </MDBListGroupItem>
            <MDBListGroupItem
              href="#"
              onClick={(e) => duplicateContent(e, content)}
              hover
              style={{ border: 'none', padding: '0.5rem' }}
            >
              <Fa icon="clone" /> {formatMessage(messages.Duplicate)}
            </MDBListGroupItem>
            <MDBListGroupItem>
              <label>{formatMessage(messages.Status)}</label>
              <MDBInput
                label={formatMessage(messages.Published)}
                type="checkbox"
                id={`checkbox-publish-${content.t}-${content.id}`}
                checked={content.status === 'published'}
                onChange={(e) =>
                  handleSwitchChange2(e, 'toggle-status', content)
                }
              />
            </MDBListGroupItem>
            <MDBListGroupItem
              href="#"
              onClick={flip}
              hover
              style={{ color: '#007bff', border: 'none' }}
            >
              <Fa icon="undo" /> {formatMessage(messages.Back)}
            </MDBListGroupItem>
          </MDBListGroup>
        </MDBContainer>
      </CardBody>
    </Card>
  );
};

export default Back;
