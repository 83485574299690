import { defineMessages } from 'react-intl.macro';

export default defineMessages({
  VerifyMessage: {
    id: 'VerifyAccount.VerifyMessage',
    defaultMessage:
      'Thank you! Your email has been verified. You can start enjoying Poltio, welcome aboard!',
  },
  Login: {
    id: 'VerifyAccount.Login',
    defaultMessage: 'Login',
  },
  ErrorOccured: {
    id: 'VerifyAccount.ErrorOccured',
    defaultMessage:
      'Error occured while verifying your account. Please try again.',
  },
});
