import React from 'react';
import { injectIntl } from 'react-intl';
import {
  MDBInput,
  MDBRow,
  MDBSwitch,
  MDBCol,
  MDBBtn,
  Fa,
  MDBIcon,
} from 'mdbreact';
import ReactTooltip from 'react-tooltip';

import messages from '../messages';
import PixelCodeSelector from '../PixelCodeSelector';
import LeadSelector from '../LeadSelector';
import ImageInput from '../../ImageInput';
import { Fragment } from 'react';

const Result = ({
  intl: { formatMessage },
  data,
  results,
  setResultImg,
  handleResultTextChange,
  handleResultSwitchChange,
  leadSelect,
  pixelCodeSelect,
  clickPixelCodeSelect,
  saveSet,
  saving,
  changeResultNumber,
}) => {
  const resultSize = 255;
  const result = data.results.map((result) => result);
  const buttonEnabled =
    data.desc.length > 0 &&
    result[0]?.title &&
    result[0]?.title?.length > 0 &&
    result[0]?.desc &&
    result[0]?.desc?.length > 0;

  return (
    <MDBCol sm="12">
      <ReactTooltip effect="solid" multiline={true} id="result" />
      <MDBRow style={{ padding: '20px' }}>
        <MDBCol className="text-center">
          <h3>{formatMessage(messages.Results)}</h3>
        </MDBCol>
      </MDBRow>
      {results.map((result, index) => (
        <MDBRow
          className="collapse-media"
          style={{ background: '#E8E8E8', padding: '10px' }}
          key={index}
        >
          <div
            className="collapse-media__item col-5"
            style={{ overflow: 'visible' }}
          >
            <ImageInput
              title={formatMessage(messages.AddImage)}
              setSRC={(s, t) => {
                setResultImg(s, index);
              }}
              src={result.background}
              answer
            />
          </div>
          <div className="col-7">
            <MDBInput
              className="form-control"
              type="textarea"
              name="title"
              label={`${formatMessage(messages.Result)} -${
                resultSize - result.title.length
              }`}
              maxlenght={resultSize}
              value={result.title || ''}
              onChange={(e) => handleResultTextChange(e, index)}
            />
            <MDBInput
              className="form-control"
              type="textarea"
              name="desc"
              label={formatMessage(messages.ResultDesc)}
              value={result.desc || ''}
              onChange={(e) => handleResultTextChange(e, index)}
            />
            <MDBInput
              className={`form-control w-100 
                    ${
                      !result.dont_shorten &&
                      result.url &&
                      result.url.length > 0 &&
                      !result.url.startsWith('http') &&
                      result.dirty
                        ? 'is-invalid'
                        : ''
                    }
                    `}
              name="url"
              label={formatMessage(messages.Url)}
              value={result.url || ''}
              onChange={(e) => handleResultTextChange(e, index)}
              type="textarea"
            />
            <MDBInput
              className="form-control w-100"
              name="url_text"
              label={formatMessage(messages.UrlText)}
              value={result.url_text || ''}
              onChange={(e) => handleResultTextChange(e, index)}
              type="textarea"
            />
            <MDBInput
              className="form-control w-100"
              name="luv"
              label={formatMessage(messages.LeadUrlVariable)}
              value={result.luv || ''}
              onChange={(e) => handleResultTextChange(e, index)}
              type="textarea"
            />
            <MDBRow>
              <MDBCol md="6">
                <label>{formatMessage(messages.DontShortenLink)}</label>
                <span
                  data-tip={formatMessage(messages.DontShortenDesc)}
                  data-place="bottom"
                  data-for="result"
                >
                  {' '}
                  <MDBIcon far icon="question-circle" />
                </span>
              </MDBCol>
              <MDBCol md="6">
                <MDBSwitch
                  checked={result.dont_shorten === 1 ? true : false}
                  onChange={(e) => handleResultSwitchChange(e, index)}
                />
              </MDBCol>
            </MDBRow>
            {data.is_calculator ? (
              <Fragment>
                <MDBInput
                  className="form-control"
                  type="number"
                  name="min_c"
                  label={'Min'}
                  value={result.min_c}
                  onChange={(e) => handleResultTextChange(e, index)}
                  onKeyPress={(e) => {
                    if (!/^[0-9]$/i.test(e.key)) {
                      e.preventDefault();
                      return false;
                    }
                  }}
                />
                <MDBInput
                  className="form-control"
                  type="number"
                  name="max_c"
                  label={'Max'}
                  value={result.max_c}
                  onChange={(e) => handleResultTextChange(e, index)}
                  onKeyPress={(e) => {
                    if (!/^[0-9]$/i.test(e.key)) {
                      e.preventDefault();
                      return false;
                    }
                  }}
                />
              </Fragment>
            ) : null}
            <MDBRow>
              <PixelCodeSelector
                index={index}
                pixelCode={pixelCodeSelect}
                id={result.pixel_code_id}
              />
            </MDBRow>
            <MDBRow>
              <PixelCodeSelector
                index={index}
                pixelCode={clickPixelCodeSelect}
                id={result.click_pixel_code_id}
                clickPixelCode
              />
            </MDBRow>
            <MDBRow>
              <LeadSelector
                index={index}
                leadSelect={leadSelect}
                id={result.lead_id}
              />
            </MDBRow>
          </div>
        </MDBRow>
      ))}
      <MDBRow>
        <MDBCol className="text-center">
          <MDBBtn
            rounded
            color="secondary"
            onClick={(e) => changeResultNumber(e, 'add')}
          >
            {formatMessage(messages.AddResult)}
          </MDBBtn>
          {results?.length > 1 && (
            <MDBBtn
              rounded
              color="secondary"
              onClick={(e) => changeResultNumber(e, 'delete')}
            >
              {formatMessage(messages.DeleteResult)}
            </MDBBtn>
          )}
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol className="text-center">
          <MDBBtn
            rounded
            color="danger"
            onClick={(e) => saveSet(e, true)}
            disabled={saving}
            className="float-left"
          >
            {formatMessage(messages.SaveAndFinish)}
          </MDBBtn>
          <MDBBtn
            rounded
            color="primary"
            onClick={saveSet}
            disabled={!buttonEnabled}
            className="float-right"
          >
            {formatMessage(messages.SaveAndEditQuestions)}{' '}
            <Fa icon="arrow-right" />
          </MDBBtn>
        </MDBCol>
      </MDBRow>
      <MDBRow style={{ marginRight: '15px' }}>
        <MDBCol>
          {!buttonEnabled ? (
            <div className={'float-right text-danger'}>
              {data.desc.length === 0
                ? `${formatMessage(messages.PleaseEnterDesc)}`
                : null}
            </div>
          ) : null}
        </MDBCol>
      </MDBRow>
      <MDBRow style={{ marginRight: '15px' }}>
        <MDBCol>
          {!buttonEnabled ? (
            <div className={'float-right text-danger'}>
              {result[0]?.title.length === 0
                ? `${formatMessage(messages.PleaseEnterResult)}`
                : null}
            </div>
          ) : null}
        </MDBCol>
      </MDBRow>
      <MDBRow style={{ marginRight: '15px' }}>
        <MDBCol>
          {!buttonEnabled ? (
            <div className={'float-right text-danger'}>
              {result[0]?.desc == null
                ? `${formatMessage(messages.PleaseEnterResultDesc)}`
                : null}
            </div>
          ) : null}
        </MDBCol>
      </MDBRow>
      {result[0]?.desc != null ? (
        <MDBRow style={{ marginRight: '15px' }}>
          <MDBCol>
            {!buttonEnabled ? (
              <div className={'float-right text-danger'}>
                {result[0]?.desc.length === 0
                  ? `${formatMessage(messages.PleaseEnterResultDesc)}`
                  : null}
              </div>
            ) : null}
          </MDBCol>
        </MDBRow>
      ) : null}
    </MDBCol>
  );
};

export default injectIntl(Result);
