import React from 'react';

import { MDBRow, MDBCol, Input, MDBBtn, Fa } from 'mdbreact';

const ColorOption = ({ name, val, label, onChange, def, reset, resetText }) => {
  return (
    <MDBRow>
      <MDBCol md="8">
        <Input
          label={label}
          className="mt-3"
          name={name}
          value={val}
          onChange={onChange}
        />
      </MDBCol>
      <MDBCol md="1">
        <div
          style={{
            backgroundColor: val,
            height: 50,
            width: 50,
            borderStyle: 'solid',
          }}
        />
      </MDBCol>
      <MDBCol md="3">
        {val !== def && (
          <MDBBtn outline color="info" size="sm" onClick={reset}>
            <Fa icon="pencil-alt" fixed /> {resetText}
          </MDBBtn>
        )}
      </MDBCol>
    </MDBRow>
  );
};

export default ColorOption;
