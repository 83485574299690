import React from 'react';
import { useIntl } from 'react-intl';
import { MDBContainer, MDBNavbar, MDBNavbarBrand } from 'mdbreact';
import history from '../../core/history';
import logo from '../../images/poltio-logo.svg';

const TermsAndConditions = () => {
  const intl = useIntl();

  return (
    <div>
      <header>
        <MDBNavbar color="indigo" dark expand="md" scrolling fixed="top">
          <MDBNavbarBrand
            style={{ cursor: 'pointer' }}
            to="/"
            onClick={() => {
              history.push('/');
            }}
          >
            <img src={logo} alt="" />
          </MDBNavbarBrand>
        </MDBNavbar>
      </header>
      <MDBContainer>
        <div className="row mt-5 pt-5">
          <div className="col">
            {intl.locale === 'tr' ? (
              <div>
                <h2>POLTIO KULLANIM KOŞULLARI</h2>

                <h3>Poltio Nedir?</h3>

                <ul>
                  <li>
                    Poltio; anket, bilgi yarışması, ürün bulucu, kişilik testi,
                    hesaplayıcı gibi soru / cevap tabanlı interaktif içerikler
                    oluşturmaya ve oluşturulan içeriklerin yayınlanmasına imkan
                    sağlayan yazılım tabanlı bir teknoloji platformudur.
                  </li>
                  <li>
                    İçerik üretmek isteyen bireysel ve kurumsal firmalar
                    (“İçerik Sahibi”), Poltio altyapısını kullanarak çoktan
                    seçmeli ya da açık uçlu soru içerikleri oluşturup söz konusu
                    içerikleri Poltio tarafından sağlanan Poltio.com uzantılı
                    bir internet sayfası aracılığıyla ya da kendi web siteleri,
                    mobil uygulamaları, sosyal medya, reklam alanları, dijital
                    terminaller ya da farklı dijital kanallar içerisinde gömülü
                    olarak yayınlayabilirler.
                  </li>
                </ul>

                <h3>Giriş</h3>

                <ul>
                  <li>
                    İşbu Kullanım Koşulları size Poltio tarafından sunulan
                    hizmet kapsamındaki koşulları içerir ve hizmete nasıl
                    erişileceğini ve hizmetin nasıl kullanılacağını açıklar.
                    Kayıt olurken “Kayıt Ol” başlıklı doldurduğunuz formun en
                    altında yer alan kutucuğu işaretlediğinizde işbu koşulları
                    kabul etmiş olursunuz.
                  </li>
                  <li>
                    Poltio’nun sunduğu hizmet kapsamında Poltio 6698 sayılı
                    Kişisel Verilerin Korunması Kanunu (“KVKK”) kapsamında veri
                    işleyen; İçerik Sahibi ise Veri Sorumlusu sıfatındadır. Bu
                    kapsamda, Poltio KVKK ve ilgili mevzuat kapsamında bir veri
                    işleyen olarak yükümlülüklerini yerine getirdiğini ve
                    getireceğini beyan ve taahhüt eder.
                  </li>
                </ul>

                <h3>Poltio Hesap Oluşturma</h3>

                <ul>
                  <li>
                    İçerik Sahibi, Poltio hizmetlerinden yararlanmak için e-mail
                    adresiyle sisteme kaydolduktan sonra bir şifre
                    oluşturmalıdır.{' '}
                  </li>
                  <li>
                    Oluşturulan şifreyi hatırlamak ve şifrenin güvenliğini
                    sağlamak İçerik Sahibi’nin sorumluluğundadır. Ancak Poltio,
                    şifreleri hatırlatmak ve platform deneyiminizi iyileştirmek
                    için çerezler kullanır.
                  </li>
                  <li>
                    Oluşturulan hesapta yapılan tüm işlemlerden İçerik Sahibi
                    sorumludur.
                  </li>
                  <li>
                    Poltio, hesap oluşturulurken kullanılan e-mail adresi
                    üzerinden İçerik Sahibi’ni Poltio hakkında bilgilendirmek
                    için iletişime geçebilir. İçerik Sahibi isterse KVKK/GDPR
                    doğrultusunda istediği zaman e-mail aboneliğini iptal
                    edebilir.
                  </li>
                </ul>

                <h3>Poltio İçerik Oluşturma</h3>

                <ul>
                  <li>
                    Poltio aracılığıyla oluşturulan içerikler çoktan seçmeli ya
                    da açık uçlu sorulardan oluşur.
                  </li>
                  <li>
                    İçerik sahibi platform üzerinde istediği içeriği
                    yayınlayabilir ve sonuçlarını analiz edebilir. İşbu içeriğin
                    tüm hakları İçerik Sahibi’ne ait olup Poltio içerik üzerinde
                    herhangi bir mülkiyet hakkı iddia etmez. İçeriklerle alakalı
                    oluşabilecek hukuki sorunlardan Poltio sorumlu değildir.
                  </li>
                  <li>
                    Oluşturulan içeriklerde kullanılan font, renk, logo gibi
                    alanlar içeriği oluşturan İçerik Sahibi tarafından
                    istenildiği gibi özelleştirilebilir. İçeriklerde kullanılan
                    yazı, fotoğraf, video, vb. materyaller, bunlarla ilgili
                    yükümlülükler ve içeriğin genel ahlak ve yasalara uygunluğu,
                    telif hakları İçerik Sahibi’nin sorumluluğunda olup
                    Poltio’nun bu kapsamda herhangi bir sorumluluğu
                    bulunmamaktadır.
                  </li>
                  <li>
                    İçeriklerdeki soruları cevaplandıran katılımcılara,
                    verdikleri cevaplara göre özelleştirilmiş sonuçlar
                    gösterilebilir. Hangi koşullarda, hangi sonuçların
                    gösterileceği ve yapılan iletişim Poltio platformu
                    aracılığıyla İçerik Sahibi tarafından belirlenir.{' '}
                  </li>
                  <li>
                    Poltio’nun, yayınlanan içeriklerin yasal olup olmadığını
                    veya bu koşulların ihlal edilip edilmediğinden emin olmak
                    için bazı içeriklere erişim ve kontrol etme hakkına sahip
                    olmakla birlikte yasaları veya gerekli koşullarda ihlal
                    olduğunu düşündüğümüz içerikleri kaldırma, düzenleme, erişim
                    engelleme hakkına sahip olduğunu da kabul etmektesiniz.{' '}
                  </li>
                  <li>
                    Oluşturulan içerikleri bir son kullanıcı ilk defa gördüğünde
                    içerisinde Poltio’nun çerez kullanımı ve genel kullanım
                    koşulları yer alan bir uyarı metni otomatik olarak
                    kullanıcının karşısına çıkartılır. İçerik Sahibi isterse bu
                    uyarı metnini göstermeyi tercih etmeyebilir. İçerik
                    Sahibi’nin söz konusu uyarı metnini göstermeyi tercih
                    etmediği durumda, veri sorumlusu sıfatıyla mevzuattan doğan
                    gerekli yasal bilgilendirmeleri yapmak ve yükümlülüklerini
                    yerine getirmek, kendi sorumluluğunda olacaktır.
                  </li>
                  <li>
                    İçerik Sahibi oluşturulan içerikleri Poltio tarafından
                    sağlanan linkleri kullanarak istediği platformda
                    paylaşabileceği gibi, Poltio’nun sağladığı iFrame’leri
                    kullanarak kendi web sitesin ya da mobil uygulamasında da
                    yayınlayabilir.
                  </li>
                  <li>
                    Poltio platformunda bir İçerik Sahibi hesabı üzerinden
                    oluşturulan içerik, İçerik Sahibi’nin kendi sahip olduğu
                    mecrada yayınlanabileceği gibi, farklı mecralarda ve / veya
                    reklam alanlarında da yayınlanabilir.
                  </li>
                  <li>
                    İçerik Sahibi’yle ek anlaşma yapılması durumunda Poltio
                    ekibi, İçerik Sahibi hesabı üzerinden içerik oluşturma ve
                    içerik sonuçlarını analiz etme süreçlerini üstlenebilir ya
                    da bu konuda İçerik Sahibi’ne destek verebilir.
                  </li>
                </ul>

                <h3>Gizlilik</h3>

                <ul>
                  <li>
                    İçerik Sahibi platform üzerinde oluşturduğu içerikler
                    aracılığıyla içerisinde kişisel bilgi yer almayan anket
                    verilerini yine platform üzerinden analiz edebilir ya da
                    farklı bir platforma aktarabilir.
                  </li>
                  <li>
                    İçerik Sahibi, katılımcıların e-posta, cep telefonu gibi
                    kişisel verilerini Poltio üzerinden oluşturduğu içerikler
                    ile topluyorsa gerekli yasal bilgilendirmeleri son
                    kullanıcıya yapmak ve yasalar çerçevesinde kişisel verileri
                    saklamak ve söz konusu kişisel verilerin güvenliğini
                    sağlamaktan sorumludur.
                  </li>
                  <li>
                    İçerik Sahibi, platform üzerinden edindiği iletişim
                    bilgileriyle pazarlama faaliyeti yürütebilir veya işbu
                    iletişim bilgilerini üçüncü bir kişiyle paylaşabilir ancak
                    bu konuda ilgili yasalara uymak Poltio’nun değil içerik
                    sahibinin sorumluluğundadır.
                  </li>
                  <li>
                    Poltio burada ‘’veri işleyen’’ konumundadır. Toplanan veriyi
                    kullanmaz sadece raporlama amaçlı olarak platform içinde
                    saklar. Poltio verilerin saklanması aşamasında uygun
                    güvenlik düzeyini temin etmeye yönelik gerekli her türlü
                    teknik ve idari tedbirleri alacaktır. Ayrıca, Poltio ile
                    İçerik Sağlayıcısı arasındaki hizmet sona erdiğinde Poltio,
                    Kişisel Verileri Saklama ve İmha Politikasına uygun olarak
                    raporlama amaçlı toplanan veriyi imha edecektir.
                  </li>
                  <li>
                    Poltio, mevzuatın izin verdiği hallerde, ilgili kişiye işbu
                    gerekliliğe itiraz etme fırsatı tanımak kaydıyla, yasanın
                    veya yasal bir işlemin gerektirdiği durumlarda verileri ifşa
                    edebilir.
                  </li>
                </ul>

                <h4>Tekil Kullanıcı ID kullanımı</h4>

                <ul>
                  <li>
                    Poltio içeriğini kendi sitesinde yayınlayan İçerik Sahibi,
                    hali hazırda bildiği kullanıcı bilgileriyle, kullanıcının
                    Poltio içeriklerinde verdiği cevap bilgilerini eşlemek
                    isterse, bunu Poltio platformuna her kullanıcı için özel
                    olarak yükleyeceği tekil kodlar aracılığıyla yapabilir.
                    Poltio oy bilgilerini raporlarında bu tekil kod ile eşler,
                    İçerik Sahibi de analizlerinde bu kodu, kendi tarafındaki
                    bilgilerle eşlemek için kullanabilir. Bu kullanım şekli için
                    kullanıcıdan gerekli izinleri almak İçerik Sahibi’nin
                    sorumluluğundadır. Poltio’nun erişimi bu tekil kod ile
                    sınırlıdır; İçerik Sahibi’nin sahip olduğu farklı bilgilere
                    erişemez.
                  </li>
                </ul>

                <h4>Piksel Kod Kullanımı</h4>

                <ul>
                  <li>
                    İçerik Sahibi, Poltio aracılığıyla oluşturduğu içeriklerde
                    cevap seçeneği, soru, içerik geneli ya da spesifik sonuçlara
                    piksel kodları yerleştirebilir. Kullanıcı cevap verdikten
                    sonra bu piksel kodu Poltio tarafından çağrılır.
                  </li>
                  <li>
                    İçerik Sahibi piksel kodlar ile birlikte farklı kanallardan
                    gelen dönüşümleri ölçebilir, sosyal medya ya da farklı
                    reklam platformları üzerinde yeniden hedeflenebilir reklam
                    kitleleri oluşturabilir.
                  </li>
                  <li>
                    İçerik Sahibi piksel kod kullanımında gerekli kurallara
                    uyduğunu kabul eder.
                  </li>
                </ul>

                <h4>Raporlar</h4>

                <ul>
                  <li>
                    İçerik Sahibi giriş bilgileri ile giriş yaptıktan sonra
                    Poltio üzerinde oluşturduğu içeriklere ve verilere
                    erişebilir.{' '}
                  </li>
                  <li>
                    Poltio platformu üzerinde kullanıcıların içerikler
                    içerisinde yer alan sorulara verdikleri çoktan seçmeli ve
                    açık uçlu cevaplar, erişim tarihi, içeriğin gösterildiği
                    internet sitesinin adresi, kendilerine gösterilen sonuç ve
                    kullanıcının bu sonuçla ilgili aldığı aksiyon gibi bilgiler
                    raporlama ve analiz amaçlı olarak saklanır.
                  </li>
                  <li>
                    Poltio Pro’daki analiz ara yüzü ile belirli grafikler
                    halinde görselleştirilir ve rapor çekme özelliği ile bu
                    veriler dışa aktarılabilir.{' '}
                  </li>
                  <li>
                    Poltio platformunu kullanan İçerik Sahibi bu bilgileri rapor
                    olarak çekebileceği ve bu bilgilere erişim sağlayabileceği
                    gibi, eş zamanlı olarak kendi platformlarına yazılmasını
                    sağlayabilir veya belirttiği bir internet adresine
                    sonuçların canlı olarak webhook şeklinde yollanmasını tercih
                    edebilir. Eğer verilerini Google ile paylaşırsa raporları
                    canlı bir Google Sheet olarak da alabilir.
                  </li>
                  <li>
                    Poltio kullanıcı IP adreslerini yasal olarak saklamakla
                    yükümlüdür ama İçerik Sahibi’yle paylaşmaz. Kullanıcı IP
                    adresleri kullanılarak kullanıcının lokasyonu ile ilgili
                    tahmin yapılabilir ve lokasyona ilişkin bu bilgi de
                    raporlara eklenebilir.
                  </li>
                  <li>
                    Poltio’nun yüksek performans odaklı altyapısı bulut tabanlı
                    ve alanında lider veri merkezlerinde barındırılmaktadır.
                    Verilerimiz yüksek güvenlik önlemleriyle birlikte
                    yurtdışındaki veri merkezlerinde barındırılmaktadır.
                  </li>
                </ul>

                <h4>Ödemeler</h4>
                <ul>
                  <li>
                    İçerik Sahibi anlaşılan şartlarda ödeme yapmakla sorumludur.
                  </li>
                  <li>
                    İçerik Sahibi anlaşma süresi bittiğinde istediğinde servisi
                    kullanmaktan vazgeçebilir.
                  </li>
                  <li>
                    Poltio, yapılan anlaşma doğrultusunda belirlenen zaman
                    aralığında hizmet karşılığı gereken ödemeyi almadığında
                    platforma erişim hakkını engelleyebilir.
                  </li>
                  <li>
                    Poltio ilgili kanunlara aykırı paylaşımlar veya gerekli
                    durumlar sebebiyle sözleşmeyi belirtilen tarihler arasında
                    feshetme hakkına sahiptir.
                  </li>
                  <li>
                    Uygun olmayan ve yasaklı kullanımları tekrarlı veya açık bir
                    şekilde ihlal etmeniz nedeniyle Poltio’nun Hizmetleri
                    tarafınıza sunmayı durdurması durumunda Poltio, Hizmetleri
                    kullanmanızı engellemek amacıyla IP adresinizin engellenmesi
                    gibi önlemler alınabilir.
                  </li>
                </ul>

                <h3>Poltio Çerez kullanımı</h3>
                <h4>Çerez Nedir?</h4>
                <p>
                  Çerezler, Poltio platformu aracılığıyla oluşturulmuş
                  içerikleri görüntülediğinizde ve tarayıcınızın izin verdiği
                  durumlarda, mobil veya masaüstü cihazınıza kaydedilen küçük
                  metin dosyaları veya bilgilerdir. Çerezler genellikle
                  kullanıcıların geldikleri internet site isimlerini, kullanım
                  ömürlerini (cihazınızda ne kadar süre ile kalacağı gibi) ve
                  rastgele verilen sayılardan oluşan değerleri içerir.
                </p>
                <h4>Ne için Kullanıyoruz?</h4>
                <p>
                  Çerezleri, size daha kişiselleştirilmiş bir hizmet sunabilmek
                  ve aynı zamanda platformumuz üzerinde oluşturulmuş anketlere
                  verilebilecek mükerrer oyları engelleyebilmek ve tekrar bir
                  içerik görüntülediğinizde daha önce oy kullanmış olduğunu
                  anketlerin bilgilerini sizin ile paylaşabilmek veya ilgili
                  içerik gösterebilmek için kullanıyoruz. Çerezleri,
                  kullanıcılarımızın altyapımız aracılığıyla oluşturulan
                  içerikleri nasıl kullandıklarını anlamamızı sağlayan isimsiz
                  ve toplu istatistiki verileri bir araya getirmemize ve
                  sitelerimizin yapılarını ve içeriklerini geliştirmemize
                  yardımcı olmaları amacıyla kullanmaktayız. Bu verilerin,
                  kimliğinizi tanımlamamızı sağlayabilecek nitelikte kişisel
                  veriler olmadığını belirtiriz.
                </p>

                <h4>Hangi Türlerini Kullanıyoruz?</h4>
                <p>
                  Oturum çerezleri ve kalıcı çerezler olmak üzere platformumuzda
                  genelinde iki tür çerez kullanmaktayız. Oturum çerezleri
                  geçici çerezler olup Poltio aracılığıyla oluşturulan bir
                  içerikle etkileşiminiz tamamlanıncaya kadar geçerlidir. Kalıcı
                  çerezler ise siz silinceye veya süreleri doluncaya (çerezlerin
                  cihazınızda ne kadar kalacağı, cerezlerin kullanım ömürlerine
                  bağlı olarak değişir) kadar sabit diskinizde kalır.
                </p>

                <h4>Üçüncü Parti Çerezleri</h4>
                <p>
                  Sağladıkları servisleri size sunabilmeleri için, Poltio’yu
                  ziyaret ettiğinizde cihazınızda sizin adınıza çerez ayarları
                  yapan bazı üçüncü parti sağlayıcılarla çalışıyoruz. Bu
                  çerezler hakkında daha fazla bilgi edinmek ve bu çerezlerin
                  nasıl kontrol edileceğine ilişkin ayrıntılı bilgi için, lütfen
                  söz konusu üçüncü parti sağlayıcıların gizlilik politikalarını
                  veya çerez politikalarını inceleyiniz.
                </p>
                <h4>Çerezleri nasıl kontrol edebilir veya silebilirsiniz?</h4>

                <p>
                  Birçok internet tarayıcısı, varsayılan olarak çerezleri
                  otomatik olarak kabul etmeye ayarlıdır. Bu ayarları, çerezleri
                  engelleyecek veya cihazınıza çerez gönderildiğinde uyarı
                  verecek şekilde değiştirebilirsiniz. Çerezleri yönetmenin
                  birkaç yolu bulunmaktadır. Tarayıcı ayarlarınızı nasıl
                  düzenleyeceğiniz hakkında ayrıntılı bilgi almak için lütfen
                  tarayıcınızın talimat veya yardım ekranına başvurun. Eğer
                  kullandığımız çerezleri devre dışı bırakırsanız, bu eylem
                  Poltio’daki kullanıcı deneyiminizi etkileyebilir; örneğin
                  Poltio aracılığıyla oluşturulmuş belirli içerikleri
                  görüntüleyemeyebilir veya ziyaret ettiğinizde sizin için
                  özelleştirilmiş olan bilgilere ulaşamayabilirsiniz. Poltio
                  aracılığıyla oluşturulmuş içerikleri görüntülemek için farklı
                  cihazlar kullanıyorsanız (ör. bilgisayar, akıllı telefon,
                  tablet vb.), bu cihazların her birindeki her tarayıcının çerez
                  tercihlerinize uygun şekilde ayarlanmış olduğundan emin
                  olmanız gerekmektedir.
                </p>

                <p>
                  Poltio, bir Cana Bilişim Hizmetleri ve Ticaret Anonim Şirketi
                  markasıdır. Şirketin temsilcisi Ahmet Tosun’dur.
                </p>

                <p>
                  Tüm ilgili kişiler Şirkete veya temsilcisine ulaşarak kişisel
                  verilerin işlenip işlenmediğini öğrenme, Kişisel verileri
                  işlenmişse buna ilişkin bilgi talep etme, kişisel verilerin
                  işlenme amacını ve bunların amacına uygun kullanılıp
                  kullanılmadığını öğrenme, yurt içinde veya yurt dışında
                  kişisel verilerin aktarıldığı üçüncü kişileri bilme, kişisel
                  verilerin eksik veya yanlış işlenmiş olması hâlinde bunların
                  düzeltilmesini isteme, KVKK m. 7de öngörülen şartlar
                  çerçevesinde kişisel verilerin silinmesini veya yok edilmesini
                  isteme, KVKK m. 11’in (d) ve (e) bentleri uyarınca yapılan
                  işlemlerin (düzeltme ve silinme), kişisel verilerin
                  aktarıldığı üçüncü kişilere bildirilmesini isteme, işlenen
                  verilerin münhasıran otomatik sistemler vasıtasıyla analiz
                  edilmesi suretiyle kişinin kendisi aleyhine bir sonucun ortaya
                  çıkmasına itiraz etme, kişisel verilerin kanuna aykırı olarak
                  işlenmesi sebebiyle zarara uğraması hâlinde zararın
                  giderilmesini talep etme haklarına sahiptir.
                </p>

                <p>
                  Cana Bilişim Hizmetleri ve Tic. AŞ Adres: Büyükdere Cad. Loft
                  Residance Blok No: 201 İç kapı No: 6 Şişli/ İstanbul İletişim:
                  destek@poltio.com
                </p>
              </div>
            ) : (
              <div>
                <h3>Terms of Service</h3>
                <p>
                  These Terms of Service (these &ldquo;<b>Terms</b>&rdquo;)
                  constitute an agreement between you, the user, and Poltio,
                  Inc. (&ldquo;<b>Poltio</b>&rdquo;, &ldquo;<b>we</b>&rdquo; or
                  &ldquo;<b>us</b>&rdquo;). These Terms govern your access to
                  and use of the Poltio mobile application (the &ldquo;
                  <b>App</b>&rdquo;), the website located at www.poltio.com (the
                  &ldquo;<b>Website</b>&rdquo;), including any content,
                  functionality (including, but not limited to, your voting and
                  interactions with trivia and other survey questions) and
                  services offered on or through the App or the Website, and any
                  of our other products or services (collectively referred to as
                  the &ldquo;<b>Services</b>&rdquo;), whether or not you
                  register as a user
                </p>
                <p>
                  Please read these Terms carefully because they contain
                  provisions that affect your rights and obligations, including
                  a mandatory arbitration provision and a waiver of your right
                  to participate in a class action. By using the Services, you
                  accept and agree to be bound and abide by these Terms and our
                  Privacy Policy (located at www.poltio.com), which is
                  incorporated herein by reference. If you do not want to agree
                  to these Terms or the Privacy Policy, you must not access or
                  use the Services.
                </p>
                <h3>Use of the Services</h3>
                <p>
                  You may use the Services only if you agree to form a binding
                  contract with Poltio and are not a person barred from
                  receiving Services under the laws of your applicable
                  jurisdiction.
                </p>
                <p>
                  You affirm that you are either more than 18 years of age, or
                  an emancipated minor, or possess legal parental or guardian
                  consent, and are fully able and competent to enter into the
                  terms, conditions, obligations, affirmations, representations
                  and warranties set forth in these Terms. In any case, you
                  represent and warrant that you are over the age of 13, as the
                  Services are not intended for children under the age of 13 and
                  Poltio does not knowingly collect personal information from
                  children under the age of 13. If you are under 13 years old,
                  you may not use the Services. If you are the parent or
                  guardian of a child under the age of 13, and you have
                  discovered that your child created an account and would like
                  to have it deleted, please email us at info@poltio.com with
                  your child’s username, email address and password. If you
                  submit content related to your child younger than 13, please
                  do not include personally identifying information about that
                  child, including but not limited to your child’s name,
                  address, email address and phone number.
                </p>
                <p>
                  You agree that you will not create an account or access the
                  Services if you (i) do not meet the age and eligibility
                  requirements listed in the paragraph above or (ii) have been
                  previously removed by Poltio or banned from using the Services
                  for any reason.
                </p>
                <p>
                  You acknowledge and confirm that (i) you have read and
                  understand all of the terms, conditions, policies, provisions,
                  disclosures and disclaimers contained herein, (ii) these Terms
                  have the same force and effect as a signed agreement, and
                  (iii) you expressly accept and agree to be bound by the terms
                  hereof.
                </p>
                <p>
                  For those users located in the United States (&ldquo;
                  <b>U.S.</b>&rdquo;), to be eligible to register an account and
                  to participate in any of the Services you must: (a) be
                  personally assigned to the email address submitted during your
                  account registration; (b) have the power to enter into a
                  contract with Poltio; (c) be physically located within the
                  U.S. when accessing your account and participating in the
                  Services; (d) be physically located within a U.S. state in
                  which participation in the Services is unrestricted by that
                  state’s laws; and (e) at all times abide by these Terms.{' '}
                  <i>
                    If any one of these requirements is not met at any time, we
                    reserve the right to suspend or close your account with or
                    without notice
                  </i>
                </p>
                <p>
                  For any non-U.S. located users, to be eligible to register an
                  account, participate in any event or receive any Services, you
                  must (a) be a natural person who is personally assigned to the
                  email address submitted during your account registration; (b)
                  have the power to enter into a contract with Poltio; (c) by
                  physically located in a jurisdiction in which participation in
                  the Services is permitted and unrestricted by such country’s
                  laws; and (d) at all times abide by these terms.{' '}
                  <i>
                    If any one of these requirements is not met at any time, we
                    reserve the right to suspend or close your account with or
                    without notice
                  </i>
                </p>
                <p>
                  If you access the Services from a location outside the U.S.,
                  you are responsible for compliance with the laws of that
                  jurisdiction regarding online conduct and acceptable content.
                  Poltio makes no claim that the content of the Services may be
                  utilized, accessed or appropriate outside the U.S. You must
                  not access or use the Services from a territory that is
                  embargoed by the U.S. or from which it is illegal to receive
                  products, services, or software from the U.S.
                </p>
                <h3>Data Privacy and Personal Information</h3>
                <p>
                  By agreeing to these Terms, you also consent to our Privacy
                  Policy which is incorporated into these Terms by reference. If
                  you live outside the U.S., by agreeing to these Terms you
                  consent to the transfer and processing of your personal
                  information in the U.S. in accordance with the Privacy Policy
                  and U.S. law.
                </p>
                <p>
                  You should not submit or upload personal information or any
                  other information about yourself or others that you are not
                  comfortable disclosing to the general public. This information
                  will be viewable by other members of the Poltio community, may
                  be searchable on indexing websites such as Google and may be
                  disclosed to third parties under certain circumstances as
                  provided in our Privacy Policy.
                </p>
                <p>
                  Poltio disclaims all liability for the disclosure of
                  information that you choose to submit voluntarily. You assume
                  all such risks with regards to your use of the Services.
                </p>
                <p>
                  You agree that all information you provide to register with or
                  use the Services or otherwise is correct, current and complete
                  and is governed by our Privacy Policy, and you consent to all
                  actions we take with respect to your information consistent
                  with our Privacy Policy. To the extent that you provide any
                  information, including but not limited to personal information
                  or information related to any transaction or transaction
                  party, through the Services, or access or seek access to any
                  such information through the Services, you represent, warrant
                  and covenant that (i) you are providing or accessing only your
                  own information or the information of others that you are
                  authorized to provide to third parties, and you have all
                  required consents and permissions required to share such
                  information; (ii) the use of such information by Poltio and
                  its representatives and users will not infringe upon or
                  misappropriate the intellectual property rights or otherwise
                  violate the rights of any third parties, or violate any
                  applicable law, rule or regulation; and (iii) you will not
                  provide any information or otherwise use the Services in a
                  manner that is harmful, fraudulent, threatening, abusive,
                  harassing, tortuous, defamatory, vulgar, obscene, libelous, or
                  otherwise objectionable, or that jeopardizes the security of
                  your account in any way.
                </p>
                <h3>Third Party Links</h3>
                <p>
                  The Services may link to or embed independent third party
                  websites or social media widgets. Such hyperlinks and widgets
                  are provided for your reference and convenience only. Poltio
                  is not liable for the availability or accuracy of such
                  third-party sources, and you assume all risk in using them.
                  When you link to them, these third-party sites or services may
                  place cookies and other tracking technologies on your computer
                  and may have different rules for using or sharing any personal
                  information you submit to them. We encourage you to read their
                  privacy policies before submitting your personal information.
                </p>
                <p>
                  When you access third party websites or interact or
                  communicate with third parties, including other users, through
                  the Services, you do so at your own risk. Poltio is not
                  responsible for the actions, content, information, data,
                  practices, policies, votes, answers or opinions of any third
                  parties, including other users. You are solely responsible for
                  your interactions with other users of the Services, and any
                  other parties with whom you interact through the Services. You
                  should make whatever investigation you feel necessary or
                  appropriate before proceeding with any online or offline
                  interaction with any other person.
                </p>
                <p>
                  If you have a dispute with one or more users, you release us
                  (and our officers, directors, agents, subsidiaries, joint
                  ventures and employees) from claims, demands and damages
                  (actual and consequential) of every kind and nature, known and
                  unknown, arising out of or in any way connected with such
                  disputes, including damages for loss of profits, goodwill,
                  use, privacy or data. We reserve the right, but have no
                  obligation, to become involved in any way with these disputes.
                  If you are a California resident, you waive California Civil
                  Code §1542, which says: “A general release does not extend to
                  claims which the creditor does not know or suspect to exist in
                  his or her favor at the time of executing the release, which
                  if known by him or her must have materially affected his
                  settlement with the debtor.” And, if you are not a California
                  resident, you waive any applicable state statutes of a similar
                  effect.
                </p>
                <h3>User Accounts</h3>
                <p>
                  If you choose to create an account, you will be asked to
                  provide certain registration details or other information,
                  such as username, password, email address, and other profile
                  information. You can select any username that is available,
                  except that it cannot be an impersonation of another person,
                  be a term that is the same or confusingly similar to a famous
                  trademark, or be a term that is offensive in any way. It is a
                  condition of your use of the Services that all the information
                  you provide is correct, current and complete. You agree that
                  all information you provide to register with or use the
                  Services or otherwise is governed by our Privacy Policy, and
                  you consent to all actions we take with respect to your
                  information consistent with our Privacy Policy. Further,
                  Poltio only allows users to create one user account and you
                  hereby agree that you will only create one such user account.
                </p>
                <p>
                  You agree to keep your username and password confidential, and
                  to not share your account with others. You agree to notify us
                  immediately of any unauthorized access to your username or
                  password or any other breach of security. You should use
                  particular caution when accessing your account from a public
                  or shared computer so that others are not able to view or
                  record your personal information.You agree to keep your
                  username and password confidential, and to not share your
                  account with others. You agree to notify us immediately of any
                  unauthorized access to your username or password or any other
                  breach of security. You should use particular caution when
                  accessing your account from a public or shared computer so
                  that others are not able to view or record your personal
                  information.
                </p>
                <p>
                  You understand that Poltio provides the Services over the
                  Internet and/or a mobile application, that such connections
                  can be unpredictable, insecure, and unstable, and that Poltio
                  cannot guarantee uninterrupted access to the Services. Poltio
                  disclaims all liability in connection with any security
                  incidents or breaches arising from or attributable to an
                  Internet and/or data connection. You agree to waive any and
                  all claims against Poltio in connection therewith.
                </p>
                <p>
                  We are constantly changing and improving our Services. We
                  reserve the right to add or remove functionalities or features
                  of the Services, and we may suspend or stop a Service
                  altogether, including, for example, if you do not comply with
                  these Terms or if we are investigating suspected misconduct.
                  You can stop using our Services at any time. We may also stop
                  providing Services to you, or add or create new limits to our
                  Services or restrict your access to all or a part of the
                  Services at any time without notice or liability. We will not
                  be liable if for any reason all or any part of the Services
                  are unavailable at any time or for any period. The right to
                  access and use the Services is revoked in jurisdictions where
                  it may be prohibited, if any.
                </p>
                <h3>Mobile Application</h3>
                <p>
                  The App is available via a mobile device. Using your mobile
                  device, you will have the ability to: (i) browse and post
                  content on the Services, (ii) vote on various polls, (iii)
                  interact with trivia and other survey questions, (iv) receive
                  and reply to comment messages, and (v) access certain features
                  through an application downloaded and installed on a mobile
                  device (collectively, the &ldquo;<b>Mobile Services</b>
                  &rdquo;). To the extent you access the Services through a
                  mobile device, your wireless service carrier’s standard
                  charges, data rates and other fees may apply. You should check
                  with your carrier to find out what plans are available and how
                  much they cost. In addition, downloading, installing, or using
                  certain Mobile Services may be prohibited or restricted by
                  your carrier, and not all Mobile Services may work with all
                  carriers or devices. Therefore, you should check with your
                  carrier to find out if the Mobile Services are available for
                  your mobile device, and what restrictions, if any, may be
                  applicable to your use of such Mobile Services. By using the
                  Mobile Services, you agree that we may communicate with you
                  regarding Poltio and other entities by electronic means to
                  your mobile device and that certain information about your
                  usage of the Mobile Services may be communicated to us.
                </p>
                <h3>Licenses Granted</h3>
                <p>
                  Poltio grants to you a revocable, non-transferable,
                  non-exclusive, limited and non-assignable license to access
                  and use the Services in accordance with these Terms. Poltio
                  reserves all other rights in the Services not expressly
                  granted to you.
                </p>
                <p>
                  You grant Poltio a worldwide, unlimited, irrevocable,
                  royalty-free license (with the right to sublicense) to
                  publicly and privately use, publish, reproduce, adapt, embed,
                  display, copy, edit, perform, create derivative works from, or
                  otherwise make use of any content, votes and other question
                  responses that you submit through the Services (“”). You
                  understand and acknowledge that you are responsible for any
                  User Content you submit or contribute, and you, not Poltio,
                  are fully responsible for such content, including its
                  legality, reliability, accuracy and appropriateness. You also
                  acknowledge and agree that any User Content (excluding
                  individual votes and quiz responses) that is not marked as
                  “private” may be embedded in third party sites by use of the
                  Services’ embedment functionality. We note, however, that the
                  aggregate results of individual votes and quiz responses may
                  be made available through the Services’ embedment
                  functionality.
                </p>
                <p>
                  We are not responsible, or liable to any third party, for the
                  content or accuracy of any User Content posted by you or any
                  other user of the Services. We cannot guarantee the identity
                  of any other users with whom you may interact in the course of
                  using the Services, or the authenticity of any data which
                  users may provide about themselves. You acknowledge that all
                  content accessed by you using the Services is at your own risk
                  and you will be solely responsible for any damage or loss to
                  any party resulting therefrom.
                </p>
                <p>
                  You grant Poltio a worldwide, unlimited, irrevocable,
                  royalty-free license (with the right to sublicense) to
                  publicly and privately use, distribute, modify, offer for sale
                  or exploit any suggestions, ideas, criticism, or other type of
                  feedback you make relating to the Services, with no obligation
                  to pay you royalties or any other compensation.
                </p>
                <h3>Embedded Content</h3>
                <p>
                  Certain portions of the Services provide the functionality for
                  you to “embed” certain polls and trivia quizzes on third party
                  sites. If you include the necessary widget on your third party
                  site, the actual content will be served from our servers. If
                  you elect to embed content from the Services, you agree as
                  follows: (i) you will not alter, in any respect, the embedded
                  content from how it is served from our services and (ii) the
                  embedded content may be used for commercial purposes,
                  including on advertising-supported pages, provided that (a)
                  the embedded content shall not be included in, or used as part
                  of, a service that sells access to polls, trivia quizzes,
                  surveys and any results of such services, (b) you shall not
                  insert advertising, sponsorship or promotional messages in, or
                  immediately adjacent to, the embedded content and (c) to the
                  extent you sell any advertising, sponsorship or promotional
                  material to appear on the same page as the embedded content,
                  such page shall contain other content not provided by Poltio
                  that is reasonably a sufficient basis for such a sale. You may
                  not block, inhibit, build upon or disable any portion of the
                  embedded content, including, without limitation, links back to
                  the Services. Without limiting any provision hereof, Poltio
                  shall have no liability to you for any reason with respect to
                  your use of the embedded content and you agree to defend,
                  indemnify and hold Poltio and Poltio’s affiliates, directors,
                  officers, employees and agents harmless from any and all
                  claims, liabilities, costs and expenses, including attorneys’
                  fees, arising in any way from your use of the embedded
                  content.
                </p>
                <h3>User Conduct and Community Guidelines</h3>
                <p>
                  You may use the Services only for lawful purposes and in
                  accordance with these Terms. Without limiting the generality
                  of the foregoing, you agree to use the Services in accordance
                  with the provisions below:
                </p>
                <p>
                  <ul>
                    <li>
                      You will only use the Services in accordance with the laws
                      of your jurisdiction.
                    </li>
                    <li>
                      You will be respectful and considerate of other users and
                      do your part to make Poltio a safe, supportive community
                      for all users.
                    </li>
                    <li>
                      You will register for the Services using accurate and
                      current information, and you will not impersonate or
                      attempt to impersonate Poltio, a Poltio employee, another
                      user or any other person or entity (including, without
                      limitation, by using email addresses or user names
                      associated with any of the foregoing).
                    </li>
                    <li>
                      You will only post User Content that is true and
                      informative and otherwise positively impacts the substance
                      of a conversation or topic.
                    </li>
                    <li>
                      You will not post User Content that infringes another’s
                      intellectual property rights.
                    </li>
                    <li>
                      You may not decompile, reverse engineer, or otherwise
                      attempt to obtain the source code of any content on the
                      Services.
                    </li>
                    <li>
                      You will independently verify the quality, relevance or
                      accuracy of any information posted on the Services by
                      others.
                    </li>
                    <li>
                      You will not use, store, copy, upload, display, post,
                      reproduce, modify, translate, republish, distribute,
                      broadcast, transmit, create derivative works from,
                      display, license, sell or otherwise exploit any part of
                      the Services or content on the Services in any form
                      whatsoever other than as expressly permitted under these
                      Terms.
                    </li>
                    <li>
                      You will not use contact information received through the
                      Services for phishing, spamming and other unsolicited
                      communications or data collection.
                    </li>
                    <li>
                      You will not post unsolicited promotional content,
                      advertising, political campaigns, contests, raffles or
                      solicitations.
                    </li>
                    <li>
                      You will not use the Services in a manner intended to
                      damage, disable, overburden or impair the Services or to
                      disrupt or interfere with anothers use of the Services.
                    </li>
                    <li>
                      You will not attempt to hack or otherwise gain
                      unauthorized access to the Services or user accounts.
                    </li>
                    <li>
                      You will only create one personal user account and you
                      will not attempt to create any additional or fake profiles
                      or user accounts.
                    </li>
                    <li>
                      You will not submit fraudulent votes, you will not attempt
                      to vote from other users’ profiles and you will not
                      otherwise manipulate polls or trivia/survey responses in
                      any way.
                    </li>
                    <li>
                      You will not remove, obscure, block, hide or otherwise
                      alter the display of any advertising (or any parts or
                      aspects thereof), Poltio brand elements, including logos,
                      trademarks, service marks or other items displayed by
                      Poltio in connection with the Services in any manner
                      whatsoever, including when using the embedding
                      functionality to display User Content on your or other
                      third party sites.
                    </li>
                    <li>
                      You will not use high volume, automated, or electronic
                      means to access the Services (including without limitation
                      robots, spiders or scripts).
                    </li>
                    <li>
                      You will not affect the display of the Services through
                      use of framing, pop-ups or third-party ads.
                    </li>
                    <li>
                      You will not transmit malware, software viruses, Trojan
                      horses, worms or any other malicious application to or
                      through the Services.
                    </li>
                    <li>
                      You will not threaten, dox, or otherwise harass other
                      users.
                    </li>
                    <li>
                      You will not post information that is inaccurate, false,
                      fraudulent, or misleading, including submissions made
                      under false pretenses.
                    </li>
                    <li>
                      You will not post unlawful, harassing, libelous, abusive,
                      threatening, defamatory, vulgar, obscene, racist, harmful,
                      or otherwise objectionable material of any kind.
                    </li>
                    <li>
                      You will not act in a manner that violates these Terms.
                    </li>
                    <li>
                      You will comply with applicable local, state, national or
                      international laws, statutes, ordinances, rules,
                      regulations or ethical codes governing your jurisdiction.
                    </li>
                    <li>
                      You will notify us immediately of any content of the
                      Services that violates these Terms.
                    </li>
                  </ul>
                </p>
                <p>
                  We have the right to: (i) remove or refuse to post any User
                  Content for any or no reason in our sole discretion; (ii) take
                  any action with respect to any User Content that we deem
                  necessary or appropriate in our sole discretion, including if
                  we believe that such User Content violates these Terms,
                  infringes any intellectual property right or other right of
                  any person or entity, threatens the personal safety of users
                  of the Services or the public or could create liability for
                  Poltio; (iii) disclose your identity or other information
                  about you to any third party who claims that material posted
                  by you violates their rights, including their intellectual
                  property rights or their right to privacy; (iv) take
                  appropriate legal action, including without limitation,
                  referral to law enforcement, for any illegal or unauthorized
                  use of the Services; and (v) terminate or suspend your access
                  to all or part of the Services for any or no reason, including
                  without limitation, any violation of these Terms.
                </p>
                <p>
                  YOU WAIVE AND HOLD HARMLESS POLTIO AND ITS AFFILIATES,
                  LICENSEES AND SERVICE PROVIDERS FROM ANY CLAIMS RESULTING FROM
                  ANY ACTION TAKEN BY SUCH PARTIES DURING OR AS A RESULT OF ITS
                  INVESTIGATIONS AND FROM ANY ACTIONS TAKEN AS A CONSEQUENCE OF
                  INVESTIGATIONS BY SUCH PARTIES OR LAW ENFORCEMENT AUTHORITIES.
                </p>
                <p>
                  We have no liability or responsibility to anyone for
                  performance or nonperformance of the activities described in
                  this section.
                </p>
                <h3>Proprietary Rights</h3>
                <p>
                  The Services and all past, present and future content of the
                  Services, including all software, hardware and technology used
                  to provide the Services (including our proprietary code and
                  third-party software), user interfaces, materials displayed or
                  performed on the Services, such as text, graphics, articles,
                  photographs, images, illustrations and the design, structure,
                  sequence and “look and feel” of the Services, and all other
                  intellectual property, including all trademarks, service
                  marks, logos, or any other trade name, trademarks, service
                  marks and other distinctive or proprietary brand features of
                  Poltio, are (i) owned by Poltio, its licensors or other
                  providers of such material, (ii) protected by U.S. and
                  international copyright, trademark, patent, trade secret and
                  other intellectual property or proprietary rights laws, (iii)
                  are intended solely for the personal, non-commercial use of
                  our users, and (iv) may only be used in accordance with these
                  Terms.
                </p>
                <p>
                  Using our Services does not give you ownership of any
                  intellectual property rights in our Services or any content on
                  the Services. You may not use content from our Services,
                  including User Content, unless you obtain permission from its
                  owner or are otherwise permitted by law. These terms do not
                  grant you the right to use any of our technology or
                  intellectual property. You may not remove, obscure, or alter
                  any copyright or other legal notices displayed in or along
                  with our Services.
                </p>
                <p>
                  No right, title or interest in or to the Services or any
                  content on the Services is transferred to you, and all rights
                  not expressly granted are reserved by Poltio. Any use of the
                  Services not expressly permitted by these Terms is a breach of
                  these Terms and may violate copyright, trademark and other
                  laws.
                </p>
                <h3>Claims of Copyright Infringement</h3>
                <p>
                  If you believe that your copyrighted work has been unlawfully
                  copied and is accessible on the Services in a way that
                  constitutes copyright infringement, please send a notice of
                  copyright infringement to:
                </p>
                <p>
                  <ul>
                    <li>Poltio, Inc.</li>
                    <li>Attn: Copyright Agent</li>
                    <li>12 E. 49th Street, 11th Floor</li>
                    <li>New York, NY 10017</li>
                    <li>Phone: +1 516 6432416</li>
                    <li>Email: ahmet@poltio.com</li>
                  </ul>
                </p>
                <p>The notice should include: </p>
                <p>
                  <ul>
                    <li>
                      Your full name and electronic or physical signature.
                    </li>
                    <li>
                      Your mailing address, phone number and email address (at
                      which you can be contacted by us or the alleged
                      infringer).
                    </li>
                    <li>
                      A statement that you are the owner, or are authorized to
                      act on behalf of the owner, of the copyright that is
                      allegedly infringed, and that the information in your
                      notice is accurate.
                    </li>
                    <li>
                      A statement that you have a good faith belief that the
                      disputed use of the copyrighted materials is not
                      authorized by the copyright owner, its agent or by law,
                      such as fair use.
                    </li>
                    <li>
                      A description of the copyrighted work(s) that you claim
                      has been infringed.
                    </li>
                    <li>
                      A description of the location of the copyrighted materials
                      on the Services.
                    </li>
                  </ul>
                </p>
                <h3>Electronic Delivery Statement and Your Consent</h3>
                <p>
                  You agree to receive legal notices and other information
                  concerning Poltio or the Services electronically, including
                  notice to any email address that you may provide.
                </p>
                <h3>Term and Termination</h3>
                <p>
                  These Terms will remain in effect until terminated by you or
                  Poltio. We reserve the right to terminate or change the
                  Services or your access to the Services, without notice, at
                  any time and for any reason, including your violation of these
                  Terms or for business reasons. The disclaimer of warranties,
                  limitation of liability, jurisdictional, limitation of time to
                  file claims, indemnification and arbitration provisions
                  survive any termination.
                </p>
                <p>
                  You agree that Poltio may, for any reason and without notice
                  to you, immediately terminate or otherwise restrict your
                  access to the Services. Poltio has no obligation to maintain,
                  store, or transfer your information or data that you have
                  posted on or uploaded to the Services.
                </p>
                <h3>Disclaimer of Representations and Warranties</h3>
                <p>
                  THE SERVICES AND ANY ASSOCIATED CONTENT ARE PROVIDED ON AN “AS
                  IS” AND “AS AVAILABLE” BASIS, WITHOUT ANY WARRANTIES OF ANY
                  KIND, EITHER EXPRESS OR IMPLIED. NEITHER POLTIO NOR ANY PERSON
                  ASSOCIATED WITH POLTIO MAKES ANY REPRESENTATION OR WARRANTY
                  WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY,
                  QUALITY, ACCURACY OR AVAILABILITY OF THE SERVICES OR ANY
                  ASSOCIATED CONTENT. WITHOUT LIMITING THE FOREGOING, NEITHER
                  POLTIO NOR ANYONE ASSOCIATED WITH POLTIO REPRESENTS OR
                  WARRANTS THAT THE SERVICES OR ANY ASSOCIATED CONTENT WILL BE
                  ACCURATE, RELIABLE, ERROR-FREE, UPDATED OR UNINTERRUPTED, THAT
                  DEFECTS WILL BE CORRECTED, THAT THE SERVICES ARE FREE OF
                  VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE SERVICES WILL
                  OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
                </p>
                <p>
                  POLTIO HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER
                  EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT
                  LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT
                  AND FITNESS FOR PARTICULAR PURPOSE.
                </p>
                <p>
                  THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE
                  EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
                </p>
                <h3>Limitation of Liability</h3>
                <p>
                  YOUR USE OF THE SERVICES AND ANY CONTENT ON THE SERVICES IS AT
                  YOUR SOLE RISK AND DISCRETION. You may not assert claims for
                  money damages arising from the Services or any associated
                  content against Poltio, its affiliates, licensors or service
                  providers, or our respective employees, contractors, officers,
                  directors and agents. Poltio, its affiliates, licensors and
                  service providers, and our respective employees, contractors,
                  officers, directors and agents shall not be liable for any
                  direct, indirect, special, incidental, consequential or
                  punitive damages, including but not limited to personal
                  injury, pain and suffering, emotional distress, or loss of
                  revenue, profits, goodwill or data, whether caused by tort
                  (including negligence), breach of contract or otherwise, even
                  if we knew or should have known of the possibility of such
                  damages, arising out of or in connection with (i) your use, or
                  inability to use, the Services or any associated content or
                  User Content, (ii) any interaction with any third party
                  through or in connection with the Services, including other
                  users, (iii) any information offered or provided within or
                  through the Services regarding investment advice or otherwise,
                  including by investment professionals, or (iii) any other
                  matter relating to the Services. In no event shall Poltio, its
                  affiliates, licensors or service providers, or our respective
                  employees, contractors, officers, directors and agents be
                  liable to you in the aggregate (for all potential claims by
                  you) for any damages incurred in excess of one hundred dollars
                  ($100). Because some states or jurisdictions do not allow the
                  exclusion or the limitation of liability for consequential or
                  incidental damages, in such states or jurisdictions, our
                  liability shall be limited to the extent permitted by law.
                </p>
                <p>
                  YOU EXPRESSLY UNDERSTAND THAT POLTIO IS NOT LIABLE FOR ANY
                  HARM THAT RESULTS FROM USER ENGAGEMENT IN THE SERVICES,
                  INCLUDING ANY HARM THAT RESULTS FROM ANY CONDUCT OF OTHER
                  USERS.
                </p>
                <h3>Indemnification</h3>
                <p>
                  You are solely legally liable if your use or misuse of the
                  Services or any associated content causes damage to the
                  Services, to you, or to someone or something else. You agree
                  to defend, indemnify, and hold harmless Poltio, its
                  affiliates, licensors and service providers, and our
                  respective employees, contractors, officers, directors and
                  agents from and against all liabilities, claims, damages,
                  judgment, awards, losses, costs, expenses, fines or fees
                  (including attorneys fees and investigation costs), that arise
                  from or relate to your use or misuse of the Services, your
                  User Content or violation of these Terms or violation of a
                  third party’s rights. We reserve the right, at our own
                  expense, to assume the exclusive defense and control of any
                  matter otherwise subject to indemnification by you, in which
                  event you will cooperate with us in asserting any available
                  defenses.
                </p>
                <h3>Limitation on Time to File Claims</h3>
                <p>
                  Any cause of action or claim you may have arising out of or
                  relating to these Terms or the Services must be commenced
                  within one (1) year after the cause of action accrues,
                  otherwise, such cause of action is permanently barred.{' '}
                </p>
                <h3>Choice of Law and Jurisdiction</h3>
                <p>
                  You agree that the laws of the State of New York and U.S.
                  federal law govern these Terms, your use of the Services, and
                  any claim or dispute that you may have against Poltio, without
                  regard to New York’s conflict of laws rules. You consent to
                  the personal and exclusive jurisdiction of the federal and
                  state courts of New York, New York. The United Nations
                  Convention on Contracts for the International Sale of Goods
                  does not apply to this Agreement.
                </p>
                <h3>Binding Arbitration</h3>
                <p>
                  In the rare event you have a dispute with Poltio, we encourage
                  you to contact us immediately so we can work together toward a
                  satisfactory resolution. If we cannot come to a resolution,
                  you agree that any claims, disputes, demands, counts,
                  controversies, or causes of action between you and Poltio
                  arising out of or relating to these Terms, the Services,
                  including the enforceability of these Terms or our Privacy
                  Policy or information practices will be finally and
                  exclusively resolved by binding arbitration on an individual
                  basis only (except as stated below). The arbitrator may not
                  consolidate more than one persons claims, and may not
                  otherwise preside over any form of any class or representative
                  proceeding.
                </p>
                <p>
                  You understand that you are waiving your right to have your
                  claims heard in court by a judge or jury. An arbitration award
                  is as enforceable as a court order and is subject to only
                  limited review by a judge. You also understand and agree that
                  this arbitration provision prevents you from participating as
                  a plaintiff or as a class member in any purported class action
                  or representative proceeding.
                </p>
                <p>
                  Arbitration will be subject to the Federal Arbitration Act,
                  and shall be commenced and conducted by the American
                  Arbitration Association (&ldquo;<b>AAA</b>&rdquo;) under the
                  Commercial Arbitration Rules and the Supplementary Procedures
                  for Consumer Related Disputes, as amended by this provision,
                  by one arbitrator that is acceptable to both parties. You can
                  find out more AAA and how to file an arbitration claim at
                  www.adr.org or by calling 800-778-7879.
                </p>
                <p>
                  Your arbitration fees and your share of arbitrator
                  compensation shall be governed by the AAA rules and, where
                  appropriate, limited by the AAA Consumer Rules. In the event
                  you are able to demonstrate that the costs of arbitration will
                  be prohibitive as compared to the costs of litigation, Poltio
                  will pay as much of your filing, administrative, and
                  arbitrator fees in connection with the arbitration as the
                  arbitrator deems necessary to prevent arbitration from being
                  cost-prohibitive. In such case, we will make arrangements to
                  pay all necessary fees directly to the AAA. If the arbitrator
                  determines the claim(s) you assert in the arbitration are
                  frivolous, you agree to reimburse Poltio for all fees
                  associated with the arbitration paid by Poltio on your behalf,
                  which you otherwise would be obligated to pay under the AAA’s
                  rules.
                </p>
                <p>
                  The arbitration will be conducted through written submission,
                  unless the arbitrator determines that a telephonic hearing is
                  required. Except as otherwise provided in these Terms, you and
                  Poltio may litigate in court to compel arbitration, stay
                  proceedings pending arbitration, or to confirm, modify, vacate
                  or enter judgment on the award entered by the arbitrator. In
                  the event this arbitration provision is held unenforceable by
                  a court, or in the event AAA refuses to arbitrate the dispute,
                  all controversies, disputes, demands, counts, claims, or
                  causes of action between you and Poltio shall be exclusively
                  brought in the state or federal courts of New York, New York.{' '}
                </p>
                <p>
                  Despite this provision, you may file a claim in a small claims
                  court that has jurisdiction to hear the matter.
                </p>
                <h3>Severability and Integration</h3>
                <p>
                  These Terms and any supplemental terms, policies, rules and
                  guidelines posted on the Services constitute the entire
                  agreement between you and Poltio. If any part of these Terms
                  is held invalid or unenforceable, that portion shall be
                  construed in a manner consistent with applicable law to
                  reflect, as nearly as possible, the original intentions of the
                  parties, and the remaining portions shall remain in full force
                  and effect.
                </p>
                <h3>General Provisions</h3>
                <p>
                  You may not assign these Terms without Poltio’s prior written
                  approval. Poltio may assign these Terms without your consent
                  to: (i) a subsidiary or affiliate; (ii) an acquirer of
                  Poltio’s business or assets; or (iii) a successor by merger.
                  Any purported assignment in violation of this section shall be
                  void. No joint venture, partnership, employment, or agency
                  relationship exists between you and Poltio as a result of
                  these Terms or your use of the Services. Our failure to
                  enforce any right or provision in these Terms shall not
                  constitute a waiver of such right or provision unless
                  acknowledged and agreed to by Poltio in writing.
                </p>
                <h3>Contact</h3>
                <p>
                  The Services are operated by Poltio. All feedback, comments,
                  requests for technical support and other communications
                  relating to the Services should be directed to us at
                  info@poltio.com.
                </p>
                <h3>Changes to these Terms</h3>
                <p>
                  We may modify these Terms at any time in our sole discretion.
                  We will post notice of changes to these Terms on the Website
                  and/or the App. Changes will not apply retroactively and will
                  only become effective when (i) you use the Services after you
                  know about the change, or (ii) thirty days after they are
                  posted (whichever is sooner). However, changes addressing new
                  functions for a Service or changes made for legal reasons will
                  be effective immediately. We will also update the Last
                  Modified Date below accordingly. Your continued use of the
                  Services after the Last Modified Date signifies your consent
                  to the modified Terms. You are expected to check this page
                  frequently so you are aware of any changes, as they are
                  binding on you. If the modified Terms are not acceptable to
                  you, you should not access or use the Services after the Last
                  Modified Date.
                </p>
                <p>Last Modified Date: 10.24.2017</p>
              </div>
            )}
          </div>
        </div>
      </MDBContainer>
    </div>
  );
};

export default TermsAndConditions;
