import React from 'react';
import { MDBRow, MDBCol, MDBCard, MDBView, MDBCardBody } from 'mdbreact';

const SectionWithTitle = ({ title, children }) => {
  return (
    <section className="mb-5">
      <MDBCard cascade narrow>
        <MDBRow>
          <MDBCol xl="12" md="12" className="mr-0">
            <MDBView
              cascade
              className="gradient-card-header light-blue lighten-1"
            >
              <h4 className="h4-responsive mb-0 font-weight-bold">{title}</h4>
            </MDBView>
            <MDBCardBody cascade className="pb-3">
              <MDBRow className="pt-3 card-body">
                <MDBCol md="12">{children}</MDBCol>
              </MDBRow>
            </MDBCardBody>
          </MDBCol>
        </MDBRow>
      </MDBCard>
    </section>
  );
};

export default SectionWithTitle;
