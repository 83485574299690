import React, { Fragment } from 'react';
import { MDBBtn } from 'mdbreact';

const Actions = ({
  messages,
  formatMessage,
  performTriviaAction,
  triviaState,
}) => (
  <Fragment>
    <MDBBtn
      color="primary"
      rounded
      onClick={(e) => performTriviaAction(e, 'start')}
      disabled={triviaState.status !== 'ready'}
    >
      {formatMessage(messages.Start)}
    </MDBBtn>
    <MDBBtn
      color="danger"
      rounded
      onClick={(e) => performTriviaAction(e, 'reset')}
    >
      {formatMessage(messages.Reset)}
    </MDBBtn>
  </Fragment>
);

export default Actions;
