import { defineMessages } from 'react-intl.macro';

export default defineMessages({
  ChangeTheme: {
    id: 'Theme.ChangeTheme',
    defaultMessage: 'Change Theme',
  },
  ButtonBackGround: {
    id: 'Theme.ButtonBackGround',
    defaultMessage: 'Button Background',
  },
  SelectedButtonBackGround: {
    id: 'Theme.SelectedButtonBackGround',
    defaultMessage: 'Selected Button Background',
  },
  ButtonOtherBackGround: {
    id: 'Theme.ButtonOtherBackGround',
    defaultMessage: 'Unselected Button Background',
  },
  ButtonColor: {
    id: 'Theme.ButtonColor',
    defaultMessage: 'Button Text Color',
  },
  SelectedButtonColor: {
    id: 'Theme.SelectedButtonColor',
    defaultMessage: 'Selected Button Text Color',
  },
  ButtonOtherColor: {
    id: 'Theme.ButtonOtherColor',
    defaultMessage: 'Unselected Button Text Color',
  },
  ButtonBorderRadius: {
    id: 'Theme.ButtonBorderRadius',
    defaultMessage: 'Primary Button Border Radius',
  },
  AnswerButtonBorderRadius: {
    id: 'Theme.AnswerButtonBorderRadius',
    defaultMessage: 'Answer Border Radius',
  },
  FontFamily: {
    id: 'Theme.FontFamily',
    defaultMessage: 'Font Family',
  },
  GoogleFontFamily: {
    id: 'Theme.GoogleFontFamily',
    defaultMessage: 'Google Font Family',
  },
  FooterBackground: {
    id: 'Theme.FooterBackground',
    defaultMessage: 'Footer Background',
  },
  BodyColor: {
    id: 'Theme.BodyColor',
    defaultMessage: 'Body Color',
  },
  BackgroundColor: {
    id: 'Theme.BackgroundColor',
    defaultMessage: 'Background Color',
  },
  Save: {
    id: 'Theme.Save',
    defaultMessage: 'Save',
  },
  ChangesSaved: {
    id: 'Theme.ChangesSaved',
    defaultMessage: 'Changes Saved',
  },
  RestoreDefaults: {
    id: 'Theme.RestoreDefaults',
    defaultMessage: 'Restore Defaults',
  },
  ChooseImage: {
    id: 'Theme.ChooseImage',
    defaultMessage: 'Choose Image',
  },
  EmbedBackgroundImage: {
    id: 'Theme.QuestionImage',
    defaultMessage: 'Embed Background Image',
  },
  EmbedFooterImage: {
    id: 'Theme.EmbedFooterImage',
    defaultMessage: 'Embed Footer Image',
  },
  CropImage: {
    id: 'Theme.CropImage',
    defaultMessage: 'Crop Image',
  },
  DeleteImage: {
    id: 'Theme.DeleteImage',
    defaultMessage: 'Delete Image',
  },
  TitleFontSize: {
    id: 'Theme.TitleFontSize',
    defaultMessage: 'Title Font Size',
  },
  TitleLineHeight: {
    id: 'Theme.TitleLineHeight',
    defaultMessage: 'Title Line Height',
  },
  AnswerFontSize: {
    id: 'Theme.AnswerFontSize',
    defaultMessage: 'Answer Font Size',
  },
  AnswerLineHeight: {
    id: 'Theme.AnswerLineHeight',
    defaultMessage: 'Answer Line Height',
  },
  ItMayTake: {
    id: 'Theme.ItMayTake',
    defaultMessage:
      '*It may take up to 5 minutes for the theme changes to take place.',
  },
  CoverBackgroundColor: {
    id: 'Theme.CoverBackgroundColor',
    defaultMessage: 'Cover Card Background Color',
  },
  CoverTextColor: {
    id: 'Theme.CoverTextColor',
    defaultMessage: 'Cover Card Text Color',
  },
  QuestionBackgroundColor: {
    id: 'Theme.QuestionBackgroundColor',
    defaultMessage: 'Question Card Background Color',
  },
  QuestionTextColor: {
    id: 'Theme.QuestionTextColor',
    defaultMessage: 'Question Card Text Color',
  },
  ResultBackgroundColor: {
    id: 'Theme.ResultBackgroundColor',
    defaultMessage: 'Result Card Background Color',
  },
  ResultTextColor: {
    id: 'Theme.ResultTextColor',
    defaultMessage: 'Result Card Text Color',
  },
  ResultDescColor: {
    id: 'Theme.ResultDescColor',
    defaultMessage: 'Result Card Description Text Color',
  },
  CoverDescColor: {
    id: 'Theme.CoverDescColor',
    defaultMessage: 'Cover Card Description Text Color',
  },
  MPInfoColor: {
    id: 'Theme.MPInfoColor',
    defaultMessage: 'Multi Punch Info Color',
  },
  TitleFontWeight: {
    id: 'Theme.TitleFontWeight',
    defaultMessage: 'Title Font Weight',
  },
  ButtonFontWeight: {
    id: 'Theme.ButtonFontWeight',
    defaultMessage: 'Button Font Weight',
  },
  TextInputBorderRadius: {
    id: 'Theme.TextInputBorderRadius',
    defaultMessage: 'Text Input Border Radius',
  },
  CardShadow: {
    id: 'Theme.CardShadow',
    defaultMessage: 'Card Shadow',
  },
  CardBorderRadius: {
    id: 'Theme.CardBorderRadius',
    defaultMessage: 'Card Border Radius',
  },
});
