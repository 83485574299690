import React, { useEffect, useState } from 'react';
import { toast } from 'mdbreact';
import { injectIntl } from 'react-intl';

import AnswerInput from './AnswerInput';
import CardInput from './CardInput';

import { uploadImage } from '../../api';
import { convertToBase64Image } from '../../core/helper';
import {
  feedAnswerImagePathRetina,
  feedImagePathRetina,
} from '../../core/constants';
import messages from './messages';

const ImageInput = ({
  answer: isAnswer,
  src: __src,
  getSRC,
  setSRC,
  target,
  title,
  intl: { formatMessage },
}) => {
  const image = __src || (getSRC ? getSRC(target) : '');
  const [src, setSrc] = useState(image);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // ensure that src exists when image is defined
    if (image && !src) {
      setSrc(image);
    }
  }, [image, src]);

  const fileChange = (event) => {
    setIsLoading(true);
    let files;
    if (event.type) {
      event.preventDefault();
      if (event.dataTransfer) {
        files = event.dataTransfer.files;
      } else if (event.target) {
        files = event.target.files;
      }
    } else {
      files = event;
    }

    const fsize = files[0].size;
    const fsizemb = Math.round(fsize / (1024 * 1024));
    if (fsizemb > 5) {
      toast.error(formatMessage(messages.FileSizeError));
      setIsLoading(false);
      return;
    }

    const reader = new window.FileReader();
    if (files[0]) {
      reader.readAsDataURL(files[0]);
    }
    reader.onload = async () => {
      if (!/\.(gif|jpg|jpeg|tiff|png)$/i.test(files[0].name)) {
        return;
      }

      await setImage(reader.result, files[0].name.split('.').pop());
    };
  };

  const onDelete = (event) => {
    event.preventDefault();

    setImage('');
  };

  const setImage = async (img_64, ext = 'jpeg') => {
    try {
      let url = '';
      if (img_64 !== '') {
        const response = await uploadImage(img_64, ext);
        url = response.data.file;
      }

      setSRC(url, target);
      setSrc(img_64);
      setIsLoading(false);
    } catch (e) {
      setImage('');
      setSrc(__src);
      setIsLoading(false);
      toast.error(formatMessage(messages.ErrorUploading));
    }
  };

  if (src && !src.includes('data:image')) {
    let path = src;
    if (!src.includes('.poltio.com')) {
      path = `${
        isAnswer ? feedAnswerImagePathRetina : feedImagePathRetina
      }/${src}`;
    }
    convertToBase64Image(path).then((data) => setSrc(data));
  }

  if (isAnswer) {
    return (
      <AnswerInput
        onDelete={onDelete}
        src={src}
        title={title}
        fileChange={fileChange}
        isLoading={isLoading}
      />
    );
  } else {
    return (
      <CardInput
        onDelete={onDelete}
        src={src}
        setSrc={setImage}
        title={title}
        fileChange={fileChange}
        formatMessage={formatMessage}
        isLoading={isLoading}
      />
    );
  }
};

export default injectIntl(ImageInput);
