export const initialTriviaState = {
  mode: 'normal',
  tier: 'free',
  player_leaderboard: 0,
  elimination: 0,
  donate: 0,
  start_date: null,
  start_date_timezone: -3,
  register_fields: [
    {
      name: 'username',
      label: 'Username',
      type: 'text',
      active: true,
      is_optional: 0,
    },
    {
      name: 'email',
      label: 'Email',
      type: 'text',
      active: false,
      is_optional: 0,
    },
    {
      name: 'phone',
      label: 'Phone',
      type: 'text',
      active: false,
      is_optional: 0,
    },
    {
      name: 'comment',
      label: 'Comment',
      type: 'text',
      active: false,
      is_optional: 0,
    },
    {
      name: 'tc',
      label: 'Terms and Conditions',
      type: 'checkbox',
      active: false,
      is_optional: 0,
    },
  ],
  domain_id: null,
};

export function reducer(state, action) {
  switch (action.type) {
    case 'timezone_change':
      return {
        ...state,
        start_date_timezone: action.data,
      };
    case 'set_mode':
      return {
        ...state,
        mode: action.data,
      };
    case 'set_tier':
      return {
        ...state,
        tier: action.data,
      };
    case 'set_domain':
      return {
        ...state,
        domain_id: action.data,
      };
    case 'switch_change':
      return {
        ...state,
        [action.data.name]: action.data.checked ? 1 : 0,
      };
    case 'date_change':
      return {
        ...state,
        start_date: action.data,
      };
    case 'register_field_change':
      return {
        ...state,
        register_fields: state.register_fields.map((f) => {
          if (action.data.name !== 'username' && f.name === action.data.name) {
            return {
              ...f,
              active: !f.active,
            };
          }
          return f;
        }),
      };
    case 'register_optional_field_change':
      return {
        ...state,
        register_fields: state.register_fields.map((f) => {
          if (action.data.name !== 'username' && f.name === action.data.name) {
            return {
              ...f,
              is_optional: f.active ? (f.is_optional === 1 ? 0 : 1) : 0,
            };
          }
          return f;
        }),
      };
    case 'register_input_change':
      return {
        ...state,
        register_fields: state.register_fields.map((f) => {
          if (f.name === action.data.name) {
            return {
              ...f,
              label: action.data.value,
            };
          }
          return f;
        }),
      };
    default:
      throw new Error();
  }
}
