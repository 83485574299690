/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import {
  Row,
  Col,
  Container,
  Stepper,
  Step,
  toast,
  Card,
  CardBody,
  MDBBtn,
  MDBIcon,
} from 'mdbreact';
import ReactTooltip from 'react-tooltip';
import history from '../../../core/history';
import {
  createTest,
  finishTest,
  getEditTest,
  deleteContent,
} from '../../../api';

import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import messages from '../messages';

const initialState = {
  formActivePanel: 1,
  quizData: {
    title: '',
    background: '',
    desc: '',
    privacy: 'private',
    results: [
      {
        title: '',
        background: '',
        desc: '',
        url: '',
        url_text: '',
      },
      {
        title: '',
        background: '',
        desc: '',
        url: '',
        url_text: '',
      },
    ],
    display_repeat: 0,
    hide_counter: 0,
    hide_results: 0,
    play_once: 0,
    play_once_strategy: 'result',
    play_once_msg: null,
    play_once_img: null,
    play_once_link: null,
    play_once_btn: null,
    embed_background: '',
    embed_footer_img: '',
    embed_footer_url: null,
    end_date: 1,
    end_date_day: '30',
    end_date_hour: '00',
    end_date_minute: '00',
  },
  questions: [],
  src: null,
  crop: {
    x: 0,
    y: 0,
    width: 50,
    aspect: 9 / 5,
  },
  pixelCrop: null,
  cropped: false,
  testResults: [],
  endDateEdited: false,
};

class TestEdit extends Component {
  state = initialState;

  async componentDidMount() {
    try {
      const { id } = this.props.match.params;
      const testResponse = await getEditTest(id);
      this.setState({
        createdTestId: testResponse.data.id,
        quizData: testResponse.data,
        testResults: testResponse.data.results,
        questions: testResponse.data.questions,
        src: testResponse.data.background ? testResponse.data.background : null,
        cropped: true,
      });
    } catch (e) {
      if (e && e.response && e.response.status === 404) {
        const { formatMessage } = this.props.intl;
        history.push('/content');
        toast.error(formatMessage(messages.NoContentFound));
      }
    }
  }

  pixelCode = (index, pc) => {
    if (pc) {
      this.setState({
        quizData: {
          ...this.state.quizData,
          results: this.state.quizData.results.map((a, i) => {
            if (i === index) {
              return {
                ...a,
                pixel_code_id: pc.id,
                pixel_code_name: pc.name,
              };
            }
            return a;
          }),
        },
      });
    }
  };

  leadSelect = (index, lead) => {
    if (lead) {
      this.setState({
        quizData: {
          ...this.state.quizData,
          results: this.state.quizData.results.map((a, i) => {
            if (i === index) {
              return {
                ...a,
                lead_id: lead.id,
                lead_name: lead.name,
              };
            }
            return a;
          }),
        },
      });
    }
  };

  handleNextPrevClick = (param) => (e) => {
    const { formatMessage } = this.props.intl;
    if (param === 3) {
      let validated = true;
      this.state.quizData.results.forEach((r) => {
        if (!r.dont_shorten && r.url && !r.url.startsWith('http') && r.dirty) {
          toast.error(formatMessage(messages.ValidUrl));
          validated = false;
        }
      });
      if (validated) {
        this.setState({
          formActivePanel: param,
        });
      }
    } else {
      this.setState({
        formActivePanel: param,
      });
    }
  };

  saveTestEdit = async (finish) => {
    const { formatMessage } = this.props.intl;
    const { quizData, src, createdTestId, endDateEdited } = this.state;
    if (!endDateEdited) {
      delete quizData.end_date;
      delete quizData.end_date_day;
      delete quizData.end_date_hour;
      delete quizData.end_date_minute;
    }
    try {
      const r = await createTest(quizData, src, true, createdTestId);
      this.setState({ quizData: r.data, testResults: r.data.results });

      if (this.refs.questionStep) {
        await this.refs.questionStep.addQuestion(false); // clears answers completely
        await finishTest(createdTestId);
      }

      if (finish) {
        history.push('/content');
        toast.success(formatMessage(messages.FinishEditingSuccess));
      } else {
        window.scrollTo({ top: 100, left: 0, behavior: 'smooth' });
      }
    } catch (e) {
      toast.error('Error occured!');
      console.log(e);
    }
  };

  handleTextChange = async (e) => {
    const inputText = e.target.value;
    this.setState({
      quizData: {
        ...this.state.quizData,
        [e.target.name]: inputText,
      },
    });
  };

  handleTextChangeWithoutEmbedly = (e) => {
    this.setState({
      quizData: {
        ...this.state.quizData,
        [e.target.name]: e.target.value === '' ? null : e.target.value,
      },
    });
  };

  setSRC = (src, target) => {
    if (target && target.length > 0) {
      this.setState({
        quizData: {
          ...this.state.quizData,
          [target]: src,
        },
      });
    } else {
      this.setState({ src: src });
    }
  };

  getSRC = (target) => {
    if (target && target.length > 0) {
      return this.state.quizData[target];
    }
    return this.state.src;
  };

  getSelectValue = (value, name) => {
    if (value) {
      this.setState({
        quizData: {
          ...this.state.quizData,
          [name]: value,
        },
      });
    }
  };

  handleSwitchChange = (e, name) => {
    if (name === 'privacy') {
      this.setState({
        quizData: {
          ...this.state.quizData,
          [name]: e.target.value === 'true' ? 'public' : 'private',
        },
      });
    } else if (name === 'play_once') {
      this.setState({
        quizData: {
          ...this.state.quizData,
          [name]: e.target.checked ? 1 : 0,
        },
      });
    } else if (name === 'hide_counter') {
      this.setState({
        quizData: {
          ...this.state.quizData,
          [name]: e.target.value === 'true' ? 1 : 0,
        },
      });
    } else if (name === 'end_date') {
      this.setState({
        quizData: {
          ...this.state.quizData,
          [name]: e.target.value === 'true' ? 0 : 1,
          end_date_day: e.target.value === 'true' ? '00' : '30',
          end_date_hour: '00',
          end_date_minute: '00',
        },
        endDateEdited: true,
      });
    } else {
      this.setState({
        quizData: {
          ...this.state.quizData,
          [name]: e.target.value === 'true' ? 0 : 1,
        },
      });
    }
  };

  getSelectValue2 = (value, name) => {
    if (value === '3' || value === '0' || !value) return;
    this.setState({
      quizData: {
        ...this.state.quizData,
        [name]: value,
      },
    });
  };

  setResultImg = (index, value) => {
    const { quizData } = this.state;

    this.setState({
      quizData: {
        ...quizData,
        results: quizData.results.map((a, i) => {
          if (index === i) {
            return { ...a, background: value };
          }
          return a;
        }),
      },
    });
  };

  changeNumber = (e, direction) => {
    e.preventDefault();
    if (direction === 'add') {
      this.setState({
        quizData: {
          ...this.state.quizData,
          results: [
            ...this.state.quizData.results,
            { title: '', background: '', desc: '' },
          ],
        },
      });
    } else if (direction === 'delete') {
      this.setState({
        quizData: {
          ...this.state.quizData,
          results: this.state.quizData.results.slice(0, -1),
        },
      });
    }
  };

  handleAnswerChange = (e, i) => {
    const { name, value } = e.target;
    this.setState({
      quizData: {
        ...this.state.quizData,
        results: this.state.quizData.results.map((a, index) => {
          if (index === i) {
            return {
              ...a,
              [name]: name === 'url' ? value.trim() : value,
              dirty: name === 'url' ? true : a.dirty,
            };
          }
          return a;
        }),
      },
    });
  };

  handleResultSwitchChange = (e, i) => {
    this.setState({
      quizData: {
        ...this.state.quizData,
        results: this.state.quizData.results.map((a, index) => {
          if (index === i) {
            return { ...a, dont_shorten: e.target.checked ? 1 : 0 };
          }
          return a;
        }),
      },
    });
  };

  setQuestions = (questions) => this.setState({ questions });

  deleteQuestion = async (e, id) => {
    e.preventDefault();
    const { formatMessage } = this.props.intl;
    if (!window.confirm(formatMessage(messages.SureDeleteQuestion))) return;

    try {
      this.setState({ deleting: true });
      await deleteContent('question', id);
      const { data } = await getEditTest(this.state.quizData.id);
      this.setState({
        quizData: data,
        testResults: data.results,
        questions: data.questions,
      });
      toast.success(formatMessage(messages.ContentDeleted));
      this.setState({ deleting: false });
    } catch (e) {
      toast.error(formatMessage(messages.ErrorDeletingQuestion));
      this.setState({ deleting: false });
    }
  };

  endDateEditClick = () => {
    this.setState({ endDateEdited: true });
  };

  copyQuestion = (qi) => {
    const { questions } = this.state;
    const qu = questions[qi];
    this.setState({
      questions: [
        ...questions,
        {
          ...qu,
          id: null,
          answers: qu.answers.map((a) => {
            return {
              ...a,
              id: null,
            };
          }),
        },
      ],
    });
  };

  leadSelectSet = (i, lead) => {
    if (lead) {
      this.setState({
        quizData: {
          ...this.state.quizData,
          lead_id: lead.id,
          lead_name: lead.name,
        },
      });
    }
  };

  render() {
    const {
      quizData: poll,
      src,
      createdTestId,
      testResults,
      questions,
      formActivePanel,
      deleting,
      endDateEdited,
    } = this.state;
    const { formatMessage } = this.props.intl;

    const btnStep1 =
      poll.title &&
      poll.title.length > 0 &&
      (!src || src.length <= 0 || (src && src.length > 0));

    const resultsInvalid = poll.results.some((r) => r.title === '');

    const navButtonText = [
      formatMessage(messages.Info),
      formatMessage(messages.Results),
      formatMessage(messages.Questions),
    ];

    return (
      <Container>
        <Row>
          <Col md="7">
            <Card>
              <CardBody>
                <h2 className="text-center font-weight-bold pt-4 pb-5">
                  <strong>{formatMessage(messages.EditTest)}</strong>
                </h2>

                <Stepper form>
                  <Step form>
                    <a href="" onClick={(e) => e.preventDefault()}>
                      <button
                        type="button"
                        className={`btn btn-${
                          this.state.formActivePanel === 1
                            ? 'indigo'
                            : 'default'
                        } btn-circle Ripple-parent`}
                      >
                        1
                        <div
                          className="Ripple "
                          style={{
                            top: '0px',
                            left: '0px',
                            width: '0px',
                            height: '0px',
                          }}
                        />
                      </button>
                    </a>
                    <p>{formatMessage(messages.TestInfo)}</p>
                  </Step>
                  <Step form>
                    <a href="" onClick={(e) => e.preventDefault()}>
                      <button
                        type="button"
                        className={`btn btn-${
                          this.state.formActivePanel === 2
                            ? 'indigo'
                            : 'default'
                        } btn-circle Ripple-parent`}
                      >
                        2
                        <div
                          className="Ripple "
                          style={{
                            top: '0px',
                            left: '0px',
                            width: '0px',
                            height: '0px',
                          }}
                        />
                      </button>
                    </a>
                    <p>{formatMessage(messages.TestResults)}</p>
                  </Step>
                  <Step form>
                    <a href="" onClick={(e) => e.preventDefault()}>
                      <button
                        type="button"
                        className={`btn btn-${
                          this.state.formActivePanel === 3
                            ? 'indigo'
                            : 'default'
                        } btn-circle Ripple-parent`}
                      >
                        3
                        <div
                          className="Ripple "
                          style={{
                            top: '0px',
                            left: '0px',
                            width: '0px',
                            height: '0px',
                          }}
                        />
                      </button>
                    </a>
                    <p>{formatMessage(messages.TestQuestions)}</p>
                  </Step>
                </Stepper>

                <Row>
                  {formActivePanel === 1 && (
                    <Step1
                      handleTextChange={this.handleTextChange}
                      poll={poll}
                      setSRC={this.setSRC}
                      getSRC={this.getSRC}
                      getSelectValue={this.getSelectValue}
                      getSelectValue2={this.getSelectValue2}
                      handleSwitchChange={this.handleSwitchChange}
                      handleTextChangeWithoutEmbedly={
                        this.handleTextChangeWithoutEmbedly
                      }
                      endDateEdited={endDateEdited}
                      endDateEditClick={this.endDateEditClick}
                      leadSelect={this.leadSelectSet}
                    />
                  )}
                  {formActivePanel === 2 && (
                    <Step2
                      quizData={poll}
                      setResultImg={this.setResultImg}
                      changeNumber={this.changeNumber}
                      handleAnswerChange={this.handleAnswerChange}
                      handleResultSwitchChange={this.handleResultSwitchChange}
                      pixelCode={this.pixelCode}
                      leadSelect={this.leadSelect}
                    />
                  )}
                  {formActivePanel === 3 && (
                    <Step3
                      ref="questionStep"
                      intl={this.props.intl}
                      createdTestId={createdTestId}
                      testResults={testResults}
                      setQuestions={this.setQuestions}
                      questions={questions}
                      deleting={deleting}
                    />
                  )}
                  <div
                    className="btn-group col-md-8 px-0 d-block"
                    style={{ marginTop: '20px' }}
                  >
                    {formActivePanel > 1 && (
                      <button
                        type="button"
                        className="btn btn-indigo btn-rounded Ripple-parent float-left"
                        onClick={this.handleNextPrevClick(formActivePanel - 1)}
                      >
                        {`${formatMessage(messages.Prev)} (${
                          navButtonText[formActivePanel - 2]
                        })`}
                      </button>
                    )}
                    {formActivePanel < 3 && (
                      <button
                        type="button"
                        className={`btn btn-indigo btn-rounded Ripple-parent float-left ${
                          btnStep1 ? '' : 'disabled'
                        }`}
                        onClick={this.handleNextPrevClick(formActivePanel + 1)}
                      >
                        {`${formatMessage(messages.Next)} (${
                          navButtonText[formActivePanel]
                        })`}
                      </button>
                    )}
                  </div>
                  <div
                    className="btn-group col-md-4 px-0 d-block"
                    style={{ marginTop: '20px' }}
                  >
                    <button
                      type="button"
                      className={`btn btn-danger btn-rounded Ripple-parent float-right ${
                        btnStep1 && !resultsInvalid ? '' : 'disabled'
                      }`}
                      onClick={() => this.saveTestEdit(false)}
                    >
                      {formatMessage(messages.Save)}
                    </button>
                  </div>
                </Row>
                <Row className="text-center" style={{ marginTop: '30px' }}>
                  <Col md="12">
                    <button
                      type="button"
                      className={`btn btn-danger btn-rounded Ripple-parent ${
                        btnStep1 && !resultsInvalid ? '' : 'disabled'
                      }`}
                      onClick={() => this.saveTestEdit(true)}
                    >
                      {formatMessage(messages.FinishEditing)}
                    </button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>

          <Col md="5">
            {questions && questions.length > 0 ? (
              <Card>
                <CardBody>
                  <h2 className="text-center font-weight-bold pt-4 pb-5">
                    <strong>{formatMessage(messages.YourQuestions)}</strong>
                  </h2>
                  <table style={{ width: '100%' }}>
                    <tbody>
                      {questions.map((q, i) => (
                        <tr key={i}>
                          {formActivePanel === 3 ? (
                            <td style={{ width: '40%' }}>
                              <ReactTooltip effect="solid" multiline={true} />
                              <span
                                data-tip={formatMessage(messages.Delete)}
                                data-place="bottom"
                              >
                                <MDBBtn
                                  rounded
                                  color="danger"
                                  size="sm"
                                  onClick={(e) => this.deleteQuestion(e, q.id)}
                                  disabled={questions.length < 3 || !q.id}
                                >
                                  <MDBIcon icon="trash-alt" />
                                </MDBBtn>
                              </span>
                              <span
                                data-tip={formatMessage(messages.Copy)}
                                data-place="bottom"
                              >
                                <MDBBtn
                                  rounded
                                  color="info"
                                  size="sm"
                                  onClick={() => this.copyQuestion(i)}
                                  disabled={!q.id}
                                >
                                  <MDBIcon icon="copy" />
                                </MDBBtn>
                              </span>
                            </td>
                          ) : null}
                          <td>
                            <h6>{`${i + 1}. ${q.title}`}</h6>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {questions.length < 3 && (
                    <p className="text-center pt-4 pb-5">
                      {`* ${formatMessage(messages.AtLeastTwo)}`}
                    </p>
                  )}
                </CardBody>
              </Card>
            ) : null}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default injectIntl(TestEdit);
