import React, {
  useState,
  useEffect,
  useRef,
  Fragment,
  useCallback,
} from 'react';
import { injectIntl } from 'react-intl';
import {
  MDBContainer,
  MDBTooltip,
  MDBSpinner,
  MDBBtn,
  MDBRow,
  MDBIcon,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
  toast,
  FormInline,
  MDBCardHeader,
} from 'mdbreact';
import { getPoolsList, addPool, deletePool } from '../../api';

import messages from './messages';
import SectionWithTitle from '../Common/SectionWithTitle';
import Pagination from '../Pagination';

const register_fields = [
  {
    name: 'username',
    label: 'Username',
    type: 'text',
    active: true,
    is_optional: 0,
  },
  {
    name: 'email',
    label: 'Email',
    type: 'text',
    active: false,
    is_optional: 0,
  },
  {
    name: 'phone',
    label: 'Phone',
    type: 'text',
    active: false,
    is_optional: 0,
  },
  {
    name: 'comment',
    label: 'Comment',
    type: 'text',
    active: false,
    is_optional: 0,
  },
  {
    name: 'tc',
    label: 'Terms and Conditions',
    type: 'checkbox',
    active: false,
    is_optional: 0,
  },
];

const ManagePools = ({ intl: { formatMessage } }) => {
  const [poolData, setPoolData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageData, setPageData] = useState({
    totalSize: 100,
    page: 1,
    sizePerPage: 50,
    lastPage: null,
  });
  const inputEl = useRef(null);
  const [fields, setFields] = useState(register_fields);

  const fetchPoolsList = useCallback(async () => {
    setLoading(true);
    const { data } = await getPoolsList(pageData.page, pageData.sizePerPage);
    setPoolData(data.data);
    setLoading(false);
    setPageData((p) => {
      return {
        page: p.page,
        sizePerPage: p.sizePerPage,
        totalSize: data.total,
        lastPage: data.last_page,
      };
    });
  }, [pageData.page, pageData.sizePerPage]);

  const handlePageChange = (page, sizePerPage) => {
    setPageData({
      ...pageData,
      page,
      sizePerPage,
    });
  };

  useEffect(() => {
    fetchPoolsList();
  }, [fetchPoolsList]);

  const handleClick = async () => {
    const pool = inputEl.current.state.innerValue;
    try {
      const activeFields = fields.filter((r) => r.active);
      await addPool(pool, activeFields);
      toast.info(formatMessage(messages.Added));
      fetchPoolsList();
      setFields(register_fields);
      inputEl.current.state.innerValue = '';
    } catch (e) {
      if (e.response && e.response.status === 400) {
        toast.error(formatMessage(messages.DomainNotValid));
      } else if (e.response && e.response.status === 403) {
        toast.error(formatMessage(messages.DomainInSystem));
      } else {
        toast.error('Error occured!');
      }
    }
  };

  const handleDeleteClick = async (id) => {
    if (window.confirm(formatMessage(messages.AreYouSure))) {
      try {
        await deletePool(id);
        toast.info(formatMessage(messages.Deleted));
        fetchPoolsList();
      } catch (e) {
        if (e.response && e.response.status === 400) {
          toast.error(formatMessage(messages.PoolNotEmpty));
        } else {
          toast.error('Error occured!');
        }
      }
    }
  };

  const onFieldActiveChange = (e, name) => {
    setFields(
      fields.map((f) => {
        if (name !== 'username' && f.name === name) {
          return {
            ...f,
            active: e.target.checked,
          };
        }
        return f;
      })
    );
  };

  const onFieldOptionalChange = (e, name) => {
    setFields(
      fields.map((f) => {
        if (name !== 'username' && f.name === name) {
          return {
            ...f,
            is_optional: f.active ? (f.is_optional === 1 ? 0 : 1) : 0,
          };
        }
        return f;
      })
    );
  };

  const onFieldTextChange = (e, name) => {
    setFields(
      fields.map((f) => {
        if (f.name === name) {
          return {
            ...f,
            label: e.target.value,
          };
        }
        return f;
      })
    );
  };

  return (
    <MDBContainer fluid>
      <SectionWithTitle title={formatMessage(messages.Title)}>
        <MDBRow>
          <MDBCol md="7">
            <Fragment>
              <Pagination
                last={pageData.lastPage}
                active={pageData.page}
                size={pageData.sizePerPage}
                handlePageChange={handlePageChange}
              />
              {loading ? (
                <MDBSpinner />
              ) : (
                <div className="row text-center">
                  <div className="col-12">
                    <table className="table table-striped table-bordered table-sm table-fixed-header">
                      <thead>
                        <tr>
                          <th className="th-sm">{'ID'}</th>
                          <th className="th-sm">{'Name'}</th>
                          <th className="th-sm">{'User Count'}</th>
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        {poolData.map((row, i) => (
                          <tr key={i} style={{ cursor: 'pointer' }}>
                            <td>
                              <MDBTooltip placement="top" domElement>
                                <span>{row.id}</span>
                                <div>{row.id}</div>
                              </MDBTooltip>
                            </td>
                            <td style={{ maxWidth: '200px' }}>
                              <MDBTooltip placement="top" domElement>
                                <span>{row.name}</span>
                                <div>{row.name}</div>
                              </MDBTooltip>
                            </td>
                            <td>
                              <MDBTooltip placement="top" domElement>
                                <span>{row.user_counter}</span>
                                <div>{row.user_counter}</div>
                              </MDBTooltip>
                            </td>
                            <td>
                              <MDBBtn
                                outline
                                color="danger"
                                size="sm"
                                onClick={() => handleDeleteClick(row.id)}
                                disabled={row.user_counter > 0}
                              >
                                <MDBIcon icon="eraser" />
                              </MDBBtn>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </Fragment>
          </MDBCol>
          <MDBCol md="5" className="text-center">
            <MDBCard className="profile-card">
              <MDBCardBody className="pt-0 mt-0">
                <div className="text-center">
                  <h3 className="mb-3 font-weight-bold">
                    <strong>{formatMessage(messages.AddNewDomain)}</strong>
                  </h3>
                </div>
                <MDBInput
                  type="text"
                  label={formatMessage(messages.DomainName)}
                  ref={inputEl}
                />
                <MDBCard className="profile-card">
                  <MDBCardHeader>
                    {formatMessage(messages.RegisterFields)}
                  </MDBCardHeader>
                  <MDBCardBody className="pt-0 mt-0">
                    <table style={{ margin: 'auto' }}>
                      <tbody>
                        {fields.map((field, i) => (
                          <Fragment key={i}>
                            <tr>
                              <td style={{ textAlign: 'left', width: '25%' }}>
                                <FormInline>
                                  <MDBInput
                                    label={
                                      field.label.length > 0
                                        ? field.label
                                        : field.name
                                    }
                                    type="checkbox"
                                    size="sm"
                                    id={`checkbox${field.name}`}
                                    value={field.name}
                                    checked={field.active}
                                    onChange={(e) =>
                                      onFieldActiveChange(e, field.name)
                                    }
                                  />
                                  {field.name !== 'username' ? (
                                    <MDBInput
                                      label={formatMessage(messages.Optional)}
                                      type="checkbox"
                                      size="sm"
                                      id={`checkbox${field.name}optional`}
                                      value={field.name}
                                      checked={field.is_optional}
                                      onChange={(e) =>
                                        onFieldOptionalChange(e, field.name)
                                      }
                                    />
                                  ) : null}
                                </FormInline>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <MDBInput
                                  hint={field.label}
                                  name={field.name}
                                  type="text"
                                  size="sm"
                                  onChange={(e) =>
                                    onFieldTextChange(e, field.name)
                                  }
                                  disabled={!field.active}
                                />
                              </td>
                            </tr>
                          </Fragment>
                        ))}
                      </tbody>
                    </table>
                  </MDBCardBody>
                </MDBCard>
                <MDBBtn color="primary" size="sm" onClick={handleClick}>
                  {formatMessage(messages.Add)}
                </MDBBtn>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </SectionWithTitle>
    </MDBContainer>
  );
};

export default injectIntl(ManagePools);
