/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, Fragment } from 'react';
import { injectIntl } from 'react-intl';
import {
  Row,
  Col,
  Container,
  Card,
  CardBody,
  Table,
  TableHead,
  MDBInput,
  Button,
  toast,
  InputSwitch,
  MDBSwitch,
} from 'mdbreact';
import { getProfileFeed } from '../../../api';
import InfiniteScroll from 'react-infinite-scroller';
import * as clipboard from 'clipboard-polyfill';

import messages from '../messages';

class SetCreate extends Component {
  state = {
    contents: [],
    nextOffset: null,
    hasMore: true,
    loading: false,
    selectedPolls: [],
    displayNotify: false,
    displayShare: false,
    isVertical: false,
  };

  loadMore = async () => {
    const { hasMore, loading, nextOffset } = this.state;
    if (hasMore && !loading) {
      this.setState({ loading: true });
      const { data } = await getProfileFeed(nextOffset);
      this.setState({
        contents: [
          ...this.state.contents,
          ...data.data.filter(
            (d) => d.t === 'poll' && ['text', 'media'].includes(d.answer_type)
          ),
        ],
        nextOffset: data.nextOffset,
        hasMore: data.data.length > 0,
        loading: false,
      });
    }
  };

  onChange = (id) => {
    this.setState({
      selectedPolls: this.state.selectedPolls.includes(id)
        ? this.state.selectedPolls.filter((p) => p !== id)
        : [...this.state.selectedPolls, id],
    });
  };

  copyEmbedCode = () => {
    const { formatMessage } = this.props.intl;
    try {
      clipboard.writeText(this.getEmbedCode());
      toast.info(formatMessage(messages.Copied));
    } catch (e) {
      console.log(e);
      toast.info(formatMessage(messages.Error));
    }
  };

  getPollIds = () => {
    const { selectedPolls } = this.state;
    const pollIds = selectedPolls.reduce(
      (total, field, i) =>
        (total += `${field}${i === selectedPolls.length - 1 ? '' : ','}`),
      ''
    );
    return pollIds;
  };

  getEmbedCode = () => {
    const pollIds = this.getPollIds();
    const { displayNotify, displayShare, isVertical } = this.state;
    return `<iframe id="poltio-embed-set-${pollIds}" class="poltio-embed" src="https://www.poltio.com/e/${
      isVertical ? 'vertical/' : ''
    }set/${pollIds}/?utm_source=embed2&utm_medium=&utm_campaign=set-${pollIds}&share=${
      displayShare ? 'on' : 'off'
    }&notify=${
      displayNotify ? 'on' : 'off'
    }" width="100%" height="650" frameBorder="0" allowFullScreen="allowfullscreen" scrolling="yes" title="Embed"></iframe>`;
  };

  handleSwitchChange = (e, name) => {
    this.setState({ [name]: !this.state[name] });
  };

  render() {
    const {
      contents,
      hasMore,
      selectedPolls,
      displayShare,
      displayNotify,
      isVertical,
    } = this.state;
    const { formatMessage } = this.props.intl;
    const pollIds = this.getPollIds();

    return (
      <Container>
        <Row>
          <Col md="7">
            <Card>
              <CardBody>
                <h2 className="text-center font-weight-bold pt-4 pb-5">
                  <strong>{formatMessage(messages.CreateSet)}</strong>
                </h2>
                <Col>
                  <Table>
                    <TableHead>
                      <tr>
                        <th>{formatMessage(messages.YourPolls)}</th>
                        <th>{formatMessage(messages.Select)}</th>
                      </tr>
                    </TableHead>
                    <InfiniteScroll
                      className="col"
                      element={'tbody'}
                      hasMore={hasMore}
                      initialLoad
                      useWindow
                      pageStart={0}
                      loadMore={this.loadMore}
                      loader={
                        <tr key="s0">
                          <td>{formatMessage(messages.Loading)}</td>
                        </tr>
                      }
                    >
                      {contents.map((content, i) => (
                        <tr key={i}>
                          <td>{content.title}</td>
                          <td>
                            <MDBInput
                              label=" "
                              type="checkbox"
                              id={`checkbox${i}`}
                              onChange={() => this.onChange(content.id)}
                            />
                          </td>
                        </tr>
                      ))}
                    </InfiniteScroll>
                  </Table>
                </Col>
              </CardBody>
            </Card>
          </Col>
          <Col md="5">
            <h2 className="text-center font-weight-bold pt-4 pb-5">
              <strong>{formatMessage(messages.SetPreview)}</strong>
            </h2>
            {selectedPolls.length > 0 ? (
              <Fragment>
                <Row className="text-center pt-4 pb-5">
                  <Col>
                    {`${formatMessage(messages.SingleView)}: `}
                    <a
                      href={`https://www.poltio.com/e/${
                        isVertical ? 'vertical/' : ''
                      }set/${pollIds}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {`https://www.poltio.com/e/${
                        isVertical ? 'vertical/' : ''
                      }set/${pollIds}`}
                    </a>
                  </Col>
                </Row>
                <Row className="text-center">
                  <Col>
                    <label>{formatMessage(messages.DisplayShare)}</label>
                  </Col>
                  <Col>
                    <InputSwitch
                      onChange={(e) =>
                        this.handleSwitchChange(e, 'displayShare')
                      }
                      checked={displayShare}
                      labelLeft={formatMessage(messages.Off)}
                      labelRight={formatMessage(messages.On)}
                    />
                  </Col>
                </Row>
                <Row className="text-center">
                  <Col>
                    <label>{formatMessage(messages.DisplayNotify)}</label>
                  </Col>
                  <Col>
                    <InputSwitch
                      onChange={(e) =>
                        this.handleSwitchChange(e, 'displayNotify')
                      }
                      checked={displayNotify}
                      labelLeft={formatMessage(messages.Off)}
                      labelRight={formatMessage(messages.On)}
                    />
                  </Col>
                </Row>
                <Row className="text-center" style={{ margin: 'auto' }}>
                  <MDBInput
                    style={{ width: '400px', height: '200px' }}
                    type="textarea"
                    label={formatMessage(messages.EmbedCode)}
                    outline
                    value={this.getEmbedCode()}
                  />
                </Row>
                <Row style={{ marginTop: '1.5rem' }}>
                  <Col>
                    <label>{formatMessage(messages.Vertical)}</label>
                  </Col>
                  <Col>
                    <MDBSwitch
                      onChange={() =>
                        this.setState({ isVertical: !this.state.isVertical })
                      }
                      checked={this.state.isVertical}
                      labelLeft={formatMessage(messages.Off)}
                      labelRight={formatMessage(messages.On)}
                    />
                  </Col>
                </Row>
                <Row className="text-center">
                  <Button
                    color="indigo"
                    onClick={this.copyEmbedCode}
                    style={{ margin: 'auto', marginBottom: '50px' }}
                  >
                    {formatMessage(messages.CopyCode)}
                  </Button>
                </Row>
              </Fragment>
            ) : null}
            {pollIds ? (
              <iframe
                src={`//www.poltio.com/e/${
                  isVertical ? 'vertical/' : ''
                }set/${pollIds}?share=${displayShare ? 'on' : 'off'}&notify=${
                  displayNotify ? 'on' : 'off'
                }`}
                width="100%"
                height="1000"
                scrolling="yes"
                frameBorder="0"
                title={`set${pollIds}`}
              />
            ) : (
              <h6 className="text-center pt-4 pb-5">
                {formatMessage(messages.YourContent)}
              </h6>
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default injectIntl(SetCreate);
