import { defineMessages } from 'react-intl.macro';

export default defineMessages({
  PixelCodes: {
    id: 'PixelCodes.PixelCodes',
    defaultMessage: 'Pixel Codes',
  },
  EnterSearchText: {
    id: 'PixelCodes.EnterSearchText',
    defaultMessage: 'Enter Search Text',
  },
  Id: {
    id: 'PixelCodes.Id',
    defaultMessage: 'ID',
  },
  Name: {
    id: 'PixelCodes.Name',
    defaultMessage: 'Name',
  },
  FireCount: {
    id: 'PixelCodes.FireCount',
    defaultMessage: 'Pixel Fire Count',
  },
  Loading: {
    id: 'PixelCodes.Loading',
    defaultMessage: 'Loading...',
  },
  Edit: {
    id: 'PixelCodes.Edit',
    defaultMessage: 'Edit',
  },
  ErrorSaving: {
    id: 'PixelCodes.ErrorSaving',
    defaultMessage: 'Error Saving Pixel Code',
  },
  ErrorDeleting: {
    id: 'PixelCodes.ErrorDeleting',
    defaultMessage: 'Error Deleting Pixel Code',
  },
  AddNew: {
    id: 'PixelCodes.AddNew',
    defaultMessage: 'Add new pixel code',
  },
  EditPc: {
    id: 'PixelCodes.EditPc',
    defaultMessage: 'Edit pixel code',
  },
  Type: {
    id: 'PixelCodes.Type',
    defaultMessage: 'Type your code',
  },
  TypeName: {
    id: 'PixelCodes.TypeName',
    defaultMessage: 'Type a name for your code',
  },
  Save: {
    id: 'PixelCodes.Save',
    defaultMessage: 'Save',
  },
  Delete: {
    id: 'PixelCodes.Delete',
    defaultMessage: 'Delete',
  },
  UserId: {
    id: 'PixelCodes.UserId',
    defaultMessage: 'User Id',
  },
  LeadDate: {
    id: 'PixelCodes.LeadDate',
    defaultMessage: 'Lead Date',
  },
  PcAdded: {
    id: 'PixelCodes.PcAdded',
    defaultMessage: 'Pixel Code is saved!',
  },
  PcDeleted: {
    id: 'PixelCodes.PcDeleted',
    defaultMessage: 'Pixel Code is deleted!',
  },
  AreYouSure: {
    id: 'PixelCodes.AreYouSure',
    defaultMessage: 'Are you sure you want to delete this pixel code?',
  },
  EnterValidHtml: {
    id: 'PixelCodes.EnterValidHtml',
    defaultMessage: 'Enter a valid html code!',
  },
  InUse: {
    id: 'PixelCodes.InUse',
    defaultMessage: 'Pixel Code is in use. Do you want to continue?',
  },
  ParentPage: {
    id: 'PixelCodes.ParentPage',
    defaultMessage: 'Parent Page',
  },
  QuestionTitle: {
    id: 'PixelCodes.QuestionTitle',
    defaultMessage: 'Question Title',
  },
  QuestionNumber: {
    id: 'PixelCodes.QuestionNumber',
    defaultMessage: 'Question Number',
  },
  QuestionID: {
    id: 'PixelCodes.QuestionID',
    defaultMessage: 'Question ID',
  },
  AnswerNumber: {
    id: 'PixelCodes.AnswerNumber',
    defaultMessage: 'Answer Number',
  },
  AnswerID: {
    id: 'PixelCodes.AnswerID',
    defaultMessage: 'Answer ID',
  },
  AnswerTitle: {
    id: 'PixelCodes.AnswerTitle',
    defaultMessage: 'Answer Title',
  },
  AddParams: {
    id: 'PixelCodes.AddParams',
    defaultMessage: 'Add parameters to your pixel code',
  },
});
