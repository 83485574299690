import React, { Fragment } from 'react';
import { MDBRow, MDBFormInline, MDBInput } from 'mdbreact';

const Tier = ({ formatMessage, messages, credit, triviaState, dispatch }) => (
  <Fragment>
    <MDBRow center style={{ paddingTop: '20px' }}>
      <h5 className="font-weight-bold mb-3">
        {formatMessage(messages.SelectTier)}
      </h5>
    </MDBRow>
    <h6 className="mb-3">
      {`${formatMessage(messages.AvailableCredits)}: ${credit}`}
    </h6>
    <MDBRow center>
      <MDBFormInline>
        <MDBInput
          label={formatMessage(messages.Demo)}
          type="checkbox"
          id="checkbox5"
          value="free"
          checked={triviaState.tier === 'free'}
          onChange={(e) => dispatch({ type: 'set_tier', data: e.target.value })}
        />
        <MDBInput
          label={formatMessage(messages.Paid)}
          type="checkbox"
          id="checkbox6"
          value="credit"
          checked={triviaState.tier === 'credit'}
          onChange={(e) => dispatch({ type: 'set_tier', data: e.target.value })}
        />
      </MDBFormInline>
    </MDBRow>
  </Fragment>
);

export default Tier;
