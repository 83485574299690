import React, { useState, useImperativeHandle, forwardRef } from 'react';

import messages from './messages';
import { FormattedMessage } from 'react-intl';

let TextInput = (
  {
    className = '',
    style,
    type,
    icon,
    label,
    maxLength,
    minLength = 1,
    name,
    value,
    onChange,
    infoText,
    disabled,
    required,
    rows,
    textarea,
  },
  ref
) => {
  const [text, setText] = useState('');
  const [active, setActive] = useState(false);

  if (type === 'textarea') {
    textarea = true;
  }

  useImperativeHandle(ref, () => ({ value: text }));

  let _onChange = (event) => {
    const val = event.target.value;
    if (maxLength && maxLength <= val.length - 1) return;

    setText(val);
    if (onChange) onChange(event);
  };

  const _text = (text || value || '').toString();

  let charsLeft = -1;
  if (maxLength) {
    charsLeft = maxLength - _text.length;
  }
  if (required) {
    required = minLength > _text.length;
  }
  const isActive = active || _text.length > 0;

  return (
    <div className={`input-wrapper ${className}`}>
      <div className="md-form form-group d-block">
        {icon && <i className={`fa fa-${icon} prefix`} />}
        <div
          className="w-100 position-absolute input-label"
          style={{ pointerEvents: 'none' }}
        >
          <label
            className={`w-auto position-relative float-left fadeInLeft ${
              isActive ? 'active' : ''
            } ${disabled ? 'disabled' : ''}`}
          >
            {label}
          </label>
        </div>
        {textarea ? (
          <textarea
            name={name}
            className="md-textarea form-control mb-0 pb-0 w-100"
            rows={rows}
            style={style}
            value={_text}
            onChange={_onChange}
            onFocus={() => setActive(true)}
            onBlur={() => setActive(false)}
            disabled={!!disabled}
          />
        ) : (
          <input
            type="text"
            name={name}
            className="form-control mb-0"
            style={style}
            value={_text}
            onChange={_onChange}
            onFocus={() => setActive(true)}
            onBlur={() => setActive(false)}
            disabled={!!disabled}
          />
        )}
        <div className="form-text mt-0">
          <small className="mb-0 mx-1 float-left text-muted">{infoText}</small>
          <small className="mb-0 mx-1 float-right text-info">
            {charsLeft >= 0 && charsLeft}
          </small>
          <small className="mb-0 mx-1 float-right text-danger">
            {required && <FormattedMessage {...messages.Required} />}
          </small>
        </div>
      </div>
    </div>
  );
};

TextInput = forwardRef(TextInput);

export default TextInput;
