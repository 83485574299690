import React, { useState, useEffect, useCallback } from 'react';
import { injectIntl } from 'react-intl';
import { MDBContainer, Spinner, MDBBtn } from 'mdbreact';
import { getSheethookLogs } from '../../api';
import history from '../../core/history';
import Pagination from '../Pagination';

import messages from './messages';
import SectionWithTitle from '../Common/SectionWithTitle';
import PayloadModal from './PayloadModal';

const SheethookLogs = ({
  intl: { formatMessage, formatDate, formatTime },
  ...props
}) => {
  const [logs, setLogs] = useState([]);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [lastPage, setLastPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(async () => {
    setLoading(true);
    const data = props.location.state.row;
    const r = await getSheethookLogs(data.id, page, sizePerPage);
    setLogs(r.data.data);
    setPage(page);
    setSizePerPage(sizePerPage);
    setLastPage(r.data.last_page);
    setLoading(false);
  }, [page, sizePerPage, props.location.state.row]);

  useEffect(() => {
    const data = props.location.state.row;
    if (data) {
      try {
        fetchData(page, sizePerPage);
      } catch (e) {
        history.push('/webhooks');
      }
    } else {
      history.push('/webhooks');
    }
  }, [props.location.state.row, page, sizePerPage, fetchData]);

  const [modal, setModal] = useState(false);
  const [payload, setPayload] = useState('');

  const openPayloadModal = (e, payload) => {
    if (e) {
      e.preventDefault();
    }
    setPayload(payload);
    setModal((prevModal) => !prevModal);
  };

  const data = props.location.state.row || {};

  return (
    <MDBContainer fluid>
      <PayloadModal
        payload={payload}
        isOpen={modal}
        toggle={openPayloadModal}
        formatMessage={formatMessage}
        messages={messages}
      />
      <SectionWithTitle
        title={`${formatMessage(messages.ViewLogs)}: #${data.id} - ${
          data.name
        }`}
      >
        <div>
          <Pagination
            last={lastPage}
            active={page}
            size={sizePerPage}
            handlePageChange={(page, sizePerPage) => {
              setPage(page);
              setSizePerPage(sizePerPage);
            }}
          />
          <div className="row">
            <div className="col-12">
              {loading ? (
                <Spinner blue big />
              ) : (
                <table
                  className="table table-striped table-bordered table-sm table-fixed-header"
                  style={
                    loading
                      ? { border: 'none', borderTop: '1px solid #dee2e6' }
                      : {}
                  }
                >
                  <thead>
                    <tr>
                      <th className="th-sm">{'ID'}</th>
                      <th className="th-sm">{'Logs'}</th>
                      <th className="th-sm">{'Created At'}</th>
                      <th className="th-sm">{'Health'}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {logs.map((log, i) => {
                      return (
                        <tr key={i}>
                          <td>{log.id}</td>
                          <td>
                            <MDBBtn
                              outline
                              color="primary"
                              size="sm"
                              onClick={(e) => openPayloadModal(e, log.logs)}
                            >
                              {formatMessage(messages.ViewPayload)}
                            </MDBBtn>
                          </td>
                          <td>
                            {`${formatDate(
                              new Date(log.created_at)
                            )} ${formatTime(new Date(log.created_at))}`}
                          </td>
                          <td>
                            <MDBBtn color={log.health || 'grey'} size="sm">
                              {log.health || 'N/A'}
                            </MDBBtn>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </SectionWithTitle>
    </MDBContainer>
  );
};

export default injectIntl(SheethookLogs);
