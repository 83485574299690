import React, { Component, Fragment } from 'react';
import { injectIntl } from 'react-intl';
import {
  Col,
  Row,
  MDBSelect,
  MDBSelectInput,
  MDBSelectOption,
  MDBSelectOptions,
  Select,
  SelectInput,
  SelectOption,
  SelectOptions,
  InputSwitch,
  MDBInput,
  MDBBtn,
  MDBIcon,
} from 'mdbreact';
import ReactTooltip from 'react-tooltip';

import messages from '../messages';
import ImageInput from '../../ImageInput';
import LeadSelector from '../LeadSelector';

class Step1 extends Component {
  state = {
    expanded: false,
  };

  render() {
    const { formatMessage } = this.props.intl;
    const {
      poll,
      handleTextChange,
      setSRC,
      getSRC,
      getSelectValue2,
      handleSwitchChange,
      handleTextChangeWithoutEmbedly,
      endDateEditClick,
      endDateEdited,
      leadSelect,
    } = this.props;

    let remTitleLimit =
      250 -
      (poll && poll.title && poll.title.length && poll.title.length > 0
        ? poll.title.length
        : 0);

    const inputSwitcher = (text, target, value, tooltip) => (
      <Row style={{ marginTop: '1.5rem' }}>
        <Col>
          <label>
            {text}{' '}
            <span data-tip={tooltip}>
              <MDBIcon far icon="question-circle" />
            </span>
            <ReactTooltip effect="solid" multiline={true} />
          </label>
        </Col>
        <Col>
          <InputSwitch
            onChange={(e) => handleSwitchChange(e, target)}
            checked={value}
            labelLeft={formatMessage(messages.Off)}
            labelRight={formatMessage(messages.On)}
          />
        </Col>
      </Row>
    );

    return (
      <Col md="12">
        <Col md="12">
          <MDBInput
            type="textarea"
            maxLength={250}
            label={`${formatMessage(messages.TestTitle)} - ${remTitleLimit}`}
            className="mt-3"
            name="title"
            value={poll.title}
            onChange={handleTextChange}
          />
        </Col>
        <Col md="12">
          <MDBInput
            label={formatMessage(messages.Description)}
            className="mt-3"
            name="desc"
            value={poll.desc}
            onChange={handleTextChange}
            type="textarea"
          />
        </Col>
        <ImageInput
          title={formatMessage(messages.TestImage)}
          setSRC={setSRC}
          getSRC={getSRC}
        />
        <ImageInput
          title={formatMessage(messages.EmbedBg)}
          setSRC={setSRC}
          getSRC={getSRC}
          target={'embed_background'}
        />
        <ImageInput
          title={formatMessage(messages.CustomLogo)}
          ratio={2}
          setSRC={setSRC}
          getSRC={getSRC}
          target={'embed_footer_img'}
        />
        <Col md="12">
          <MDBInput
            type="textarea"
            maxLength={250}
            label={`${formatMessage(messages.FooterLink)}`}
            className="mt-3 mb-1"
            name="embed_footer_url"
            value={poll.embed_footer_url || ''}
            onChange={handleTextChangeWithoutEmbedly}
          />
        </Col>
        <Col md="4">
          <LeadSelector index={0} leadSelect={leadSelect} id={poll.lead_id} />
        </Col>
        {this.state.expanded ? (
          <Fragment>
            <Col md="12">
              <Row style={{ margin: '1.5rem' }}>
                <Col className="text-center">
                  <MDBBtn
                    color="primary"
                    onClick={() =>
                      this.setState({ expanded: !this.state.expanded })
                    }
                  >
                    <strong>{`- ${formatMessage(messages.Collapse)}`}</strong>
                  </MDBBtn>
                </Col>
              </Row>
              {inputSwitcher(
                formatMessage(messages.Hidden),
                'privacy',
                poll.privacy !== 'public',
                formatMessage(messages.HiddenTip)
              )}
              {inputSwitcher(
                formatMessage(messages.DisplayRepeat),
                'display_repeat',
                poll.display_repeat !== 0,
                formatMessage(messages.DisplayRepeatTip)
              )}
              {inputSwitcher(
                formatMessage(messages.HideVoteCounter),
                'hide_counter',
                poll.hide_counter === 0,
                formatMessage(messages.HideVoteCounterTip)
              )}
              {inputSwitcher(
                formatMessage(messages.HidePerc),
                'hide_results',
                poll.hide_results === 1,
                formatMessage(messages.HidePercTip)
              )}
              {inputSwitcher(
                formatMessage(messages.PlayOnce),
                'play_once',
                poll.play_once === 1,
                formatMessage(messages.PlayOnceTip)
              )}
            </Col>
            {poll.play_once ? (
              <Fragment>
                <Row>
                  <Col>
                    <MDBSelect
                      getValue={(val) =>
                        getSelectValue2(val[0], 'play_once_strategy')
                      }
                      label={
                        poll.play_once_strategy === 'result'
                          ? formatMessage(messages.ResultOptionDesc)
                          : formatMessage(messages.StartOptionDesc)
                      }
                    >
                      <MDBSelectInput />
                      <MDBSelectOptions>
                        <MDBSelectOption
                          value={'result'}
                          checked={poll.play_once_strategy === 'result'}
                        >
                          {formatMessage(messages.ResultOption)}
                        </MDBSelectOption>
                        <MDBSelectOption
                          value={'start'}
                          checked={poll.play_once_strategy === 'start'}
                        >
                          {formatMessage(messages.StartOption)}
                        </MDBSelectOption>
                      </MDBSelectOptions>
                    </MDBSelect>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <MDBInput
                      type="text"
                      maxLength={250}
                      label={`${formatMessage(messages.PlayOnceMsg)}`}
                      className="mt-3 mb-1"
                      name="play_once_msg"
                      value={poll.play_once_msg}
                      onChange={handleTextChangeWithoutEmbedly}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <MDBInput
                      type="text"
                      maxLength={250}
                      label={`${formatMessage(messages.PlayOnceLink)}`}
                      className="mt-3 mb-1"
                      name="play_once_link"
                      value={poll.play_once_link}
                      onChange={handleTextChangeWithoutEmbedly}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <MDBInput
                      type="text"
                      maxLength={250}
                      label={`${formatMessage(messages.PlayOnceBtn)}`}
                      className="mt-3 mb-1"
                      name="play_once_btn"
                      value={poll.play_once_btn}
                      onChange={handleTextChangeWithoutEmbedly}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <ImageInput
                      title={formatMessage(messages.PlayOnceImg)}
                      setSRC={setSRC}
                      getSRC={getSRC}
                      target={'play_once_img'}
                    />
                  </Col>
                </Row>
              </Fragment>
            ) : null}
            <Col md="12">
              <Row style={{ marginTop: '1.5rem' }}>
                <Col>
                  <label>
                    {formatMessage(messages.EndDate)}{' '}
                    <span data-tip={formatMessage(messages.EndDateTip)}>
                      <MDBIcon far icon="question-circle" />
                    </span>
                    <ReactTooltip effect="solid" multiline={true} />
                  </label>
                </Col>
                <Col>
                  <InputSwitch
                    onChange={(e) => handleSwitchChange(e, 'end_date')}
                    checked={poll.end_date ? true : false}
                    labelLeft={formatMessage(messages.Off)}
                    labelRight={formatMessage(messages.On)}
                  />
                </Col>
              </Row>
              {poll.end_date ? (
                endDateEdited ? (
                  <Row style={{ marginTop: '1.5rem' }}>
                    <Col md="4">
                      <label>{formatMessage(messages.Days)}:</label>
                      <Select
                        getValue={(val) =>
                          getSelectValue2(val[0], 'end_date_day')
                        }
                      >
                        <SelectInput selected={poll.end_date_day} />
                        <SelectOptions>
                          {[...Array(32).keys()].map((elem, index) => (
                            <SelectOption
                              value={`0${index}`.slice(-2)}
                              key={`day${index}`}
                            >
                              {`0${index}`.slice(-2)}
                            </SelectOption>
                          ))}
                        </SelectOptions>
                      </Select>
                    </Col>
                    <Col md="4">
                      <label>{formatMessage(messages.Hours)}:</label>
                      <Select
                        getValue={(val) =>
                          getSelectValue2(val[0], 'end_date_hour')
                        }
                      >
                        <SelectInput selected={poll.end_date_hour} />
                        <SelectOptions>
                          {[...Array(24).keys()].map((elem, index) => (
                            <SelectOption
                              value={`0${index}`.slice(-2)}
                              key={`hour${index}`}
                            >
                              {`0${index}`.slice(-2)}
                            </SelectOption>
                          ))}
                        </SelectOptions>
                      </Select>
                    </Col>
                    <Col md="4">
                      <label>{formatMessage(messages.Minutes)}:</label>
                      <Select
                        getValue={(val) =>
                          getSelectValue2(val[0], 'end_date_minute')
                        }
                      >
                        <SelectInput selected={poll.end_date_minute} />
                        <SelectOptions>
                          {[...Array(60).keys()].map((elem, index) => (
                            <SelectOption
                              value={`0${index}`.slice(-2)}
                              key={`minute${index}`}
                            >
                              {`0${index}`.slice(-2)}
                            </SelectOption>
                          ))}
                        </SelectOptions>
                      </Select>
                    </Col>
                  </Row>
                ) : (
                  <MDBBtn size="sm" onClick={endDateEditClick}>
                    {formatMessage(messages.EditEndDate)}
                  </MDBBtn>
                )
              ) : null}
            </Col>
          </Fragment>
        ) : (
          <Row style={{ margin: '1.5rem' }}>
            <Col className="text-center">
              <MDBBtn
                color="primary"
                onClick={() =>
                  this.setState({ expanded: !this.state.expanded })
                }
              >
                <strong>{`+ ${formatMessage(messages.Expand)}`}</strong>
              </MDBBtn>
            </Col>
          </Row>
        )}
      </Col>
    );
  }
}

export default injectIntl(Step1);
