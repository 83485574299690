/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Row, Col, Container, Card, CardBody, toast } from 'mdbreact';
import { editPoll, getEditQuestion } from '../../../api';
import history from '../../../core/history';

import Step1 from './Step1';
import messages from '../messages';

const initialState = {
  pollData: {
    title: '',
    answer_type: 'yesno',
    answers: [
      { id: null, title: 'yes', background: '' },
      { id: null, title: 'no', background: '' },
    ],
    require_login: 0,
    privacy: 'private',
    play_once: 0,
    play_once_msg: null,
    play_once_img: null,
    play_once_link: null,
    play_once_btn: null,
    answer_time_limit: -1,
    hide_counter: 0,
    hide_results: 0,
    auto_reports_enabled: 0,
    rotate_answers: 0,
    rotate_answers_last: 0,
    end_date: 1,
    end_date_day: '30',
    end_date_hour: '00',
    end_date_minute: '00',
    allow_multiple_answers: 0,
    max_multi_punch_answer: 0,
    embed_background: '',
    embed_footer_img: '',
  },
  src: null,
  crop: {
    x: 0,
    y: 0,
    width: 50,
    aspect: 9 / 5,
  },
  pixelCrop: null,
  image: null,
  cropped: false,
  createdPollId: null,
  showPoll: true,
  isEmbedly: false,
  endDateEdited: false,
};

class PollEdit extends Component {
  state = initialState;

  async componentDidMount() {
    try {
      const { id } = this.props.match.params;
      const pollResponse = await getEditQuestion(id);
      this.setState({
        createdPollId: id,
        pollData: pollResponse.data,
        src: pollResponse.data.background ? pollResponse.data.background : null,
        cropped: true,
      });
    } catch (e) {
      if (e && e.response && e.response.status === 404) {
        const { formatMessage } = this.props.intl;
        history.push('/content');
        toast.error(formatMessage(messages.NoContentFound));
      }
    }
  }

  pixelCode = (index, pc) => {
    if (pc) {
      this.setState({
        pollData: {
          ...this.state.pollData,
          answers: this.state.pollData.answers.map((a, i) => {
            if (i === index) {
              return {
                ...a,
                pixel_code_id: pc.id,
                pixel_code_name: pc.name,
              };
            }
            return a;
          }),
        },
      });
    }
  };

  leadSelect = (index, lead) => {
    if (lead) {
      this.setState({
        pollData: {
          ...this.state.pollData,
          answers: this.state.pollData.answers.map((a, i) => {
            if (i === index) {
              return {
                ...a,
                lead_id: lead.id,
                lead_name: lead.name,
              };
            }
            return a;
          }),
        },
      });
    }
  };

  handleTextChange = async (e) => {
    const inputText = e.target.value;
    this.setState({
      pollData: {
        ...this.state.pollData,
        [e.target.name]: inputText,
      },
    });
  };

  handleTextChangeWithoutEmbedly = (e) => {
    this.setState({
      pollData: {
        ...this.state.pollData,
        [e.target.name]: e.target.value === '' ? null : e.target.value,
      },
    });
  };

  setSRC = (src, target) => {
    if (target && target.length > 0) {
      this.setState({
        pollData: {
          ...this.state.pollData,
          [target]: src,
        },
      });
    } else {
      this.setState({ src: src });
    }
  };

  getSRC = (target) => {
    if (target && target.length > 0) {
      return this.state.pollData[target];
    }
    return this.state.src;
  };

  getSelectValue = (value, name) => {
    if (value) {
      this.setState({
        pollData: {
          ...this.state.pollData,
          [name]: value,
        },
      });
    }
  };

  getSelectValue2 = (value, name) => {
    if (value === '3' || value === '0' || !value) return;
    this.setState({
      pollData: {
        ...this.state.pollData,
        [name]: value,
      },
    });
  };

  handleAnswerChange = (e, i) => {
    this.setState({
      pollData: {
        ...this.state.pollData,
        answers: this.state.pollData.answers.map((a, index) => {
          if (index === i) {
            return { ...a, title: e.target.value };
          }
          return a;
        }),
      },
    });
  };

  setAnswerImg = (index, value) => {
    const {
      pollData,
      pollData: { answers },
    } = this.state;

    this.setState({
      pollData: {
        ...pollData,
        answers: answers.map((a, i) => {
          if (index === i) {
            return { ...a, background: value };
          }
          return a;
        }),
      },
    });
  };

  changeNumber = (e, direction) => {
    e.preventDefault();
    if (direction === 'add') {
      this.setState({
        pollData: {
          ...this.state.pollData,
          answers: [
            ...this.state.pollData.answers,
            { title: '', background: '' },
          ],
          max_multi_punch_answer: this.state.pollData.answers.length + 1,
        },
      });
    } else if (direction === 'delete') {
      this.setState({
        pollData: {
          ...this.state.pollData,
          answers: this.state.pollData.answers.slice(0, -1),
          max_multi_punch_answer: this.state.pollData.answers.length - 1,
        },
      });
    }
  };

  deleteAnswer = (e, index) => {
    e.preventDefault();
    this.setState({
      pollData: {
        ...this.state.pollData,
        answers: this.state.pollData.answers.filter((a, i) => i !== index),
        max_multi_punch_answer: this.state.pollData.answers.length - 1,
      },
    });
  };

  handleSwitchChange = (e, name) => {
    if (name === 'privacy') {
      this.setState({
        pollData: {
          ...this.state.pollData,
          [name]: e.target.value === 'true' ? 'public' : 'private',
        },
      });
    } else if (name === 'play_once') {
      this.setState({
        pollData: {
          ...this.state.pollData,
          [name]: e.target.checked ? 1 : 0,
        },
      });
    } else if (name === 'rotate_answers') {
      this.setState({
        pollData: {
          ...this.state.pollData,
          [name]: e.target.checked ? 1 : 0,
          rotate_answers_last: 0,
        },
      });
    } else if (name === 'rotate_answers_last') {
      this.setState({
        pollData: {
          ...this.state.pollData,
          [name]: e.target.checked ? 1 : 0,
          rotate_answers: 0,
        },
      });
    } else if (name === 'answer_time_limit') {
      this.setState({
        pollData: {
          ...this.state.pollData,
          [name]: e.target.value === 'false' ? -1 : 0,
        },
      });
    } else if (name === 'hide_counter') {
      this.setState({
        pollData: {
          ...this.state.pollData,
          [name]: e.target.value === 'true' ? 1 : 0,
        },
      });
    } else if (name === 'end_date') {
      this.setState({
        pollData: {
          ...this.state.pollData,
          [name]: e.target.value === 'true' ? 0 : 1,
          end_date_day: e.target.value === 'true' ? '00' : '30',
          end_date_hour: '00',
          end_date_minute: '00',
        },
        endDateEdited: true,
      });
    } else {
      this.setState({
        pollData: {
          ...this.state.pollData,
          [name]: e.target.value === 'true' ? 0 : 1,
        },
      });
    }
  };

  createPoll = async (event) => {
    if (event) event.preventDefault();
    const { formatMessage } = this.props.intl;
    const { pollData, src, isEmbedly, endDateEdited } = this.state;

    this.setState({ showPoll: false });
    if (!endDateEdited) {
      delete pollData.end_date;
      delete pollData.end_date_day;
      delete pollData.end_date_hour;
      delete pollData.end_date_minute;
    }
    try {
      if (isEmbedly) {
        const r = await editPoll(pollData, src);
        this.setState({
          ...initialState,
          createdPollId: r.data.id,
          showPoll: true,
        });
      } else {
        await editPoll(pollData, src);
        history.push('/content');
        toast.success(formatMessage(messages.FinishEditingSuccess));
      }
    } catch (error) {
      console.log(error);
    }
  };

  endDateEditClick = () => {
    this.setState({ endDateEdited: true });
  };

  render() {
    const {
      pollData,
      src,
      createdPollId,
      showPoll,
      isEmbedly,
      endDateEdited,
    } = this.state;
    const { formatMessage } = this.props.intl;

    return (
      <Container>
        <Row>
          <Col md="7">
            <Card>
              <CardBody>
                <h2 className="text-center font-weight-bold pt-4 pb-5">
                  <strong>{formatMessage(messages.EditPoll)}</strong>
                </h2>

                <Row>
                  <Step1
                    handleTextChange={this.handleTextChange}
                    poll={pollData}
                    src={src}
                    setSRC={this.setSRC}
                    getSRC={this.getSRC}
                    getSelectValue={this.getSelectValue}
                    getSelectValue2={this.getSelectValue2}
                    isEmbedly={isEmbedly}
                    handleSwitchChange={this.handleSwitchChange}
                    handleTextChangeWithoutEmbedly={
                      this.handleTextChangeWithoutEmbedly
                    }
                    endDateEdited={endDateEdited}
                    endDateEditClick={this.endDateEditClick}
                    handleAnswerChange={this.handleAnswerChange}
                    changeNumber={this.changeNumber}
                    setAnswerImg={this.setAnswerImg}
                    pixelCode={this.pixelCode}
                    leadSelect={this.leadSelect}
                    deleteAnswer={this.deleteAnswer}
                    createPoll={this.createPoll}
                  />
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col md="5">
            <h2 className="text-center font-weight-bold pt-4 pb-5">
              <strong>{formatMessage(messages.PollPreview)}</strong>
            </h2>
            {showPoll && createdPollId ? (
              <iframe
                src={`//www.poltio.com/e/poll/${createdPollId}`}
                width="100%"
                height="650"
                scrolling="no"
                frameBorder="0"
                title={`poll${createdPollId}`}
              />
            ) : (
              <h6 className="text-center pt-4 pb-5">
                {formatMessage(messages.YourContent)}
              </h6>
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default injectIntl(PollEdit);
