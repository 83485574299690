import React from 'react';
import { MDBContainer, MDBNavbar, MDBNavbarBrand } from 'mdbreact';
import history from '../../core/history';
import logo from '../../images/poltio-logo.svg';

const TermsGG = () => (
  <div>
    <header>
      <MDBNavbar color="indigo" dark expand="md" scrolling fixed="top">
        <MDBNavbarBrand
          style={{ cursor: 'pointer' }}
          to="/"
          onClick={() => {
            history.push('/');
          }}
        >
          <img src={logo} alt="" />
        </MDBNavbarBrand>
      </MDBNavbar>
    </header>
    <MDBContainer>
      <div className="row mt-5 pt-5">
        <div className="col">
          <p>
            Ankete katılmanız halinde, Kişisel Verilerin Korunması Kanunu
            uyarınca alışveriş deneyimi bilgilerinizi ve site üzerinde yer alan
            üyelik ID’nizi anket çalışmasını yapabilmek ve sonrasında sizlere
            sunduğumuz hizmetleri geliştirebilmek amacıyla GittiGidiyor Bilgi
            Teknolojileri San. ve Tic. A.Ş. olarak veri sorumlusu sıfatıyla KVKK
            m.5/1 uyarınca açık rızana dayalı olarak işleyeceğiz ve anketin
            yapılabilmesi için hizmet aldığımız ve yurt içinde ve yurt dışında
            yer alan tedarikçilerimizle açık rızanıza dayalı olarak
            paylaşacağız. Verileriniz, bu anket aracılığıyla elektronik ortamda
            otomatik yöntemlerle elde edilmektedir. KVKK m.11 uyarınca sahip
            olduğunuz haklarınızı kullanmak için bizimle{' '}
            <a
              href="https://yardim.gittigidiyor.net/s/article/Kisisel-Veri-Basvuru"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://yardim.gittigidiyor.net/s/article/Kisisel-Veri-Basvuru
            </a>{' '}
            adresindeki yönlendirmeleri izleyerek iletişime geçebilirsiniz.
          </p>
        </div>
      </div>
    </MDBContainer>
  </div>
);

export default TermsGG;
