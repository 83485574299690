import { defineMessages } from 'react-intl.macro';

export default defineMessages({
  Home: {
    id: 'NavBar.Home',
    defaultMessage: 'Home',
  },
  Content: {
    id: 'NavBar.Content',
    defaultMessage: 'Content',
  },
  PixelCodes: {
    id: 'NavBar.PixelCodes',
    defaultMessage: 'Pixel Codes',
  },
  Leads: {
    id: 'NavBar.Leads',
    defaultMessage: 'Leads',
  },
  Users: {
    id: 'NavBar.Users',
    defaultMessage: 'Users',
  },
  Reports: {
    id: 'NavBar.Reports',
    defaultMessage: 'Reports',
  },
  LivePolling: {
    id: 'NavBar.LivePolling',
    defaultMessage: 'Live Polling',
  },
  Trivia: {
    id: 'NavBar.Trivia',
    defaultMessage: 'Trivia',
  },
  NoNotifications: {
    id: 'NavBar.NoNotifications',
    defaultMessage: 'You have no notifications',
  },
  Settings: {
    id: 'NavBar.Settings',
    defaultMessage: 'Settings',
  },
  Logout: {
    id: 'NavBar.Logout',
    defaultMessage: 'Logout',
  },
  Theme: {
    id: 'NavBar.Theme',
    defaultMessage: 'Theme',
  },
  Webhooks: {
    id: 'NavBar.Webhooks',
    defaultMessage: 'Web Hooks',
  },
  Sheethooks: {
    id: 'NavBar.Sheethooks',
    defaultMessage: 'Sheet Hooks',
  },
  HomeTip: {
    id: 'NavBar.HomeTip',
    defaultMessage:
      'Go to your dashboard to <br/> view your account statistics',
  },
  ContentTip: {
    id: 'NavBar.ContentTip',
    defaultMessage:
      'Click to display, edit and manage <br/> content that you created so far',
  },
  PixelCodesTip: {
    id: 'NavBar.PixelCodesTip',
    defaultMessage:
      'Click to add, edit and control <br/> tracking codes you add to your content',
  },
  LeadsTip: {
    id: 'NavBar.LeadsTip',
    defaultMessage:
      'Click to add, edit and control lead generation forms <br/> (Form Types: Input, Redirect and, Text/Image Display) you add to your content',
  },
  WebhooksTip: {
    id: 'NavBar.WebhooksTip',
    defaultMessage: 'Web Hooks',
  },
  SheethooksTip: {
    id: 'NavBar.SheethooksTip',
    defaultMessage: 'Sheet Hooks',
  },
  ThemeTip: {
    id: 'NavBar.ThemeTip',
    defaultMessage:
      'Customize the look and appearance of your content <br/> by changing colors, fonts and background',
  },
  ReportsTip: {
    id: 'NavBar.ReportsTip',
    defaultMessage:
      'Display and download the data summary <br/> for a particular content',
  },
  LivePollingTip: {
    id: 'NavBar.LivePollingTip',
    defaultMessage:
      'Get instant feedback at any venue. <br/> Create and manage sessions for live polls.',
  },
  TriviaTip: {
    id: 'NavBar.TriviaTip',
    defaultMessage:
      'Create and manage your own trivia game <br/> with customizable options',
  },
});
