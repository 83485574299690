/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { injectIntl } from 'react-intl';
import { MDBCol, MDBContainer, MDBRow, MDBFooter } from 'mdbreact';
import messages from './messages';

const Footer = ({ intl: { formatMessage } }) => (
  <MDBFooter color="blue" className="font-small pt-4" style={{ marginTop: 0 }}>
    <MDBContainer fluid className="text-center text-md-center">
      <MDBRow>
        <MDBCol md="4">
          <h5 className="title">
            <a
              href="https://blog.poltio.com?utm_source=prologin"
              target="_blank"
            >
              {'Blog'}
            </a>
          </h5>
        </MDBCol>
        <MDBCol md="4">
          <h5 className="title">
            <a
              href="https://www.poltio.com/?utm_source=prologinfooter"
              target="_blank"
            >
              {formatMessage(messages.Social)}
            </a>
          </h5>
        </MDBCol>
        <MDBCol md="4">
          <h5 className="title">
            <a href="https://trivia.poltio.com/" target="_blank">
              {formatMessage(messages.Trivia)}
            </a>
          </h5>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
    <div className="footer-copyright text-center py-3">
      <MDBContainer fluid>
        &copy; {new Date().getFullYear()} Copyright:{' '}
        <a href="https://www.poltio.com" target="_blank">
          {' '}
          Poltio.com{' '}
        </a>
      </MDBContainer>
    </div>
  </MDBFooter>
);

export default injectIntl(Footer);
