import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import {
  Container,
  Button,
  TabPane,
  TabContent,
  MDBNav,
  MDBNavItem,
  MDBLink,
  Row,
  Col,
  Stepper,
  Step,
  FormInline,
  MDBBtn,
} from 'mdbreact';
import messages from '../messages';
import PixelCodeSelector from '../PixelCodeSelector';
import LeadSelector from '../LeadSelector';
import ImageInput from '../../ImageInput';
import TextInput from '../../TextInput';

class Step2 extends Component {
  state = {
    activeItem: '1',
  };

  static getDerivedStateFromProps(props) {
    const {
      poll: { answer_type },
    } = props;
    if (answer_type === 'yesno') {
      return { activeItem: '1' };
    } else if (answer_type === 'text') {
      return { activeItem: '2' };
    } else if (answer_type === 'media') {
      return { activeItem: '3' };
    } else if (answer_type === 'score') {
      return { activeItem: '4' };
    }
    return { activeItem: '1' };
  }

  toggle = (e, tab) => e.preventDefault();

  render() {
    const { activeItem } = this.state;
    const { formatMessage } = this.props.intl;
    const {
      poll,
      handleAnswerChange,
      changeNumber,
      setAnswerImg,
      pixelCode,
      leadSelect,
      deleteAnswer,
    } = this.props;

    const charLimit = 255;

    return (
      <Container md="12">
        <MDBNav tabs className="nav-justified">
          <MDBNavItem>
            <MDBLink
              to="#"
              active={activeItem === '1'}
              onClick={(e) => {
                this.toggle(e, '1');
              }}
            >
              {formatMessage(messages.Yesno)}
            </MDBLink>
          </MDBNavItem>
          <MDBNavItem>
            <MDBLink
              to="#"
              active={activeItem === '2'}
              onClick={(e) => {
                this.toggle(e, '2');
              }}
            >
              {formatMessage(messages.Text)}
            </MDBLink>
          </MDBNavItem>
          <MDBNavItem>
            <MDBLink
              to="#"
              active={activeItem === '3'}
              onClick={(e) => {
                this.toggle(e, '3');
              }}
            >
              {formatMessage(messages.Media)}
            </MDBLink>
          </MDBNavItem>
          <MDBNavItem>
            <MDBLink
              to="#"
              active={activeItem === '4'}
              onClick={(e) => {
                this.toggle(e, '4');
              }}
            >
              {formatMessage(messages.Score)}
            </MDBLink>
          </MDBNavItem>
        </MDBNav>
        <TabContent activeItem={activeItem}>
          <TabPane tabId="1">
            {poll.answers.map((answer, i) => (
              <FormInline
                sm="12"
                key={i}
                className="rounded"
                style={{ backgroundColor: '#e4e2e2', marginBottom: '10px' }}
              >
                <div className="col-6">
                  <TextInput
                    name="title"
                    style={{ width: '100%' }}
                    value={answer.title}
                    disabled
                  />
                </div>
                <Col>
                  <Row>
                    <PixelCodeSelector
                      index={i}
                      pixelCode={pixelCode}
                      id={answer.pixel_code_id}
                    />
                  </Row>
                  <Row>
                    <LeadSelector
                      index={i}
                      leadSelect={leadSelect}
                      id={answer.lead_id}
                    />
                  </Row>
                </Col>
              </FormInline>
            ))}
          </TabPane>
          <TabPane tabId="2">
            <Row>
              <Col sm="12">
                {poll.answers.map((answer, i) => (
                  <FormInline
                    sm="12"
                    key={i}
                    className="rounded"
                    style={{
                      backgroundColor: '#e4e2e2',
                      marginBottom: '10px',
                    }}
                  >
                    <div className="col-6">
                      <TextInput
                        label={`${formatMessage(messages.Answer)} ${i + 1}`}
                        maxLenght={charLimit}
                        name={`answer${i}`}
                        onChange={(e) => handleAnswerChange(e, i)}
                        value={answer.title}
                        required
                        textarea
                      />
                    </div>
                    <div className="col-6">
                      <Row>
                        <PixelCodeSelector
                          index={i}
                          pixelCode={pixelCode}
                          id={answer.pixel_code_id}
                        />
                      </Row>
                      <Row>
                        <LeadSelector
                          index={i}
                          leadSelect={leadSelect}
                          id={answer.lead_id}
                        />
                      </Row>
                    </div>
                    <Row>
                      <div className="col-6">
                        <MDBBtn
                          outline
                          color="danger"
                          size="sm"
                          onClick={(e) => deleteAnswer(e, i)}
                          disabled={
                            answer.vote_count > 0 || poll.answers.length < 3
                          }
                        >
                          {formatMessage(messages.Delete)}
                        </MDBBtn>
                      </div>
                    </Row>
                  </FormInline>
                ))}
                <Button
                  color="secondary"
                  onClick={(e) => changeNumber(e, 'add')}
                >
                  {formatMessage(messages.AddAnswer)}
                </Button>
                {poll.answers.length > 2 && (
                  <Button
                    color="secondary"
                    onClick={(e) => changeNumber(e, 'delete')}
                    disabled={
                      poll.answers[poll.answers.length - 1].vote_count > 0
                    }
                  >
                    {formatMessage(messages.DeleteAnswer)}
                  </Button>
                )}
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="3">
            <Row>
              <Col sm="12">
                {poll.answers.map((answer, i) => (
                  <FormInline
                    key={i}
                    className="collapse-media rounded"
                    style={{
                      backgroundColor: '#e4e2e2',
                      marginBottom: '10px',
                    }}
                  >
                    <div className="col-5 p-1">
                      <div className="collapse-media__item">
                        <ImageInput
                          title={formatMessage(messages.AddImage)}
                          setSRC={(s, t) => {
                            setAnswerImg(i, s);
                          }}
                          src={answer.background}
                          answer
                        />
                      </div>
                    </div>
                    <div className="col-7">
                      <TextInput
                        label={`${formatMessage(messages.Answer)} ${i + 1}`}
                        maxLength={charLimit}
                        name={`answer${i}`}
                        onChange={(e) => handleAnswerChange(e, i)}
                        style={{ width: '100%' }}
                        value={answer.title}
                        textarea
                      />
                      <PixelCodeSelector
                        index={i}
                        pixelCode={pixelCode}
                        id={answer.pixel_code_id}
                      />
                      <LeadSelector
                        index={i}
                        leadSelect={leadSelect}
                        id={answer.lead_id}
                      />
                    </div>
                    <div className="col-6">
                      <MDBBtn
                        outline
                        color="danger"
                        size="sm"
                        onClick={(e) => deleteAnswer(e, i)}
                        disabled={
                          answer.vote_count > 0 || poll.answers.length < 3
                        }
                      >
                        {formatMessage(messages.Delete)}
                      </MDBBtn>
                    </div>
                  </FormInline>
                ))}
                <Button
                  color="secondary"
                  onClick={(e) => changeNumber(e, 'add')}
                >
                  {formatMessage(messages.AddAnswer)}
                </Button>
                {poll.answers.length > 2 && (
                  <Button
                    color="secondary"
                    onClick={(e) => changeNumber(e, 'delete')}
                    disabled={
                      poll.answers[poll.answers.length - 1].vote_count > 0
                    }
                  >
                    {formatMessage(messages.DeleteAnswer)}
                  </Button>
                )}
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="4">
            <Row>
              <Col sm="12">
                <Stepper form>
                  <Step form>
                    <a href="#formstep1" onClick={(e) => e.preventDefault()}>
                      <Button color="default" circle>
                        1
                      </Button>
                    </a>
                  </Step>
                  <Step form>
                    <a href="#formstep1" onClick={(e) => e.preventDefault()}>
                      <Button color="default" circle>
                        2
                      </Button>
                    </a>
                  </Step>
                  <Step form>
                    <a href="#formstep1" onClick={(e) => e.preventDefault()}>
                      <Button color="default" circle>
                        3
                      </Button>
                    </a>
                  </Step>
                  <Step form>
                    <a href="#formstep1" onClick={(e) => e.preventDefault()}>
                      <Button color="default" circle>
                        4
                      </Button>
                    </a>
                  </Step>
                  <Step form>
                    <a href="#formstep1" onClick={(e) => e.preventDefault()}>
                      <Button color="default" circle>
                        5
                      </Button>
                    </a>
                  </Step>
                </Stepper>
              </Col>
              <Col>
                {poll.answers.map((answer, i) => (
                  <div
                    style={{ display: 'flex', alignItems: 'center' }}
                    key={i}
                  >
                    <PixelCodeSelector
                      index={i}
                      pixelCode={pixelCode}
                      id={answer.pixel_code_id}
                    />
                    <LeadSelector
                      index={i}
                      leadSelect={leadSelect}
                      id={answer.lead_id}
                    />
                  </div>
                ))}
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </Container>
    );
  }
}

export default injectIntl(Step2);
