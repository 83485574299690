import { defineMessages } from 'react-intl.macro';

export default defineMessages({
  Id: {
    id: 'Webhooks.Id',
    defaultMessage: 'ID',
  },
  Name: {
    id: 'Webhooks.Name',
    defaultMessage: 'Name',
  },
  Url: {
    id: 'Webhooks.Url',
    defaultMessage: 'Url',
  },
  IsActive: {
    id: 'Webhooks.IsActive',
    defaultMessage: 'Is Active',
  },
  WebhookAdded: {
    id: 'Webhooks.WebhookAdded',
    defaultMessage: 'Webhook Added',
  },
  Yes: {
    id: 'Webhooks.Yes',
    defaultMessage: 'Yes',
  },
  No: {
    id: 'Webhooks.No',
    defaultMessage: 'No',
  },
  Loading: {
    id: 'Webhooks.Loading',
    defaultMessage: 'Loading...',
  },
  Edit: {
    id: 'Webhooks.Edit',
    defaultMessage: 'Edit',
  },
  ErrorSaving: {
    id: 'Webhooks.ErrorSaving',
    defaultMessage: 'Error Saving Web Hook',
  },
  AddNew: {
    id: 'Webhooks.AddNew',
    defaultMessage: 'Add New Web Hook',
  },
  Save: {
    id: 'Webhooks.Save',
    defaultMessage: 'Save',
  },
  Delete: {
    id: 'Webhooks.Delete',
    defaultMessage: 'Delete',
  },
  ViewLogs: {
    id: 'Webhooks.ViewLogs',
    defaultMessage: 'View Logs',
  },
  Webhooks: {
    id: 'Webhooks.Webhooks',
    defaultMessage: 'Web Hooks',
  },
  AreYouSure: {
    id: 'Webhooks.AreYouSure',
    defaultMessage: 'Are you sure you want to delete this web hook?',
  },
  Deleted: {
    id: 'Webhooks.WebhookDeleted',
    defaultMessage: 'Webhook Deleted',
  },
  StatusSet: {
    id: 'Webhooks.StatusSet',
    defaultMessage: 'Webhook Status Set',
  },
  ViewPayload: {
    id: 'Webhooks.ViewPayload',
    defaultMessage: 'View Payload',
  },
  HookDesc: {
    id: 'Webhooks.HookDesc',
    defaultMessage:
      'Poltio will send a POST request with a JSON body to your URL with the following format.',
  },
  Info: {
    id: 'Webhooks.Info',
    defaultMessage: 'Info',
  },
  Close: {
    id: 'Webhooks.Close',
    defaultMessage: 'Close',
  },
  SeeAll: {
    id: 'Webhooks.SeeAll',
    defaultMessage: 'See All Hooks',
  },
  EditWebhook: {
    id: 'Webhooks.EditWebhook',
    defaultMessage: 'Edit Web Hook',
  },
  Show: {
    id: 'Webhooks.Show',
    defaultMessage: 'Show',
  },
  EnterId: {
    id: 'Webhooks.EnterId',
    defaultMessage: 'Enter Id',
  },
  Type: {
    id: 'Webhooks.Type',
    defaultMessage: 'Type',
  },
  ID: {
    id: 'Webhooks.ID',
    defaultMessage: 'ID',
  },
  Delay: {
    id: 'Webhooks.Delay',
    defaultMessage: 'Delay (s)',
  },
  SendAnswers: {
    id: 'Webhooks.SendAnswers',
    defaultMessage: 'Send Answers',
  },
  SendAnswersTip: {
    id: 'Webhooks.SendAnswersTip',
    defaultMessage: 'Send all the votes with the data',
  },
  SendLeads: {
    id: 'Webhooks.SendLeads',
    defaultMessage: 'Send Leads',
  },
  SendLeadsTip: {
    id: 'Webhooks.SendLeadsTip',
    defaultMessage: 'Send all user input leads with the data',
  },
  IncompleteSend: {
    id: 'Webhooks.IncompleteSend',
    defaultMessage: 'Send Incomplete',
  },
  IncompleteSendTip: {
    id: 'Webhooks.IncompleteSendTip',
    defaultMessage: 'Triggers webhook even if the user did not see the result',
  },
  IncompleteDelay: {
    id: 'Webhooks.IncompleteDelay',
    defaultMessage: 'Incomplete Delay (s)',
  },
  IncompleteDelaySmaller: {
    id: 'Webhooks.IncompleteDelaySmaller',
    defaultMessage: 'Incomplete delay cannot be smaller than delay or 60!',
  },
  NameEmpty: {
    id: 'Webhooks.NameEmpty',
    defaultMessage: 'Name cannot be empty!',
  },
  IncompleteDelayDesc: {
    id: 'Webhooks.IncompleteDelayDesc',
    defaultMessage:
      'Delay in seconds between set start and webhook fire. For example if you set this field as 180, Poltio will send votes up till 180 seconds after your visitor starts the set. If that visitor will complete the set after 180 seconds, you will also recieve another webhook event for that.',
  },
});
