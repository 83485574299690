/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { toast, Spinner } from 'mdbreact';
import { getVoteSources } from '../../api';
import history from '../../core/history';

import messages from './messages';
import Pagination from '../Pagination';

import SectionWithTitle from '../Common/SectionWithTitle';

class VoteSourcesReport extends Component {
  state = {
    id: null,
    type: null,
    votes: [],
    totalSize: 25,
    page: 1,
    sizePerPage: 25,
    fetching: false,
    downloading: false,
    loading: true,
  };

  async componentDidMount() {
    const { id, type } = this.props;
    this.setState({ id, type });

    if (id && type) {
      try {
        await this.fetchData(this.state.page, this.state.sizePerPage, id, type);
        this.setState({ loading: false });
      } catch (e) {
        history.push('/reports', { id, type });
      }
    }
  }

  fetchData = async (
    page = this.state.page,
    sizePerPage = this.state.sizePerPage,
    idParam,
    typeParam
  ) => {
    const { id, type } = this.state;
    if (!id && !idParam) return;

    this.setState({ fetching: true });
    try {
      const r = await getVoteSources(
        id || idParam,
        type || typeParam,
        page,
        sizePerPage
      );
      this.setState({
        votes: r.data.data,
        totalSize: r.data.total,
        page,
        sizePerPage,
        fetching: false,
      });
    } catch (e) {
      toast.error('Error occured!');
      this.setState({
        votes: [],
        totalSize: 100,
        page: 1,
        sizePerPage: 100,
        fetching: false,
      });
    }
  };

  handlePageChange = (page, sizePerPage) => {
    this.fetchData(page, sizePerPage);
  };

  render() {
    const { formatMessage } = this.props.intl;
    const { votes, loading, fetching } = this.state;

    return (
      <SectionWithTitle title={formatMessage(messages.VoteSources)}>
        {(loading || fetching) && votes.length === 0 && <Spinner big blue />}
        {!loading && (
          <div>
            <Pagination
              last={this.state.lastPage}
              active={this.state.page}
              size={this.state.sizePerPage}
              handlePageChange={this.handlePageChange}
            />
            <div className="row">
              {loading || fetching ? (
                <Spinner big blue />
              ) : (
                <div className="col-12">
                  <table className="table table-striped table-bordered table-sm table-fixed-header">
                    <thead>
                      <tr>
                        <th className="th-sm">{'Vote Count'}</th>
                        <th className="th-sm">{'Source'}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {votes.map((vote, i) => {
                        let source = vote.source;
                        if (source.startsWith('https://www.poltio.com')) {
                          source = source.substring(22);
                        }

                        return (
                          <tr key={i}>
                            <td>{vote.vote_count}</td>
                            <td>{source}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        )}
      </SectionWithTitle>
    );
  }
}

export default injectIntl(VoteSourcesReport);
