import React from 'react';
import { MDBRow, MDBCol } from 'mdbreact';

const Info = ({ messages, formatMessage, triviaState, socketData }) => (
  <MDBCol>
    <MDBRow center>
      <h5 className="font-weight-bold mb-3">
        {`${formatMessage(messages.Url)}: ${
          triviaState.domain || 'trivia.poltio.com'
        }`}
      </h5>
    </MDBRow>
    <MDBRow center>
      <h5 className="font-weight-bold mb-3">
        {`${formatMessage(messages.BoardUrl)}: ${
          triviaState.domain || 'trivia.poltio.com'
        }/board/${triviaState.id}`}
      </h5>
    </MDBRow>
    <MDBRow center>
      <h5 className="font-weight-bold mb-3">
        {`${formatMessage(messages.Code)}: ${triviaState.id}`}
      </h5>
    </MDBRow>
    <MDBRow center>
      <h5 className="font-weight-bold mb-3">
        {`${formatMessage(messages.Mode)}: ${triviaState.mode || 'normal'}`}
      </h5>
    </MDBRow>
    <MDBRow center>
      <h5 className="font-weight-bold mb-3">
        {`${formatMessage(messages.PeopleRegistered)}: ${
          socketData.player_count
        }`}
      </h5>
    </MDBRow>
    <MDBRow center>
      <h5 className="font-weight-bold mb-3">
        {`${formatMessage(messages.ConnectedPlayers)}: ${
          socketData.connected_player
        }`}
      </h5>
    </MDBRow>
  </MDBCol>
);

export default Info;
