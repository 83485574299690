/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { Spinner, MDBInput } from 'mdbreact';
import api from '../../api/client';
import DatePicker from 'react-datepicker';
import dayjs from 'dayjs';
import history from '../../core/history';

import messages from './messages';

import SectionWithTitle from '../Common/SectionWithTitle';
import { addDays } from 'date-fns';

const ContentStats = ({ intl }) => {
  const d = new Date();
  d.setMonth(d.getMonth() - 1);
  const [startDate, setStartDate] = useState(d);
  const [endDate, setEndDate] = useState(new Date());
  const [query, setQuery] = useState(10);
  const [take, setTake] = useState(10);
  const [contents, setContents] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timeOutId = setTimeout(() => setTake(query), 500);
    return () => clearTimeout(timeOutId);
  }, [query]);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      if (!startDate || !endDate) {
        return;
      } else {
        const { data } = await api.get(
          `/account-summary?start=${dayjs(startDate).format(
            'YYYY-MM-DD'
          )}&end=${dayjs(endDate).format('YYYY-MM-DD')}&take=${take}`
        );
        setContents(data);
        setLoading(false);
      }
    }
    fetchData();
  }, [endDate, startDate, take]);

  return (
    <SectionWithTitle title={intl.formatMessage(messages.ContentStats)}>
      <div className="row">
        <div className="col-4">{intl.formatMessage(messages.StartDate)}</div>
        <div className="col-4">{intl.formatMessage(messages.EndDate)}</div>
        <div className="col-4">
          {intl.formatMessage(messages.NumberOfContent)}
        </div>
      </div>
      <div className="row">
        <div className="col-4">
          <DatePicker
            className="form-control"
            customInput={<MDBInput style={{ width: '20rem' }} />}
            locale={intl.locale.substring(0, 2)}
            selected={startDate}
            disabledKeyboardNavigation
            maxDate={addDays(endDate, 0)}
            onChange={(date) => {
              setStartDate(date);
            }}
          />
        </div>

        <div className="col-4">
          <DatePicker
            className="form-control"
            customInput={<MDBInput style={{ width: '20rem' }} />}
            locale={intl.locale.substring(0, 2)}
            selected={endDate}
            disabledKeyboardNavigation
            maxDate={addDays(new Date(), 0)}
            onChange={(date) => {
              setEndDate(date);
            }}
          />
        </div>
        <div className="col-4">
          <MDBInput
            style={{ width: '20rem' }}
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
        </div>
      </div>
      {loading ? (
        <Spinner big blue />
      ) : (
        <div>
          <div className="row">
            <div className="col-12">
              <table className="table table-striped table-bordered table-sm table-fixed-header">
                <thead>
                  <tr>
                    <th className="th-sm">{'Type'}</th>
                    <th className="th-sm">{'ID'}</th>
                    <th className="th-sm">{'Vote Count'}</th>
                    <th className="th-sm">{'Voter Count'}</th>
                    <th className="th-sm">{'Start Count'}</th>
                    <th className="th-sm">{'Finish Count'}</th>
                    <th className="th-sm">{'Completion Rate'}</th>
                    <th className="th-sm">{'Title'}</th>
                    <th className="th-sm">{'Created At'}</th>
                  </tr>
                </thead>
                <tbody>
                  {contents.map((c, i) => (
                    <tr key={i}>
                      <td>{c.content_type}</td>
                      <td style={{ textDecoration: 'underline' }}>
                        <a
                          href="#"
                          onClick={() =>
                            history.push(
                              `/content-detail/${c.content_type}/${c.content_id}`
                            )
                          }
                        >
                          {c.content_id}
                        </a>
                      </td>
                      <td>{c.vote_count}</td>
                      <td>{c.voter_count}</td>
                      <td>{c.start_count}</td>
                      <td>{c.finish_count}</td>
                      <td>
                        {c.content_type === 'poll'
                          ? '-'
                          : `${(
                              (c.finish_count / (c.start_count || 1)) *
                              100
                            ).toFixed(0)}%`}
                      </td>
                      <td>{c.content.title}</td>
                      <td>{`${intl.formatDate(
                        c.content.created_at
                      )} ${intl.formatTime(c.content.created_at)}`}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </SectionWithTitle>
  );
};

export default injectIntl(ContentStats);
